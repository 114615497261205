import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetUser, selectUser } from "../../slices/user";
import AdminDesktopHeader from "../AdminDesktopHeader";
import { FaSignInAlt, FaSignOutAlt, FaFileDownload } from "react-icons/fa";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import api, { GetLogbookRegister } from "../../api";
import { showError } from "../../utils/helper";
import { resetToken, selectToken } from "../../slices/token";
import moment from "moment";
import "moment/locale/es";
import { BsFillCheckCircleFill } from "react-icons/bs";
// import ModalPhotoEnlargeTwo from "../ModalPhotoEnlargeTwo";
import SearchInputOutlined from "../SearchInputOutlined";
import { CSVLink } from "react-csv";
import { GoCircleSlash } from "react-icons/go";
import AdminDesktopDropView from "../AdminDesktopDropView/AdminDesktopDropView";
import AdminDesktopRegisterDetails from "../AdminDesktopRegisterDetails/AdminDesktopRegisterDetails";
import ButtonDatePicker from "../ButtonDatePicker/ButtonDatePicker";

interface FileExport {
  fecha_hora: string;
  evento: string;
  tipo_de_acceso: string;
  punto_de_acceso: string;
  nombre_del_visitante: string;
  anfitrión: string;
  departamento: string;
  tipo_de_registro: string;
  fotos: string;
}

const AdminDesktopLogbookContent = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [accessServerFilter, setAccessServerFilter] = React.useState<string>(
    labels.all
  );
  const [accessServer, setAccessServer] = React.useState<number | null>(null);
  const [dateRefactor, setDateRefactor] = React.useState<string>("all");
  const [date, setDate] = React.useState<Date | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [logbookList, setLogbookList] =
    React.useState<GetLogbookRegister | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<
    "access" | "exit" | null
  >(null);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  // const [showModal, setShowModal] = React.useState<boolean>(false);
  // const [transactionPhotos, setTransactionPhotos] = React.useState<any[]>([]);
  const [fileArray, setFileArray] = React.useState<null | FileExport[]>(null);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [transactionId, setTransactionId] = React.useState<number | null>(null);

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const currentDate = new Date();
  const fileName = `${labels.logbook} - ${moment(currentDate).format("LL")}`;

  const debouncedSearchText = useDebounce(searchInput, 300);

  React.useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add = tableHeaderHeight + tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
  }, []);

  const handleAccessServerFilterChange = (event: SelectChangeEvent) => {
    const filteredAccessServer = user?.access_servers.filter(
      ({ name }) => name === event.target.value
    );
    setAccessServerFilter(event.target.value);
    if (event.target.value === labels.all) {
      setAccessServer(null);
    } else {
      if (filteredAccessServer) {
        setAccessServer(filteredAccessServer[0]?.id);
      }
    }
  };

  React.useEffect(() => {
    setPage(1);
  }, [date, accessServer, debouncedSearchText, statusFilter, rowsPerPage]);

  const dateChange = (event: SelectChangeEvent) => {
    setDateRefactor(event.target.value);
    if (event.target.value === "all") {
      setDate(null);
    }
    if (event.target.value === "today") {
      setDate(new Date());
    }
    if (event.target.value === "calendar") {
      setDate(null);
    }
  };

  const onMouseOverFuncIcons = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.color = "#666";
  };

  const onMouseOutFuncIcons = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.color = "";
  };

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  const onMouseOverFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  // END POINT TRANSACTIONS LIST--------------------------------------------------------------------------->>
  // END POINT LISTA DE TRANSACCIONES---------------------------------------------------------------------->>
  React.useEffect(() => {
    getLogbookListData(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    page,
    rowsPerPage,
    date,
    accessServer,
    debouncedSearchText,
    statusFilter,
  ]);

  const getLogbookListData = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.logbookRequestTwo(
          page,
          rowsPerPage,
          date !== null ? moment(date).format("YYYY-MM-DD") : "",
          accessServer !== null ? accessServer : "",
          debouncedSearchText,
          statusFilter !== null ? statusFilter : ""
        );
        setLogbookList(result);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getLogbookListForFile(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    date,
    accessServer,
    debouncedSearchText,
    statusFilter,
    user?.last_access_selected,
  ]);

  const getLogbookListForFile = async (debouncedSearchText: string) => {
    try {
      if (token) {
        const result = await api.logbookRequestTwo(
          1,
          100,
          date !== null ? moment(date).format("YYYY-MM-DD") : "",
          accessServer !== null ? accessServer : "",
          debouncedSearchText,
          statusFilter !== null ? statusFilter : ""
        );
        setFileArray(
          result.transactions.map(
            ({
              user,
              transaction_photos,
              created_at,
              access_type,
              access_server,
              visitor_name,
              access_key_type,
              access_allowed,
            }) => ({
              fecha_hora: moment(created_at).format("ddd, D MMM YY - H:mma"),
              evento: access_type === "exit" ? "salida" : "entrada",
              tipo_de_acceso:
                access_allowed === true ? "autorizado" : "no autorizado",
              punto_de_acceso: access_server?.name,
              nombre_del_visitante: visitor_name,
              anfitrión: user?.name,
              departamento: user?.group,
              tipo_de_registro: access_key_type,
              fotos: transaction_photos.map(({ url }) => url).join(", "),
            })
          )
        );
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <AdminDesktopHeader
        title={"Bitácora de entradas/salidas"}
        buttonLabel={""}
        refObject={headerRef}
      />
      <div
        ref={filtersRef}
        style={{
          width: "100%",
          boxSizing: "border-box",
          minHeight: 60,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "25%" }}>
          <FormControl fullWidth>
            <Select
              size="small"
              sx={{ bgcolor: "white" }}
              style={{ color: "#10418a" }}
              value={accessServerFilter}
              onChange={handleAccessServerFilterChange}
            >
              <MenuItem value={labels.all}>{labels.all_access_points}</MenuItem>
              {user?.access_servers?.map(({ name, id }) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title={labels.entries} arrow placement="top">
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (statusFilter === "access") {
                  setStatusFilter(null);
                } else {
                  setStatusFilter("access");
                }
              }}
            >
              <FaSignInAlt
                fontSize={22}
                color={statusFilter === "access" ? "#10418a" : "#999999"}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
          <Tooltip title={labels.exits} arrow placement="top">
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (statusFilter === "exit") {
                  setStatusFilter(null);
                } else {
                  setStatusFilter("exit");
                }
              }}
            >
              <FaSignOutAlt
                fontSize={22}
                color={statusFilter === "exit" ? "#10418a" : "#999999"}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
        </div>
        <div style={{ width: "25%" }}>
          {dateRefactor !== "calendar" ? (
            <FormControl fullWidth>
              <Select
                size="small"
                sx={{ bgcolor: "white", borderColor: "blue" }}
                style={{ color: "#10418a" }}
                value={dateRefactor}
                onChange={dateChange}
              >
                <MenuItem value={"all"}>{labels.all_the_dates}</MenuItem>
                <MenuItem value={"today"}>{labels.today}</MenuItem>
                <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <div className="flex flex-dir-row justify-content-space-between align-items-center">
              <div style={{ width: "47.5%" }}>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    sx={{ bgcolor: "white", borderColor: "blue" }}
                    style={{ color: "#10418a" }}
                    value={dateRefactor}
                    onChange={dateChange}
                  >
                    <MenuItem value={"all"}>{labels.all_the_dates}</MenuItem>
                    <MenuItem value={"today"}>{labels.today}</MenuItem>
                    <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className="flex justify-content-flex-end"
                style={{ width: "47.5%" }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ButtonDatePicker
                      label={`${
                        date === null
                          ? labels.choose
                          : moment(date).format("DD/MMM")
                      }`}
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                    />
                  </LocalizationProvider>
                  {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      value={date}
                      onChange={(newDate: Date | null) => {
                        setDate(newDate);
                      }}
                      inputFormat="DD/MMM"
                      className="primary-color"
                      renderInput={({ inputRef, inputProps }) => (
                        <input
                          type={"button"}
                          ref={inputRef}
                          {...inputProps}
                          className={
                            date !== null
                              ? "m-0 p-0 py-4 date-filter font-14 primary-color"
                              : "m-0 p-0 py-4 date-filter-null font-14"
                          }
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                  {/* {date === null && (
                    <div className="no-date-filter font-14 primary-color">
                      {labels.choose}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ width: "35%", marginLeft: 10 }}>
          <SearchInputOutlined
            value={searchInput}
            setValue={setSearchInput}
            size="small"
          />
        </div>
        <div style={{ marginRight: 6, marginLeft: 20 }}>
          {fileArray && fileArray?.length > 0 ? (
            <CSVLink data={fileArray} filename={fileName}>
              <Tooltip title="Descargar tabla" arrow placement="top-start">
                <div>
                  <FaFileDownload
                    fontSize={32}
                    color="#10418a"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Tooltip>
            </CSVLink>
          ) : (
            <FaFileDownload fontSize={32} color="#999999" />
          )}
        </div>
      </div>
      <div
        style={{
          height: `calc(100% - ${mainContentHeight}px - 25px)`,
          backgroundColor: "white",
          boxSizing: "border-box",
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 4,
          position: "relative",
          boxShadow:
            "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          overflow: "auto",
        }}
      >
        <div ref={tableHeader} style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                height: "auto",
                backgroundColor: "#e8e8e8",
              }}
            >
              <tr>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.date}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "7.5%",
                  }}
                >
                  {labels.hour}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.type}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "16%",
                  }}
                >
                  {labels.visitor}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "16%",
                  }}
                >
                  {labels.user}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "13%",
                  }}
                >
                  {labels.access_point}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "12.5%",
                  }}
                >
                  {labels.created_by}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "7.5%",
                  }}
                >
                  {labels.type}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "7.5%",
                  }}
                >
                  {labels.access_table}
                </th>
                {/* <th
                  style={{ paddingTop: 16, paddingBottom: 16, width: "7.5%" }}
                >
                  <BsFillCameraFill fontSize={20} />
                </th> */}
              </tr>
            </thead>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containersHeight}px)`,
            overflowY: "auto",
            backgroundColor: "#f8f8f8",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table style={{ width: "100%", tableLayout: "fixed" }}>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
              >
                {logbookList?.transactions &&
                logbookList.transactions.length > 0 ? (
                  logbookList.transactions.map(
                    ({
                      id,
                      visitor_name,
                      access_allowed,
                      access_granted,
                      user,
                      access_type,
                      created_at,
                      event_hour,
                      access_server,
                      access_key_type,
                      created_by_user,
                    }) => (
                      <tr
                        key={id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setTransactionId(id);
                          setShowDetails(true);
                        }}
                        onMouseOver={onMouseOverFunc}
                        onMouseLeave={onMouseOutFunc}
                      >
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "10%",
                          }}
                        >
                          {moment(created_at).format("DD/MMM/YY")}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            textAlign: "center",
                            width: "7.5%",
                          }}
                        >
                          {moment(created_at).format("hh:mma")}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          {access_key_type === "visit" && (
                            <p className="p-0 m-0 font-14">{labels.visitor}</p>
                          )}
                          {access_key_type === "provider" && (
                            <p className="p-0 m-0 font-14">{labels.provider}</p>
                          )}
                          {access_key_type === "personal" && (
                            <p className="p-0 m-0 font-14">{labels.user}</p>
                          )}
                          {access_key_type === "temporary" && (
                            <p className="p-0 m-0 font-14">
                              {labels.temporary}
                            </p>
                          )}
                          {access_key_type === "visitor" && (
                            <p className="p-0 m-0 font-14">{labels.visitor}</p>
                          )}
                          {access_key_type === "eventbrite" && (
                            <p className="p-0 m-0 font-14">{labels.event}</p>
                          )}
                          {access_key_type === "passtrack_event" && (
                            <p className="p-0 m-0 font-14">{labels.event}</p>
                          )}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "16%",
                          }}
                        >
                          {visitor_name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "16%",
                          }}
                        >
                          {user?.name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "13%",
                          }}
                        >
                          {access_server?.name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            width: "12.5%",
                          }}
                        >
                          {created_by_user?.name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            textAlign: "center",
                            width: "7.5%",
                          }}
                        >
                          {access_type === "access" ? (
                            <Tooltip title="Entrada" arrow placement="top">
                              <div>
                                <FaSignInAlt fontSize={20} color="#999" />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Salida" arrow placement="top">
                              <div>
                                <FaSignOutAlt fontSize={20} color="#999" />
                              </div>
                            </Tooltip>
                          )}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            textAlign: "center",
                            width: "7.5%",
                          }}
                        >
                          {access_allowed !== false &&
                          access_granted !== false ? (
                            <Tooltip title="Autorizado" arrow placement="top">
                              <div>
                                <BsFillCheckCircleFill
                                  fontSize={20}
                                  color="#458747"
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="No autorizado"
                              arrow
                              placement="top"
                            >
                              <div>
                                <GoCircleSlash fontSize={20} color="#b94a48" />
                              </div>
                            </Tooltip>
                          )}
                        </td>
                        {/* <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: 10,
                            textAlign: "center",
                            width: "7.5%",
                          }}
                        >
                          {transaction_photos.length > 0 && (
                            <Tooltip
                              title="Ver fotografía(s)"
                              arrow
                              placement="top-start"
                            >
                              <div>
                                <BsImageFill
                                  fontSize={20}
                                  color="#10418a"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setTransactionPhotos(transaction_photos);
                                    setShowModal(true);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          )}
                          {transaction_photos.length > 0
                            ? transaction_photos.length
                            : labels.na}
                        </td> */}
                      </tr>
                    )
                  )
                ) : (
                  <tr className="flex justify-content-center py-7">
                    <td>{labels.no_results_found}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div
          ref={tableFooter}
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#e8e8e8",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex align-items-center ml-8 mr-4">
            <p className="pr-4 primary-color">{labels.rows}</p>
            <FormControl
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                width: 100,
              }}
            >
              <Select
                value={rowsPerPage}
                style={{ color: "#10418a" }}
                size="small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                }}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div>
          {logbookList && (
            <div className="flex" style={{ alignItems: "center" }}>
              <div className="flex mx-2">
                <p className="primary-color">
                  {labels.page} <span className="primary-color">{page}</span>{" "}
                  {labels.of}{" "}
                  <span className="primary-color">
                    {logbookList?.total_pages}
                  </span>
                </p>
              </div>
              <div className="mx-2">
                <IconButton
                  color="primary"
                  disabled={page <= 1}
                  onClick={handleChangeDownPage}
                >
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={page >= logbookList?.total_pages}
                  onClick={handleChangeUpPage}
                >
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminDesktopDropView
        showView={showDetails}
        setShowView={setShowDetails}
        content={<AdminDesktopRegisterDetails id={transactionId} />}
        title={labels.register_details}
        extraFunc={false}
      />
      {/* <ModalPhotoEnlargeTwo
        showModal={showModal}
        setShowModal={setShowModal}
        transactionPhoto={transactionPhotos}
      /> */}
    </div>
  );
};

export default AdminDesktopLogbookContent;
