import React from "react";
import "./admin-desktop-register-details.css";
import { CircularProgress, Divider } from "@mui/material";
import moment from "moment-timezone";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaCarAlt, FaIdCard, FaUser, FaLaptop } from "react-icons/fa";
import api, { GetLogbookRegisterTransaction } from "../../api";
import { showError } from "../../utils/helper";
import { resetToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { IoIosCloseCircle } from "react-icons/io";
import { MdSubtitles } from "react-icons/md";
import { BACK_ID_NETWORKS } from "../../utils/constants";

interface AdminDesktopRegisterDetailsParams {
  id: number | null;
}

const AdminDesktopRegisterDetails = ({
  id,
}: AdminDesktopRegisterDetailsParams) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transactionId, setTransactionId] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [findTransactionId, setFindTransactionId] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [transactionPhoto, setTransactionPhoto] = React.useState<string>("");
  const [photoType, setPhotoType] = React.useState<
    "id" | "front_plates" | "back_plates" | "other" | "accreditation" | ""
  >("");

  const customAttributesArray: any[] = transactionId?.custom_attrs
    ? Object.entries(transactionId?.custom_attrs)
    : [];

  React.useEffect(() => {
    getInitialPhotoType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, transactionId, findTransactionId]);

  const getInitialPhotoType = () => {
    if (findTransactionId && transactionId?.access_type === "exit") {
      const idTransactionPhoto = findTransactionId?.transaction_photos?.find(
        ({ access_server_photo_type }) => access_server_photo_type === "id"
      );
      const frontPlatesTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "front_plates"
        );
      const backPlatesTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "back_plates"
        );
      const otherTransactionPhoto = findTransactionId?.transaction_photos?.find(
        ({ access_server_photo_type }) => access_server_photo_type === "other"
      );
      const accreditationTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "accreditation"
        );
      if (idTransactionPhoto) {
        setPhotoType("id");
      } else if (frontPlatesTransactionPhoto) {
        setPhotoType("front_plates");
      } else if (backPlatesTransactionPhoto) {
        setPhotoType("back_plates");
      } else if (otherTransactionPhoto) {
        setPhotoType("other");
      } else if (accreditationTransactionPhoto) {
        setPhotoType("accreditation");
      }
    } else {
      if (transactionId && transactionId?.access_type === "access") {
        const idTransactionPhoto = transactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) => access_server_photo_type === "id"
        );
        const frontPlatesTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "front_plates"
          );
        const backPlatesTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "back_plates"
          );
        const otherTransactionPhoto = transactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) => access_server_photo_type === "other"
        );
        const accreditationTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "accreditation"
          );
        if (idTransactionPhoto) {
          setPhotoType("id");
        } else if (frontPlatesTransactionPhoto) {
          setPhotoType("front_plates");
        } else if (backPlatesTransactionPhoto) {
          setPhotoType("back_plates");
        } else if (otherTransactionPhoto) {
          setPhotoType("other");
        } else if (accreditationTransactionPhoto) {
          setPhotoType("accreditation");
        }
      }
    }
  };

  React.useEffect(() => {
    getTransactionPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoType, findTransactionId, transactionId]);

  const getTransactionPhoto = () => {
    if (findTransactionId && transactionId?.access_type === "exit") {
      const filteredTransactionPhoto =
        findTransactionId?.transaction_photos?.filter(
          ({ access_server_photo_type }) =>
            access_server_photo_type === photoType
        );
      setTransactionPhoto(filteredTransactionPhoto[0]?.url);
    } else {
      if (transactionId && transactionId?.access_type === "access") {
        const filteredTransactionPhoto =
          transactionId?.transaction_photos?.filter(
            ({ access_server_photo_type }) =>
              access_server_photo_type === photoType
          );
        setTransactionPhoto(filteredTransactionPhoto[0]?.url);
      }
    }
  };

  // END POINT GET TRANSACTION ID-------------------------------------------------------------------------->>
  // END POINT TOMAR ID DE TRANSACCION--------------------------------------------------------------------->>
  React.useEffect(() => {
    if (id) {
      getTransactionId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getTransactionId = async () => {
    try {
      setIsLoading(true);
      const result = await api.transactionIdRequest(Number(id));
      setTransactionId(result);
      if (result?.related_transaction_id) {
        const resultRelatedTransaction = await api.transactionIdRequest(
          result?.related_transaction_id
        );
        setFindTransactionId(resultRelatedTransaction);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.unprocessable_entity);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-dir-col align-items-center"
      style={{
        width: 500,
        height: `calc(100% - 99.5px)`,
        boxSizing: "border-box",
        position: "relative",
        overflow: "auto",
      }}
    >
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div
          className="w-90 flex flex-dir-col justify-content-center align-items-center shadow-one"
          style={{
            marginRight: 10,
            marginLeft: 10,
            marginTop: 10,
            marginBottom: 10,
            padding: 8,
            backgroundColor: "white",
            borderRadius: 5,
            maxWidth: 500,
          }}
        >
          {transactionId?.access_allowed === false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex py-5">
                <div className="w-100 flex flex-dir-col justify-content-center ml-4">
                  <div className="flex align-items-center">
                    <IoIosCloseCircle
                      fontSize="28px"
                      className="text-warning m-0 p-0"
                    />
                    <p className="uppercase font-18 medium subtitle-color m-0 p-0 ml-3">
                      {labels.access_denied}
                    </p>
                  </div>
                  <p className="font-14 text-warning m-0 p-0 mt-8 ml-3">
                    {labels.reason}: {transactionId?.notes}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_granted === false && (
            <div className="w-100 flex py-5">
              <div className="w-100 flex flex-dir-col justify-content-center ml-4">
                <div className="flex align-items-center">
                  <IoIosCloseCircle
                    fontSize="30px"
                    className="text-warning m-0 p-0"
                  />
                  <p className="uppercase font-18 medium subtitle-color m-0 p-0 ml-3">
                    {labels.unauthorized_access}
                  </p>
                </div>
                <p className="font-14 text-warning m-0 p-0 mt-8 ml-3">
                  {labels.status}:{" "}
                  {transactionId?.access_key_status?.description ===
                    "access_removed" && labels.qr_code_deleted}
                  {transactionId?.access_key_status?.description ===
                    "not_in_time" && labels.access_out_of_time}
                  {transactionId?.access_key_status?.description ===
                    "door_not_included" && labels.access_door_denied}
                  {transactionId?.access_key_status?.description ===
                    "only_access" && labels.last_access_not_registered}
                  {transactionId?.access_key_status?.description ===
                    "only_exit" && labels.last_exit_not_registered}
                  {transactionId?.access_key_status?.description ===
                    "visitor_key" && labels.new_qr_code}
                  {transactionId?.access_key_status?.description ===
                    "not_during_scheduled_hour" &&
                    labels.hour_access_unauthorized}
                </p>
              </div>
            </div>
          )}
          <div className="w-100 flex flex-dir-col background-gray-color align-items-flex-start py-5">
            <div className="ml-6">
              {transactionId?.access_key_type === "visit" && (
                <p className="p-0 m-0 medium font-14">{labels.visitor}</p>
              )}
              {transactionId?.access_key_type === "provider" && (
                <p className="p-0 m-0 medium font-14">{labels.provider}</p>
              )}
              {transactionId?.access_key_type === "personal" && (
                <p className="p-0 m-0 medium font-14">{labels.host}</p>
              )}
              {transactionId?.access_key_type === "temporary" && (
                <p className="p-0 m-0 medium font-14">{labels.temporary}</p>
              )}
              {transactionId?.access_key_type === "visitor" && (
                <p className="p-0 m-0 medium font-14">{labels.visitor}</p>
              )}
              {transactionId?.access_key_type === "eventbrite" && (
                <p className="p-0 m-0 medium font-14">{labels.event}</p>
              )}
              {transactionId?.access_key_type === "passtrack_event" && (
                <p className="p-0 m-0 medium font-14">{labels.event}</p>
              )}
            </div>
            <p className="medium font-18 mb-0 mt-2 ml-6 mr-6">
              {transactionId?.visitor_name}
            </p>
          </div>
          {transactionId?.access_type === "exit"
            ? findTransactionId &&
              findTransactionId?.transaction_photos.length > 0 && (
                <div className="w-100 background-gray-color flex flex-dir-col justify-content-center align-items-center mt-4 mb-4 pt-4 pb-4">
                  <div className="w-100 flex justify-content-center align-items-center">
                    <img
                      src={transactionPhoto}
                      alt="captured_photo_id"
                      className="transaction-photo-style"
                    />
                  </div>
                  <div className="w-100 flex justify-content-center align-items-center mt-5 mb-2">
                    {findTransactionId?.transaction_photos.map(
                      ({ access_server_photo_type }) => (
                        <div key={access_server_photo_type}>
                          {access_server_photo_type === "id" && (
                            <FaIdCard
                              fontSize="24px"
                              className={
                                photoType === "id"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("id")}
                            />
                          )}
                          {user?.network.id === BACK_ID_NETWORKS && (
                            <>
                              {access_server_photo_type === "back_plates" && (
                                <MdSubtitles
                                  fontSize="26px"
                                  className={
                                    photoType === "back_plates"
                                      ? "primary-color m-0 p-0 mx-5"
                                      : "title-color m-0 p-0 mx-5"
                                  }
                                  onClick={() => setPhotoType("back_plates")}
                                />
                              )}
                            </>
                          )}
                          {access_server_photo_type === "front_plates" && (
                            <FaCarAlt
                              fontSize="24px"
                              className={
                                photoType === "front_plates"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("front_plates")}
                            />
                          )}
                          {user?.network.id !== BACK_ID_NETWORKS && (
                            <>
                              {access_server_photo_type === "back_plates" && (
                                <FaUser
                                  fontSize="24px"
                                  className={
                                    photoType === "back_plates"
                                      ? "primary-color m-0 p-0 mx-5"
                                      : "title-color m-0 p-0 mx-5"
                                  }
                                  onClick={() => setPhotoType("back_plates")}
                                />
                              )}
                            </>
                          )}
                          {access_server_photo_type === "other" && (
                            <FaLaptop
                              fontSize="24px"
                              className={
                                photoType === "other"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("other")}
                            />
                          )}
                          {access_server_photo_type === "accreditation" && (
                            <FaUser
                              fontSize="24px"
                              className={
                                photoType === "accreditation"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("accreditation")}
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )
            : transactionId &&
              transactionId?.transaction_photos.length > 0 && (
                <div className="w-100 background-gray-color flex flex-dir-col justify-content-center align-items-center mt-4 mb-4 pt-4 pb-4">
                  <div className="w-100 flex justify-content-center align-items-center">
                    <img
                      src={transactionPhoto}
                      alt="captured_photo_id"
                      className="transaction-photo-style"
                    />
                  </div>
                  <div className="w-100 flex justify-content-center align-items-center mt-5 mb-2">
                    {transactionId?.transaction_photos.map(
                      ({ access_server_photo_type }) => (
                        <div key={access_server_photo_type}>
                          {access_server_photo_type === "id" && (
                            <FaIdCard
                              fontSize="24px"
                              className={
                                photoType === "id"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("id")}
                            />
                          )}
                          {access_server_photo_type === "front_plates" && (
                            <FaCarAlt
                              fontSize="24px"
                              className={
                                photoType === "front_plates"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("front_plates")}
                            />
                          )}
                          {access_server_photo_type === "back_plates" && (
                            <FaUser
                              fontSize="24px"
                              className={
                                photoType === "back_plates"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("back_plates")}
                            />
                          )}
                          {access_server_photo_type === "other" && (
                            <FaLaptop
                              fontSize="24px"
                              className={
                                photoType === "other"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("other")}
                            />
                          )}
                          {access_server_photo_type === "accreditation" && (
                            <FaUser
                              fontSize="24px"
                              className={
                                photoType === "accreditation"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("accreditation")}
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
          <Divider className="w-100" />
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {transactionId?.access_allowed === false &&
                  transactionId?.access_granted !== false &&
                  labels.host}
                {transactionId?.access_granted === false && labels.host}
                {transactionId?.access_allowed !== false &&
                  transactionId?.access_granted !== false &&
                  labels.visited}
                :
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="font-14 m-0 p-0 medium">
                {transactionId?.user?.name}
              </p>
              <p className="subtitle-color font-12 m-0 p-0 mt-2">
                {transactionId?.user?.group}
              </p>
            </div>
          </div>
          {transactionId?.related_transaction_id &&
            transactionId?.access_type === "exit" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_access_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(findTransactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {findTransactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_type === "exit" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_exit_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(transactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {transactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_type === "access" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_access_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(transactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {transactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.related_transaction_id &&
            transactionId?.access_type === "access" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_exit_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(findTransactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {findTransactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_granted !== false ? (
            transactionId?.access_allowed === false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.date_hour}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(transactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {transactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )
          ) : (
            <div className="w-100 flex my-5">
              <div className="w-30 flex justify-content-flex-start px-6">
                <p className="subtitle-color font-14 medium m-0 p-0">
                  {labels.date_hour}:
                </p>
              </div>
              <div className="w-70 flex flex-dir-col">
                <p className="subtitle-color font-14 m-0 p-0 medium">
                  {moment(transactionId?.created_at).format(
                    "dddd, DD MMM YY - hh:mma"
                  )}
                </p>
                <p className="subtitle-color font-12 m-0 p-0 mt-2">
                  {transactionId?.access_server?.name}
                </p>
              </div>
            </div>
          )}
          <Divider className="w-100" />
          {transactionId?.custom_attrs &&
            customAttributesArray.length > 0 &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100">
                {customAttributesArray.map((item, index) => (
                  <div className="w-100 flex my-6" key={index}>
                    <div className="w-30 flex justify-content-flex-start px-6">
                      <p className="subtitle-color font-14 medium m-0 p-0">
                        {item[0]}:
                      </p>
                    </div>
                    <div className="w-70 flex flex-dir-col">
                      <p className="subtitle-color font-14 m-0 p-0 medium">
                        {item[1]}
                      </p>
                    </div>
                  </div>
                ))}
                <Divider className="w-100" />
              </div>
            )}
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {labels.record_created_by}
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="font-14 m-0 p-0 medium">
                {transactionId?.created_by_user
                  ? transactionId?.created_by_user?.name
                  : labels.na}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDesktopRegisterDetails;
