import React from "react";

const OfflineView = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Error de conexion de internet</p>
    </div>
  );
};

export default OfflineView;
