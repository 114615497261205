import React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Switch,
  Checkbox,
  Button,
  Autocomplete,
  CircularProgress,
  Modal,
  Box,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import moment from "moment";
import ValidityForm from "../ValidityForm/ValidityForm";
import { styled } from "@mui/material/styles";
import { resetUser, selectUser } from "../../slices/user";
import SearchInputOutlined from "../SearchInputOutlined/SearchInputOutlined";
import api, {
  CommunityMembers,
  CreateAccessKeyParams,
  Providers,
} from "../../api";
import { showError } from "../../utils/helper";
import { resetToken, selectToken } from "../../slices/token";
import CustomAttributes from "../CustomAttributes/CustomAttributes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};

const TemporarySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface AdminDesktopNewInvitationProps {
  showContacts: boolean;
  setShowContacts: (showContacts: boolean) => void;
  setShowContactsDisabled: (showContactsDisabled: boolean) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCloseFunction?: () => void;
  setInvitationId: (invitationId: number | null) => void;
}

const AdminDesktopNewInvitation = ({
  showContacts,
  setShowContacts,
  setShowContactsDisabled,
  open,
  setOpen,
  onCloseFunction,
  setInvitationId,
}: AdminDesktopNewInvitationProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const defaultAntipassbackValue = user
    ? user?.network?.passback_activated_by_default !== null
      ? user?.network?.passback_activated_by_default
      : true
    : true;
  const disabledAntipassback =
    user?.network?.block_antipassback !== null
      ? user?.network?.block_antipassback
      : false;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isProvider, setIsProvider] = React.useState<string>("false");
  const [isTemporary, setIsTemporary] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [hour, setHour] = React.useState<string | null>(
    moment(date).format("HH")
  );
  const [minutes, setMinutes] = React.useState<string | null>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [hoursLaterTemporary, setHoursLaterTemporary] =
    React.useState<string>("2");
  const [monday, setMonday] = React.useState<boolean>(true);
  const [tuesday, setTuesday] = React.useState<boolean>(true);
  const [wednesday, setWednesday] = React.useState<boolean>(true);
  const [thursday, setThursday] = React.useState<boolean>(true);
  const [friday, setFriday] = React.useState<boolean>(true);
  const [saturday, setSaturday] = React.useState<boolean>(true);
  const [sunday, setSunday] = React.useState<boolean>(true);
  const [daysArray, setDaysArray] = React.useState<number[]>([
    1, 2, 3, 4, 5, 6, 0,
  ]);
  const [finalAccessPointTemporary, setFinalAccessPointTemporary] =
    React.useState<number[]>([]);
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<string>("");
  const [communityMembers, setCommunityMembers] = React.useState<
    CommunityMembers[] | null
  >(null);
  const [backupCommunityMembers, setBackupCommunityMembers] = React.useState<
    CommunityMembers[] | null
  >(null);
  const [checked, setChecked] = React.useState(false);
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [provider, setProvider] = React.useState<string | null>("");
  const [fullProvidersList, setFullProvidersList] =
    React.useState<Providers | null>(null);
  const [addProvider, setAddProvider] = React.useState<string>("");
  const [providersList, setProvidersList] = React.useState<string[]>([]);
  const [existedContact, setExistedContact] = React.useState<boolean>(false);
  const [visitMotive, setVisitMotive] = React.useState<string>("");
  const [invitationCustomAttributes, setInvitationCustomAttributes] =
    React.useState<any | null>(null);
  const [passbackTracking, setPassbackTracking] = React.useState<boolean>(
    defaultAntipassbackValue
  );
  const [temporaryDuration, setTemporaryDuration] = React.useState<
    "days" | "weeks" | "months"
  >("days");
  const [temporaryDurationValue, setTemporaryDurationValue] =
    React.useState<string>("1");
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);
  const [successfulInvitationModal, setSuccessfulInvitationModal] =
    React.useState<boolean>(false);
  const [temporaryAllDay, setTemporaryAllDay] = React.useState<boolean>(false);
  const [errorPhoneLabel, setErrorPhoneLabel] = React.useState<boolean>(false);
  const [errorPhoneDigits, setErrorPhoneDigits] =
    React.useState<boolean>(false);
  const [errorPhoneLetters, setErrorPhoneLetters] =
    React.useState<boolean>(false);
  const [errorEmail, setErrorEmail] = React.useState<boolean>(false);

  const softtekNetwork = 81;

  const userId = user?.id;
  const isoDate = `${moment(date).format(
    `YYYY-MM-DDT${hour}:${minutes}:ss.SSSZ`
  )}`;
  // Parsea la fecha con Moment.js
  const fechaInicial = moment(isoDate);

  // Resta dos horas a la fecha inicial
  const fechaRestada = fechaInicial.subtract(2, "hours");

  // Obtén la nueva fecha en el formato deseado
  const nuevaFecha = fechaRestada.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  const temporaryTimeRestriction = hour + ":" + minutes;
  const filteredProvider = fullProvidersList?.providers.filter(
    ({ display_name }) => display_name === provider
  );

  const phoneDisabled = phoneNumber.length < 9 || phoneNumber.length > 11;

  const disabledProvider = invitationCustomAttributes
    ? provider === "Otro"
      ? !addProvider ||
        filteredProvider?.length === 0 ||
        finalAccessPointTemporary.length === 0 ||
        !visitMotive ||
        !customAttributesRequired
      : filteredProvider?.length === 0 ||
        finalAccessPointTemporary.length === 0 ||
        !visitMotive ||
        !customAttributesRequired
    : provider === "Otro"
    ? !addProvider ||
      filteredProvider?.length === 0 ||
      finalAccessPointTemporary.length === 0 ||
      !visitMotive
    : filteredProvider?.length === 0 ||
      finalAccessPointTemporary.length === 0 ||
      !visitMotive;

  const disabledVisitor = invitationCustomAttributes
    ? !name ||
      !phoneNumber ||
      finalAccessPointTemporary.length === 0 ||
      !customAttributesRequired ||
      !visitMotive
    : !name ||
      !phoneNumber ||
      finalAccessPointTemporary.length === 0 ||
      !visitMotive;

  const scrollContainer: any = React.useRef(null);

  React.useEffect(() => {
    onReloadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onReloadComponent = () => {
    if (open) {
      setIsLoading(true);
      getInitialValues();
      setTimeout(() => {
        getInvitationCustomAttributes();
        getProviders();
        getCommunityMembers();
      }, 400);
    } else {
      setTimeout(() => {
        scrollToTop();
      }, 400);
    }
  };

  const getInitialValues = () => {
    setName("");
    setPhoneNumber("");
    setEmail("");
    setCountryCode("+52");
    setIsProvider("false");
    if (user?.network.id === softtekNetwork) {
      setIsTemporary(true);
    } else {
      setIsTemporary(false);
    }
    setDate(new Date());
    setHour(moment(date).format("HH"));
    setMinutes("00");
    setHoursLater("1");
    setMonday(true);
    setThursday(true);
    setWednesday(true);
    setTuesday(true);
    setFriday(true);
    setSaturday(true);
    setSunday(true);
    setDaysArray([1, 2, 3, 4, 5, 6, 0]);
    setFinalAccessPointTemporary([]);
    setSearchCommunityMember("");
    setProvider("");
    setFullProvidersList(null);
    setAddProvider("");
    setProvidersList([]);
    setExistedContact(false);
    setVisitMotive("");
    setInvitationCustomAttributes(null);
    setPassbackTracking(defaultAntipassbackValue);
    setTemporaryDuration("days");
    setTemporaryDurationValue("1");
    setCustomAttributesRequired(false);
    setCommunityMembers(null);
    setBackupCommunityMembers(null);
    setShowContacts(false);
    setShowContactsDisabled(true);
    setTemporaryAllDay(false);
    setErrorEmail(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLabel(false);
    setErrorPhoneLetters(false);
  };

  const scrollToTop = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = 0;
    }
  };

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = invitationCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  React.useEffect(() => {
    getDaysRestrictions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monday, tuesday, wednesday, thursday, friday, saturday, sunday]);

  const getDaysRestrictions = () => {
    const newArray: number[] = [];
    if (monday) {
      newArray.push(1);
    }
    if (tuesday) {
      newArray.push(2);
    }
    if (wednesday) {
      newArray.push(3);
    }
    if (thursday) {
      newArray.push(4);
    }
    if (friday) {
      newArray.push(5);
    }
    if (saturday) {
      newArray.push(6);
    }
    if (sunday) {
      newArray.push(0);
    }
    setDaysArray(newArray);
  };

  const handleCheckboxChange = (id: number) => {
    if (finalAccessPointTemporary.includes(id)) {
      setFinalAccessPointTemporary(
        finalAccessPointTemporary.filter((item) => item !== id)
      );
    } else {
      setFinalAccessPointTemporary([...finalAccessPointTemporary, id]);
    }
  };

  React.useEffect(() => {
    const completePhone = countryCode + phoneNumber;
    const filteredCommunityMember = communityMembers?.filter(
      (element) => element.phone === completePhone
    );
    if (filteredCommunityMember && filteredCommunityMember?.length > 0) {
      setExistedContact(true);
      setSaveCommunityMember(false);
      setChecked(false);
    } else {
      setExistedContact(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, phoneNumber]);

  React.useEffect(() => {
    if (!showContacts) {
      setSearchCommunityMember("");
    }
  }, [showContacts]);

  React.useEffect(() => {
    manegeSearchCommunityMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommunityMember]);

  const manageVisitorProvider = (e: any) => {
    if (e.target.value === "false") {
      setIsProvider(e.target.value);
      setShowContactsDisabled(false);
      setProvider("");
      setAddProvider("");
    } else {
      setIsProvider(e.target.value);
      setIsTemporary(false);
      setShowContactsDisabled(true);
      setShowContacts(false);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setCountryCode("+52");
    }
  };

  const manegeSearchCommunityMember = () => {
    if (!!searchCommunityMember) {
      const getSearchedCommunityMember = communityMembers?.filter((element) =>
        element.name.toLowerCase().includes(searchCommunityMember.toLowerCase())
      );
      setCommunityMembers(
        getSearchedCommunityMember ? getSearchedCommunityMember : []
      );
    } else {
      setCommunityMembers(backupCommunityMembers);
    }
  };

  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTemporary(event.target.checked);
    if (event.target.checked === false) {
      setPassbackTracking(defaultAntipassbackValue);
    }
  };

  const checkNumbers = (str: string) => {
    return /^\d+$/.test(str);
  };

  const validateEmail = (email: string) => {
    const validateCode = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validateCode.test(email);
  };

  const onBlurTextField = (
    parameter: string,
    setParameter: (parameter: string) => void
  ) => {
    const trimName = parameter.trim();
    setParameter(trimName);
  };

  const onBlurEmailField = () => {
    if (email) {
      const trimEmail = email.trim().replace(/\s+/g, "");
      setEmail(trimEmail);
      const verifiedEmail = validateEmail(trimEmail);
      if (!verifiedEmail) {
        setErrorEmail(true);
      }
    }
  };

  const onFocusEmailField = () => {
    setErrorEmail(false);
  };

  const onBlurPhoneField = () => {
    if (phoneNumber) {
      const trimPhone = phoneNumber.trim().replace(/\s+/g, "");
      setPhoneNumber(trimPhone);
      const checkOnlyNumbers: boolean = checkNumbers(trimPhone);
      if (trimPhone.length !== 10) {
        setErrorPhoneLabel(true);
        setErrorPhoneDigits(true);
      }
      if (!checkOnlyNumbers) {
        setErrorPhoneLabel(true);
        setErrorPhoneLetters(true);
      }
    }
  };

  const onFocusPhoneField = () => {
    setErrorPhoneLabel(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLetters(false);
  };

  const getCommunityMembers = async () => {
    if (userId) {
      try {
        setIsLoading(true);
        const result = await api.communityMembersRequest(1, 100, "");
        if (result) {
          const newArray: CommunityMembers[] = result.community_members.filter(
            (element) => element?.user?.id === userId
          );
          setCommunityMembers(newArray);
          setBackupCommunityMembers(newArray);
          if (newArray.length === 0) {
            setShowContactsDisabled(true);
          } else {
            setShowContactsDisabled(false);
          }
        }
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.user_no_access);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onMouseOverFunc = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  const getProviders = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const result = await api.providersRequest();
        const providersName = Array.from(
          new Set(result.providers.map(({ display_name }) => display_name))
        );
        setFullProvidersList(result);
        setProvidersList(providersName);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.wrong_parameters);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSelectCommunityMember = (cm: CommunityMembers) => {
    setName(cm.name);
    setEmail(cm.email);
    if (cm.phone.startsWith("+52")) {
      setCountryCode("+52");
      setPhoneNumber(cm.phone.substring(3));
    } else {
      setCountryCode("+1");
      setPhoneNumber(cm.phone.substring(2));
    }
  };

  const getInvitationCustomAttributes = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const result = await api.getInvitationCustomAttributes();
        setInvitationCustomAttributes(
          result.custom_attributes.map((item) => ({
            ...item,
            value:
              item.attribute_type === "string"
                ? ""
                : item.attribute_type === "integer"
                ? ""
                : item.attribute_type === "boolean"
                ? false
                : item.attribute_type === "list"
                ? ""
                : item.attribute_type === "date" && new Date(),
          }))
        );
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const createInvitation = async () => {
    const payload: CreateAccessKeyParams = {
      access_key: {
        visitor_name:
          isProvider === "true"
            ? provider === "Otro"
              ? addProvider
              : ""
            : name,
        visitor_phone: phoneNumber,
        country_code: countryCode,
        visitor_email: email,
        access_key_type_id: isProvider === "true" ? 5 : isTemporary ? 2 : 1,
        reason_for_visit: "",
        notes: visitMotive,
        community_member_id: null,
        save_community_member: saveCommunityMember,
        visitor_duration: isTemporary ? "" : hoursLater,
        duration: isTemporary ? temporaryDuration : "",
        temporary_duration: isTemporary ? temporaryDurationValue : "",
        valid_from: moment(isoDate).format("DD/MM/YYYY HH:mm:ss"),
        // valid_from: moment(nuevaFecha).format("DD/MM/YYYY HH:mm:ss"),
        access_servers: finalAccessPointTemporary,
      },
    };
    if (isTemporary) {
      payload.access_key.passback_tracking = passbackTracking;
      payload.access_key.key_restrictions = {
        days: daysArray.length > 0 ? daysArray : [1, 2, 3, 4, 5, 6, 0],
        time: temporaryAllDay
          ? null
          : temporaryTimeRestriction
          ? temporaryTimeRestriction
          : "00:00",
        duration_in_minutes: temporaryAllDay
          ? null
          : Number(hoursLaterTemporary),
      };
    }
    if (invitationCustomAttributes) {
      const customAttributes: any = {};
      invitationCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
        }
      );
      payload.access_key.custom_attrs = customAttributes;
    }
    if (isProvider === "true") {
      payload.access_key.provider = filteredProvider
        ? filteredProvider[0]?.name
        : "";
    }
    try {
      setIsLoading(true);
      const response = await api.createAccessKey(payload);
      if (response) {
        setInvitationId(response.access_key.id);
        setSuccessfulInvitationModal(true);
        setTimeout(() => {
          setSuccessfulInvitationModal(false);
          setOpen(false);
          onCloseFunction && onCloseFunction();
        }, 3000);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, error?.response?.data?.error?.message[0]);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={scrollContainer}
        style={{
          width: 700,
          height: `calc(100% - 99.5px)`,
          boxSizing: "border-box",
          position: "relative",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              display: "flex",
              borderRadius: 4,
              marginBottom: 10,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div
              style={{
                width: "50%",
                boxSizing: "border-box",
                paddingRight: 10,
              }}
            >
              <div style={{ width: "100%" }}>
                <FormControl fullWidth>
                  <Select
                    value={isProvider}
                    onChange={(e) => manageVisitorProvider(e)}
                    inputProps={{ "aria-label": "Whithout label" }}
                    disabled={isLoading ? true : false}
                  >
                    <MenuItem value={"false"}>{labels.visitor}</MenuItem>
                    <MenuItem value={"true"}>{labels.provider}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                boxSizing: "border-box",
                paddingLeft: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 56,
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      marginRight: 10,
                    }}
                  >
                    {labels.multiple_access}
                  </p>
                </div>
                <TemporarySwitch
                  checked={isTemporary}
                  onChange={onChangeSwitch}
                  disabled={
                    isLoading ? true : isProvider === "true" ? true : false
                  }
                />
              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: 18,
              marginBottom: 20,
              marginTop: 30,
              marginLeft: 30,
              paddingTop: 0,
            }}
          >
            {isProvider === "true" ? labels.provider : labels.visitor_data}
          </p>
          {isProvider === "true" ? (
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: 30,
                display: "flex",
                borderRadius: 4,
                boxShadow:
                  "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              <div
                style={{
                  width: "50%",
                  boxSizing: "border-box",
                  paddingRight: 10,
                }}
              >
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={providersList}
                  onChange={(event: any, newValue: string | null) => {
                    setProvider(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      value={provider}
                      onChange={(e) => {
                        setProvider(e.target.value);
                      }}
                      label={labels.select_provider}
                      disabled={isLoading ? true : false}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  boxSizing: "border-box",
                  paddingLeft: 10,
                }}
              >
                {provider === "Otro" && (
                  <TextField
                    value={addProvider}
                    label={labels.specify_provider}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setAddProvider(e.target.value);
                    }}
                    disabled={isLoading ? true : false}
                    onBlur={() => onBlurTextField(addProvider, setAddProvider)}
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: 30,
                display: "flex",
                borderRadius: 4,
                boxShadow:
                  "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              <div
                style={{
                  width: "50%",
                  boxSizing: "border-box",
                  paddingRight: 10,
                }}
              >
                <div>
                  <div style={{ width: "100%", marginBottom: 15 }}>
                    <p
                      className="title-color"
                      style={{ marginBottom: 10, padding: 0 }}
                    >
                      {labels.full_name} *
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="outlined"
                        placeholder={`${labels.full_name}`}
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        disabled={isLoading ? true : false}
                        onBlur={() => onBlurTextField(name, setName)}
                      />
                    </FormControl>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p
                      className="title-color"
                      style={{ marginBottom: 10, padding: 0 }}
                    >
                      {labels.email}
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="outlined"
                        placeholder={labels.email}
                        type="text"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        disabled={isLoading ? true : false}
                        onBlur={onBlurEmailField}
                        onFocus={onFocusEmailField}
                        error={errorEmail}
                      />
                    </FormControl>
                    {errorEmail && (
                      <p
                        className="m-0 p-0 font-12 ml-4 mt-2"
                        style={{ color: "#d32f2f" }}
                      >
                        {labels.error_email}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  boxSizing: "border-box",
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: 15,
                  }}
                >
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.phone_number}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        boxSizing: "border-box",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "35%", marginRight: 5 }}>
                        <FormControl fullWidth>
                          <Select
                            value={countryCode}
                            onChange={(e) => {
                              setCountryCode(e.target.value);
                            }}
                            inputProps={{ "aria-label": "Whithout label" }}
                            disabled={isLoading ? true : false}
                          >
                            <MenuItem value={"+52"}>MX +52</MenuItem>
                            <MenuItem value={"+1"}>US +1</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ width: "65%", marginLeft: 5 }}>
                        <FormControl variant="outlined" fullWidth>
                          <OutlinedInput
                            id="outlined"
                            placeholder={`${labels.phone_number}`}
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            disabled={isLoading ? true : false}
                            onBlur={onBlurPhoneField}
                            onFocus={onFocusPhoneField}
                            error={errorPhoneLabel}
                          />
                        </FormControl>
                        {errorPhoneLabel && (
                          <>
                            {errorPhoneDigits && (
                              <p
                                className="m-0 p-0 font-12 ml-4 mt-2"
                                style={{ color: "#d32f2f" }}
                              >
                                {labels.ten_digits_error_label}
                              </p>
                            )}
                            {errorPhoneLetters && (
                              <p
                                className="m-0 p-0 font-12 ml-4 mt-2"
                                style={{ color: "#d32f2f" }}
                              >
                                {labels.only_numbers}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: 45 }}
                >
                  <Checkbox
                    checked={checked}
                    value={saveCommunityMember}
                    onChange={handleSaveCommunityMemberChange}
                    disabled={isLoading ? true : existedContact}
                  />
                  <div>
                    <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                      {labels.save_contact}
                    </p>
                    {existedContact && (
                      <p className="m-0 mt-0 mb-0 font-12 mt-2 subtitle-color">
                        {labels.existed_contact}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <p
            style={{
              fontSize: 18,
              marginBottom: 20,
              marginTop: 30,
              marginLeft: 30,
              paddingTop: 0,
            }}
          >
            {labels.validity}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              display: "flex",
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <ValidityForm
              isLoading={isLoading}
              date={date}
              setDate={setDate}
              hour={hour}
              setHour={setHour}
              minutes={minutes}
              setMinutes={setMinutes}
              hoursLater={hoursLater}
              hoursLaterTemporary={hoursLaterTemporary}
              setHoursLaterTemporary={setHoursLaterTemporary}
              setHoursLater={setHoursLater}
              temporaryDuration={temporaryDuration}
              setTemporaryDuration={setTemporaryDuration}
              temporaryDurationValue={temporaryDurationValue}
              setTemporaryDurationValue={setTemporaryDurationValue}
              isTemporary={isTemporary}
              monday={monday}
              setMonday={setMonday}
              tuesday={tuesday}
              setTuesday={setTuesday}
              wednesday={wednesday}
              setWednesday={setWednesday}
              thursday={thursday}
              setThursday={setThursday}
              friday={friday}
              setFriday={setFriday}
              saturday={saturday}
              setSaturday={setSaturday}
              sunday={sunday}
              setSunday={setSunday}
              allowAllDay={temporaryAllDay}
              setAllowAllDay={setTemporaryAllDay}
            />
          </div>
          <p
            style={{
              fontSize: 18,
              marginBottom: 20,
              marginTop: 30,
              marginLeft: 30,
              paddingTop: 0,
            }}
          >
            {labels.visit_details}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              display: "flex",
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div
              style={{
                width: "50%",
                boxSizing: "border-box",
                paddingRight: 10,
              }}
            >
              <div>
                <div style={{ width: "100%", marginBottom: 15 }}>
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.visit_reason} *
                  </p>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="outlined"
                      placeholder={labels.reason}
                      type="text"
                      value={visitMotive}
                      onChange={(e) => {
                        setVisitMotive(e.target.value);
                      }}
                      disabled={isLoading ? true : false}
                      onBlur={() =>
                        onBlurTextField(visitMotive, setVisitMotive)
                      }
                    />
                  </FormControl>
                </div>
                {isTemporary === true && (
                  <>
                    <div className="flex">
                      <Checkbox
                        checked={passbackTracking}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setPassbackTracking(event.target.checked);
                        }}
                        disabled={isLoading ? true : disabledAntipassback}
                      />
                      <p
                        className="subtitle-color-ni font-14 medium"
                        style={{ color: disabledAntipassback ? "#bdbdbd" : "" }}
                      >
                        {labels.anti_passback_text}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              style={{
                width: "50%",
                boxSizing: "border-box",
                paddingLeft: 10,
              }}
            >
              <div>
                <div style={{ width: "100%", marginBottom: 15 }}>
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.allowed_access_points} *
                  </p>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      borderRadius: 4,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#c4c4c4",
                      padding: 10,
                    }}
                  >
                    {user?.access_servers.map(
                      ({ name, venue_name, id }, index) => (
                        <div
                          key={`${id}${index}`}
                          className="flex align-items-center mb-2"
                        >
                          <Checkbox
                            onChange={() => handleCheckboxChange(id)}
                            checked={finalAccessPointTemporary.includes(id)}
                            color="secondary"
                            disabled={isLoading ? true : false}
                          />
                          <div className="flex flex-dir-col">
                            <p className="subtitle-color font-14 m-0 p-0">
                              {name}
                            </p>
                            <p className="title-color font-12 m-0 p-0">
                              {venue_name}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {invitationCustomAttributes &&
            invitationCustomAttributes.length > 0 && (
              <>
                <p
                  style={{
                    fontSize: 18,
                    marginBottom: 20,
                    marginTop: 30,
                    marginLeft: 30,
                    paddingTop: 0,
                  }}
                >
                  {labels.others}
                </p>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    boxSizing: "border-box",
                    padding: 30,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 20,
                    alignItems: "center",
                    borderRadius: 4,
                    boxShadow:
                      "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  {invitationCustomAttributes.map(
                    (
                      {
                        id,
                        attribute_name,
                        attribute_type,
                        required,
                        options,
                        value,
                      }: any,
                      index: number
                    ) => (
                      <div
                        key={`${id}${index}`}
                        style={{
                          width: `calc(50% - 10px)`,
                          boxSizing: "border-box",
                        }}
                      >
                        <CustomAttributes
                          isLoading={isLoading}
                          type={attribute_type}
                          name={attribute_name}
                          required={required}
                          value={value}
                          setValue={(newValue: any) => {
                            const newInvitationCustomAttributes = [
                              ...invitationCustomAttributes,
                            ];
                            newInvitationCustomAttributes[index].value =
                              newValue;
                            setInvitationCustomAttributes(
                              newInvitationCustomAttributes
                            );
                          }}
                          listArray={options}
                        />
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 40,
              paddingBottom: 40,
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{ height: 56, width: 400 }}
              onClick={createInvitation}
              disabled={
                isLoading
                  ? true
                  : phoneDisabled
                  ? true
                  : isProvider === "true"
                  ? disabledProvider
                  : disabledVisitor
              }
            >
              {isLoading ? (
                <CircularProgress style={{ color: "white", fontSize: 16 }} />
              ) : (
                labels.generate_invitation
              )}
            </Button>
          </div>
        </div>
      </div>
      {showContacts && (
        <div
          style={{
            width: 450,
            height: `calc(100% - 99.5px)`,
            backgroundColor: "#f8f8f8",
            boxSizing: "border-box",
            padding: 20,
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              overflow: "auto",
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="title-color"
                style={{ marginBottom: 10, padding: 0 }}
              >
                {labels.search_contact}
              </p>
              <SearchInputOutlined
                isLoading={isLoading}
                value={searchCommunityMember}
                setValue={setSearchCommunityMember}
                size="large"
              />
            </div>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {communityMembers && communityMembers.length > 0 ? (
                communityMembers.map((element, index) => (
                  <div
                    key={`${element.id}${index}`}
                    style={{
                      display: "flex",
                      backgroundColor: "",
                      boxSizing: "border-box",
                      paddingLeft: 10,
                      paddingRight: 10,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottomColor: "#e4e4e4",
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      cursor: "pointer",
                    }}
                    onMouseOver={onMouseOverFunc}
                    onMouseLeave={onMouseOutFunc}
                    onClick={() =>
                      isLoading ? {} : onSelectCommunityMember(element)
                    }
                  >
                    <p style={{ marginRight: 10 }}>{element.name}</p>
                    <p>{element.phone}</p>
                  </div>
                ))
              ) : (
                <div>
                  <p>{labels.contact_not_found}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* CREATED ACCESS KEY MODAL------------------------------------------------------------>> */}
      {/* MODAL INVITACION CREADA------------------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={successfulInvitationModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-14 subtitle-color text-center pt-10 pb-10">
              {labels.invitation_sent}
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminDesktopNewInvitation;
