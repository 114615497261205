import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ErrorState {
  error: boolean;
  message: string;
}

const initialState: ErrorState = {
  error: false,
  message: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      const newState = { ...state };
      newState.error = true;
      newState.message = action.payload;
      return newState;
    },
    resetError: (state) => {
      const newState = { ...state };
      newState.error = false;
      newState.message = "";
      return newState;
    },
  },
});

export const { setError, resetError } = errorSlice.actions;

export const selectError = (state: RootState) => state.error.error;

export const selectErrorMessage = (state: RootState) => state.error.message;

export default errorSlice.reducer;
