import { Button, Divider } from "@mui/material";
import { selectLabels } from "../../slices/language";
import { useAppSelector, useWindowSize } from "../../app/hooks";
import React, { Fragment, ReactElement } from "react";
import Webcam from "react-webcam";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import "./capture-register-image.scss";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { isMobile } from "react-device-detect";

interface Props {
  handleClose: () => void;
  photo: string | null;
  setPhoto: (photo: string | null) => void;
  imageReference?: ReactElement;
  ticketPhoto?: boolean;
}

const CaptureRegisterImage = ({
  handleClose,
  photo,
  setPhoto,
  imageReference,
  ticketPhoto,
}: Props) => {
  const webcamRef = React.useRef<Webcam>(null);
  const labels = useAppSelector(selectLabels);

  const { width } = useWindowSize();

  interface DeviceProps {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
  }

  const [imgSrc, setImgSrc] = React.useState<string | null>(photo);

  const [devices, setDevices] = React.useState<DeviceProps[]>([]);
  const [finalDeviceId, setFinalDeviceId] = React.useState<string>(
    devices[0]?.deviceId
  );

  let aspectRatio;

  // if (
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent
  //   )
  // ) {
  //   // true for mobile device
  //   aspectRatio = 1.6666666667;
  // } else {
  //   // false for not mobile device
  //   aspectRatio = 0.6666666667;
  // }

  // if (width >= 1200) {
  //   aspectRatio = 0.6666666667;
  // } else {
  //   aspectRatio = 1.6666666667;
  // }

  if (isMobile) {
    aspectRatio = 0.6666666667;
  } else {
    aspectRatio = 1.6666666667;
  }

  const videoConstraints = {
    aspectRatio: aspectRatio,
    facingMode: "environment",
  };

  console.log("isMobile", isMobile);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(
        mediaDevices.filter(({ kind }: DeviceProps) => kind === "videoinput")
      ),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  console.log("devices", devices);

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef, setImgSrc]);

  const webcamWidth = width * 0.8;
  const webcamHeight = webcamWidth * 0.7;

  React.useEffect(() => {
    firstDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const firstDevice = () => {
    setFinalDeviceId(devices[0]?.deviceId);
  };

  return (
    <Fragment>
      {width >= 1200 ? (
        ticketPhoto ? (
          <div className="w-100 flex align-items-center justify-content-space-evenly">
            <div className="w-50 flex justify-content-center align-items-center">
              <div className="w-75 flex justify-content-center align-items-center my-4">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="95%"
                  videoConstraints={{
                    deviceId: finalDeviceId,
                    aspectRatio: 0.6666666667,
                  }}
                  className="webcam-style"
                />
              </div>
            </div>
            {!imgSrc && (
              <div className="w-50 flex flex-dir-col align-items-center">
                <div className="w-80 mt-3">{imageReference}</div>
                <div className="my-8">
                  <Button
                    onClick={capture}
                    variant="contained"
                    disableElevation
                    sx={{ py: "6.5px" }}
                    className="icon-button-style"
                  >
                    <PhotoCameraIcon />
                  </Button>
                </div>
                <div className="w-100 flex justify-content-center">
                  <Divider className="w-80 mb-4" />
                </div>
                {devices.length > 1 && (
                  <div className="w-100 flex justify-content-center">
                    <div className="w-80 flex flex-dir-col align-items-center justify-content-center py-5 main-container-bg">
                      <p className="text-primary-color font-16 p-0 m-0 my-5 medium">
                        Seleccionar cámara
                      </p>
                      {devices?.map(({ deviceId, label }) => (
                        <div key={deviceId} className="w-90 my-3">
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ py: "10px" }}
                            disableElevation
                            onClick={() => setFinalDeviceId(deviceId)}
                            startIcon={
                              finalDeviceId === deviceId ? (
                                <CheckRoundedIcon />
                              ) : null
                            }
                          >
                            {label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {imgSrc && (
              <div className="w-50 flex flex-dir-col align-items-center">
                <div className="w-50 mt-5">
                  <div className="w-100 flex justify-content-center align-items-center flex-dir-col">
                    <img
                      src={imgSrc}
                      alt="captured_photo"
                      className="image-style"
                    />
                  </div>
                </div>
                <div className="w-100 flex justify-content-center">
                  <Divider className="w-80 my-4 mb-6" />
                </div>
                <div className="w-100 flex align-items-center justify-content-space-evenly my-3">
                  <Button
                    onClick={() => {
                      setPhoto(null);
                      setImgSrc(null);
                    }}
                    variant="contained"
                    disableElevation
                    sx={{ py: "6.25px" }}
                    className="icon-button-style"
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      setPhoto(imgSrc);
                      handleClose();
                    }}
                    variant="contained"
                    disableElevation
                    className="icon-button-style"
                  >
                    {labels.save_photo}
                  </Button>
                  <Button
                    onClick={capture}
                    variant="contained"
                    disableElevation
                    sx={{ py: "6.25px" }}
                    className="icon-button-style"
                  >
                    <PhotoCameraIcon />
                  </Button>
                </div>
                <div className="w-100 flex justify-content-center">
                  <Divider className="w-80 my-4 mt-6" />
                </div>
                {devices.length > 1 && (
                  <div className="w-100 flex justify-content-center">
                    <div className="w-80 flex flex-dir-col align-items-center justify-content-center py-5 main-container-bg">
                      <p className="text-primary-color font-16 p-0 m-0 my-5 medium">
                        Seleccionar cámara
                      </p>
                      {devices?.map(({ deviceId, label }) => (
                        <div className="w-90 my-3">
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ py: "10px" }}
                            disableElevation
                            onClick={() => setFinalDeviceId(deviceId)}
                            startIcon={
                              finalDeviceId === deviceId ? (
                                <CheckRoundedIcon />
                              ) : null
                            }
                          >
                            {label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="w-100 flex align-items-center">
            <div className="w-65 flex justify-content-flex-start align-items-center">
              <div className="w-100 flex justify-content-center align-items-center my-4">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="95%"
                  videoConstraints={{
                    deviceId: finalDeviceId,
                    aspectRatio: 1.6666666667,
                  }}
                  className="webcam-style"
                />
              </div>
            </div>
            {!imgSrc && (
              <div className="w-35 flex flex-dir-col align-items-center">
                <div className="w-80 mt-3">{imageReference}</div>
                <div className="my-8">
                  <Button
                    onClick={capture}
                    variant="contained"
                    disableElevation
                    sx={{ py: "6.5px" }}
                    className="icon-button-style"
                  >
                    <PhotoCameraIcon />
                  </Button>
                </div>
                <div className="w-100 flex justify-content-center">
                  <Divider className="w-80 mb-4" />
                </div>
                {devices.length > 1 && (
                  <div className="w-100 flex justify-content-center">
                    <div className="w-80 flex flex-dir-col align-items-center justify-content-center py-5 main-container-bg">
                      <p className="text-primary-color font-16 p-0 m-0 my-5 medium">
                        Seleccionar cámara
                      </p>
                      {devices?.map(({ deviceId, label }) => (
                        <div key={deviceId} className="w-90 my-3">
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ py: "10px" }}
                            disableElevation
                            onClick={() => setFinalDeviceId(deviceId)}
                            startIcon={
                              finalDeviceId === deviceId ? (
                                <CheckRoundedIcon />
                              ) : null
                            }
                          >
                            {label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {imgSrc && (
              <div className="w-35 flex flex-dir-col align-items-center">
                <div className="w-80 mt-5">
                  <div className="w-100 flex justify-content-center align-items-center flex-dir-col">
                    <img
                      src={imgSrc}
                      alt="captured_photo"
                      className="image-style"
                    />
                  </div>
                </div>
                <div className="w-100 flex justify-content-center">
                  <Divider className="w-80 my-4 mb-6" />
                </div>
                <div className="w-100 flex align-items-center justify-content-center my-3">
                  <Button
                    onClick={() => {
                      setPhoto(null);
                      setImgSrc(null);
                    }}
                    variant="contained"
                    disableElevation
                    sx={{ py: "6.25px" }}
                    className="icon-button-style"
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      setPhoto(imgSrc);
                      handleClose();
                    }}
                    variant="contained"
                    disableElevation
                    className="icon-button-style mx-10"
                  >
                    {labels.save_photo}
                  </Button>
                  <Button
                    onClick={capture}
                    variant="contained"
                    disableElevation
                    sx={{ py: "6.25px" }}
                    className="icon-button-style"
                  >
                    <PhotoCameraIcon />
                  </Button>
                </div>
                <div className="w-100 flex justify-content-center">
                  <Divider className="w-80 my-4 mt-6" />
                </div>
                {devices.length > 1 && (
                  <div className="w-100 flex justify-content-center">
                    <div className="w-80 flex flex-dir-col align-items-center justify-content-center py-5 main-container-bg">
                      <p className="text-primary-color font-16 p-0 m-0 my-5 medium">
                        Seleccionar cámara
                      </p>
                      {devices?.map(({ deviceId, label }) => (
                        <div className="w-90 my-3">
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ py: "10px" }}
                            disableElevation
                            onClick={() => setFinalDeviceId(deviceId)}
                            startIcon={
                              finalDeviceId === deviceId ? (
                                <CheckRoundedIcon />
                              ) : null
                            }
                          >
                            {label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      ) : (
        // (
        // <div className="w-100 flex align-items-center">
        //   <div className="w-65 flex justify-content-flex-start align-items-center">
        //     <div className="w-100 flex justify-content-center align-items-center my-4">
        //       {ticketPhoto ? (
        //         <Webcam
        //           audio={false}
        //           ref={webcamRef}
        //           screenshotFormat="image/jpeg"
        //           width="95%"
        //           videoConstraints={{
        //             deviceId: finalDeviceId,
        //             aspectRatio: 0.6666666667,
        //           }}
        //           className="webcam-style"
        //         />
        //       ) : (
        //         <Webcam
        //           audio={false}
        //           ref={webcamRef}
        //           screenshotFormat="image/jpeg"
        //           width="95%"
        //           videoConstraints={{
        //             deviceId: finalDeviceId,
        //             aspectRatio: 1.6666666667,
        //           }}
        //           className="webcam-style"
        //         />
        //       )}
        //     </div>
        //   </div>
        //   {!imgSrc && (
        //     <div className="w-35 flex flex-dir-col align-items-center">
        //       <div className="w-80 mt-3">{imageReference}</div>
        //       <div className="my-8">
        //         <Button
        //           onClick={capture}
        //           variant="contained"
        //           disableElevation
        //           sx={{ py: "6.5px" }}
        //           className="icon-button-style"
        //         >
        //           <PhotoCameraIcon />
        //         </Button>
        //       </div>
        //       <div className="w-100 flex justify-content-center">
        //         <Divider className="w-80 mb-4" />
        //       </div>
        //       {devices.length > 1 && (
        //         <div className="w-100 flex justify-content-center">
        //           <div className="w-80 flex flex-dir-col align-items-center justify-content-center py-5 main-container-bg">
        //             <p className="text-primary-color font-16 p-0 m-0 my-5 medium">
        //               Seleccionar cámara
        //             </p>
        //             {devices?.map(({ deviceId, label }) => (
        //               <div key={deviceId} className="w-90 my-3">
        //                 <Button
        //                   fullWidth
        //                   variant="contained"
        //                   sx={{ py: "10px" }}
        //                   disableElevation
        //                   onClick={() => setFinalDeviceId(deviceId)}
        //                   startIcon={
        //                     finalDeviceId === deviceId ? (
        //                       <CheckRoundedIcon />
        //                     ) : null
        //                   }
        //                 >
        //                   {label}
        //                 </Button>
        //               </div>
        //             ))}
        //           </div>
        //         </div>
        //       )}
        //     </div>
        //   )}
        //   {imgSrc && (
        //     <div className="w-35 flex flex-dir-col align-items-center">
        //       <div className="w-80 mt-5">
        //         <div className="w-100 flex justify-content-center align-items-center flex-dir-col">
        //           <img
        //             src={imgSrc}
        //             alt="captured_photo"
        //             className="image-style"
        //           />
        //         </div>
        //       </div>
        //       <div className="w-100 flex justify-content-center">
        //         <Divider className="w-80 my-4 mb-6" />
        //       </div>
        //       <div className="w-100 flex align-items-center justify-content-center my-3">
        //         <Button
        //           onClick={() => {
        //             setPhoto(null);
        //             setImgSrc(null);
        //           }}
        //           variant="contained"
        //           disableElevation
        //           sx={{ py: "6.25px" }}
        //           className="icon-button-style"
        //         >
        //           <DeleteIcon />
        //         </Button>
        //         <Button
        //           onClick={() => {
        //             setPhoto(imgSrc);
        //             handleClose();
        //           }}
        //           variant="contained"
        //           disableElevation
        //           className="icon-button-style mx-10"
        //         >
        //           {labels.save_photo}
        //         </Button>
        //         <Button
        //           onClick={capture}
        //           variant="contained"
        //           disableElevation
        //           sx={{ py: "6.25px" }}
        //           className="icon-button-style"
        //         >
        //           <PhotoCameraIcon />
        //         </Button>
        //       </div>
        //       <div className="w-100 flex justify-content-center">
        //         <Divider className="w-80 my-4 mt-6" />
        //       </div>
        //       {devices.length > 1 && (
        //         <div className="w-100 flex justify-content-center">
        //           <div className="w-80 flex flex-dir-col align-items-center justify-content-center py-5 main-container-bg">
        //             <p className="text-primary-color font-16 p-0 m-0 my-5 medium">
        //               Seleccionar cámara
        //             </p>
        //             {devices?.map(({ deviceId, label }) => (
        //               <div className="w-90 my-3">
        //                 <Button
        //                   fullWidth
        //                   variant="contained"
        //                   sx={{ py: "10px" }}
        //                   disableElevation
        //                   onClick={() => setFinalDeviceId(deviceId)}
        //                   startIcon={
        //                     finalDeviceId === deviceId ? (
        //                       <CheckRoundedIcon />
        //                     ) : null
        //                   }
        //                 >
        //                   {label}
        //                 </Button>
        //               </div>
        //             ))}
        //           </div>
        //         </div>
        //       )}
        //     </div>
        //   )}
        // </div>
        //)
        <>
          <div className="w-100 flex justify-content-center align-items-center">
            <div className="flex justify-content-center align-items-center my-4">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                // height={webcamHeight}
                width={webcamWidth}
                // width={"120%"}
                videoConstraints={videoConstraints}
                className="webcam-style"
              />
            </div>
          </div>
          {!imgSrc && (
            <>
              <Divider />
              {imageReference}
              <div className="flex justify-content-center align-items-center my-4">
                <Button
                  onClick={capture}
                  variant="contained"
                  disableElevation
                  sx={{ py: "6.5px" }}
                  className="icon-button-style"
                >
                  <PhotoCameraIcon />
                </Button>
              </div>
            </>
          )}
          {imgSrc && (
            <>
              <Divider />
              <div className="flex justify-content-center align-items-center flex-dir-col my-4">
                <img
                  src={imgSrc}
                  alt="captured_photo"
                  className="image-style"
                />
              </div>
              <Divider className="my-4" />
              <div className="flex align-items-center justify-content-space-evenly my-4">
                <Button
                  onClick={() => {
                    setPhoto(null);
                    setImgSrc(null);
                  }}
                  variant="contained"
                  disableElevation
                  sx={{ py: "6.25px" }}
                  className="icon-button-style"
                >
                  <DeleteIcon />
                </Button>
                <Button
                  onClick={() => {
                    setPhoto(imgSrc);
                    handleClose();
                  }}
                  variant="contained"
                  disableElevation
                  className="icon-button-style"
                >
                  {labels.save_photo}
                </Button>
                <Button
                  onClick={capture}
                  variant="contained"
                  disableElevation
                  sx={{ py: "6.25px" }}
                  className="icon-button-style"
                >
                  <PhotoCameraIcon />
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Fragment>
  );
};

export default CaptureRegisterImage;
