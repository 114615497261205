import React from "react";
import AdminDesktopHeader from "../AdminDesktopHeader";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../slices/user";
import { NETWORKS_UUIDS } from "./uuids";
import { selectLabels } from "../../slices/language";
import { CircularProgress } from "@mui/material";

const AdminDesktopIntelligenceContent = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [showIframe, setShowIframe] = React.useState<boolean>(false);
  const [networkUuid, setNetworkUuid] = React.useState<string>("");
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);

  const intelligenceLink = `https://nimanic.viurdata.com/#/public/753b10e5-a508-44f8-af3b-414ad0b3a2ee?a_uuid=${networkUuid}`;

  const headerRef: any = React.useRef(null);

  React.useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    setMainContentHeight(headerHeight);
  }, []);

  React.useEffect(() => {
    getNetworkUUID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (networkUuid) {
      setShowIframe(true);
    }
  }, [networkUuid]);

  const getNetworkUUID = () => {
    const filteredValue = NETWORKS_UUIDS.filter(
      (network) => network.id === user?.network?.id
    );
    if (filteredValue) {
      setNetworkUuid(filteredValue[0]?.uuid);
    } else {
      setNetworkUuid("");
    }
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <AdminDesktopHeader
        title={"Inteligencia"}
        buttonLabel={"NUEVA INVITACION"}
        refObject={headerRef}
      />
      <div
        style={{
          width: "100%",
          height: `calc(100% - ${mainContentHeight}px)`,
          boxSizing: "border-box",
          padding: 20,
          overflowY: "auto",
          backgroundColor: "#f8f8f8",
          position: "relative",
        }}
      >
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: `100%`,
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {showIframe && (
          <iframe
            src={intelligenceLink}
            title={labels.intelligence}
            width={"100%"}
            height={"1800px"}
            onLoad={handleIframeLoad}
            style={{
              border: "1px rgba(196, 196, 196, 0.5) solid",
              opacity: isLoading ? 0 : 1,
            }}
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default AdminDesktopIntelligenceContent;
