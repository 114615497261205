import React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  Button,
  Modal,
  Box,
  CircularProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import moment from "moment";
import ValidityForm from "../ValidityForm/ValidityForm";
import { resetUser, selectUser } from "../../slices/user";
import SearchInputOutlined from "../SearchInputOutlined/SearchInputOutlined";
import api, {
  CommunityMembers,
  CreateEventParams,
  EventGuests,
} from "../../api";
import { showError } from "../../utils/helper";
import { resetToken, selectToken } from "../../slices/token";
import { IoIosCloseCircle, IoIosSave } from "react-icons/io";
import CustomAttributes from "../CustomAttributes/CustomAttributes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};
interface AdminDesktopNewEventProps {
  showContacts: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  setShowContacts: (showContacts: boolean) => void;
  setShowContactsDisabled: (showContactsDisabled: boolean) => void;
  onCloseFunction: () => void;
}

const AdminDesktopNewEvent = ({
  showContacts,
  open,
  setOpen,
  setShowContacts,
  setShowContactsDisabled,
  onCloseFunction,
}: AdminDesktopNewEventProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [eventName, setEventName] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [hour, setHour] = React.useState<string | null>(
    moment(date).format("HH")
  );
  const [minutes, setMinutes] = React.useState<string | null>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [finalAccessPointTemporary, setFinalAccessPointTemporary] =
    React.useState<number[]>([]);
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<string>("");
  const [checked, setChecked] = React.useState(false);
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [communityMembers, setCommunityMembers] = React.useState<
    CommunityMembers[] | null
  >(null);
  const [backupCommunityMembers, setBackupCommunityMembers] = React.useState<
    CommunityMembers[] | null
  >(null);
  const [existedContact, setExistedContact] = React.useState<boolean>(false);
  const [addGuests, setAddGuests] = React.useState<EventGuests[]>([]);
  const [deniedModal, setDeniedModal] = React.useState<boolean>(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [invitationCustomAttributes, setInvitationCustomAttributes] =
    React.useState<any | null>(null);
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);
  const [errorPhoneLabel, setErrorPhoneLabel] = React.useState<boolean>(false);
  const [errorPhoneDigits, setErrorPhoneDigits] =
    React.useState<boolean>(false);
  const [errorPhoneLetters, setErrorPhoneLetters] =
    React.useState<boolean>(false);
  const [errorEmail, setErrorEmail] = React.useState<boolean>(false);

  const userId = user?.id;
  const scrollContainer: any = React.useRef(null);
  const scrollGuestRef: any = React.useRef(null);

  const isoDate = `${moment(date).format(
    `YYYY-MM-DDT${hour}:${minutes}:ss.SSSZ`
  )}`;

  const disabledButton = invitationCustomAttributes
    ? !eventName ||
      !description ||
      finalAccessPointTemporary.length === 0 ||
      addGuests.length === 0 ||
      !customAttributesRequired
    : !eventName ||
      !description ||
      finalAccessPointTemporary.length === 0 ||
      addGuests.length === 0;

  const disabledAddGuestButton = !name || !phoneNumber || errorPhoneLabel;

  const scrollToCenter = () => {
    if (scrollGuestRef.current) {
      scrollGuestRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  React.useEffect(() => {
    onReloadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onReloadComponent = () => {
    if (open) {
      setIsLoading(true);
      getInitialValues();
      setTimeout(() => {
        getCommunityMembers();
        getInvitationCustomAttributes();
      }, 400);
    } else {
      setTimeout(() => {
        scrollToTop();
      }, 400);
    }
  };

  const getInitialValues = () => {
    setEventName("");
    setDescription("");
    setAddGuests([]);
    setName("");
    setPhoneNumber("");
    setEmail("");
    setCountryCode("+52");
    setDate(new Date());
    setHour(moment(date).format("HH"));
    setMinutes("00");
    setHoursLater("1");
    setFinalAccessPointTemporary([]);
    setInvitationCustomAttributes(null);
    setSearchCommunityMember("");
    setExistedContact(false);
    setCommunityMembers(null);
    setBackupCommunityMembers(null);
    setShowContacts(false);
    setCustomAttributesRequired(false);
    setShowContactsDisabled(true);
    setErrorPhoneLabel(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLetters(false);
    setErrorEmail(false);
  };

  const scrollToTop = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = 0;
    }
  };

  const handleCheckboxChange = (id: number) => {
    if (finalAccessPointTemporary.includes(id)) {
      setFinalAccessPointTemporary(
        finalAccessPointTemporary.filter((item) => item !== id)
      );
    } else {
      setFinalAccessPointTemporary([...finalAccessPointTemporary, id]);
    }
  };

  const handleAddGuest = () => {
    const newGuest: EventGuests = {};
    newGuest.community_member = false;
    newGuest.phone = countryCode + phoneNumber;
    newGuest.name = name;
    newGuest.email = email;
    newGuest.save = saveCommunityMember;
    if (
      addGuests.find(({ name }) => name === newGuest.name) ||
      addGuests.find(({ phone }) => phone === newGuest.phone)
    ) {
      setDeniedModal(true);
      setTimeout(() => {
        setDeniedModal(false);
      }, 2500);
      // } else if (
      //   newGuest.email !== "" &&
      //   addGuests.find(({ email }) => email === newGuest.email)
      // ) {
      //   setDeniedModal(true);
    } else {
      setAddGuests([...addGuests, newGuest]);
      setName("");
      setCountryCode("+52");
      setPhoneNumber("");
      setEmail("");
      setSaveCommunityMember(false);
      setChecked(false);
    }
  };

  const handleQuitGuest = (index: number) => {
    const newArray = [...addGuests];
    newArray.splice(index, 1);
    setAddGuests(newArray);
  };

  React.useEffect(() => {
    const completePhone = countryCode + phoneNumber;
    const filteredCommunityMember = communityMembers?.filter(
      (element) => element.phone === completePhone
    );
    if (filteredCommunityMember && filteredCommunityMember?.length > 0) {
      setExistedContact(true);
      setSaveCommunityMember(false);
      setChecked(false);
    } else {
      setExistedContact(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, phoneNumber]);

  React.useEffect(() => {
    manegeSearchCommunityMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommunityMember]);

  const manegeSearchCommunityMember = () => {
    if (!!searchCommunityMember) {
      const getSearchedCommunityMember = communityMembers?.filter((element) =>
        element.name.toLowerCase().includes(searchCommunityMember.toLowerCase())
      );
      setCommunityMembers(
        getSearchedCommunityMember ? getSearchedCommunityMember : []
      );
    } else {
      setCommunityMembers(backupCommunityMembers);
    }
  };

  const onSelectCommunityMember = (cm: CommunityMembers) => {
    setName(cm.name);
    setEmail(cm.email);
    if (cm.phone.startsWith("+52")) {
      setCountryCode("+52");
      setPhoneNumber(cm.phone.substring(3));
    } else {
      setCountryCode("+1");
      setPhoneNumber(cm.phone.substring(2));
    }
  };

  const getCommunityMembers = async () => {
    if (userId) {
      try {
        setIsLoading(true);
        const result = await api.communityMembersRequest(1, 100, "");
        if (result) {
          const newArray: CommunityMembers[] = result.community_members.filter(
            (element) => element?.user?.id === userId
          );
          setCommunityMembers(newArray);
          setBackupCommunityMembers(newArray);
          if (newArray.length === 0) {
            setShowContactsDisabled(true);
          } else {
            setShowContactsDisabled(false);
          }
        }
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.user_no_access);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onMouseOverFunc = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  const checkNumbers = (str: string) => {
    return /^\d+$/.test(str);
  };

  const validateEmail = (email: string) => {
    const validateCode = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validateCode.test(email);
  };

  const onBlurTextField = (
    parameter: string,
    setParameter: (parameter: string) => void
  ) => {
    const trimName = parameter.trim();
    setParameter(trimName);
  };

  const onBlurEmailField = () => {
    if (email) {
      const trimEmail = email.trim().replace(/\s+/g, "");
      setEmail(trimEmail);
      const verifiedEmail = validateEmail(trimEmail);
      if (!verifiedEmail) {
        setErrorEmail(true);
      }
    }
  };

  const onFocusEmailField = () => {
    setErrorEmail(false);
  };

  const onBlurPhoneField = () => {
    if (phoneNumber) {
      const trimPhone = phoneNumber.trim().replace(/\s+/g, "");
      setPhoneNumber(trimPhone);
      const checkOnlyNumbers: boolean = checkNumbers(trimPhone);
      if (trimPhone.length !== 10) {
        setErrorPhoneLabel(true);
        setErrorPhoneDigits(true);
      }
      if (!checkOnlyNumbers) {
        setErrorPhoneLabel(true);
        setErrorPhoneLetters(true);
      }
    }
  };

  const onFocusPhoneField = () => {
    setErrorPhoneLabel(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLetters(false);
  };

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = invitationCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  const getInvitationCustomAttributes = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const result = await api.getInvitationCustomAttributes();
        setInvitationCustomAttributes(
          result.custom_attributes.map((item) => ({
            ...item,
            value:
              item.attribute_type === "string"
                ? ""
                : item.attribute_type === "integer"
                ? ""
                : item.attribute_type === "boolean"
                ? false
                : item.attribute_type === "list"
                ? ""
                : item.attribute_type === "date" && new Date(),
          }))
        );
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const generateEvent = async () => {
    const payload: CreateEventParams = {
      event: {
        name: eventName,
        description: description,
        start_date: moment(isoDate).format("DD/MM/YYYY HH:mm:ss"),
        tolerance: Number(hoursLater),
        event_type_id: 2,
      },
      guests: addGuests,
      access_servers: finalAccessPointTemporary,
    };
    // SECCION CUSTOM ATTRIBUTES - APLICAR CUANDO SE ARREGLE ESTA OPCION-------------------->>
    // if (invitationCustomAttributes) {
    //   const customAttributes: any = {};
    //   invitationCustomAttributes.forEach(
    //     ({ attribute_name, attribute_type, value }: any) => {
    //       customAttributes[attribute_name] =
    //         attribute_type === "date"
    //           ? moment(value).format("DD-MM-YYYY")
    //           : value;
    //     }
    //   );
    //   payload.event.custom_attrs = customAttributes;
    // }
    try {
      setIsLoading(true);
      const result = await api.createEvent(payload);
      if (result) {
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
          setOpen(false);
          onCloseFunction();
        }, 3000);
      }
    } catch (error: any) {
      console.log("error generar evento", error?.message);
      console.log("error generar evento", error?.response);
      console.error(error);
      if (
        error?.response?.status === 401 &&
        error?.message !== "timeout of 30000ms exceeded"
      ) {
        showError(dispatch, labels.not_authorized);
      } else if (
        error?.response?.status === 422 &&
        error?.message !== "timeout of 30000ms exceeded"
      ) {
        showError(dispatch, labels.wrong_parameters);
      } else if (
        error?.response?.status === 403 &&
        error?.message !== "timeout of 30000ms exceeded"
      ) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else if (error?.message === "timeout of 30000ms exceeded") {
        // setSuccessModal(true);
        console.log("timeout error");
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={scrollContainer}
        style={{
          width: 700,
          height: `calc(100% - 99.5px)`,
          boxSizing: "border-box",
          position: "relative",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          <p
            style={{
              fontSize: 18,
              marginBottom: 20,
              marginLeft: 30,
              paddingTop: 0,
            }}
          >
            {labels.event_data}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              display: "flex",
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div
              style={{
                width: "50%",
                boxSizing: "border-box",
                paddingRight: 10,
              }}
            >
              <div>
                <div style={{ width: "100%", marginBottom: 15 }}>
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.event_name} *
                  </p>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="outlined"
                      placeholder={labels.name}
                      type="text"
                      value={eventName}
                      onChange={(e) => {
                        setEventName(e.target.value);
                      }}
                      disabled={isLoading}
                      onBlur={() => onBlurTextField(eventName, setEventName)}
                    />
                  </FormControl>
                </div>
                <div style={{ width: "100%" }}>
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.description} *
                  </p>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="outlined"
                      placeholder={labels.description}
                      type="text"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      disabled={isLoading}
                      onBlur={() =>
                        onBlurTextField(description, setDescription)
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                boxSizing: "border-box",
                paddingLeft: 10,
              }}
            >
              <div>
                <div style={{ width: "100%", marginBottom: 15 }}>
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.allowed_access_points} *
                  </p>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      borderRadius: 4,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#c4c4c4",
                      padding: 10,
                    }}
                  >
                    {user?.access_servers.map(
                      ({ name, venue_name, id }, index) => (
                        <div key={id} className="flex align-items-center mb-2">
                          <Checkbox
                            onChange={() => handleCheckboxChange(id)}
                            checked={finalAccessPointTemporary.includes(id)}
                            color="secondary"
                            disabled={isLoading ? true : false}
                          />
                          <div className="flex flex-dir-col">
                            <p className="subtitle-color font-14 m-0 p-0">
                              {name}
                            </p>
                            <p className="title-color font-12 m-0 p-0">
                              {venue_name}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: 18,
              marginBottom: 20,
              marginTop: 30,
              marginLeft: 30,
              paddingTop: 0,
            }}
          >
            {labels.validity}
          </p>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              display: "flex",
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <ValidityForm
              isLoading={isLoading}
              date={date}
              setDate={setDate}
              hour={hour}
              setHour={setHour}
              minutes={minutes}
              setMinutes={setMinutes}
              hoursLater={hoursLater}
              setHoursLater={setHoursLater}
              hoursLaterTemporary=""
              setHoursLaterTemporary={() => {}}
              temporaryDuration={"days"}
              setTemporaryDuration={() => {}}
              temporaryDurationValue={""}
              setTemporaryDurationValue={() => {}}
              isTemporary={false}
              monday={false}
              setMonday={() => {}}
              tuesday={false}
              setTuesday={() => {}}
              wednesday={false}
              setWednesday={() => {}}
              thursday={false}
              setThursday={() => {}}
              friday={false}
              setFriday={() => {}}
              saturday={false}
              setSaturday={() => {}}
              sunday={false}
              setSunday={() => {}}
              allowAllDay={false}
              setAllowAllDay={() => {}}
            />
          </div>
          <p
            style={{
              fontSize: 18,
              marginBottom: 20,
              marginTop: 30,
              marginLeft: 30,
              paddingTop: 0,
            }}
          >
            {labels.guests}
          </p>
          <div
            ref={scrollGuestRef}
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "50%",
                  boxSizing: "border-box",
                  paddingRight: 10,
                }}
              >
                <div>
                  <div style={{ width: "100%", marginBottom: 15 }}>
                    <p
                      className="title-color"
                      style={{ marginBottom: 10, padding: 0 }}
                    >
                      {labels.full_name} *
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="outlined"
                        placeholder={`${labels.full_name}`}
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        disabled={isLoading}
                        onBlur={() => onBlurTextField(name, setName)}
                      />
                    </FormControl>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p
                      className="title-color"
                      style={{ marginBottom: 10, padding: 0 }}
                    >
                      {labels.email}
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="outlined"
                        placeholder={labels.email}
                        type="text"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        disabled={isLoading}
                        error={errorEmail}
                        onBlur={onBlurEmailField}
                        onFocus={onFocusEmailField}
                      />
                    </FormControl>
                    {errorEmail && (
                      <p
                        className="m-0 p-0 font-12 ml-4 mt-2"
                        style={{ color: "#d32f2f" }}
                      >
                        {labels.error_email}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  boxSizing: "border-box",
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: 15,
                  }}
                >
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.phone_number} *
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        boxSizing: "border-box",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "35%", marginRight: 5 }}>
                        <FormControl fullWidth>
                          <Select
                            value={countryCode}
                            onChange={(e) => {
                              setCountryCode(e.target.value);
                            }}
                            inputProps={{ "aria-label": "Whithout label" }}
                            disabled={isLoading}
                          >
                            <MenuItem value={"+52"}>MX +52</MenuItem>
                            <MenuItem value={"+1"}>US +1</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ width: "65%", marginLeft: 5 }}>
                        <FormControl variant="outlined" fullWidth>
                          <OutlinedInput
                            id="outlined"
                            placeholder={`${labels.phone_number} (${labels.ten_digits})`}
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                            }}
                            disabled={isLoading}
                            error={errorPhoneLabel}
                            onBlur={onBlurPhoneField}
                            onFocus={onFocusPhoneField}
                          />
                        </FormControl>
                        {errorPhoneLabel && (
                          <>
                            {errorPhoneDigits && (
                              <p
                                className="m-0 p-0 font-12 ml-4 mt-2"
                                style={{ color: "#d32f2f" }}
                              >
                                {labels.ten_digits_error_label}
                              </p>
                            )}
                            {errorPhoneLetters && (
                              <p
                                className="m-0 p-0 font-12 ml-4 mt-2"
                                style={{ color: "#d32f2f" }}
                              >
                                {labels.only_numbers}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: 45 }}
                >
                  <Checkbox
                    checked={checked}
                    value={saveCommunityMember}
                    onChange={handleSaveCommunityMemberChange}
                    disabled={isLoading ? true : existedContact}
                  />
                  <div>
                    <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                      {labels.save_contact}
                    </p>
                    {existedContact && (
                      <p className="m-0 mt-0 mb-0 font-12 mt-2 subtitle-color">
                        {labels.existed_contact}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className="flex align-items-center"
                  style={{ marginTop: 45 }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    style={{ height: 56, width: 400 }}
                    onClick={handleAddGuest}
                    disabled={isLoading ? true : disabledAddGuestButton}
                  >
                    {labels.add_guest}
                  </Button>
                </div>
              </div>
            </div>
            {addGuests && addGuests.length > 0 && (
              <>
                <p
                  className="title-color"
                  style={{ marginBottom: 10, padding: 0 }}
                >
                  {labels.guest_number}: {addGuests.length}
                </p>
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    border: "1px #c4c4c4 solid",
                    borderRadius: 4,
                  }}
                >
                  {addGuests.map((element, index) => (
                    <div
                      key={`${element.id}${index}`}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderBottom:
                          addGuests.length - 1 === index
                            ? ""
                            : "1px #f2f2f2 solid",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {element.save && (
                          <IoIosSave
                            className="title-color mr-4"
                            style={{ fontSize: 18 }}
                          />
                        )}
                        <p className="m-0 p-0">{element.name}</p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className="m-0 p-0 mr-8">{element.phone}</p>
                        <IoIosCloseCircle
                          className="primary-color"
                          style={{ fontSize: 18, cursor: "pointer" }}
                          onClick={() => handleQuitGuest(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {/* //SECCION CUSTOM ATTRIBUTES - APLICAR CUANDO SE ARREGLE ESTA OPCION------------------->> */}
          {/* {invitationCustomAttributes &&
            invitationCustomAttributes.length > 0 && (
              <>
                <p
                  style={{
                    fontSize: 18,
                    marginBottom: 20,
                    marginTop: 30,
                    marginLeft: 30,
                    paddingTop: 0,
                  }}
                >
                  {labels.others}
                </p>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    boxSizing: "border-box",
                    padding: 30,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 20,
                    alignItems: "center",
                    borderRadius: 4,
                    boxShadow:
                      "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  {invitationCustomAttributes.map(
                    (
                      {
                        id,
                        attribute_name,
                        attribute_type,
                        required,
                        options,
                        value,
                      }: any,
                      index: number
                    ) => (
                      <div
                        key={`${id}${index}`}
                        style={{
                          width: `calc(50% - 10px)`,
                          boxSizing: "border-box",
                        }}
                      >
                        <CustomAttributes
                          isLoading={isLoading}
                          type={attribute_type}
                          name={attribute_name}
                          required={required}
                          value={value}
                          setValue={(newValue: any) => {
                            const newInvitationCustomAttributes = [
                              ...invitationCustomAttributes,
                            ];
                            newInvitationCustomAttributes[index].value =
                              newValue;
                            setInvitationCustomAttributes(
                              newInvitationCustomAttributes
                            );
                          }}
                          listArray={options}
                        />
                      </div>
                    )
                  )}
                </div>
              </>
            )} */}
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 40,
              paddingBottom: 40,
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{ height: 56, width: 400 }}
              disabled={isLoading ? true : disabledButton}
              onClick={generateEvent}
            >
              {isLoading ? (
                <CircularProgress style={{ color: "white", fontSize: 16 }} />
              ) : (
                labels.create_event
              )}
            </Button>
          </div>
        </div>
      </div>
      {showContacts && (
        <div
          style={{
            width: 450,
            height: `calc(100% - 99.5px)`,
            backgroundColor: "#f8f8f8",
            boxSizing: "border-box",
            padding: 20,
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              overflow: "auto",
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="title-color"
                style={{ marginBottom: 10, padding: 0 }}
              >
                {labels.search_contact}
              </p>
              <SearchInputOutlined
                value={searchCommunityMember}
                setValue={setSearchCommunityMember}
                size="large"
              />
            </div>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {communityMembers &&
                communityMembers?.length > 0 &&
                communityMembers.map((element, index) => (
                  <div
                    key={`${element.id}${index}`}
                    style={{
                      display: "flex",
                      backgroundColor: "",
                      boxSizing: "border-box",
                      paddingLeft: 10,
                      paddingRight: 10,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottomColor: "#e4e4e4",
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      cursor: "pointer",
                    }}
                    onMouseOver={onMouseOverFunc}
                    onMouseLeave={onMouseOutFunc}
                    onClick={
                      isLoading
                        ? () => {}
                        : () => {
                            onSelectCommunityMember(element);
                            scrollToCenter();
                          }
                    }
                  >
                    <p style={{ marginRight: 10 }}>{element.name}</p>
                    <p>{element.phone}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {/* COPIED NOT ADDED COMMUNITY MEMBER--------------------------------------------------->> */}
      {/* MODAL COMMUNITY MEMBER NO ANADIDO--------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={deniedModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-14 subtitle-color text-center pt-10 pb-10">
              {labels.have_guest_one}
            </p>
          </div>
        </Box>
      </Modal>

      {/* COPIED SENDED INVITATIONS----------------------------------------------------------->> */}
      {/* MODAL INVITACIONES ENVIADAS--------------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={successModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-14 subtitle-color text-center pt-10 pb-10">
              {labels.sended_invitations}
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminDesktopNewEvent;
