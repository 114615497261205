import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface TokenState {
  value: string | null;
  status: "idle" | "loading" | "failed";
}

const initialState: TokenState = {
  value: null,
  status: "idle",
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    getToken: (state) => {
      const newState = { ...state };
      newState.status = "loading";
      const currentToken = localStorage.getItem("token");
      if (currentToken) {
        newState.value = currentToken;
        newState.status = "idle";
      }
      return newState;
    },
    gettingToken: (state) => {
      const newState = { ...state };
      newState.status = "loading";
      return newState;
    },
    setToken: (state, action) => {
      const newState = { ...state };
      newState.status = "idle";
      newState.value = action.payload;
      localStorage.setItem("token", action.payload);
      return newState;
    },
    resetToken: (state) => {
      const newState = { ...state };
      newState.status = "idle";
      newState.value = null;
      localStorage.removeItem("token");
      return newState;
    },
  },
});

export const { getToken, setToken, resetToken, gettingToken } =
  tokenSlice.actions;

export const selectToken = (state: RootState) => state.token.value;

export const selectIsLoadingToken = (state: RootState) =>
  state.token.status === "loading";

export default tokenSlice.reducer;
