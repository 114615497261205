import React from "react";
import ReactDOM from "react-dom";
import "./css/master.scss";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import AppRouter from "./routes";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#10418a",
    },
    secondary: {
      main: "#ff8000",
    },
  },
  // components: {
  //   DatePicker: {
  //     styleOverrides: {
  //       contained: {
  //         borderRadius: 30,
  //       },
  //     },
  //   },
  // },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
