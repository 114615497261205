import React from "react";
import "./invitation-list.scss";
import QRCode from "qrcode.react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaSignInAlt, FaSignOutAlt, FaLock, FaEnvelope } from "react-icons/fa";
import { ImQrcode } from "react-icons/im";
import { MdQrCode2 } from "react-icons/md";
import {
  IoQrCodeOutline,
  IoClipboardOutline,
  IoClipboardSharp,
} from "react-icons/io5";

interface InvitationListProps {
  statusIcon: string | null;
  // qrCode: string;
  visitorName: string;
  hostName: string;
  department: string;
  date: string;
  startHour: string;
  endHour: string;
  invitationType: number;
  onPress: () => void;
  isDeleted?: any;
}

const InvitationList = ({
  statusIcon,
  // qrCode,
  visitorName,
  hostName,
  department,
  date,
  startHour,
  endHour,
  invitationType,
  onPress,
  isDeleted,
}: InvitationListProps) => {
  const labels = useAppSelector(selectLabels);

  return (
    <>
      <div
        className={
          isDeleted === null
            ? "w-100 m-0 py-5 px-6 main-list-container"
            : "w-100 m-0 py-5 px-6 main-list-container-deleted"
        }
        onClick={onPress}
      >
        <div className="flex justify-content-space-between align-items-center">
          <p
            className={
              isDeleted === null
                ? "p-0 m-0 primary-color medium font-14"
                : "p-0 m-0 light-grey medium font-14"
            }
          >
            {date}
          </p>
          {invitationType === 1 && (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color medium font-14"
                  : "p-0 m-0 light-grey medium font-14"
              }
            >
              {labels.visitor}
            </p>
          )}
          {invitationType === 2 && (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color medium font-14"
                  : "p-0 m-0 light-grey medium font-14"
              }
            >
              {labels.temporary}
            </p>
          )}
          {invitationType === 3 && (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color medium font-14"
                  : "p-0 m-0 light-grey medium font-14"
              }
            >
              {labels.event}
            </p>
          )}
          {invitationType === 4 && (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color medium font-14"
                  : "p-0 m-0 light-grey medium font-14"
              }
            >
              {labels.event}
            </p>
          )}
          {invitationType === 5 && (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color medium font-14"
                  : "p-0 m-0 light-grey medium font-14"
              }
            >
              {labels.provider}
            </p>
          )}
          {invitationType === 7 && (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color medium font-14"
                  : "p-0 m-0 light-grey medium font-14"
              }
            >
              {labels.user_key}
            </p>
          )}
        </div>
        <div className="flex justify-content-space-between align-items-center mt-2">
          {invitationType !== 2 ? (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 subtitle-color font-14"
                  : "p-0 m-0 light-grey font-14"
              }
            >
              {startHour} - {endHour}
            </p>
          ) : (
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 subtitle-color font-14"
                  : "p-0 m-0 light-grey font-14"
              }
            >
              {startHour}
            </p>
          )}
          <div className="flex justify-content-space-between align-items-center">
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 subtitle-color font-14 mr-5"
                  : "p-0 m-0 light-grey font-14 mr-5"
              }
            >
              {labels.status}:
            </p>
            {statusIcon === "access" && (
              <FaSignInAlt
                fontSize="16px"
                className={
                  isDeleted === null
                    ? "title-color m-0 p-0"
                    : "light-grey m-0 p-0"
                }
              />
            )}
            {statusIcon === "exit" && (
              <FaSignOutAlt
                fontSize="16px"
                className={
                  isDeleted === null
                    ? "title-color m-0 p-0"
                    : "light-grey m-0 p-0"
                }
              />
            )}
            {statusIcon === "try" && (
              <FaLock
                fontSize="16px"
                className={
                  isDeleted === null
                    ? "title-color m-0 p-0"
                    : "light-grey m-0 p-0"
                }
              />
            )}
            {statusIcon === null && (
              <FaEnvelope
                fontSize="16px"
                className={
                  isDeleted === null
                    ? "title-color m-0 p-0"
                    : "light-grey m-0 p-0"
                }
              />
            )}
          </div>
        </div>
        <div className="flex justify-content-flex-start align-items-center mt-5">
          {invitationType !== 5 && (
            <div className="flex justify-content-center align-items-center mr-5">
              <IoQrCodeOutline
                fontSize="30px"
                className={
                  isDeleted === null
                    ? "title-color m-0 p-0"
                    : "light-grey m-0 p-0"
                }
              />
            </div>
          )}
          <div className="flex flex-dir-col justify-content-space-between align-items-flex-start">
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 primary-color font-18 medium mb-1"
                  : "p-0 m-0 light-grey font-18 medium mb-1"
              }
            >
              {visitorName}
            </p>
            <p
              className={
                isDeleted === null
                  ? "p-0 m-0 subtitle-color font 14 mt-1"
                  : "p-0 m-0 light-grey font 14 mt-1"
              }
            >
              {hostName} - {department}
            </p>
          </div>
        </div>
        {/* {isDeleted !== null && (
          <div className="deleted-text-container">
            <p className="deleted-text">{labels.deleted}</p>
          </div>
        )} */}
      </div>
    </>
  );
};

export default InvitationList;
