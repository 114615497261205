import { InputBase, Paper } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaSearch } from "react-icons/fa";

interface SearchInputHeaderProps {
  value: string;
  setValue: (value: string) => void;
}

const SearchInputHeader = ({ value, setValue }: SearchInputHeaderProps) => {
  const labels = useAppSelector(selectLabels);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "40px",
      }}
    >
      <FaSearch style={{ color: "#10418a", marginLeft: 8, fontSize: 22 }} />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${labels.search}...`}
        onChange={handleChange}
        value={value}
      />
    </Paper>
  );
};

export default SearchInputHeader;
