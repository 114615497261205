import { Paper, Button } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { selectUserRole } from "../../slices/user";
import { MENU_ITEMS } from "../MenuContainer/helper";
import "./mobile-lower-menu.scss";

const MobileLowerMenu = () => {
  const labels = useAppSelector(selectLabels);
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);
  const { pathname } = location;
  const menuItems = MENU_ITEMS(labels, userRole);
  const visibleMenuItems = menuItems.filter((item) => !item.hide);

  return (
    <Paper
      elevation={2}
      square
      className="z-99 vw-100 flex justify-content-space-between align-items-center m-p-0i lower-menu-style px-8 border-box position-fixed b-0"
      style={{ height: 70 }}
    >
      <div className="w-100 general-upper-line" />
      <div className="flex">
        {menuItems.map(({ label, icon: Icon, path, hide }) => {
          const isSelected = pathname.includes(path);
          if (hide) {
            return null;
          }
          return (
            <div key={label}>
              <Button
                variant="outlined"
                className={`m-p-0i lower-menu-button-style ${
                  isSelected ? "pressed-menu-button" : "title-color"
                }`}
                style={{ width: `calc(100vw / ${Number(visibleMenuItems.length)})` }}
                onClick={
                  isSelected
                    ? undefined
                    : () => {
                        navigate(path);
                      }
                }
              >
                {/* {isSelected && <div className="w-100 upper-line-button" />} */}
                <div className="flex flex-dir-col align-items-center m-p-0i">
                  <Icon style={{ fontSize: 24 }} className="pt-5" />
                  <p
                    className={`${
                      !isSelected && "title-color"
                    } m-0 p-0 font-12 capitalize pb-3`}
                  >
                    {label}
                  </p>
                </div>
              </Button>
            </div>
          );
        })}
        {/* <div>
          <Button
            variant="outlined"
            className="m-p-0i lower-menu-button-style pressed-menu-button"
          >
            <div className="w-100 upper-line-button" />
            <div className="flex flex-dir-col align-items-center m-p-0i">
              <EventNoteRoundedIcon fontSize="large" className="pt-5" />
              <p className="m-0 p-0 font-12 capitalize pb-3">
                {labels.logbook}
              </p>
            </div>
          </Button>
        </div>
        <div>
          <Button
            variant="outlined"
            className="title-color m-p-0i lower-menu-button-style"
          >
            <div className="flex flex-dir-col align-items-center m-p-0i">
              <QrCodeScannerRoundedIcon fontSize="large" className="pt-5" />
              <p className="title-color m-0 p-0 font-12 capitalize pb-3">
                {labels.qr_scanner}
              </p>
            </div>
          </Button>
        </div>
        <div>
          <Button variant="outlined" className="m-p-0i lower-menu-button-style">
            <div className="flex flex-dir-col align-items-center m-p-0i">
              <NoteAltRoundedIcon fontSize="large" className="pt-5" />
              <p className="m-0 p-0 font-12 capitalize pb-3">
                {labels.register}
              </p>
            </div>
          </Button>
        </div>
        <div>
          <Button variant="outlined" className="m-p-0i lower-menu-button-style">
            <div className="flex flex-dir-col align-items-center m-p-0i">
              <DraftsRoundedIcon fontSize="large" className="pt-5" />
              <p className="m-0 p-0 font-12 capitalize pb-3">
                {labels.invitations}
              </p>
            </div>
          </Button>
        </div> */}
      </div>
    </Paper>
  );
};

export default MobileLowerMenu;
