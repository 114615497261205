import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import AdminDesktopHeader from "../AdminDesktopHeader";
import {
  FaTrashAlt,
  FaQrcode,
  FaSignInAlt,
  FaSignOutAlt,
} from "react-icons/fa";
import { resetToken, selectToken } from "../../slices/token";
import { showError } from "../../utils/helper";
import { resetUser } from "../../slices/user";
import api, { AccessKey, GetAccessKeys } from "../../api";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import SearchInputOutlined from "../SearchInputOutlined";
import { BsFillPlusCircleFill } from "react-icons/bs";
import AdminDesktopNewInvitation from "../AdminDesktopNewInvitation";
import AdminDesktopDropView from "../AdminDesktopDropView/AdminDesktopDropView";
import { RiEyeCloseLine, RiEyeFill } from "react-icons/ri";
import AdminDesktopInvitationDetails from "../AdminDesktopInvitationDetails/AdminDesktopInvitationDetails";
import {
  FaShareAlt,
  FaCopy,
  FaPrint,
  FaEnvelope,
  FaEdit,
  FaFileDownload,
} from "react-icons/fa";
import { GoCircleSlash } from "react-icons/go";
import CopyToClipboard from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ButtonDatePicker from "../ButtonDatePicker/ButtonDatePicker";
import "./admin-desktop-invitation-content.scss";
import AdminDesktopEditInvitation from "../AdminDesktopEditInvitation";
import { MdDelete } from "react-icons/md";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};

interface FileToExport {
  tipo_de_invitación: string;
  vigencia: string;
  nombre_del_visitante: string;
  anfitrión: string;
  departamento: string;
  estatus: string;
  puntos_de_acceso: string;
  eliminada: string;
}

const AdminDesktopInvitationContent = () => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [invitationType, setInvitationType] = React.useState<string>("all");
  const [dateRefactor, setDateRefactor] = React.useState<string>("all");
  const [date, setDate] = React.useState<
    Date | null | "last_week" | "last_month"
  >(null);
  const [selectedDate, setSelectedDate] = React.useState<
    Date | null | undefined
  >(null);
  const [page, setPage] = React.useState<number>(1);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [statusFilter, setStatusFilter] = React.useState<
    "access" | "exit" | "try" | "unknown" | "deleted" | null
  >(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [invitationsList, setInvitationsList] =
    React.useState<GetAccessKeys | null>(null);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  const [newInvitation, setNewInvitation] = React.useState<boolean>(false);
  const [showContacts, setShowContacts] = React.useState<boolean>(false);
  const [showInvitationDetails, setShowInvitationDetails] =
    React.useState<boolean>(false);
  const [invitationId, setInvitationId] = React.useState<number | null>(null);
  const [showShareContent, setShowShareContent] =
    React.useState<boolean>(false);
  const [copiedLinkModal, setCopiedLinkModal] = React.useState<boolean>(false);
  const [editInvitation, setEditInvitation] = React.useState<boolean>(false);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [invitationsListErrorCode, setInvitationsListErrorCode] =
    React.useState<number | null>(null);
  const [invitationsListErrorCount, setInvitationsListErrorCount] =
    React.useState<number>(0);
  const [invitationsTableErrorCode, setInvitationsTableErrorCode] =
    React.useState<number | null>(null);
  const [invitationsTableErrorCount, setInvitationsTableErrorCount] =
    React.useState<number>(0);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);
  const [invitationValidFrom, setInvitationValidFrom] =
    React.useState<string>("");
  const [invitationUniqueId, setInvitationUniqueId] = React.useState<
    string | undefined
  >(undefined);
  const [invitationVisitorEmail, setInvitationVisitorEmail] =
    React.useState<string>("");
  const [showContactsDisabled, setShowContactsDisabled] =
    React.useState<boolean>(true);
  const [createdInvitationId, setCreatedInvitationId] = React.useState<
    number | null
  >(null);
  const [accessKey, setAccessKey] = React.useState<AccessKey | null>(null);
  const [deleteAccessKeyModal, setDeleteAccessKeyModal] =
    React.useState<boolean>(false);

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const concernedElement: any = React.useRef(null);
  const printIcon: any = React.useRef(null);

  const currentDate = new Date();
  const fileName = `${labels.invitations} - ${moment(currentDate).format(
    "LL"
  )}`;
  const disabledShareButton =
    !invitationValidFrom && !invitationUniqueId && !invitationVisitorEmail;

  const debouncedSearchText = useDebounce(searchInput, 500);

  const emailSubject = React.useMemo(() => {
    return `Passtrack - ${labels.invitation_text_email} ${moment(
      invitationValidFrom
    ).format("DD/MM/YYYY")}`;
  }, [labels.invitation_text_email, invitationValidFrom]);

  React.useEffect(() => {
    if (createdInvitationId) {
      setTimeout(() => {
        setInvitationId(createdInvitationId);
        setShowInvitationDetails(true);
        setCreatedInvitationId(null);
      }, 3300);
    }
  }, [createdInvitationId]);

  React.useEffect(() => {
    if (invitationsListErrorCount !== 5) {
      if (invitationsListErrorCode === 500) {
        setInvitationsListErrorCount(invitationsListErrorCount + 1);
        getInvitationsData(debouncedSearchText);
      } else if (invitationsListErrorCode === 200) {
        setInvitationsListErrorCount(0);
      }
    } else {
      showError(dispatch, labels.generic_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationsListErrorCode]);

  React.useEffect(() => {
    if (invitationsTableErrorCount !== 5) {
      if (invitationsTableErrorCode === 500) {
        setInvitationsTableErrorCount(invitationsTableErrorCount + 1);
        arrayToFile(debouncedSearchText);
      } else if (invitationsTableErrorCode === 200) {
        setInvitationsTableErrorCount(0);
      }
    } else {
      showError(dispatch, labels.generic_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationsTableErrorCode]);

  const statusFilterManagement = (
    type: "try" | "access" | "exit" | "unknown" | "delete" | null
  ) => {
    if (type === "try") {
      if (statusFilter === "try") {
        setStatusFilter(null);
      } else {
        setStatusFilter("try");
      }
    }
    if (type === "access") {
      if (statusFilter === "access") {
        setStatusFilter(null);
      } else {
        setStatusFilter("access");
      }
    }
    if (type === "exit") {
      if (statusFilter === "exit") {
        setStatusFilter(null);
      } else {
        setStatusFilter("exit");
      }
    }
    if (type === "unknown") {
      if (statusFilter === "unknown") {
        setStatusFilter(null);
      } else {
        setStatusFilter("unknown");
      }
    }
    if (type === "delete") {
      if (statusFilter === "deleted") {
        setStatusFilter(null);
      } else {
        setStatusFilter("deleted");
      }
    }
  };

  React.useEffect(() => {
    const func = (event: MouseEvent) => {
      if (showShareContent) {
        if (!concernedElement.current.contains(event.target)) {
          setShowShareContent(false);
        }
      }
    };
    document.addEventListener("mousedown", func);
    return () => document.removeEventListener("mousedown", func);
  });

  React.useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add = tableHeaderHeight + tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
  }, []);

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  const handleInvitationTypeChange = (event: SelectChangeEvent) => {
    setInvitationType(event.target.value);
  };

  React.useEffect(() => {
    getCalendarDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRefactor, selectedDate]);

  const getCalendarDate = () => {
    if (dateRefactor === "calendar") {
      if (!selectedDate) {
        setDate(null);
      } else {
        setDate(selectedDate);
      }
    }
  };

  const dateChange = (event: SelectChangeEvent) => {
    setDateRefactor(event.target.value);
    if (event.target.value === "all") {
      setDate(null);
      setSelectedDate(null);
    }
    if (event.target.value === "today") {
      setDate(new Date());
      setSelectedDate(null);
    }
    if (event.target.value === "last week") {
      setDate("last_week");
      setSelectedDate(null);
    }
    if (event.target.value === "last month") {
      setDate("last_month");
      setSelectedDate(null);
    }
    if (event.target.value === "calendar") {
      setDate(null);
      setSelectedDate(null);
    }
  };

  // END POINT INVITATIONS LIST------------------------------------------------>>
  // END POINT LISTA DE INVITACIONES------------------------------------------->>
  React.useEffect(() => {
    getInvitationsData(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    page,
    rowsPerPage,
    debouncedSearchText,
    date,
    statusFilter,
    invitationType,
  ]);

  const getInvitationsData = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.invitationsRequest(
          page,
          rowsPerPage,
          debouncedSearchText,
          date ? `${date}` : "",
          statusFilter,
          invitationType !== "all" ? invitationType : ""
        );
        setInvitationsList(result);
        setInvitationsListErrorCode(200);
      }
    } catch (error: any) {
      console.error(error);
      setInvitationsListErrorCode(error?.response?.status);
      if (error?.response?.status === 401) {
        dispatch(resetToken());
        dispatch(resetUser());
        showError(dispatch, labels.token_expired);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onMouseOverFuncIcons = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.color = "#666";
  };

  const onMouseOutFuncIcons = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.color = "";
  };

  const onMouseOverFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  const onCloseCopiedModal = () => {
    setCopiedLinkModal(false);
  };

  const onCopyInvitationLink = () => {
    setCopiedLinkModal(true);
    setTimeout(onCloseCopiedModal, 1500);
  };

  const printAction = () => {
    setTimeout(() => {
      setShowShareContent(false);
      window.print();
    }, 500);
  };

  const onPressEditInvitation = () => {
    setShowInvitationDetails(false);
    setTimeout(() => {
      setEditInvitation(true);
    }, 400);
  };

  React.useEffect(() => {
    arrayToFile(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, searchInput, date, statusFilter, invitationType]);

  const arrayToFile = async (debouncedSearchText: string) => {
    try {
      if (token) {
        const result = await api.invitationsRequest(
          1,
          50,
          debouncedSearchText,
          date ? `${date}` : "",
          statusFilter,
          invitationType !== "all" ? invitationType : ""
        );
        setFileArray(
          result.access_keys.map(
            ({
              user,
              access_key_type_id,
              valid_from_date,
              valid_until_date,
              visitor_name,
              last_transaction_type,
              access_servers,
              deleted_at,
            }) => ({
              tipo_de_invitación:
                access_key_type_id === 1
                  ? "visitante"
                  : access_key_type_id === 2
                  ? "recurrente"
                  : access_key_type_id === 3
                  ? "evento"
                  : access_key_type_id === 4
                  ? "evento"
                  : access_key_type_id === 5
                  ? "proveedor"
                  : "visitante",
              vigencia: `${valid_from_date} - ${valid_until_date}`,
              nombre_del_visitante: visitor_name,
              anfitrión: `${user?.name}`,
              departamento: `${user?.network_group?.name}`,
              estatus:
                last_transaction_type !== null
                  ? last_transaction_type === "access"
                    ? "acceso"
                    : last_transaction_type === "exit"
                    ? "salida"
                    : last_transaction_type === "try"
                    ? "denegado"
                    : "sin usar"
                  : "sin usar",
              puntos_de_acceso: access_servers
                .map(({ name }) => name)
                .join(", "),
              eliminada: deleted_at ? "si" : "",
            })
          )
        );
        setInvitationsTableErrorCode(200);
      }
    } catch (error: any) {
      console.error(error);
      setInvitationsTableErrorCode(error?.response?.status);
      if (error?.response?.status === 401) {
        // showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      }
    }
  };

  const qrCode = invitationUniqueId ? invitationUniqueId : "";
  const invitationLink =
    "https://app.passtrack.mx/my_access_key?locale=es&uuid=";
  const invitationMailLink = "https://app.passtrack.mx/my_access_key?locale=es";
  const finalLink = `${invitationLink}${qrCode}`;

  const deleteInvitation = async () => {
    if (invitationId) {
      try {
        await api.deleteAccessKey(Number(invitationId));
        setDeleteAccessKeyModal(false);
        setShowInvitationDetails(false);
        getInvitationsData(debouncedSearchText);
        arrayToFile(debouncedSearchText);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.errors.not_authorized);
        } else if (error.response.stauts === 422) {
          showError(dispatch, labels.errors.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.errors.generic_error);
        }
      }
    }
  };

  return (
    <div className="admin-desktop-invitations-main-container">
      <AdminDesktopHeader
        title={labels.my_invitations}
        buttonLabel={""}
        refObject={headerRef}
      />
      <div
        ref={filtersRef}
        className="admin-desktop-invitations-filters-container"
      >
        <div style={{ width: "25%" }}>
          <FormControl fullWidth>
            <Select
              size="small"
              sx={{ bgcolor: "white" }}
              style={{ color: "#10418a" }}
              value={invitationType}
              onChange={handleInvitationTypeChange}
            >
              <MenuItem value="all">{labels.all_types}</MenuItem>
              <MenuItem value={"visitor"}>{labels.visitor}</MenuItem>
              <MenuItem value={"temporary"}>{labels.temporary}</MenuItem>
              <MenuItem value={"provider"}>{labels.provider}</MenuItem>
              <MenuItem value={"passtrack_event"}>{labels.event}</MenuItem>
              <MenuItem value={"personal"}>{labels.user_key}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title={labels.entries} arrow placement="top">
            <div className="admin-desktop-invitations-status-icons-container">
              <FaSignInAlt
                fontSize={22}
                color={statusFilter === "access" ? "#10418a" : "#999999"}
                onClick={() => statusFilterManagement("access")}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
          <Tooltip title={labels.exits} arrow placement="top">
            <div className="admin-desktop-invitations-status-icons-container">
              <FaSignOutAlt
                fontSize={22}
                color={statusFilter === "exit" ? "#10418a" : "#999999"}
                onClick={() => statusFilterManagement("exit")}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
          <Tooltip title={labels.not_authorized} arrow placement="top">
            <div className="admin-desktop-invitations-status-icons-container">
              <GoCircleSlash
                fontSize={22}
                color={statusFilter === "try" ? "#10418a" : "#999999"}
                onClick={() => statusFilterManagement("try")}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
          <Tooltip title={labels.not_used_plural} arrow placement="top">
            <div className="admin-desktop-invitations-status-icons-container">
              <FaQrcode
                fontSize={20}
                color={statusFilter === "unknown" ? "#10418a" : "#999999"}
                onClick={() => statusFilterManagement("unknown")}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
          <Tooltip title={labels.deleted_plural} arrow placement="top">
            <div className="admin-desktop-invitations-status-icons-container">
              <FaTrashAlt
                fontSize={18}
                color={statusFilter === "deleted" ? "#10418a" : "#999999"}
                onClick={() => statusFilterManagement("delete")}
                onMouseOver={onMouseOverFuncIcons}
                onMouseLeave={onMouseOutFuncIcons}
              />
            </div>
          </Tooltip>
        </div>
        <div style={{ width: "25%" }}>
          {dateRefactor !== "calendar" ? (
            <FormControl fullWidth>
              <Select
                size="small"
                sx={{ bgcolor: "white", borderColor: "blue" }}
                style={{ color: "#10418a" }}
                value={dateRefactor}
                onChange={dateChange}
              >
                <MenuItem value={"all"}>{labels.all_the_dates}</MenuItem>
                <MenuItem value={"today"}>{labels.today}</MenuItem>
                <MenuItem value={"last week"}>{labels.last_week}</MenuItem>
                <MenuItem value={"last month"}>{labels.last_month}</MenuItem>
                <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <div className="flex flex-dir-row justify-content-space-between align-items-center">
              <div style={{ width: "47.5%" }}>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    sx={{ bgcolor: "white", borderColor: "blue" }}
                    style={{ color: "#10418a" }}
                    value={dateRefactor}
                    onChange={dateChange}
                  >
                    <MenuItem value={"all"}>{labels.all_the_dates}</MenuItem>
                    <MenuItem value={"today"}>{labels.today}</MenuItem>
                    <MenuItem value={"last week"}>{labels.last_week}</MenuItem>
                    <MenuItem value={"last month"}>
                      {labels.last_month}
                    </MenuItem>
                    <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className="flex justify-content-flex-end"
                style={{ width: "47.5%" }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ButtonDatePicker
                      label={`${
                        selectedDate == null
                          ? labels.choose
                          : moment(selectedDate).format("DD/MMM")
                      }`}
                      value={selectedDate}
                      onChange={(newValue) => setSelectedDate(newValue)}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ width: "25%", marginLeft: 10 }}>
          <SearchInputOutlined
            value={searchInput}
            setValue={setSearchInput}
            size="small"
          />
        </div>
        <div style={{ marginRight: 6, marginLeft: 20 }}>
          {fileArray && fileArray?.length > 0 ? (
            <CSVLink data={fileArray} filename={fileName}>
              <Tooltip title={labels.download_content} arrow placement="top">
                <div>
                  <FaFileDownload
                    fontSize={32}
                    color="#10418a"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Tooltip>
            </CSVLink>
          ) : (
            <FaFileDownload fontSize={32} color="#999999" />
          )}
        </div>
        <Tooltip title={labels.new_invitation} arrow placement="top-start">
          <div style={{ marginRight: 6, marginLeft: 20 }}>
            <BsFillPlusCircleFill
              fontSize={32}
              color="#10418a"
              onClick={() => setNewInvitation(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Tooltip>
      </div>
      <div
        className="admin-desktop-invitations-table-container"
        style={{
          height: `calc(100% - ${mainContentHeight}px - 25px)`,
        }}
      >
        <div ref={tableHeader} style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead className="admin-desktop-invitations-thead">
              <tr>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "20%",
                  }}
                >
                  {labels.visitor}
                </th>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "12.5%",
                  }}
                >
                  {labels.type}
                </th>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "20%",
                  }}
                >
                  {labels.user}
                </th>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "17.5%",
                  }}
                >
                  {labels.access_points}
                </th>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "10%",
                  }}
                >
                  {labels.valid_from}
                </th>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "10%",
                  }}
                >
                  {labels.valid_thru}
                </th>
                <th
                  className="admin-desktop-invitations-th"
                  style={{
                    width: "10%",
                  }}
                >
                  {labels.state}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          className="admin-desktop-invitations-table-body-container"
          style={{
            height: `calc(100% - ${containersHeight}px)`,
          }}
        >
          {isLoading ? (
            <div className="admin-desktop-invitations-loading-container">
              <CircularProgress />
            </div>
          ) : (
            <table style={{ width: "100%" }}>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
              >
                {invitationsList?.access_keys &&
                invitationsList.access_keys.length > 0 ? (
                  invitationsList.access_keys.map(
                    ({
                      id,
                      visitor_name,
                      last_transaction_type,
                      user,
                      access_servers,
                      access_key_type_id,
                      valid_from,
                      valid_until,
                      deleted_at,
                    }) => (
                      <tr
                        key={id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setInvitationId(id);
                          setShowInvitationDetails(true);
                        }}
                        onMouseOver={onMouseOverFunc}
                        onMouseLeave={onMouseOutFunc}
                      >
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "20%",
                          }}
                        >
                          {visitor_name}
                        </td>
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "12.5%",
                            textAlign: "center",
                          }}
                        >
                          {access_key_type_id === 1 && labels.visitor}
                          {access_key_type_id === 2 && labels.temporary}
                          {access_key_type_id === 3 && labels.event}
                          {access_key_type_id === 4 && labels.event}
                          {access_key_type_id === 5 && labels.provider}
                          {access_key_type_id === 7 && labels.user_key}
                        </td>
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "20%",
                          }}
                        >
                          {user?.name}
                        </td>
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "17.5%",
                          }}
                        >
                          {access_servers &&
                            access_servers.length > 0 &&
                            access_servers.map(({ name }, index) => (
                              <p key={index}>{name}</p>
                            ))}
                        </td>
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          {moment(valid_from).format("DD/MMM/YY")}
                        </td>
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          {moment(valid_until).format("DD/MMM/YY")}
                        </td>
                        <td
                          className="desktop-tables-font admin-desktop-invitations-td"
                          style={{
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          {deleted_at ? (
                            <Tooltip
                              title={labels.deleted}
                              arrow
                              placement="top"
                            >
                              <div>
                                <MdDelete fontSize={22} color="#999" />
                              </div>
                            </Tooltip>
                          ) : (
                            <>
                              {last_transaction_type === "access" && (
                                <Tooltip
                                  title={labels.entry}
                                  arrow
                                  placement="top"
                                >
                                  <div>
                                    <FaSignInAlt fontSize={22} color="#999" />
                                  </div>
                                </Tooltip>
                              )}
                              {last_transaction_type === "exit" && (
                                <Tooltip
                                  title={labels.with_exit_register}
                                  arrow
                                  placement="top"
                                >
                                  <div>
                                    <FaSignOutAlt fontSize={22} color="#999" />
                                  </div>
                                </Tooltip>
                              )}
                              {last_transaction_type === "try" && (
                                <Tooltip
                                  title={labels.not_authorized_desktop}
                                  arrow
                                  placement="top"
                                >
                                  <div>
                                    <GoCircleSlash fontSize={22} color="#999" />
                                  </div>
                                </Tooltip>
                              )}
                              {last_transaction_type === null && (
                                <Tooltip
                                  title={labels.not_used}
                                  arrow
                                  placement="top"
                                >
                                  <div>
                                    <FaQrcode fontSize={20} color="#999" />
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr className="flex justify-content-center py-7">
                    <td>{labels.no_results_found}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div
          ref={tableFooter}
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#e8e8e8",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex align-items-center ml-8 mr-4">
            <p className="pr-4 primary-color">{labels.rows}</p>
            <FormControl
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                width: 100,
              }}
            >
              <Select
                value={rowsPerPage}
                style={{ color: "#10418a" }}
                size="small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div>
          {invitationsList && (
            <div className="flex" style={{ alignItems: "center" }}>
              <div className="flex mx-2">
                <p className="primary-color">
                  {labels.page} <span className="primary-color">{page}</span>{" "}
                  {labels.of}{" "}
                  <span className="primary-color">
                    {invitationsList?.total_pages}
                  </span>
                </p>
              </div>
              <div className="mx-2">
                <IconButton
                  color="primary"
                  disabled={page <= 1}
                  onClick={handleChangeDownPage}
                >
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={page >= invitationsList?.total_pages}
                  onClick={handleChangeUpPage}
                >
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminDesktopDropView
        showView={newInvitation}
        setShowView={setNewInvitation}
        content={
          <AdminDesktopNewInvitation
            open={newInvitation}
            setOpen={setNewInvitation}
            showContacts={showContacts}
            setShowContacts={setShowContacts}
            setShowContactsDisabled={setShowContactsDisabled}
            onCloseFunction={() => {
              getInvitationsData(debouncedSearchText);
              arrayToFile(debouncedSearchText);
            }}
            setInvitationId={setCreatedInvitationId}
          />
        }
        title={labels.new_invitation}
        extraFunc={true}
        extraFuncElement={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: !showContactsDisabled ? "pointer" : "default",
            }}
            onClick={() =>
              !showContactsDisabled ? setShowContacts(!showContacts) : {}
            }
          >
            <p
              className={`${
                !showContactsDisabled ? "primary-color" : "text-disabled-color"
              }`}
              style={{
                padding: 0,
                margin: 0,
                marginRight: 10,
              }}
            >
              {labels.contacts}
            </p>
            {showContacts ? (
              <RiEyeFill
                className={`${
                  !showContactsDisabled
                    ? "primary-color"
                    : "text-disabled-color"
                }`}
                fontSize={24}
              />
            ) : (
              <RiEyeCloseLine
                className={`${
                  !showContactsDisabled
                    ? "primary-color"
                    : "text-disabled-color"
                }`}
                fontSize={24}
              />
            )}
          </div>
        }
      />
      <AdminDesktopDropView
        showView={showInvitationDetails}
        setShowView={setShowInvitationDetails}
        content={
          // eslint-disable-next-line jsx-a11y/no-access-key
          <AdminDesktopInvitationDetails
            open={showInvitationDetails}
            invitationId={invitationId ? invitationId : null}
            accessKey={accessKey}
            setAccessKey={setAccessKey}
            setInvitationValidFrom={setInvitationValidFrom}
            setInvitationUniqueId={setInvitationUniqueId}
            setInvitationVisitorEmail={setInvitationVisitorEmail}
          />
        }
        title={labels.invitation}
        onCLoseFunc={() => {
          setInvitationId(null);
          setInvitationUniqueId(undefined);
          setInvitationValidFrom("");
          setInvitationVisitorEmail("");
        }}
        extraFunc={true}
        extraFuncElement={
          <div style={{ display: "flex", alignItems: "center" }}>
            {!accessKey?.deleted_at && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                  marginRight: 14,
                }}
                onClick={() =>
                  !disabledShareButton ? setDeleteAccessKeyModal(true) : {}
                }
              >
                <MdDelete
                  className={`${
                    !disabledShareButton
                      ? "primary-color"
                      : "text-disabled-color"
                  }`}
                  fontSize={30}
                />
              </div>
            )}
            {accessKey &&
              accessKey.access_key_type_id === 1 &&
              accessKey.deleted_at === null &&
              accessKey.last_transaction_type !== "exit" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                    marginRight: 14,
                  }}
                  onClick={() =>
                    !disabledShareButton ? onPressEditInvitation() : {}
                  }
                >
                  <FaEdit
                    className={`${
                      !disabledShareButton
                        ? "primary-color"
                        : "text-disabled-color"
                    }`}
                    fontSize={30}
                  />
                </div>
              )}
            {!accessKey?.deleted_at && (
              <div
                ref={concernedElement}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() =>
                  !disabledShareButton ? setShowShareContent(true) : {}
                }
              >
                <FaShareAlt
                  className={`${
                    !disabledShareButton
                      ? "primary-color"
                      : "text-disabled-color"
                  }`}
                  fontSize={28}
                />
                {showShareContent && (
                  <div className="admin-desktop-invitations-share-content-container">
                    <CopyToClipboard text={finalLink}>
                      <div
                        style={{
                          marginRight: 20,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={onCopyInvitationLink}
                      >
                        <FaCopy style={{ color: "#10418a" }} fontSize={22} />
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 10,
                            marginTop: 4,
                            color: "#10418a",
                          }}
                        >
                          {labels.copy}
                        </p>
                      </div>
                    </CopyToClipboard>
                    <div
                      ref={printIcon}
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={printAction}
                    >
                      <FaPrint style={{ color: "#10418a" }} fontSize={22} />
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 10,
                          marginTop: 4,
                          color: "#10418a",
                        }}
                      >
                        {labels.print}
                      </p>
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: invitationVisitorEmail ? "pointer" : "default",
                      }}
                      onClick={() =>
                        invitationVisitorEmail
                          ? (window.location.href = `mailto:${invitationVisitorEmail}?subject=${emailSubject}&body=${invitationMailLink}%26uuid=${qrCode}`)
                          : {}
                      }
                    >
                      <FaEnvelope
                        className={`${
                          invitationVisitorEmail
                            ? "primary-color"
                            : "text-disabled-color"
                        }`}
                        fontSize={22}
                      />
                      <p
                        className={`${
                          invitationVisitorEmail
                            ? "primary-color"
                            : "text-disabled-color"
                        }`}
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 10,
                          marginTop: 4,
                        }}
                      >
                        {labels.email_user_detail}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
      <AdminDesktopDropView
        showView={editInvitation}
        setShowView={setEditInvitation}
        content={
          <AdminDesktopEditInvitation
            open={editInvitation}
            setOpen={setEditInvitation}
            showContacts={showContacts}
            setShowContacts={setShowContacts}
            setShowContactsDisabled={setShowContactsDisabled}
            onCloseFunction={() => {
              getInvitationsData(debouncedSearchText);
              arrayToFile(debouncedSearchText);
            }}
            invitationId={invitationId ? invitationId : null}
            setInvitationId={() => {}}
          />
        }
        title={labels.edit_invitation}
        extraFunc={true}
        extraFuncElement={
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => setShowContacts(!showContacts)}
          >
            <p
              style={{
                padding: 0,
                margin: 0,
                marginRight: 10,
                color: "#10418a",
              }}
            >
              {labels.contacts}
            </p>
            {showContacts ? (
              <RiEyeFill style={{ color: "#10418a" }} fontSize={24} />
            ) : (
              <RiEyeCloseLine style={{ color: "#10418a" }} fontSize={24} />
            )}
          </div>
        }
      />
      {/* COPIED LINK MODAL------------------------------------------------------------------->> */}
      {/* MODAL LINK COPIADO------------------------------------------------------------------>> */}
      <Modal disableEscapeKeyDown open={copiedLinkModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-14 subtitle-color text-center pt-10 pb-10">
              {labels.invite_link}
            </p>
          </div>
        </Box>
      </Modal>
      {/* DELETE INVITATION MODAL------------------------------------------------------------->> */}
      {/* MODAL ELIMINAR INVITACION----------------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={deleteAccessKeyModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-16 subtitle-color text-center pt-8 pb-6">
              {labels.delete_invitation} {accessKey?.visitor_name}
            </p>
          </div>
          <div className="w-100 flex justify-content-flex-end align-items-center mb-6">
            <Button
              onClick={() => setDeleteAccessKeyModal(false)}
              style={{ marginRight: 10 }}
            >
              {labels.cancel}
            </Button>
            <Button color="error" onClick={deleteInvitation}>
              {labels.delete}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminDesktopInvitationContent;
