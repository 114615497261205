import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaSignInAlt, FaSignOutAlt, FaClipboardList } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";

interface TransactionsListProps {
  transactionDate?: string;
  transactionHour?: string;
  visitorName?: string;
  department?: string;
  userName?: string;
  type?: string;
  accessAllowed?: boolean;
  accessGranted?: boolean;
  onPress?: () => void;
  relatedTransaction: any;
  accessKeyType: any;
}

const TransactionsList = ({
  transactionDate,
  transactionHour,
  visitorName,
  department,
  userName,
  type,
  accessAllowed,
  accessGranted,
  onPress,
  relatedTransaction,
  accessKeyType,
}: TransactionsListProps) => {
  const labels = useAppSelector(selectLabels);

  const byRegister = accessKeyType === "provider" || accessKeyType === "visit";

  return (
    <>
      <div
        className="w-100 m-0 py-5 px-6 main-list-container"
        onClick={onPress}
      >
        <div className="w-100 flex justify-content-space-between align-items-center">
          <div className="w-40 flex">
            <p className="p-0 m-0 primary-color medium font-14">
              {transactionDate}
            </p>
            <p className="p-0 m-0 subtitle-color font-14 ml-3">
              {transactionHour}
            </p>
          </div>
          <div className="w-30 flex justify-content-center">
            {accessKeyType === "visit" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.visitor}
              </p>
            )}
            {accessKeyType === "provider" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.provider}
              </p>
            )}
            {accessKeyType === "personal" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.host}
              </p>
            )}
            {accessKeyType === "temporary" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.temporary}
              </p>
            )}
            {accessKeyType === "visitor" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.visitor}
              </p>
            )}
            {accessKeyType === "eventbrite" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.event}
              </p>
            )}
            {accessKeyType === "passtrack_event" && (
              <p className="p-0 m-0 primary-color medium font-14">
                {labels.event}
              </p>
            )}
          </div>
          <div className="w-30 flex">
            <div className="w-50 flex align-items-center justify-content-flex-end">
              {type === "access" ? (
                accessGranted !== false ? (
                  accessAllowed !== false ? (
                    <FaSignInAlt fontSize="22px" className="allowed m-0 p-0" />
                  ) : (
                    <FaSignInAlt
                      fontSize="22px"
                      className="not-allowed m-0 p-0"
                    />
                  )
                ) : (
                  <FaSignInAlt
                    fontSize="22px"
                    className="not-allowed m-0 p-0"
                  />
                )
              ) : (
                relatedTransaction !== null && (
                  <FaSignInAlt
                    fontSize="22px"
                    className="allowed-related m-0 p-0"
                  />
                )
              )}
            </div>
            <div className="w-50 flex align-items-center justify-content-flex-end">
              {type === "exit" ? (
                accessGranted !== false ? (
                  accessAllowed !== false ? (
                    <FaSignOutAlt fontSize="22px" className="allowed m-0 p-0" />
                  ) : (
                    <FaSignOutAlt
                      fontSize="22px"
                      className="not-allowed m-0 p-0"
                    />
                  )
                ) : (
                  <FaSignOutAlt
                    fontSize="22px"
                    className="not-allowed m-0 p-0"
                  />
                )
              ) : (
                relatedTransaction !== null && (
                  <FaSignOutAlt
                    fontSize="22px"
                    className="allowed-related m-0 p-0"
                  />
                )
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-content-flex-start align-items-flex-start mt-5">
          {byRegister ? (
            <div className="flex justify-content-center align-items-center mr-5 pt-2">
              <FaClipboardList
                fontSize={"24px"}
                className="title-color m-0 p-0"
              />
            </div>
          ) : (
            <div className="flex justify-content-center align-items-center mr-5 pt-2">
              <IoQrCodeOutline
                fontSize={"24px"}
                className="title-color m-0 p-0"
              />
            </div>
          )}
          <div className="flex flex-dir-col justify-content-space-between align-items-flex-start">
            <p className="p-0 m-0 primary-color font-18 medium mb-1">
              {visitorName}
            </p>
            <p className="p-0 m-0 subtitle-color font 14 mt-1">
              {userName} - {department}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsList;
