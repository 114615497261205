import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

interface DepartmentsListProps {
  onPress: () => void;
  departmentName: string;
  usersNumber: number;
  accessPointsNumber: number;
}

const DepartmentsList = ({
  onPress,
  departmentName,
  usersNumber,
  accessPointsNumber,
}: DepartmentsListProps) => {
  const labels = useAppSelector(selectLabels);
  return (
    <>
      <div
        className="w-100 m-0 py-5 px-6 main-list-container"
        onClick={onPress}
      >
        <div className="flex justify-content-space-between align-items-baseline">
          <p className="p-0 m-0 primary-color font-18 medium">
            {departmentName}
          </p>
        </div>
        <div className="flex justify-content-space-between align-items-baseline mt-5">
          <p className="p-0 m-0 subtitle-color font-14 medium">
            {labels.access_points}: {accessPointsNumber}
          </p>
          <p className="p-0 m-0 subtitle-color font-14 medium">
            {labels.users_admin}: {usersNumber}
          </p>
        </div>
      </div>
    </>
  );
};

export default DepartmentsList;
