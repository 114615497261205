import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getToken, selectIsLoadingToken, selectToken } from "../slices/token";
import Login from "./Login";
import QrReader from "./QrScanner";
import Register from "./Register";
import LoadingScreen from "../components/LoadingScreen";
import MenuContainer from "../components/MenuContainer";
import SignUp from "./SignUp";
import RestorePassword from "./RestorePassword";
import QrCodeScan from "./QrCodeScan/QrCodeScan";
import { getUser, selectUser, selectUserRole } from "../slices/user";
import Error from "../components/Error";
import InvitationDetail from "./InvitationDetail";
import NewInvitation from "./NewInvitation";
import MyFavorites from "./MyFavorites";
import NewUser from "./NewUser";
import EditUser from "./EditUser";
import NewEvent from "./NewEvent";
import LinkAdminApp from "./LinkAdminApp";
import ProviderTransaction from "./ProviderTransaction";
import EditInvitation from "./EditInvitation";
import EditEvent from "./EditEvent";
import EditEventGuests from "./EditEventGuests";
import InvitationIdDetail from "./InvitationIdDetail";
import InvitationsRefactor from "./InvitationsRefactor";
import LogbookRefactor from "./LogbookRefactor";
import TransactionIdDetail from "./TransactionIdDetail";
import MyUsersRefactor from "./MyUsersRefactor";
import UserDetailRefactor from "./UserDetailRefactor";
import EventsRefactor from "./EventsRefactor";
import EventDetailRefactor from "./EventDetailRefactor";
import AddEventGuestRefactor from "./AddEventGuestRefactor";
import EditEventGuestRefactor from "./EditEventGuestRefactor";
import { isMobile } from "react-device-detect";
import DesktopGuard from "./DesktopGuard";
import DesktopAdmin from "./DesktopAdmin";
import CtpatManualRegister from "./CtpatManualRegister";
import OfflineView from "./OfflineView";
import AdminMobile from "./AdminMobile";
import AccessPointsRefactor from "./AccessPointsRefactor";
import DepartmentsRefactor from "./DepartmentsRefactor";
import UsersHostsRefactor from "./UsersHostsRefactor";
import UsersGuardsRefactor from "./UsersGuardsRefactor";
import UsersAdminsRefactor from "./UsersAdminsRefactor";
import Intelligence from "./Intelligence/Intelligence";
import EditDepartment from "./EditDepartment/EditDepartment";

const AppRouter = () => {
  const token = useAppSelector(selectToken);
  const loadingToken = useAppSelector(selectIsLoadingToken);
  const userRole = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const InvitationsPage: any = <InvitationsRefactor />;
  const LogbookPage: any = <LogbookRefactor />;
  const MyUsersPage: any = <MyUsersRefactor />;
  const EventsPage: any = <EventsRefactor />;

  const softtekNetwork = 81;
  const currentNetwork = user?.network?.id;
  const receptionSofttekUser = "recepcion_softtek@passtrack.com";

  const [currentVersion, setCurrentVersion] = React.useState<
    "mobile" | "desktop"
  >("mobile");
  const [online, setOnline] = React.useState(true);

  const isMounted = React.useRef(true);

  React.useEffect(() => {
    dispatch(getToken());
    dispatch(getUser());
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const versionHandler = () => {
    if (token && userRole === "guard") {
      if (currentVersion === "mobile") {
        setCurrentVersion("desktop");
      }
      if (currentVersion === "desktop") {
        setCurrentVersion("mobile");
      }
    }
  };

  React.useEffect(() => {
    const handleOnlineStatus = () => {
      setOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  React.useEffect(() => {
    if (user?.email === receptionSofttekUser) {
      setCurrentVersion("desktop");
    }
  }, [user]);

  if (!online) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<OfflineView />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }

  if (!token) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/restore-password" element={<RestorePassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
        <Error />
      </>
    );
  }

  if (loadingToken) {
    return <LoadingScreen />;
  }

  // if (!isMobile && userRole === "guard") {
  if (currentVersion === "desktop" && userRole === "guard") {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={<DesktopGuard backToPrevVersion={versionHandler} />}
            />
          </Routes>
        </BrowserRouter>
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        {userRole === "admin" ? (
          //ESTE CODIGO ES PARA LA VERSION DE ADMINISTRADOR DE ESCRITORIO------------------------------------->>

          !isMobile ? (
            <Routes>
              <Route path="*" element={<DesktopAdmin />} />
            </Routes>
          ) : (
            // <LinkAdminApp />
            <AdminMobile>
              <Routes>
                <Route path="/inteligence" element={<Intelligence />} />
                <Route path="/invitations" element={InvitationsPage} />
                <Route path="*" element={InvitationsPage} />
                <Route path="/invitation/:id" element={<InvitationDetail />} />
                <Route
                  path="/invitation-detail/:id"
                  element={<InvitationIdDetail />}
                />
                <Route path="/logbook" element={LogbookPage} />
                <Route path="/events" element={EventsPage} />
                <Route
                  path="/access-points"
                  element={<AccessPointsRefactor />}
                />
                <Route path="/departments" element={<DepartmentsRefactor />} />
                <Route
                  path="/edit-department/:id"
                  element={<EditDepartment />}
                />
                <Route path="/users-hosts" element={<UsersHostsRefactor />} />
                <Route path="/users-guards" element={<UsersGuardsRefactor />} />
                <Route path="/users-admins" element={<UsersAdminsRefactor />} />
                <Route path="/edit-user/:uId" element={<EditUser />} />
                <Route path="/new-event" element={<NewEvent />} />
                <Route path="/new-user" element={<NewUser />} />
                <Route
                  path="/new-invitation/:cmId"
                  element={<NewInvitation />}
                />
                <Route
                  path="/event-detail/:eventId"
                  element={<EventDetailRefactor />}
                />
                <Route
                  path="/add-event-guest/:eventId"
                  element={<AddEventGuestRefactor />}
                />
                <Route
                  path="/edit-event-guest/:eventId/:invId"
                  element={<EditEventGuestRefactor />}
                />
                <Route path="/edit-event/:id" element={<EditEvent />} />
                <Route
                  path="/transaction-detail/:id"
                  element={<TransactionIdDetail />}
                />
              </Routes>
            </AdminMobile>
          )
        ) : userRole === "host" ? (
          //ESTE CODIGO ES PARA LA VERSION DE ADMINISTRADOR DE ESCRITORIO------------------------------------->>

          !isMobile ? (
            <Routes>
              <Route path="*" element={<DesktopAdmin />} />
            </Routes>
          ) : (
            // <LinkAdminApp />
            <MenuContainer goToLatestVersion={versionHandler}>
              <Routes>
                <Route path="/invitations" element={InvitationsPage} />
                <Route path="*" element={InvitationsPage} />
                <Route path="/invitation/:id" element={<InvitationDetail />} />
                <Route
                  path="/invitation-detail/:id"
                  element={<InvitationIdDetail />}
                />
                <Route path="/logbook" element={LogbookPage} />
                <Route path="/events" element={EventsPage} />
                <Route path="/my-users" element={MyUsersPage} />
                <Route
                  path="/new-invitation/:cmId"
                  element={<NewInvitation />}
                />
                <Route path="/my-favorites" element={<MyFavorites />} />
                <Route path="/new-user" element={<NewUser />} />
                <Route path="/edit-user/:uId" element={<EditUser />} />
                <Route path="/new-event" element={<NewEvent />} />
                <Route
                  path="/edit-invitation/:id"
                  element={<EditInvitation />}
                />
                <Route path="/edit-event/:id" element={<EditEvent />} />
                <Route
                  path="/add-event-guests/:id"
                  element={<EditEventGuests />}
                />
                <Route
                  path="/transaction-detail/:id"
                  element={<TransactionIdDetail />}
                />
                <Route
                  path="/user-detail/:uId"
                  element={<UserDetailRefactor />}
                />
                <Route
                  path="/event-detail/:eventId"
                  element={<EventDetailRefactor />}
                />
                <Route
                  path="/add-event-guest/:eventId"
                  element={<AddEventGuestRefactor />}
                />
                <Route
                  path="/edit-event-guest/:eventId/:invId"
                  element={<EditEventGuestRefactor />}
                />
              </Routes>
            </MenuContainer>
          )
        ) : (
          //-------------------------------------------------------------------------------------------------->>
          // <LinkAdminApp />
          // user?.deactivated_at === null ?
          <MenuContainer goToLatestVersion={versionHandler}>
            <Routes>
              {/* <Route path="/logbook" element={<Logbook />} />
            <Route path="/test" element={<LogbookFirst />} />
            <Route path="/invitations" element={<Invitations />} />
            <Route path="/invitation/:id" element={<InvitationDetail />} /> */}
              {/* {userRole === "admin" && (
              <Route path="/access-points" element={<AccessPoints />} />
            )} */}
              {/* {userRole === "admin" && (
                <Route
                  path="/link-admin-app"
                  element={<LinkAdminApp />}
                />
            )} */}
              {/* {userRole === "guard" && isMobile && ( */}
              {userRole === "guard" && (
                <>
                  <Route
                    path="/qr-scanner/camera/:gateId"
                    element={<QrCodeScan />}
                  />
                  <Route path="/register" element={<Register />} />
                  <Route path="/register" element={<CtpatManualRegister />} />
                  <Route path="/qr-scanner" element={<QrReader />} />
                  <Route path="*" element={<QrReader />} />
                  <Route path="/logbook" element={LogbookPage} />
                  {/* <Route path="/invitations" element={<Invitations />} /> */}
                  <Route path="/invitations" element={InvitationsPage} />
                  <Route
                    path="/invitation/:id"
                    element={<InvitationDetail />}
                  />
                  <Route
                    path="/invitation-detail/:id"
                    element={<InvitationIdDetail />}
                  />
                  <Route
                    path="/provider-transaction/:id/:gateId"
                    element={<ProviderTransaction />}
                  />
                  <Route
                    path="/transaction-detail/:id"
                    element={<TransactionIdDetail />}
                  />
                  {/* <Route path="/suspended-account" element={<SuspendedAccount />} /> */}
                </>
              )}
              {/* {userRole === "host" && (
                <>
                  <Route path="/invitations" element={InvitationsPage} />
                  <Route path="*" element={InvitationsPage} />
                  <Route
                    path="/invitation/:id"
                    element={<InvitationDetail />}
                  />
                  <Route
                    path="/invitation-detail/:id"
                    element={<InvitationIdDetail />}
                  />
                  <Route path="/logbook" element={LogbookPage} />
                  <Route path="/events" element={EventsPage} />
                  <Route path="/my-users" element={MyUsersPage} />
                  <Route
                    path="/new-invitation/:cmId"
                    element={<NewInvitation />}
                  />
                  <Route path="/my-favorites" element={<MyFavorites />} />
                  <Route path="/new-user" element={<NewUser />} />
                  <Route path="/edit-user/:uId" element={<EditUser />} />
                  <Route path="/new-event" element={<NewEvent />} />
                  <Route
                    path="/edit-invitation/:id"
                    element={<EditInvitation />}
                  />
                  <Route path="/edit-event/:id" element={<EditEvent />} />
                  <Route
                    path="/add-event-guests/:id"
                    element={<EditEventGuests />}
                  />
                  <Route
                    path="/transaction-detail/:id"
                    element={<TransactionIdDetail />}
                  />
                  <Route
                    path="/user-detail/:uId"
                    element={<UserDetailRefactor />}
                  />
                  <Route
                    path="/event-detail/:eventId"
                    element={<EventDetailRefactor />}
                  />
                  <Route
                    path="/add-event-guest/:eventId"
                    element={<AddEventGuestRefactor />}
                  />
                  <Route
                    path="/edit-event-guest/:eventId/:invId"
                    element={<EditEventGuestRefactor />}
                  />
                </>
              )} */}
              {userRole === "secondary_host" && (
                <>
                  {/* <Route path="/invitations" element={<Invitations />} /> */}
                  <Route path="/invitations" element={InvitationsPage} />
                  <Route path="*" element={InvitationsPage} />
                  <Route
                    path="/invitation/:id"
                    element={<InvitationDetail />}
                  />
                  <Route
                    path="/invitation-detail/:id"
                    element={<InvitationIdDetail />}
                  />
                  <Route path="/logbook" element={LogbookPage} />
                  <Route path="/events" element={EventsPage} />
                  {/* <Route path="/my-users" element={MyUsersPage} /> */}
                  <Route
                    path="/new-invitation/:cmId"
                    element={<NewInvitation />}
                  />
                  <Route path="/my-favorites" element={<MyFavorites />} />
                  <Route path="/new-user" element={<NewUser />} />
                  <Route path="/edit-user/:uId" element={<EditUser />} />
                  <Route path="/new-event" element={<NewEvent />} />
                  <Route
                    path="/edit-invitation/:id"
                    element={<EditInvitation />}
                  />
                  <Route path="/edit-event/:id" element={<EditEvent />} />
                  <Route
                    path="/add-event-guests/:id"
                    element={<EditEventGuests />}
                  />
                  <Route
                    path="/transaction-detail/:id"
                    element={<TransactionIdDetail />}
                  />
                  <Route
                    path="/user-detail/:uId"
                    element={<UserDetailRefactor />}
                  />
                  <Route
                    path="/event-detail/:eventId"
                    element={<EventDetailRefactor />}
                  />
                  <Route
                    path="/add-event-guest/:eventId"
                    element={<AddEventGuestRefactor />}
                  />
                  <Route
                    path="/edit-event-guest/:eventId/:invId"
                    element={<EditEventGuestRefactor />}
                  />
                  {/* <Route path="/suspended-account" element={<SuspendedAccount />} /> */}
                </>
              )}
            </Routes>
          </MenuContainer>
          // ) : (
          //   <SuspendedAccount />
        )}
      </BrowserRouter>
      <Error />
    </>
  );
};

export default AppRouter;
