import { registersExampleData } from "./mockData";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Registers {
    id: string;
    date: string;
    hour: string;
    invitationType: string;
    visit: string;
    personWhoVisit: string;
    department: string;
    entryPoint: string;
    type: string;
    photos: boolean;
    provider: string;
}

export interface RegistersState {
    data: Registers[];
};

const initialState: RegistersState = {
    data: registersExampleData,
};

export const registersSlice = createSlice({
    name: "registers",
    initialState,
    reducers: {
      addRegister: (state, action) => {
        const newState = { ...state };
        newState.data = [...newState.data, action.payload];
        return newState;
      },
      setRegisters: (state, action) => {
        const newState = { ...state };
        newState.data = action.payload;
        return newState;
      },
    }, 
});

export const { addRegister, setRegisters } = registersSlice.actions;

export const selectRegistersData = (state: RootState) => state.registers.data;

export default registersSlice.reducer;