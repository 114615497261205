import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
// import { logbookExampleData } from "./mockData";

export interface LogbookRegister {
  created_at: string;
  access_granted: boolean;
  access_type: string;
  id: number;
  event_hour: string;
  visitor_name: string;
  access_key_id: number;
  external_key_id: number;
  access_key_type: string;
  access_status_id: number;
  related_transaction_id: number;
  user: {
    name: string;
    group: string;
    email: string;
    phone: string;
  };
  event: number;
  access_server: {
    name: string;
    venue_name: string;
    id: number;
  };
  access_key_status: {
    description: string;
  };
  created_by_user: {
    email: string;
    group: string;
    name: string | null;
    phone: string;
  };
  transaction_photos: [
    {
      access_server_photo_type_id: number;
      url: string;
    }
  ];
}

export interface LogbookState {
  data: LogbookRegister[];
}

const initialState: LogbookState = {
  data: [],
};

export const logbookSlice = createSlice({
  name: "logbook",
  initialState,
  reducers: {
    addRegister: (state, action) => {
      const newState = { ...state };
      newState.data = [...newState.data, action.payload];
      return newState;
    },
    setRegisters: (state, action) => {
      const newState = { ...state };
      newState.data = action.payload;
      return newState;
    },
  },
});

export const { addRegister, setRegisters } = logbookSlice.actions;

export const selectLogbookData = (state: RootState) => state.logbook.data;

export default logbookSlice.reducer;
