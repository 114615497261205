import React from "react";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import SearchInputHeader from "../SearchInputHeader";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import moment from "moment";
import "moment/locale/es";
import api, {
  GetLogbookRegister,
  GetLogbookRegisterTransaction,
} from "../../api";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import LogbookDesktopGuardCredential from "../LogbookDesktopGuardCredential";
import TransactionListContainer from "../TransactionListContainer";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Pagination from "../Pagination";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";

interface LogbookDesktopGuardProps {
  newRegister: boolean;
  setNewRegister: (newRegister: boolean) => void;
}

const LogbookDesktopGuard = ({
  newRegister,
  setNewRegister,
}: LogbookDesktopGuardProps) => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [logbookList, setLogbookList] =
    React.useState<GetLogbookRegister | null>(null);
  const [date, setDate] = React.useState<Date | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [accessServer, setAccessServer] = React.useState<number | null>(
    user?.last_access_selected?.id
      ? Number(user?.last_access_selected?.id)
      : null
  );
  const [accessServerFilter, setAccessServerFilter] = React.useState<string>(
    user?.last_access_selected?.name ? user?.last_access_selected?.name : ""
  );
  const [statusFilter, setStatusFilter] = React.useState<string>("null");
  const [filters, setFilters] = React.useState<string>("date");
  const [dateFilter, setDateFilter] = React.useState<string>("all");
  const [lastTransaction, setLastTransaction] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [lbListErrorCode, setLbListErrorCode] = React.useState<number | null>(
    null
  );
  const [lbListErrorCount, setLbListErrorCount] = React.useState<number>(0);
  const [lastTransactionErrorCode, setLastTransactionErrorCode] =
    React.useState<number | null>(null);
  const [lastTransactionErrorCount, setLastTransactionErrorCount] =
    React.useState<number>(0);
  const [expandedCredential, setExpandedCredential] = React.useState<any>(null);

  const handleCredentialClick = (credentialId: number) => {
    if (expandedCredential === credentialId) {
      setExpandedCredential(null);
    } else {
      setExpandedCredential(credentialId);
    }
  };

  const debouncedSearchText = useDebounce(searchInput, 500);

  React.useEffect(() => {
    if (newRegister === true) {
      setTimeout(() => {
        setNewRegister(false);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRegister]);

  React.useEffect(() => {
    if (lbListErrorCount !== 5) {
      if (lbListErrorCode === 500) {
        setLbListErrorCount(lbListErrorCount + 1);
        getLogbookListData(debouncedSearchText);
      } else if (lbListErrorCode === 200) {
        setLbListErrorCount(0);
      }
    } else {
      showError(dispatch, labels.generic_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lbListErrorCode]);

  React.useEffect(() => {
    if (lastTransactionErrorCount !== 5) {
      if (lastTransactionErrorCode === 500) {
        setLastTransactionErrorCount(lastTransactionErrorCount + 1);
        getLastTransaction();
      } else if (lastTransactionErrorCode === 200) {
        setLastTransactionErrorCount(0);
      }
    } else {
      showError(dispatch, labels.generic_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTransactionErrorCode]);

  React.useEffect(() => {
    setAccessServerFilterInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected]);

  const setAccessServerFilterInitial = () => {
    if (user?.last_access_selected) {
      setAccessServer(user?.last_access_selected?.id);
      setAccessServerFilter(user?.last_access_selected?.name);
    } else {
      setAccessServer(null);
      setAccessServerFilter(labels.all);
    }
  };

  const dateChange = (event: SelectChangeEvent) => {
    setDateFilter(event.target.value);
    if (event.target.value === "all") {
      setDate(null);
    }
    if (event.target.value === "today") {
      setDate(new Date());
    }
    if (event.target.value === "calendar") {
      setDate(null);
    }
  };

  const handleAccessServerFilterChange = (event: SelectChangeEvent) => {
    const filteredAccessServer = user?.access_servers.filter(
      ({ name }) => name === event.target.value
    );
    setAccessServerFilter(event.target.value);
    if (event.target.value === labels.all) {
      setAccessServer(null);
    } else {
      if (filteredAccessServer) {
        setAccessServer(filteredAccessServer[0]?.id);
      }
    }
  };

  React.useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    date,
    accessServer,
    debouncedSearchText,
    statusFilter,
    user?.last_access_selected,
    newRegister,
  ]);

  // END POINT TRANSACTIONS LIST--------------------------------------------------------------------------->>
  // END POINT LISTA DE TRANSACCIONES---------------------------------------------------------------------->>
  React.useEffect(() => {
    getLogbookListData(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    page,
    rowsPerPage,
    date,
    accessServer,
    debouncedSearchText,
    statusFilter,
    user?.last_access_selected,
    newRegister
  ]);

  const getLogbookListData = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.logbookRequestTwo(
          page,
          rowsPerPage,
          date !== null ? moment(date).format("YYYY-MM-DD") : "",
          accessServer !== null ? accessServer : "",
          debouncedSearchText,
          statusFilter !== "null" ? statusFilter : ""
        );
        setLogbookList(result);
        setExpandedCredential(result?.transactions[0]?.id);
        setLbListErrorCode(200);
      }
    } catch (error: any) {
      console.error(error);
      setLbListErrorCode(error?.response?.status);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getLastTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessServer]);

  const getLastTransaction = async () => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.logbookRequestTwo(
          1,
          1,
          "",
          accessServer !== null ? accessServer : "",
          "",
          ""
        );
        setLastTransaction(result.transactions[0]);
        setLastTransactionErrorCode(200);
      }
    } catch (error: any) {
      console.error(error);
      setLastTransactionErrorCode(error?.response?.status);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        borderLeftColor: "#d9d9d9",
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: 60,
          backgroundColor: "#d9d9d9",
          boxSizing: "border-box",
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: 18, margin: 0, padding: 0, fontWeight: "500" }}>
          {labels.logbook}
        </p>
        <div
          style={{
            width: "70%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SearchInputHeader value={searchInput} setValue={setSearchInput} />
          {showFilters ? (
            <RiFilterOffFill
              onClick={() => setShowFilters(!showFilters)}
              style={{
                margin: 0,
                padding: 0,
                fontSize: 34,
                marginLeft: 14,
                cursor: "pointer",
              }}
              className="dg-blue-color"
            />
          ) : (
            <RiFilterFill
              onClick={() => setShowFilters(!showFilters)}
              style={{ fontSize: 34, marginLeft: 14, cursor: "pointer" }}
              className="dg-blue-color"
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          paddingLeft: 20,
          paddingRight: 20,
          // paddingTop: 10,
          // paddingBottom: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#e8e8e8",
          borderBottomColor: "#d9d9d9",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        }}
      >
        {showFilters && (
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "50%", boxSizing: "border-box", marginRight: 4 }}
            >
              <FormControl
                fullWidth
                size="small"
                style={{ backgroundColor: "white", borderRadius: 4 }}
              >
                <Select
                  labelId="filters"
                  value={filters}
                  onChange={(e) => {
                    setFilters(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Whithout label" }}
                  displayEmpty
                >
                  <MenuItem value="date">{labels.date_filter}</MenuItem>
                  <MenuItem value="status">{labels.status_filter}</MenuItem>
                  <MenuItem value="accessPoints">
                    {labels.access_point_filter}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              style={{ width: "50%", boxSizing: "border-box", marginLeft: 4 }}
            >
              {filters === "date" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ backgroundColor: "white", borderRadius: 4 }}
                  >
                    <Select
                      labelId="date"
                      value={dateFilter}
                      onChange={(e) => {
                        dateChange(e);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                      displayEmpty
                    >
                      <MenuItem value={"all"}>{labels.all}</MenuItem>
                      <MenuItem value={"today"}>{labels.today}</MenuItem>
                      <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
                    </Select>
                  </FormControl>
                  {dateFilter === "calendar" && (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 8,
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                          value={date}
                          onChange={(newDate: Date | null) => {
                            setDate(newDate);
                            setPage(1);
                          }}
                          inputFormat="DD/MMM"
                          className="primary-color"
                          renderInput={({ inputRef, inputProps }) => (
                            <input
                              style={{ cursor: "pointer" }}
                              type={"button"}
                              ref={inputRef}
                              {...inputProps}
                              className={
                                date !== null
                                  ? "m-0 p-0 py-4 date-filter font-14 primary-color"
                                  : "m-0 p-0 py-4 date-filter-null font-14"
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {date === null && (
                        <div className="no-date-filter font-14 primary-color">
                          {labels.choose}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {filters === "status" && (
                <FormControl
                  fullWidth
                  size="small"
                  style={{ backgroundColor: "white", borderRadius: 4 }}
                >
                  <Select
                    labelId="status"
                    value={statusFilter}
                    onChange={(e) => {
                      setStatusFilter(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    displayEmpty
                  >
                    <MenuItem value="null">{labels.all}</MenuItem>
                    <MenuItem value="access">
                      {labels.with_access_register}
                    </MenuItem>
                    <MenuItem value="exit">
                      {labels.with_exit_register}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {filters === "accessPoints" && (
                <FormControl
                  fullWidth
                  size="small"
                  style={{ backgroundColor: "white", borderRadius: 4 }}
                >
                  <Select
                    labelId="accessPoints"
                    value={accessServerFilter}
                    onChange={(e) => {
                      handleAccessServerFilterChange(e);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    displayEmpty
                  >
                    <MenuItem value={labels.all}>{labels.all}</MenuItem>
                    {user?.access_servers?.map(({ name, id }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
        )}
        {/* <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <LogbookDesktopGuardCredential
            idTransaction={Number(lastTransaction?.id)}
          />
        </div> */}
      </div>
      {isLoading ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="w-100 flex flex-dir-col pb-6"
          style={{
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            overflow: "scroll",
          }}
        >
          {logbookList && logbookList?.transactions?.length > 0 ? (
            logbookList?.transactions.map(
              ({
                access_allowed,
                access_granted,
                access_key_type,
                access_type,
                created_at,
                related_transaction_id,
                visitor_name,
                user,
                id,
              }) => (
                <div
                  key={id}
                  className="w-100 flex justify-content-center mt-5"
                >
                  <TransactionListContainer
                    transactionDate={`${moment(created_at).format(
                      `DD`
                    )} ${moment(created_at)
                      .format(`MMM`)
                      .replace(
                        /^./,
                        moment(created_at).format(`MMM`)[0].toUpperCase()
                      )}`}
                    transactionHour={moment(created_at).format("hh:mma")}
                    accessAllowed={access_allowed}
                    accessGranted={access_granted}
                    type={access_type}
                    relatedTransaction={related_transaction_id}
                    userName={user?.name}
                    department={user?.group}
                    visitorName={visitor_name}
                    accessKeyType={access_key_type}
                    onPress={() => handleCredentialClick(id)}
                    expanded={expandedCredential === id}
                    hasExitButton={false}
                    idTransaction={id}
                    onClickExit={() => {}}
                    onClickExitAccessKey={() => {}}
                  />
                </div>
              )
            )
          ) : (
            <div className="w-100 flex justify-content-center">
              <p className="font-16 subtitle-color">
                {labels.no_results_found}
              </p>
            </div>
          )}
          {logbookList?.total_pages ? (
            <div style={{ marginTop: 14 }}>
              <Pagination
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
                totalPages={logbookList?.total_pages}
                isDesktop={true}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default LogbookDesktopGuard;
