import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { selectUser } from "../../slices/user";
import Logo from "../Logo";
import "./content-to-print.scss";

interface ComponentToPrintProps {
  visitorName: string;
  visitorLastname: string;
  accessTime: string;
  hostName: string;
  department: string;
  imageUrl: string;
}

const ContentToPrint = React.forwardRef(
  (
    {
      visitorName,
      visitorLastname,
      accessTime,
      hostName,
      department,
      imageUrl,
    }: ComponentToPrintProps,
    ref: any
  ) => {
    const labels = useAppSelector(selectLabels);
    const user = useAppSelector(selectUser);

    const companyLogo = user?.network.logo_url;
    const visitorPhoto = imageUrl;

    return (
      <div
        ref={ref}
        className="w-100 h-100 flex justify-content-center align-items-center"
      >
        <div className="w-95 h-95">
          <div className="w-100 h-85 flex">
            <div className="w-60 h-100 mr-2 mt-4">
              {user && companyLogo ? (
                <img
                  src={companyLogo}
                  alt={"NetworkLogo"}
                  className="company-logo"
                />
              ) : (
                <Logo
                  type="passtrackLogo"
                  alt="Passtrack"
                  className="company-logo"
                />
              )}
              {/* <Logo
                type="lenovoLogo"
                alt="Passtrack"
                className="company-logo"
              /> */}
              <p
                className={
                  visitorName.length > 35
                    ? "font-12 m-0 ml-4 medium"
                    : "font-14 m-0 ml-4 medium"
                }
              >
                {visitorName}
              </p>
              {/* <p className="font-14 m-0 ml-4 medium">{visitorLastname}</p> */}
              <div className="w-100 flex justify-content-flex-start mt-4 ml-4">
                <p
                  className={
                    visitorName.length > 35 &&
                    hostName.length > 20 &&
                    department.length > 20
                      ? "font-10 m-0 mr-4"
                      : "font-11 m-0 mr-4"
                  }
                >
                  {labels.enter}
                </p>
                <p
                  className={
                    visitorName.length > 35 &&
                    hostName.length > 20 &&
                    department.length > 20
                      ? "font-10 m-0"
                      : "font-11 m-0"
                  }
                >
                  {accessTime}
                </p>
              </div>
              <div className="w-100 flex justify-content-flex-start mt-2 ml-4">
                <p
                  className={
                    visitorName.length > 35 &&
                    hostName.length > 20 &&
                    department.length > 20
                      ? "font-10 m-0 mr-4"
                      : "font-11 m-0 mr-4"
                  }
                >
                  {labels.receive}
                </p>
                <p
                  className={
                    visitorName.length > 35 &&
                    hostName.length > 20 &&
                    department.length > 20
                      ? "font-10 m-0"
                      : "font-11 m-0"
                  }
                >
                  {hostName}
                </p>
              </div>
              <div className="w-100 flex justify-content-flex-start mt-2 ml-4">
                <p
                  className={
                    visitorName.length > 35 &&
                    hostName.length > 20 &&
                    department.length > 20
                      ? "font-10 m-0 mr-4"
                      : "font-11 m-0 mr-4"
                  }
                >
                  {labels.dept}
                </p>
                <p
                  className={
                    visitorName.length > 35 &&
                    hostName.length > 20 &&
                    department.length > 20
                      ? "font-10 m-0"
                      : "font-11 m-0"
                  }
                >
                  {department}
                </p>
              </div>
            </div>
            <div className="w-40 h-100 flex justify-content-center align-items-center mt-4">
              {visitorPhoto && (
                <img src={visitorPhoto} alt={"UserPhoto"} width="90%" />
              )}
            </div>
          </div>
          <div className="w-100 h-15 flex align-items-center justify-content-flex-end">
            <p className="font-9 m-0 mr-2 medium">Powered by: </p>
            <Logo
              type="passtrackLogo"
              alt="Passtrack"
              className="passtrack-logo"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ContentToPrint;
