import React from "react";
import { useLocation } from "react-router-dom";

const CtpatManualRegister = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <div style={{ width: "100%", boxSizing: "border-box", padding:20 }}>
      <p style={{ textAlign: "center", fontSize: 16, fontWeight: "bold" }}>
        Ventana de validación de formulario CTPAT
      </p>
      <p>
        Parámetros de la URL:
      </p>
      <ul>
        {Array.from(query.entries()).map(([key, value]) => (
          <li key={key}>
            {key}: {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CtpatManualRegister;
