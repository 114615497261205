
export const providerExampleData = [
    {
        value: "uber",
        label: "Uber",
    },
    {
        value: "amazon",
        label: "Amazon",
    },
    {
        value: "mercado_libre",
        label: "Mercado libre",
    },
    {
        value: "didi",
        label: "Didi",
    },
    {
        value: "pizza_hut",
        label: "Pizza Hut",
    },
    {
        value: "dominos_pizza",
        label: "Domino's Pizza",
    },
    {
        value: "fedex",
        label: "FedEx",
    },
    {
        value: "dhl",
        label: "DHL",
    },
];