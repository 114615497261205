import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import ContentToPrint from "../ContentToPrint";

interface LabelPrintProps {
  visitorName: string;
  accessTime: string;
  hostName: string;
  department: string;
  imageUrl: string;
  visitorLastname: string;
}

const LabelPrint = ({
  visitorName,
  accessTime,
  hostName,
  department,
  imageUrl,
  visitorLastname,
}: LabelPrintProps) => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();

  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="w-100 flex flex-dir-col align-items-center">
      <div
        style={{
          width: 540,
          height: 360,
          marginBottom: 10,
          borderRadius: 20,
          backgroundColor: "rgba(0,0,0,0.05)",
          display: "none",
        }}
        className="upper-shadow"
      >
        <ContentToPrint
          visitorName={visitorName}
          visitorLastname={visitorLastname}
          accessTime={accessTime}
          hostName={hostName}
          department={department}
          imageUrl={imageUrl}
          ref={componentRef}
        />
      </div>
      <Button
        variant="contained"
        disableElevation
        size="large"
        fullWidth
        onClick={handlePrint}
      >
        {labels.print_label}
      </Button>
    </div>
  );
};

export default LabelPrint;
