import { Box, Modal } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import { useAppSelector } from "../../app/hooks";
// import { selectLabels } from "../../slices/language";
import { useDeviceSelectors } from "react-device-detect";
import nativeLogos from "../../assets/appstore-googleplay.png";
import passtrack from "../../assets/logo-passtrack.png";

const ModalToNativeApp = () => {
  // const labels = useAppSelector(selectLabels);

  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  console.log("selectors", selectors);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [device, setDevice] = React.useState<"ios" | "android" | null>(null);

  const iosNativeRef = "itms-apps://apps.apple.com/app/passtrack/id1629047546";
  const androidNativeRef =
    "https://play.google.com/store/apps/details?id=com.passtrack";

  React.useEffect(() => {
    const nativeModal = sessionStorage.getItem("native_modal");
    if (nativeModal !== "true") {
      getDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDevice = () => {
    if (selectors.isMobile) {
      if (selectors.isAndroid) {
        setDevice("android");
        setShowModal(true);
      }
      if (
        selectors.isIOS ||
        selectors.isIOS13 ||
        selectors.isIPad13 ||
        selectors.isIPhone13
      ) {
        setDevice("ios");
        setShowModal(true);
      }
    } else {
      setDevice(null);
    }
  };

  const closeAction = () => {
    setShowModal(false);
    sessionStorage.setItem("native_modal", "true");
  };

  const onClickDownload = () => {
    if (device === "ios") {
      window.location.href = iosNativeRef;
      closeAction();
    }
    if (device === "android") {
      window.location.href = androidNativeRef;
      closeAction();
    }
  };

  return (
    <>
      <Modal disableEscapeKeyDown open={showModal}>
        <Box sx={style}>
          <div
            className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary mt-4"
            style={{ height: 42 }}
          >
            <div style={{ width: "20%" }}></div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
            <div
              className="flex align-items-center justify-content-flex-end"
              style={{ width: "20%" }}
            >
              <CloseRoundedIcon
                className="primary-color"
                onClick={closeAction}
              />
            </div>
          </div>
          <div className="w-100 flex justify-content-center aling-items-center">
            <p
              className="subtitle-color font-16 m-0 mt-8"
              style={{ textAlign: "center" }}
            >
              Aprovecha más funcionalidades con nuestra aplicación movil
            </p>
          </div>
          <div className="w-100 flex justify-content-center">
            <img src={passtrack} alt="passtrack" width={"90%"} />
          </div>
          <div className="w-100 flex justify-content-center aling-items-center">
            <p
              className="subtitle-color font-18 m-0 mb-8"
              style={{ textAlign: "center" }}
            >
              Disponible en:
            </p>
          </div>
          <div className="w-100 flex justify-content-center mb-8">
            <img src={nativeLogos} alt="native-logos" width={"60%"} />
          </div>
          <div className="w-100 flex justify-content-center align-items-center mb-10 mt-9">
            <div
              className="background-primary-color flex shadow-one justify-content-center align-items-center"
              style={{ width: "85%", height: 42, borderRadius: 4 }}
              onClick={() => onClickDownload()}
            >
              <p className="text-white uppercase">Descárgala aquí</p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};

export default ModalToNativeApp;
