import React from "react";
import {
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import "./mobile-menu.scss";
import Logo from "../Logo";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken } from "../../slices/token";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {
  resetUser,
  selectUser,
  selectUserRole,
  setUser,
} from "../../slices/user";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { showError } from "../../utils/helper";
import DynamicKey from "../DynamicKey";
import { FaUserCircle, FaCommentDots } from "react-icons/fa";
import { BiHelpCircle } from "react-icons/bi";
import { isMobile } from "react-device-detect";
import { FaCog } from "react-icons/fa";
import AdminMobileSecMenu from "../AdminMobileSecMenu/AdminMobileSecMenu";
interface MenuMobileProps {
  goToLatestVersion: () => void;
}

const MobileMenu = ({ goToLatestVersion }: MenuMobileProps) => {
  const [isAccountOpen, setIsAccountOpen] = React.useState(false);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUserRole);
  const navigate = useNavigate();

  const [accessPoint, setAccessPoint] = React.useState<string>(
    user?.last_access_selected?.id ? `${user?.last_access_selected.id}` : ""
  );
  const [showAdminMenu, setShowAdminMenu] = React.useState<boolean>(false);
  // const [isMobileBySize, setIsMobileBySize] = React.useState<boolean>(false);

  const isHost = userRole === "host" || userRole === "secondary_host";

  const chatLink = "https://tawk.to/chat/619b47ca6bb0760a4943b0e0/1fl390rh2";

  // const handleResize = () => {
  //   const windowWidth = window.innerWidth;
  //   const windowHeight = window.innerHeight;
  //   // const isMobile =
  //   //   /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
  //   //     window.navigator.userAgent
  //   //   );
  //   const isMobileBySO = isMobile;
  //   if (windowHeight > windowWidth) {
  //     setIsMobileBySize(true);
  //   } else {
  //     if (isMobileBySO) {
  //       setIsMobileBySize(true);
  //     } else if (windowWidth <= 767) {
  //       setIsMobileBySize(true);
  //     } else {
  //       setIsMobileBySize(false);
  //     }
  //   }
  // };

  // const handleResize = () => {

  // }

  // React.useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const handleAccessPointChange = async (event: SelectChangeEvent) => {
    try {
      setAccessPoint(event.target.value);
      const newUserData = await api.changeAccessServerRequest({
        access_server: Number(event.target.value),
      });
      dispatch(setUser(newUserData));
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  React.useEffect(() => {
    handleAccessPointChangeStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleAccessPointChangeStart = async () => {
    if (user) {
      if (user.last_access_selected?.id) {
        setAccessPoint(`${user.last_access_selected?.id}`);
      }
    }
  };

  return (
    <>
      <AdminMobileSecMenu show={showAdminMenu} setShow={setShowAdminMenu} />
      {!isAccountOpen && (
        <Paper
          elevation={2}
          square
          className={
            user?.roles[0]?.name !== "security_guard"
              ? "z-99 vw-100 flex justify-content-space-between align-items-center menu-bar px-8 border-box position-fixed t-0"
              : "z-99 vw-100 flex justify-content-space-between align-items-center menu-bar-guard px-8 border-box position-fixed t-0"
          }
        >
          {user?.roles[0].name === "network_admin" && (
            <FaCog
              fontSize={24}
              style={{
                cursor: "pointer",
                color: "#10418a",
              }}
              onClick={() => setShowAdminMenu(!showAdminMenu)}
            />
          )}
          {userRole !== "guard" &&
            (user?.network?.logo_url !== null ? (
              <div className="flex align-items-center">
                <img
                  src={user?.network?.logo_url}
                  alt={"NetworkLogo"}
                  className="network-logo"
                />
              </div>
            ) : (
              <div className="flex align-items-center">
                <Logo
                  type="passtrackLogo"
                  alt="Passtrack"
                  className="mobile-logo"
                />
              </div>
            ))}
          {userRole === "guard" && (
            <div
              className="w-80"
              style={isMobile ? { maxWidth: 225 } : { maxWidth: 300 }}
            >
              <FormControl fullWidth variant="standard" size="small">
                <InputLabel id="access-point-label">
                  {labels.access_point}
                </InputLabel>
                <Select
                  labelId="access-point-label"
                  className="text-primary-color"
                  value={accessPoint}
                  onChange={handleAccessPointChange}
                  label={labels.access_point}
                >
                  {user?.access_servers.map(({ name, id }) => (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="flex align-items-center justify-content-center horizontal-spacing-5">
            {isHost && (
              <a
                href="https://passtrack.mx/manual-app-anfitrion/"
                target="_blank"
                rel="noreferrer"
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BiHelpCircle fontSize={24} className="primary-color" />
              </a>
            )}
            {/* CODIGO QUE MUESTRA EL BOTON PARA PASAR A VERSION DE ESCRITORIO------------------------->> */}

            {userRole === "guard" && !isMobile && (
              <div style={{ paddingRight: 8 }}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  size="medium"
                  onClick={goToLatestVersion}
                  disabled={false}
                >
                  {labels.go_to_latestVersion}
                </Button>
              </div>
            )}

            {/* -------------------------------------------------------------------------------------->> */}
            {isHost && <DynamicKey />}
            {/* <FaUserCircle
              fontSize={24}
              className="primary-color"
              onClick={() => {
                setIsAccountOpen(true);
              }}
            /> */}
            {/* //CODIGO PARA APLICAR CHAT ---------------------------------------------------------------->> */}
            {/* <div style={{ display: "flex", paddingRight: 6 }}>
              <a href={chatLink} target="_blank" rel="noopener noreferrer">
                <FaCommentDots
                  fontSize={24}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    color: "#10418a",
                  }}
                />
              </a>
            </div> */}
            <div style={{ display: "flex" }}>
              <FaUserCircle
                fontSize={24}
                style={{
                  position: "relative",
                  cursor: "pointer",
                  color: "#10418a",
                }}
                onClick={() => {
                  setIsAccountOpen(true);
                }}
              />
            </div>
          </div>
        </Paper>
      )}
      {isAccountOpen && (
        <Paper className="z-100 vh-100 vw-100 flex justify-content-space-between align-items-center flex-dir-col position-fixed t-0">
          <>
            <Paper
              elevation={2}
              square
              className="z-100 vw-100 flex justify-content-space-between align-items-center menu-bar px-8 border-box"
            >
              {user?.network.logo_url !== null ? (
                <div>
                  <img
                    src={user?.network.logo_url}
                    alt={"NetworkLogo"}
                    className="network-logo"
                  />
                </div>
              ) : (
                <Logo
                  type="passtrackLogo"
                  alt="Passtrack"
                  className="mobile-logo"
                />
              )}
              <CloseRoundedIcon
                className="subtitle-color"
                onClick={() => {
                  setIsAccountOpen(false);
                }}
              />
            </Paper>
            <Paper className="w-90 mt-0 pb-10 flex flex-dir-col justify-content-center align-items-center paper-no-style">
              <AccountCircleRoundedIcon
                sx={{ fontSize: 40 }}
                className="title-color mt-10"
              />
              <p className="title-color font-14">{labels.session_started}</p>
              {user && user?.name && (
                <p className="subtitle-color font-20 medium mt-1 mb-1">
                  {user?.name}
                </p>
              )}
              {user && user.email && (
                <p className="title-color font-14 mt-1 mb-2">{user?.email}</p>
              )}
              {user && user.phone && (
                <p className="title-color font-14 mt-1 mb-2">{user?.phone}</p>
              )}
              <p className="text-primary-color font-16 medium m-0 p-0 mt-6">
                {userRole === "host" && labels.host}
                {userRole === "secondary_host" && labels.secondary_host}
                {userRole === "guard" && labels.guard}
                {userRole === "admin" && labels.network_admin}
              </p>
              <p className="subtitle-color font-16 mt-6 mb-1">
                {user?.network?.company_name}
              </p>
              {userRole !== "guard" && (
                <p className="subtitle-color mt-0 pt-0">
                  {user?.network_group?.name}
                </p>
              )}
              <p className="subtitle-color font-14 mt-3 mb-1 medium">
                {labels.access_points}:
              </p>
              {user?.access_servers.map(({ name, id }) => (
                <p key={id} className="title-color font-12 my-1">
                  {name}
                </p>
              ))}
              <div
                className="w-100 flex justify-content-space-evenly align-items-center mt-10 mb-6"
                style={{ maxWidth: 400 }}
              >
                <div
                  onClick={
                    userRole === "guard"
                      ? () => {}
                      : () => {
                          navigate(`/edit-user/${user?.id}`);
                          setIsAccountOpen(false);
                        }
                  }
                  className="m-0 p-0 flex flex-dir-col justify-content-center align-items-center button-style"
                >
                  <EditRoundedIcon
                    color={userRole === "guard" ? "disabled" : "primary"}
                    sx={{ fontSize: 30 }}
                    className="p-0 m-0"
                  />
                  <div className="flex flex-dir-col pr-3">
                    <p
                      className={
                        userRole === "guard"
                          ? "text-center p-0 m-0 font-12 text-disabled-color mt-2"
                          : "text-center p-0 m-0 font-12 text-primary-color mt-2"
                      }
                    >
                      {labels.edit_user}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    dispatch(resetToken());
                    dispatch(resetUser());
                    navigate("/");
                  }}
                  className="m-0 p-0 flex flex-dir-col justify-content-center align-items-center button-style"
                >
                  <LogoutRoundedIcon
                    color={"primary"}
                    sx={{ fontSize: 30 }}
                    className="p-0 m-0"
                  />
                  <div className="flex flex-dir-col pr-3">
                    <p
                      className={
                        "text-center p-0 m-0 font-12 text-primary-color mt-2"
                      }
                    >
                      {labels.logout}
                    </p>
                  </div>
                </div>
              </div>
            </Paper>
            <div className="flex justify-content-center align-items-center flex-dir-col mt-1 mb-10 vw-100 px-8 py-6 vertical-spacing-4 order-box">
              <a
                href="https://passtrack.mx/aviso-de-privacidad/"
                target="_blank"
                rel="noreferrer"
                className="uppercase"
              >
                {labels.privacy_notice}
              </a>
            </div>
          </>
        </Paper>
      )}
    </>
  );
};

export default MobileMenu;
