import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaSignInAlt, FaSignOutAlt, FaClipboardList } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { Button, CircularProgress, Paper } from "@mui/material";
import api, { GetLogbookRegisterTransaction } from "../../api";
import { showError } from "../../utils/helper";
import { resetToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { IoIosCloseCircle } from "react-icons/io";
import { FaCarAlt, FaIdCard, FaUser, FaLaptop } from "react-icons/fa";
import "./transaction-list-container.scss";
import ModalPhotoEnlarge from "../ModalPhotoEnlarge";
import { MdSubtitles } from "react-icons/md";
import { BACK_ID_NETWORKS } from "../../utils/constants";

interface TransactionListContainerProps {
  transactionDate?: string;
  transactionHour?: string;
  visitorName?: string;
  department?: string;
  userName?: string;
  type?: string;
  accessAllowed?: boolean;
  accessGranted?: boolean;
  onPress?: () => void;
  relatedTransaction: any;
  accessKeyType: any;
  isAccessKey?: boolean;
  expanded: boolean;
  hasExitButton: boolean;
  idTransaction: number;
  onClickExit: () => void;
  onClickExitAccessKey: () => void;
}

const TransactionListContainer = ({
  transactionDate,
  transactionHour,
  visitorName,
  department,
  userName,
  type,
  accessAllowed,
  accessGranted,
  onPress,
  relatedTransaction,
  accessKeyType,
  isAccessKey,
  expanded,
  hasExitButton,
  idTransaction,
  onClickExit,
  onClickExitAccessKey,
}: TransactionListContainerProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transactionId, setTransactionId] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [findTransactionId, setFindTransactionId] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [photoType, setPhotoType] = React.useState<
    "id" | "front_plates" | "back_plates" | "other" | "accreditation" | ""
  >("");
  const [transactionPhoto, setTransactionPhoto] = React.useState<string>("");
  const [transactionIdErrorCode, setTransactionIdErrorCode] = React.useState<
    number | null
  >(null);
  const [transactionIdErrorCount, setTransactionIdErrorCount] =
    React.useState<number>(0);
  const [showPhotoModal, setShowPhotoModal] = React.useState<boolean>(false);

  const byRegister = accessKeyType === "provider" || accessKeyType === "visit";
  const enableAnimation = expanded && !isLoading;

  const customAttributesArray: any[] = transactionId?.custom_attrs
    ? Object.entries(transactionId?.custom_attrs)
    : [];

  React.useEffect(() => {
    if (!expanded) {
      setTransactionId(null);
      setFindTransactionId(null);
    }
  }, [expanded]);

  const expandPhoto = (event: any) => {
    event.stopPropagation();
    setShowPhotoModal(true);
  };

  const setPhotoFunction = (
    event: any,
    type: "id" | "front_plates" | "back_plates" | "other" | "accreditation"
  ) => {
    event.stopPropagation();
    setPhotoType(type);
  };

  React.useEffect(() => {
    if (transactionIdErrorCount !== 5) {
      if (transactionIdErrorCode === 500) {
        setTransactionIdErrorCount(transactionIdErrorCount + 1);
        getTransactionId(idTransaction);
      } else if (transactionIdErrorCode === 200) {
        setTransactionIdErrorCount(0);
      }
    } else {
      showError(dispatch, labels.generic_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionIdErrorCode]);

  React.useEffect(() => {
    getInitialPhotoType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, findTransactionId]);

  const getInitialPhotoType = () => {
    if (findTransactionId && transactionId?.access_type === "exit") {
      const idTransactionPhoto = findTransactionId?.transaction_photos?.find(
        ({ access_server_photo_type }) => access_server_photo_type === "id"
      );
      const frontPlatesTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "front_plates"
        );
      const backPlatesTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "back_plates"
        );
      const otherTransactionPhoto = findTransactionId?.transaction_photos?.find(
        ({ access_server_photo_type }) => access_server_photo_type === "other"
      );
      const accreditationTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "accreditation"
        );
      if (idTransactionPhoto) {
        setPhotoType("id");
      } else if (frontPlatesTransactionPhoto) {
        setPhotoType("front_plates");
      } else if (backPlatesTransactionPhoto) {
        setPhotoType("back_plates");
      } else if (otherTransactionPhoto) {
        setPhotoType("other");
      } else if (accreditationTransactionPhoto) {
        setPhotoType("accreditation");
      }
    } else {
      if (transactionId && transactionId?.access_type === "access") {
        const idTransactionPhoto = transactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) => access_server_photo_type === "id"
        );
        const frontPlatesTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "front_plates"
          );
        const backPlatesTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "back_plates"
          );
        const otherTransactionPhoto = transactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) => access_server_photo_type === "other"
        );
        const accreditationTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "accreditation"
          );
        if (idTransactionPhoto) {
          setPhotoType("id");
        } else if (frontPlatesTransactionPhoto) {
          setPhotoType("front_plates");
        } else if (backPlatesTransactionPhoto) {
          setPhotoType("back_plates");
        } else if (otherTransactionPhoto) {
          setPhotoType("other");
        } else if (accreditationTransactionPhoto) {
          setPhotoType("accreditation");
        }
      }
    }
  };

  React.useEffect(() => {
    getTransactionPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoType, transactionId, findTransactionId]);

  const getTransactionPhoto = () => {
    if (findTransactionId && transactionId?.access_type === "exit") {
      const filteredTransactionPhoto =
        findTransactionId?.transaction_photos?.filter(
          ({ access_server_photo_type }) =>
            access_server_photo_type === photoType
        );
      setTransactionPhoto(filteredTransactionPhoto[0]?.url);
    }
    if (transactionId && transactionId?.access_type === "access") {
      const filteredTransactionPhoto =
        transactionId?.transaction_photos?.filter(
          ({ access_server_photo_type }) =>
            access_server_photo_type === photoType
        );
      setTransactionPhoto(filteredTransactionPhoto[0]?.url);
    }
  };

  // END POINT GET TRANSACTION ID-------------------------------------------------------------------------->>
  // END POINT TOMAR ID DE TRANSACCION--------------------------------------------------------------------->>
  React.useEffect(() => {
    if (idTransaction && expanded) {
      getTransactionId(Number(idTransaction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTransaction, expanded]);

  const getTransactionId = async (transactionId: number) => {
    try {
      setIsLoading(true);
      const result = await api.transactionIdRequest(transactionId);
      setTransactionId(result);
      setTransactionIdErrorCode(200);
      if (result?.related_transaction_id) {
        const resultRelatedTransaction = await api.transactionIdRequest(
          result?.related_transaction_id
        );
        setFindTransactionId(resultRelatedTransaction);
        setTransactionIdErrorCode(200);
      }
    } catch (error: any) {
      console.error(error);
      setTransactionIdErrorCode(error.response.status);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.unprocessable_entity);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="credential"
      style={{ position: "relative" }}
      onClick={onPress}
    >
      {isLoading && (
        <div
          style={{
            width: 50,
            height: 50,
            borderRadius: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: `calc(50% - 25px)`,
            left: `calc(50% - 25px)`,
            zIndex: 99,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          }}
        >
          <CircularProgress style={{ color: "rgba(16, 65, 138, 0.75)" }} />
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            className="primary-color medium"
            style={{ margin: 0, padding: 0, marginRight: 8 }}
          >
            {transactionDate}
          </p>
          <p className="subtitle-color" style={{ margin: 0, padding: 0 }}>
            {transactionHour}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ marginRight: 12, display: "flex", alignItems: "center" }}
            >
              {type === "access" ? (
                accessGranted !== false ? (
                  accessAllowed !== false ? (
                    <FaSignInAlt fontSize="16px" className="allowed m-0 p-0" />
                  ) : (
                    <FaSignInAlt
                      fontSize="16px"
                      className="not-allowed m-0 p-0"
                    />
                  )
                ) : (
                  <FaSignInAlt
                    fontSize="16px"
                    className="not-allowed m-0 p-0"
                  />
                )
              ) : relatedTransaction !== null ? (
                <FaSignInAlt
                  fontSize="16px"
                  className="allowed-related m-0 p-0"
                />
              ) : (
                <FaSignInAlt fontSize="16px" className="blank m-0 p-0" />
              )}
            </div>
            <div
              style={{ marginRight: 12, display: "flex", alignItems: "center" }}
            >
              {type === "exit" ? (
                accessGranted !== false ? (
                  accessAllowed !== false ? (
                    <FaSignOutAlt fontSize="16px" className="allowed m-0 p-0" />
                  ) : (
                    <FaSignOutAlt
                      fontSize="16px"
                      className="not-allowed m-0 p-0"
                    />
                  )
                ) : (
                  <FaSignOutAlt
                    fontSize="16px"
                    className="not-allowed m-0 p-0"
                  />
                )
              ) : relatedTransaction !== null ? (
                <FaSignOutAlt
                  fontSize="16px"
                  className="allowed-related m-0 p-0"
                />
              ) : (
                <FaSignOutAlt fontSize="16px" className="blank m-0 p-0" />
              )}
            </div>
          </div>
          {accessKeyType === "visit" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.visitor}
            </p>
          )}
          {accessKeyType === "provider" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.provider}
            </p>
          )}
          {accessKeyType === "personal" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.host}
            </p>
          )}
          {accessKeyType === "temporary" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.temporary}
            </p>
          )}
          {accessKeyType === "visitor" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.visitor}
            </p>
          )}
          {accessKeyType === "eventbrite" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.event}
            </p>
          )}
          {accessKeyType === "passtrack_event" && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.event}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 12,
        }}
      >
        {byRegister ? (
          <div className="flex justify-content-center align-items-center mr-5 pt-2">
            <FaClipboardList
              fontSize={"24px"}
              className="title-color m-0 p-0"
            />
          </div>
        ) : (
          <div className="flex justify-content-center align-items-center mr-5 pt-2">
            <IoQrCodeOutline
              fontSize={"24px"}
              className="title-color m-0 p-0"
            />
          </div>
        )}
        <div>
          <p
            className="primary-color font-18 medium"
            style={{ margin: 0, padding: 0, marginBottom: 2 }}
          >
            {visitorName}
          </p>
          <p
            className="subtitle-color"
            style={{ margin: 0, padding: 0, marginTop: 2 }}
          >{`${userName} - ${department}`}</p>
        </div>
      </div>
      <div className={`show-content ${enableAnimation ? "expanded" : ""}`}>
        {expanded && (
          <div style={{ width: "100%" }}>
            {transactionId?.access_allowed === false &&
              (transactionId?.access_granted === false ? (
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: 12,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IoIosCloseCircle
                      fontSize="28px"
                      className="text-warning m-0 p-0"
                    />
                    <p className="p-0 m-0 ml-4 subtitle-color medium font-18">
                      {labels.unauthorized_access}
                    </p>
                  </div>
                  <p className="p-0 m-0 mt-2 text-warning">
                    {labels.status}:{" "}
                    {transactionId?.access_key_status?.description ===
                      "access_removed" && labels.qr_code_deleted}
                    {transactionId?.access_key_status?.description ===
                      "not_in_time" && labels.access_out_of_time}
                    {transactionId?.access_key_status?.description ===
                      "door_not_included" && labels.access_door_denied}
                    {transactionId?.access_key_status?.description ===
                      "only_access" && labels.last_access_not_registered}
                    {transactionId?.access_key_status?.description ===
                      "only_exit" && labels.last_exit_not_registered}
                    {transactionId?.access_key_status?.description ===
                      "visitor_key" && labels.new_qr_code}
                    {transactionId?.access_key_status?.description ===
                      "not_during_scheduled_hour" &&
                      labels.hour_access_unauthorized}
                  </p>
                  <p className="m-0 p-0 subtitle-color mt-2">
                    {labels.record_created_by}{" "}
                    {transactionId?.created_by_user?.name
                      ? transactionId?.created_by_user?.name
                      : "N/A"}
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: 12,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IoIosCloseCircle
                      fontSize="28px"
                      className="text-warning m-0 p-0"
                    />
                    <p className="p-0 m-0 ml-4 subtitle-color medium font-18">
                      {labels.access_denied}
                    </p>
                  </div>
                  <p className="p-0 m-0 mt-2 text-warning">{`${labels.reason}: ${transactionId?.notes}`}</p>
                  <p className="m-0 p-0 subtitle-color mt-2">
                    {labels.record_created_by}{" "}
                    {transactionId?.created_by_user?.name
                      ? transactionId?.created_by_user?.name
                      : "N/A"}
                  </p>
                </div>
              ))}
            {transactionId?.access_allowed !== false &&
              transactionId?.access_granted !== false && (
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    marginTop: 12,
                  }}
                >
                  {transactionId?.access_type === "exit"
                    ? findTransactionId &&
                      findTransactionId?.transaction_photos.length > 0 && (
                        <div
                          style={{
                            width: "auto",
                            boxSizing: "border-box",
                            display: "flex",
                          }}
                        >
                          <div
                            className="background-gray-color"
                            style={{
                              boxSizing: "border-box",
                              padding: 4,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: 100,
                                height: 100,
                                boxSizing: "border-box",
                                backgroundColor: "black",
                                cursor: "pointer",
                              }}
                              onClick={expandPhoto}
                            >
                              <img
                                src={transactionPhoto}
                                alt="captured_photo_id"
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="background-gray-color"
                            style={{
                              // height: 108,
                              boxSizing: "border-box",
                              paddingTop: 4,
                              paddingBottom: 4,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            {findTransactionId?.transaction_photos.map(
                              ({ access_server_photo_type }) => (
                                <div key={access_server_photo_type}>
                                  {access_server_photo_type === "id" && (
                                    <FaIdCard
                                      fontSize="24px"
                                      className={
                                        photoType === "id"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "id")
                                      }
                                    />
                                  )}
                                  {access_server_photo_type ===
                                    "front_plates" && (
                                    <FaCarAlt
                                      fontSize="24px"
                                      className={
                                        photoType === "front_plates"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "front_plates")
                                      }
                                    />
                                  )}
                                  {access_server_photo_type === "back_plates" &&
                                    user?.network.id !== BACK_ID_NETWORKS && (
                                      <FaUser
                                        fontSize="24px"
                                        className={
                                          photoType === "back_plates"
                                            ? "primary-color m-0 p-0 mx-5"
                                            : "title-color m-0 p-0 mx-5"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) =>
                                          setPhotoFunction(event, "back_plates")
                                        }
                                      />
                                    )}
                                  {access_server_photo_type === "back_plates" &&
                                    user?.network.id === BACK_ID_NETWORKS && (
                                      <MdSubtitles
                                        fontSize="24px"
                                        className={
                                          photoType === "back_plates"
                                            ? "primary-color m-0 p-0 mx-5"
                                            : "title-color m-0 p-0 mx-5"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) =>
                                          setPhotoFunction(event, "back_plates")
                                        }
                                      />
                                    )}
                                  {access_server_photo_type === "other" && (
                                    <FaLaptop
                                      fontSize="24px"
                                      className={
                                        photoType === "other"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "other")
                                      }
                                    />
                                  )}
                                  {access_server_photo_type ===
                                    "accreditation" && (
                                    <FaUser
                                      fontSize="24px"
                                      className={
                                        photoType === "accreditation"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "accreditation")
                                      }
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    : transactionId &&
                      transactionId?.transaction_photos.length > 0 && (
                        <div
                          style={{
                            width: "auto",
                            boxSizing: "border-box",
                            display: "flex",
                          }}
                        >
                          <div
                            className="background-gray-color"
                            style={{
                              boxSizing: "border-box",
                              padding: 4,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: 100,
                                height: 100,
                                boxSizing: "border-box",
                                backgroundColor: "black",
                                cursor: "pointer",
                                zIndex: 99,
                              }}
                              onClick={expandPhoto}
                            >
                              <img
                                src={transactionPhoto}
                                alt="captured_photo_id"
                                width={"100%"}
                                height={"100%"}
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </div>
                          <div
                            className="background-gray-color"
                            style={{
                              // height: 108,
                              paddingTop: 4,
                              paddingBottom: 4,
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            {transactionId?.transaction_photos.map(
                              ({ access_server_photo_type }) => (
                                <div key={access_server_photo_type}>
                                  {access_server_photo_type === "id" && (
                                    <FaIdCard
                                      fontSize="24px"
                                      className={
                                        photoType === "id"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "id")
                                      }
                                    />
                                  )}
                                  {access_server_photo_type ===
                                    "front_plates" && (
                                    <FaCarAlt
                                      fontSize="24px"
                                      className={
                                        photoType === "front_plates"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "front_plates")
                                      }
                                    />
                                  )}
                                  {access_server_photo_type === "back_plates" &&
                                    user?.network.id !== BACK_ID_NETWORKS && (
                                      <FaUser
                                        fontSize="24px"
                                        className={
                                          photoType === "back_plates"
                                            ? "primary-color m-0 p-0 mx-5"
                                            : "title-color m-0 p-0 mx-5"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) =>
                                          setPhotoFunction(event, "back_plates")
                                        }
                                      />
                                    )}
                                  {access_server_photo_type === "back_plates" &&
                                    user?.network.id === BACK_ID_NETWORKS && (
                                      <MdSubtitles
                                        fontSize="24px"
                                        className={
                                          photoType === "back_plates"
                                            ? "primary-color m-0 p-0 mx-5"
                                            : "title-color m-0 p-0 mx-5"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) =>
                                          setPhotoFunction(event, "back_plates")
                                        }
                                      />
                                    )}
                                  {access_server_photo_type === "other" && (
                                    <FaLaptop
                                      fontSize="24px"
                                      className={
                                        photoType === "other"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "other")
                                      }
                                    />
                                  )}
                                  {access_server_photo_type ===
                                    "accreditation" && (
                                    <FaUser
                                      fontSize="24px"
                                      className={
                                        photoType === "accreditation"
                                          ? "primary-color m-0 p-0 mx-5"
                                          : "title-color m-0 p-0 mx-5"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) =>
                                        setPhotoFunction(event, "accreditation")
                                      }
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                  <div style={{ marginLeft: 14 }}>
                    {transactionId?.notes && (
                      <p className="m-0 p-0 mb-4 subtitle-color">
                        <span className="medium">{labels.notes}:</span>{" "}
                        {transactionId?.notes}
                      </p>
                    )}
                    {customAttributesArray &&
                      customAttributesArray.length > 0 && (
                        <div>
                          {customAttributesArray.map((item, index) => (
                            <p
                              key={index}
                              className="m-0 p-0 mb-4 subtitle-color"
                            >
                              <span className="medium">{item[0]}:</span>{" "}
                              {item[1]}
                            </p>
                          ))}
                        </div>
                      )}
                    {transactionId?.created_by_user?.name && (
                      <p className="m-0 p-0 subtitle-color">
                        <span className="medium">
                          {labels.record_created_by}
                        </span>{" "}
                        {transactionId?.created_by_user?.name
                          ? transactionId?.created_by_user?.name
                          : "N/A"}
                      </p>
                    )}
                  </div>
                </div>
              )}
            {hasExitButton && !isAccessKey && (
              <div style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  size="medium"
                  onClick={onClickExit}
                  disabled={false}
                >
                  {labels.check_out}
                </Button>
              </div>
            )}
            {hasExitButton && isAccessKey && (
              <div style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  size="medium"
                  onClick={onClickExitAccessKey}
                  disabled={false}
                >
                  {labels.check_out}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <ModalPhotoEnlarge
        showModal={showPhotoModal}
        setShowModal={setShowPhotoModal}
        transactionPhoto={transactionPhoto}
      />
    </div>
  );
};

export default TransactionListContainer;
