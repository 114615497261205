import {
  Container,
  Grid,
  IconButton,
  Modal,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import api, { AccessKey } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { showError } from "../../utils/helper";
// import moment from "moment";
import moment from "moment-timezone";
// import "moment/locale/es";
import "moment-timezone/node_modules/moment/locale/es";
import CallIcon from "@mui/icons-material/Call";
import user, { resetUser, selectUser, selectUserRole } from "../../slices/user";
import QRCode from "qrcode.react";
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import "./invitationDetail.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import NoMeetingRoomRoundedIcon from "@mui/icons-material/NoMeetingRoomRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import { resetToken } from "../../slices/token";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";
import MailIcon from "@mui/icons-material/Mail";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
};

const InvitationDetail = () => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  // var moment = require("moment-timezone");

  const { id } = useParams();

  const [invitationId, setInvitationId] = React.useState<AccessKey | null>(
    null
  );
  const [copiedLinkModal, setCopiedLinkModal] = React.useState<boolean>(false);
  const [accessDoor, setAccessDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(
        ({ type }) => type === "access"
      )[0]?.id
    }`
  );
  const [selectDoorModal, setSelectDoorModal] = React.useState<boolean>(false);
  const [exitDoor, setExitDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(({ type }) => type === "exit")[0]
        ?.id
    }`
  );
  const [selectExitDoorModal, setSelectExitDoorModal] =
    React.useState<boolean>(false);
  const [serverTimeZone, setServerTimeZone] = React.useState<
    string | undefined
  >(undefined);
  const [validFromText, setValidFromText] = React.useState<string>("");
  const [validUntilText, setValidUntilText] = React.useState<string>("");

  const disabledContinueButton = !accessDoor;
  const disabledExitContinueButton = !exitDoor;
  const disabledGenerateAccess =
    invitationId?.last_transaction_type === "access";
  const disabledGenerateExit = invitationId?.last_transaction_type === "exit";

  React.useEffect(() => {
    getTimeZone();
  }, [invitationId]);

  const getTimeZone = () => {
    if (invitationId && invitationId.access_servers[0].time_zone) {
      const timeZone = invitationId?.access_servers[0].time_zone;
      const timeZoneComplete = timeZone.replace(/ /g, "_");
      setServerTimeZone(timeZoneComplete);
    }
  };

  const emailSubject = React.useMemo(() => {
    return `Passtrack - ${labels.invitation_text_email} ${moment(
      invitationId?.valid_from
    ).format("DD/MM/YYYY")}`;
  }, [labels.invitation_text_email, invitationId?.valid_from]);

  const handleAccessDoorChange = (event: SelectChangeEvent) => {
    setAccessDoor(event.target.value);
  };

  const handleExitDoorChange = (event: SelectChangeEvent) => {
    setExitDoor(event.target.value);
  };

  React.useEffect(() => {
    changeAccessDoor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const changeAccessDoor = () => {
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0].id
      }`
    );
  };

  React.useEffect(() => {
    changeExitDoor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const changeExitDoor = () => {
    setExitDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "exit"
        )[0].id
      }`
    );
  };

  const onValidateAccessInvitation = () => {
    setTimeout(
      () => navigate(`/provider-transaction/${id}/${accessDoor}`),
      500
    );
  };

  const onValidateExitInvitation = () => {
    setTimeout(() => navigate(`/provider-transaction/${id}/${exitDoor}`), 500);
  };

  const onCloseCopiedModal = () => {
    setCopiedLinkModal(false);
  };

  const onCopyInvitationLink = () => {
    setCopiedLinkModal(true);
    setTimeout(onCloseCopiedModal, 1500);
  };

  const momentLang = () => {
    if (navigator.language.includes("es")) {
      moment.locale("es");
      moment.localeData("es");
    } else {
      moment.locale("en");
      moment.localeData("en");
    }
  };

  React.useEffect(() => {
    momentLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (id) {
      getInvitationById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getInvitationById = async () => {
    if (id) {
      try {
        const result = await api.invitationIdRequest(Number(id));
        setInvitationId(result);
        setValidFromText(
          moment(result.valid_from)
            .tz(result.access_servers[0]?.time_zone_name)
            .format("dddd D/MMM/YY - h:mma")
        );
        setValidUntilText(
          moment(result.valid_until)
            .tz(result.access_servers[0]?.time_zone_name)
            .format("dddd D/MMM/YY - h:mma")
        );
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  const qrCode = invitationId?.unique_id;
  const invitationLink =
    "https://app.passtrack.mx/my_access_key?locale=es&uuid=";
  const invitationMailLink = "https://app.passtrack.mx/my_access_key?locale=es";
  const finalLink = `${invitationLink}${qrCode}`;

  const onClickEditInvitation = () => {
    setTimeout(() => navigate(`/edit-invitation/${id}`), 500);
  };

  //END POINT DELETE INVITATION----------------------------------------------------------------------->>
  //END POINT BORRAR INVITACION----------------------------------------------------------------------->>
  const deleteInvitation = async () => {
    if (invitationId) {
      try {
        await api.deleteAccessKey(Number(invitationId?.id));
        navigate(`/invitations`);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.errors.not_authorized);
        } else if (error.response.stauts === 422) {
          showError(dispatch, labels.errors.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.errors.generic_error);
        }
      }
    }
  };

  return (
    <>
      {/* {userRole === "guard" ? (
        <div className="container-1 w-100">
          <Grid container>
            <Grid
              item
              xs={12}
              className="w-100 flex justify-content-space-between align-items-center my-6 pt-5"
            >
              <p className="m-0 p-0 font-24 font-w-400 secondary-color">
                {labels.invitation_details}
              </p>
              <IconButton
                onClick={() => navigate(`/invitations`)}
                className="m-0 p-0"
              >
                <CloseIcon className="m-0 p-0" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="container-1 w-100">
          <Grid container>
            <Grid
              item
              xs={12}
              className="w-100 flex justify-content-space-between align-items-center"
            >
              <p className="my-6 font font-24 font-w-400 secondary-color">
                {labels.invitation_details}
              </p>
              <IconButton
                onClick={() => navigate(`/invitations`)}
                className="m-0 p-0"
              >
                <CloseIcon className="m-0 p-0" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )} */}
      <Container>
        <div className="w-100">
          <Grid container className="flex align-items-center pt-5">
            <Grid
              item
              xs={12}
              md={6}
              className="flex align-items-center justify-content-space-between"
            >
              <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
                {labels.invitation_details}
              </p>
              <IconButton onClick={() => navigate("/invitations")}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      </Container>
      <Container>
        {invitationId?.access_key_type_id === 1 && (
          <p className="text-center font-14 title-color medium mb-2">
            {labels.invitation_type}: <span>{labels.visitor}</span>
          </p>
        )}
        {invitationId?.access_key_type_id === 2 && (
          <p className="text-center font-14 title-color medium mb-2">
            {labels.invitation_type}: <span>{labels.temporary}</span>
          </p>
        )}
        {invitationId?.access_key_type_id === 3 && (
          <p className="text-center font-14 title-color medium mb-2">
            {labels.invitation_type}: <span>{labels.event}</span>
          </p>
        )}
        {invitationId?.access_key_type_id === 4 && (
          <p className="text-center font-14 title-color medium mb-2">
            {labels.invitation_type}: <span>{labels.event}</span>
          </p>
        )}
        {invitationId?.access_key_type_id === 5 && (
          <p className="text-center font-14 title-color medium mb-2">
            {labels.invitation_type}: <span>{labels.provider}</span>
          </p>
        )}
        <p className="text-center font-22 mt-2 mb-3 subtitle-color">
          {invitationId?.visitor_name}
        </p>
        {invitationId && invitationId?.deleted_at !== null && (
          <div className="w-100 flex justify-content-center">
            <p className="text-warning font-16 text-center m-0 p-0 my-4">
              {labels.deleted_access_key}
            </p>
          </div>
        )}
        <div className="w-100 flex justify-content-center">
          <div className="background-border-grey w-95 mt-5 py-5">
            <p className="title-color text-center m-0 p-0">
              {labels.last_registered_status}
            </p>
            <div className="flex justify-content-center align-items-center mt-2">
              <div className="pr-2">
                {invitationId?.last_transaction_type === "access" && (
                  <p className="subtitle-color font-18 medium m-0 p-0">
                    {labels.access}
                  </p>
                )}
                {invitationId?.last_transaction_type === "exit" && (
                  <p className="subtitle-color font-18 medium m-0 p-0">
                    {labels.exit}
                  </p>
                )}
                {invitationId?.last_transaction_type === "try" && (
                  <p className="subtitle-color font-18 medium m-0 p-0">
                    {labels.with_unauthorized_attempts}
                  </p>
                )}
                {invitationId?.last_transaction_type === null && (
                  <p className="subtitle-color font-18 medium m-0 p-0">
                    {labels.invitations_with_not_registration}
                  </p>
                )}
              </div>
              <div className="pl-2">
                {invitationId?.last_transaction_type === "access" && (
                  <div className="w-100 flex justify-content-center">
                    <LoginIcon sx={{ color: "#666" }} className="m-0 p-0" />
                  </div>
                )}
                {invitationId?.last_transaction_type === "exit" && (
                  <div className="w-100 flex justify-content-center">
                    <LogoutIcon sx={{ color: "#666" }} className="m-0 p-0" />
                  </div>
                )}
                {invitationId?.last_transaction_type === "try" && (
                  <div className="w-100 flex justify-content-center">
                    <NoMeetingRoomRoundedIcon
                      sx={{ color: "#666" }}
                      className="m-0 p-0"
                    />
                  </div>
                )}
                {invitationId?.last_transaction_type === null && (
                  <div className="w-100 flex justify-content-center">
                    <MailIcon sx={{ color: "#666" }} className="m-0 p-0" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {userRole !== "guard" && invitationId?.access_key_type_id !== 5 && (
          <div className="w-100 flex flex-dir-col qr-code-background justify-content-center, align-items-center">
            <div className="qr-container">
              <QRCode
                value={`${qrCode}`}
                size={200}
                level={"H"}
                // includeMargin={true}
                renderAs="svg"
              />
            </div>
            <div
              className="flex justify-content-space-evenly icons-container"
              style={{ maxWidth: 300 }}
            >
              <CopyToClipboard text={finalLink}>
                <IconButton size="large" onClick={onCopyInvitationLink}>
                  <FileCopyRoundedIcon fontSize="inherit" />
                </IconButton>
              </CopyToClipboard>
              <IconButton
                size="large"
                onClick={() => {
                  window.print();
                }}
              >
                <PrintRoundedIcon fontSize="inherit" />
              </IconButton>
              {invitationId?.visitor_email ? (
                <IconButton
                  size="large"
                  onClick={() =>
                    (window.location.href = `mailto:${invitationId.visitor_email}?subject=${emailSubject}&body=${invitationMailLink}%26uuid=${qrCode}`)
                  }
                >
                  <EmailRoundedIcon fontSize="inherit" />
                </IconButton>
              ) : (
                <IconButton size="large" disabled>
                  <EmailRoundedIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          </div>
        )}
        <div className="w-100 flex justify-content-center">
          <div className="background-border-grey w-95 mt-5 py-5">
            <p className="title-color ml-8 font-14 mt-3 mb-3">
              {labels.responsible_host}
            </p>
            {userRole === "guard" ? (
              <div className="w-100 mb-8 flex justify-content-center">
                <div className="w-90 flex justify-content-space-between align-items-center qr-tel-style">
                  <div className="ml-5 my-5">
                    <p className="title-color m-0 mb-1 font-18 medium">
                      {invitationId?.user?.name}
                    </p>
                    <p className="title-color font-16 m-0 mt-1">
                      {invitationId?.user?.group}
                    </p>
                  </div>
                  <div>
                    <a href={`tel:${invitationId?.user?.phone}`}>
                      <IconButton className="call-icon-button-style-button mr-5">
                        <CallIcon className="call-icon-button-style-icon" />
                      </IconButton>
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className="subtitle-color font-16 medium p-0 m-0 mt-1 ml-10">
                  {invitationId?.user.name}
                </p>
                <p className="title-color font-14 m-0 p-0 mb-1 ml-10 mb-6">
                  {invitationId?.user.group}
                </p>
              </div>
            )}
            {/* <div className="w-100 mb-8 flex justify-content-center">
            <div className="w-90 flex justify-content-space-between align-items-center qr-tel-style">
              <div className="ml-5 my-5">
                <p className="title-color m-0 mb-1 font-18 medium">
                  {invitationId?.user?.name}
                </p>
                <p className="title-color font-16 m-0 mt-1">
                  {invitationId?.user?.group}
                </p>
              </div>
              <div>
                <a href={`tel:${invitationId?.user?.phone}`}>
                  <IconButton className="call-icon-button-style-button mr-5">
                    <CallIcon className="call-icon-button-style-icon" />
                  </IconButton>
                </a>
              </div>
            </div>
          </div> */}
            <p className="title-color font-14 m-0 p-0 mb-1 ml-8">
              {labels.beginning}
            </p>
            <p className="subtitle-color font-16 medium p-0 m-0 mt-1 ml-10 mb-5">
              {/* {moment(invitationId?.valid_from).format("dddd D/MMM/YY - h:mma")} */}
              {/* {serverTimeZone
                ? moment(invitationId?.valid_from)
                    .tz(`America/${serverTimeZone}`)
                    .format("dddd D/MMM/YY - h:mma")
                : moment(invitationId?.valid_from).format(
                    "dddd D/MMM/YY - h:mma"
                  )} */}
              {validFromText}
              {/* {moment(invitationId?.valid_from)
                .tz(`${invitationId?.access_servers[0]?.time_zone_name}`)
                .format("dddd D/MMM/YY - h:mma")} */}
            </p>
            <p className="title-color font-14 m-0 p-0 mb-1 ml-8 mt-5">
              {labels.expiration}
            </p>
            <p className="subtitle-color font-16 medium p-0 m-0 mt-1 mb-5 ml-10">
              {/* {moment(invitationId?.valid_until).format(
                "dddd D/MMM/YY - h:mma"
              )} */}
              {/* {serverTimeZone
                ? moment(invitationId?.valid_until)
                    .tz(`America/${serverTimeZone}`)
                    .format("dddd D/MMM/YY - h:mma")
                : moment(invitationId?.valid_until).format(
                    "dddd D/MMM/YY - h:mma"
                  )} */}
              {validUntilText}
              {/* {moment(invitationId?.valid_until)
                .tz(`${invitationId?.access_servers[0]?.time_zone_name}`)
                .format("dddd D/MMM/YY - h:mma")} */}
            </p>
            <p className="title-color font-14 m-0 p-0 mb-3 ml-8 mt-8">
              {labels.authorized_access_points}
            </p>
            {invitationId?.access_servers.map(
              ({ name, venue_name, id, time_zone }) => (
                <div key={id}>
                  <p className="subtitle-color font-16 medium p-0 m-0 mt-3 ml-10">
                    -{name}
                  </p>
                  <p className="subtitle-color font-14 m-0 p-0 mb-0 ml-11">
                    {venue_name}
                  </p>
                  <p className="subtitle-color font-14 m-0 p-0 mb-3 ml-11 medium">
                    {labels.time_zone} - {time_zone}
                  </p>
                </div>
              )
            )}
            {invitationId?.access_key_type_id === 2 &&
              invitationId.key_restriction !== null && (
                <div>
                  <p className="title-color font-14 m-0 p-0 mt-6 mb-1 ml-8">
                    {labels.allowed_days}
                  </p>
                  <ul className="subtitle-color font-12 my-2 ml-5">
                    {invitationId?.key_restriction?.days.includes("1") && (
                      <li className="my-1">{labels.monday}</li>
                    )}
                    {invitationId?.key_restriction?.days.includes("2") && (
                      <li className="my-1">{labels.tuesday}</li>
                    )}
                    {invitationId?.key_restriction?.days.includes("3") && (
                      <li className="my-1">{labels.wednesday}</li>
                    )}
                    {invitationId?.key_restriction?.days.includes("4") && (
                      <li className="my-1">{labels.thursday}</li>
                    )}
                    {invitationId?.key_restriction?.days.includes("5") && (
                      <li className="my-1">{labels.friday}</li>
                    )}
                    {invitationId?.key_restriction?.days.includes("6") && (
                      <li className="my-1">{labels.saturday}</li>
                    )}
                    {invitationId?.key_restriction?.days.includes("0") && (
                      <li className="my-1">{labels.sunday}</li>
                    )}
                  </ul>
                  <p className="title-color font-14 m-0 p-0 mt-6 mb-1 ml-8">
                    {labels.allowed_time}
                  </p>
                  <p className="subtitle-color font-14 m-0 p-0 mb-3 ml-10">
                    {moment(invitationId?.key_restriction.time).format("h:mma")}
                  </p>
                  <p className="title-color font-14 m-0 p-0 mt-6 mb-1 ml-8">
                    {labels.tolerance}
                  </p>
                  <p className="subtitle-color font-14 m-0 p-0 mb-3 ml-10">
                    {invitationId?.key_restriction.duration_in_minutes === 1
                      ? invitationId?.key_restriction.duration_in_minutes +
                        " " +
                        labels.hour_time
                      : invitationId?.key_restriction.duration_in_minutes +
                        " " +
                        labels.hours_time}
                  </p>
                </div>
              )}
          </div>
        </div>
        {userRole !== "guard" &&
          invitationId?.access_key_type_id === 1 &&
          invitationId?.deleted_at === null &&
          invitationId?.last_transaction_type !== "exit" && (
            <div className="w-100 flex justify-content-center align-items-center">
              <div className="w-95 mt-5 mb-3">
                <Button
                  startIcon={<EditIcon />}
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="py-5"
                  onClick={onClickEditInvitation}
                >
                  {labels.edit_invitation}
                </Button>
              </div>
            </div>
          )}
        {userRole !== "guard" &&
          invitationId?.deleted_at === null &&
          invitationId?.access_key_type_id === 1 &&
          invitationId?.last_transaction_type !== "exit" && (
            <div className="w-100 flex justify-content-center align-items-center">
              <div className="w-95 mb-5 mt-3">
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="py-5"
                  onClick={deleteInvitation}
                >
                  {labels.delete_access_key}
                </Button>
              </div>
            </div>
          )}
        {userRole !== "guard" &&
          invitationId?.deleted_at === null &&
          invitationId?.access_key_type_id === 2 && (
            <div className="w-100 flex justify-content-center align-items-center">
              <div className="w-95 my-5">
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="py-5"
                  onClick={deleteInvitation}
                >
                  {labels.delete_access_key}
                </Button>
              </div>
            </div>
          )}
        {userRole !== "guard" &&
          invitationId?.deleted_at === null &&
          invitationId?.access_key_type_id === 5 &&
          invitationId?.last_transaction_type !== "exit" && (
            <div className="w-100 flex justify-content-center align-items-center">
              <div className="w-95 my-5">
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="py-5"
                  onClick={deleteInvitation}
                >
                  {labels.delete_access_key}
                </Button>
              </div>
            </div>
          )}
        {userRole !== "guard" &&
          invitationId?.deleted_at === null &&
          invitationId?.access_key_type_id === 4 &&
          invitationId?.last_transaction_type !== "exit" && (
            <div className="w-100 flex justify-content-center align-items-center">
              <div className="w-95 my-5">
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="py-5"
                  onClick={deleteInvitation}
                >
                  {labels.delete_access_key}
                </Button>
              </div>
            </div>
          )}
        {userRole === "guard" && invitationId?.access_key_type_id === 5 && (
          <>
            {invitationId?.last_transaction_type === null && (
              <div className="w-100 flex justify-content-center mt-7">
                <Button
                  variant="contained"
                  size="large"
                  className="py-6 w-95"
                  sx={{ fontWeight: "normal" }}
                  disableElevation
                  onClick={() => setSelectDoorModal(true)}
                >
                  {labels.check_in}
                </Button>
              </div>
            )}
            {invitationId?.last_transaction_type === "access" && (
              <div className="w-100 flex justify-content-center mt-7">
                <Button
                  variant="contained"
                  size="large"
                  className="py-6 w-95"
                  sx={{ fontWeight: "normal" }}
                  disableElevation
                  onClick={() => setSelectExitDoorModal(true)}
                >
                  {labels.check_out}
                </Button>
              </div>
            )}
          </>
        )}
        {/* {userRole === "guard" &&
        invitationId?.access_key_type_id === 5 &&
        invitationId.last_transaction_type === null && (
          <div className="w-100 flex justify-content-center mt-7">
            <Button
              variant="contained"
              size="large"
              className="py-6 w-80"
              sx={{ fontWeight: "normal" }}
              disableElevation
              onClick={() => setSelectDoorModal(true)}
            >
              {labels.generate_record}
            </Button>
          </div>
        )}
      {userRole === "guard" &&
        invitationId?.access_key_type_id === 5 &&
        invitationId.last_transaction_type === "access" && (
          <div className="w-100 flex justify-content-center mt-7">
            <Button
              variant="contained"
              size="large"
              className="py-6 w-80"
              sx={{ fontWeight: "normal" }}
              disableElevation
              onClick={onGenerateTransaction}
            >
              {labels.check_out}
            </Button>
          </div>
        )} */}
        <div className="lower-div mt-8 root-background" />

        {/* COPIED LINK MODAL------------------------------------------------------------------->> */}
        {/* MODAL LINK COPIADO------------------------------------------------------------------>> */}
        <Modal disableEscapeKeyDown open={copiedLinkModal}>
          <Box sx={style}>
            <div className="w-100 flex justify-content-center align-items-center">
              <p className="font-14 subtitle-color text-center">
                {labels.invite_link}
              </p>
            </div>
          </Box>
        </Modal>

        {/* SELECT ACCESS DOOR MODAL----------------------------------->>*/}
        {/* MODAL SELECCIONAR PUERTA DE ACCESO------------------------->>*/}
        <Modal disableEscapeKeyDown open={selectDoorModal}>
          <Box sx={style}>
            <Grid container className="vertical-spacing-4">
              <Grid
                item
                xs={12}
                className="flex justify-content-space-between align-items-center m-0 p-0 mb-2 mt-3"
              >
                <p className="subtitle-color font-16">
                  {labels.select_access_door}
                </p>
                <IconButton
                  onClick={() => {
                    setSelectDoorModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              className="w-100 align-items-center justify-content-center vertical-spacing-5"
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-door-label">{labels.door}</InputLabel>
                  <Select
                    labelId="select-door-label"
                    value={accessDoor}
                    onChange={handleAccessDoorChange}
                    label={labels.door}
                  >
                    {user?.last_access_selected?.gates
                      .filter(({ type }) => type === "access")
                      .map(({ name, id }) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setSelectDoorModal(false);
                  onValidateAccessInvitation();
                }}
                disabled={disabledContinueButton}
                className="mb-8"
              >
                {labels.continue}
              </Button>
            </Grid>
          </Box>
        </Modal>

        {/* SELECT EXIT DOOR MODAL------------------------------------->>*/}
        {/* MODAL SELECCIONAR PUERTA DE SALIDA------------------------->>*/}
        <Modal disableEscapeKeyDown open={selectExitDoorModal}>
          <Box sx={style}>
            <Grid container className="vertical-spacing-4">
              <Grid
                item
                xs={12}
                className="flex justify-content-space-between align-items-center m-0 p-0 mb-2 mt-3"
              >
                <p className="subtitle-color font-16">
                  {labels.select_exit_door}
                </p>
                <IconButton
                  onClick={() => {
                    setSelectExitDoorModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              className="w-100 align-items-center justify-content-center vertical-spacing-5"
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-door-label">{labels.door}</InputLabel>
                  <Select
                    labelId="select-door-label"
                    value={exitDoor}
                    onChange={handleExitDoorChange}
                    label={labels.door}
                  >
                    {user?.last_access_selected?.gates
                      .filter(({ type }) => type === "exit")
                      .map(({ name, id }) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setSelectExitDoorModal(false);
                  onValidateExitInvitation();
                }}
                disabled={disabledExitContinueButton}
                className="mb-8"
              >
                {labels.continue}
              </Button>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default InvitationDetail;
