import React from "react";
import { HiChevronDoubleRight } from "react-icons/hi";

interface AdminDesktopDropViewProps {
  showView: boolean;
  setShowView: (showView: boolean) => void;
  content: any;
  extraFunc: boolean;
  extraFuncElement?: any;
  title: string;
  onCLoseFunc?: () => void;
}

const AdminDesktopDropView = ({
  showView,
  setShowView,
  content,
  extraFunc,
  extraFuncElement,
  title,
  onCLoseFunc,
}: AdminDesktopDropViewProps) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: showView ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0)",
          transition: "background-color 0.4s ease",
          pointerEvents: showView ? "all" : "none",
          zIndex: 100,
        }}
        onClick={() => {
          setShowView(false);
          !!onCLoseFunc && onCLoseFunc();
        }}
      />
      <div
        style={{
          height: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          backgroundColor: "#f8f8f8",
          boxSizing: "border-box",
          boxShadow: showView ? `2px 0px 6px 4px rgba(0,0,0,0.3)` : "",
          transform: showView ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.4s ease",
          zIndex: 101,
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            margin: 0,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 10,
            backgroundColor: "white",
          }}
        >
          <HiChevronDoubleRight
            className="title-color"
            fontSize={24}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowView(false);
              !!onCLoseFunc && onCLoseFunc();
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingTop: 14,
            paddingBottom: 14,
            paddingLeft: 30,
            paddingRight: 30,
            justifyContent: "space-between",
            alignItems: "center",
            // borderBottomColor: "rgba(196, 196, 196, 0.25)",
            borderBottomColor: "rgba(196, 196, 196, 0.5)",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
        >
          <p
            className="m-0 p-0 subtitle-color desktop-tables-font"
            style={{ fontSize: 28 }}
          >
            {title}
          </p>
          {extraFunc && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              {extraFuncElement}
            </div>
          )}
        </div>
        <div style={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
          {content}
        </div>
      </div>
    </>
  );
};

export default AdminDesktopDropView;
