import React from "react";
import api, {
  AddEventGuestEvent,
  AddEventGuestsParams,
  CommunityMembers,
  EventGuestList,
  EventGuests,
  EventIdEventParams,
  EventIdParams,
} from "../../api";
import moment from "moment-timezone";
import "moment-timezone/node_modules/moment/locale/es";
import { FaEdit } from "react-icons/fa";
import { BsFillPlusCircleFill, BsFillXCircleFill } from "react-icons/bs";
import SearchInputOutlined from "../SearchInputOutlined/SearchInputOutlined";
import { showError } from "../../utils/helper";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import { IoIosCloseCircle, IoIosSave } from "react-icons/io";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};
interface AdminDesktopEventDetailsProps {
  idEvent: EventIdEventParams | null;
  setIdEvent: (idEvent: EventIdEventParams | null) => void;
  showContacts: boolean;
  setShowContacts: (showContacts: boolean) => void;
  setShowContactsDisabled: (showContactsDisabled: boolean) => void;
  open: boolean;
  actionOnSuccessEndpoints: () => void;
}

const AdminDesktopEventDetails = ({
  idEvent,
  setIdEvent,
  showContacts,
  setShowContacts,
  setShowContactsDisabled,
  open,
  actionOnSuccessEndpoints,
}: AdminDesktopEventDetailsProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingGuest, setIsLoadingGuest] = React.useState<boolean>(false);
  const [titleHeight, setTitleHeight] = React.useState<number>(0);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [guestList, setGuestList] = React.useState<EventGuestList | null>(null);
  const [guestsPage, setGuestsPage] = React.useState<number>(1);
  // const [eventId, setEventId] = React.useState<EventIdParams | null>(null);
  const [userEvent, setUserEvent] = React.useState<boolean>(false);
  const [addGuest, setAddGuest] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [name, setName] = React.useState<string>("");
  const [errorPhoneLabel, setErrorPhoneLabel] = React.useState<boolean>(false);
  const [errorPhoneDigits, setErrorPhoneDigits] =
    React.useState<boolean>(false);
  const [errorPhoneLetters, setErrorPhoneLetters] =
    React.useState<boolean>(false);
  const [errorEmail, setErrorEmail] = React.useState<boolean>(false);
  const [addGuests, setAddGuests] = React.useState<AddEventGuestEvent[]>([]);
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [deniedModal, setDeniedModal] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const [existedContact, setExistedContact] = React.useState<boolean>(false);
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<string>("");
  const [communityMembers, setCommunityMembers] = React.useState<
    CommunityMembers[] | null
  >(null);
  const [backupCommunityMembers, setBackupCommunityMembers] = React.useState<
    CommunityMembers[] | null
  >(null);
  const [successModal, setSuccessModal] = React.useState(false);
  const [successCall, setSuccessCall] = React.useState<boolean>(false);

  const userId = user?.id;

  const eventTitleRef: any = React.useRef(null);
  const addGuestScrollContainerRef: any = React.useRef(null);
  const scrollContainer: any = React.useRef(null);
  const saveAddedGuestButtonRef: any = React.useRef(null);
  const nameRef: any = React.useRef(null);

  const guestsResults = 5;

  const debouncedSearchText = useDebounce(searchValue, 500);

  const disabledAddGuestButton =
    !name || !phoneNumber || errorPhoneLabel || errorEmail;

  React.useEffect(() => {
    onReloadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onReloadComponent = () => {
    if (open) {
      setIsLoading(true);
      getInitialValues();
      setTimeout(() => {
        getCommunityMembers();
      }, 400);
    } else {
      setTimeout(() => {
        scrollToTop(scrollContainer);
        setIdEvent(null);
      }, 400);
    }
  };

  React.useEffect(() => {
    recallFunctions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCall]);

  const recallFunctions = () => {
    if (successCall) {
      setIsLoading(true);
      setTimeout(() => {
        actionOnSuccessEndpoints();
        requestGuestList(debouncedSearchText);
        setAddGuest(false);
        setSuccessCall(false);
        setIsLoading(false);
      }, 500);
    }
  };

  const getInitialValues = () => {
    setAddGuest(false);
    setAddGuests([]);
    setName("");
    setPhoneNumber("");
    setEmail("");
    setCountryCode("+52");
    setSearchCommunityMember("");
    setExistedContact(false);
    setCommunityMembers(null);
    setBackupCommunityMembers(null);
    setShowContacts(false);
    setShowContactsDisabled(true);
    setErrorPhoneLabel(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLetters(false);
    setErrorEmail(false);
  };

  const scrollToTop = (refElement: any) => {
    if (refElement.current) {
      refElement.current.scrollTop = 0;
    }
  };

  const handleCenterElementClick = () => {
    if (addGuestScrollContainerRef.current && saveAddedGuestButtonRef.current) {
      saveAddedGuestButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setTimeout(() => {
        saveAddedGuestButtonRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  };

  const handleCenterFormClick = () => {
    if (addGuestScrollContainerRef.current && nameRef.current) {
      nameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setTimeout(() => {
        nameRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  };

  React.useEffect(() => {
    const completePhone = countryCode + phoneNumber;
    const filteredCommunityMember = communityMembers?.filter(
      (element) => element.phone === completePhone
    );
    if (filteredCommunityMember && filteredCommunityMember?.length > 0) {
      setExistedContact(true);
      setSaveCommunityMember(false);
      setChecked(false);
    } else {
      setExistedContact(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, phoneNumber]);

  const onMouseOverFunc = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  React.useEffect(() => {
    if (eventTitleRef.current) {
      const height = eventTitleRef.current.offsetHeight;
      setTitleHeight(height);
    }
  }, []);

  const onSelectCommunityMember = (cm: CommunityMembers) => {
    handleCenterFormClick();
    setName(cm.name);
    setEmail(cm.email);
    if (cm.phone.startsWith("+52")) {
      setCountryCode("+52");
      setPhoneNumber(cm.phone.substring(3));
    } else {
      setCountryCode("+1");
      setPhoneNumber(cm.phone.substring(2));
    }
  };

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  const checkNumbers = (str: string) => {
    return /^\d+$/.test(str);
  };

  const validateEmail = (email: string) => {
    const validateCode = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validateCode.test(email);
  };

  const onBlurTextField = (
    parameter: string,
    setParameter: (parameter: string) => void
  ) => {
    const trimName = parameter.trim();
    setParameter(trimName);
  };

  const onBlurEmailField = () => {
    if (email) {
      const trimEmail = email.trim().replace(/\s+/g, "");
      setEmail(trimEmail);
      const verifiedEmail = validateEmail(trimEmail);
      if (!verifiedEmail) {
        setErrorEmail(true);
      }
    }
  };

  const onFocusEmailField = () => {
    setErrorEmail(false);
  };

  const onBlurPhoneField = () => {
    if (phoneNumber) {
      const trimPhone = phoneNumber.trim().replace(/\s+/g, "");
      setPhoneNumber(trimPhone);
      const checkOnlyNumbers: boolean = checkNumbers(trimPhone);
      if (trimPhone.length !== 10) {
        setErrorPhoneLabel(true);
        setErrorPhoneDigits(true);
      }
      if (!checkOnlyNumbers) {
        setErrorPhoneLabel(true);
        setErrorPhoneLetters(true);
      }
    }
  };

  const onFocusPhoneField = () => {
    setErrorPhoneLabel(false);
    setErrorPhoneDigits(false);
    setErrorPhoneLetters(false);
  };

  React.useEffect(() => {
    if (!showContacts) {
      setSearchCommunityMember("");
    }
  }, [showContacts]);

  React.useEffect(() => {
    manegeSearchCommunityMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommunityMember]);

  const manegeSearchCommunityMember = () => {
    if (!!searchCommunityMember) {
      const getSearchedCommunityMember = communityMembers?.filter((element) =>
        element.name.toLowerCase().includes(searchCommunityMember.toLowerCase())
      );
      setCommunityMembers(
        getSearchedCommunityMember ? getSearchedCommunityMember : []
      );
    } else {
      setCommunityMembers(backupCommunityMembers);
    }
  };

  const getCommunityMembers = async () => {
    if (userId) {
      try {
        setIsLoading(true);
        const result = await api.communityMembersRequest(1, 100, "");
        if (result) {
          const newArray: CommunityMembers[] = result.community_members.filter(
            (element) => element?.user?.id === userId
          );
          setCommunityMembers(newArray);
          setBackupCommunityMembers(newArray);
          if (newArray.length === 0) {
            setShowContactsDisabled(true);
          } else {
            setShowContactsDisabled(false);
          }
        }
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.user_no_access);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddGuest = () => {
    const newGuest: AddEventGuestEvent = {
      community_member: false,
      phone: "",
      name: "",
      email: "",
      save: false,
    };
    newGuest.community_member = false;
    newGuest.phone = countryCode + phoneNumber;
    newGuest.name = name;
    newGuest.email = email;
    newGuest.save = saveCommunityMember;
    if (
      addGuests.find(({ name }) => name === newGuest.name) ||
      addGuests.find(({ phone }) => phone === newGuest.phone)
    ) {
      setDeniedModal(true);
      setTimeout(() => {
        setDeniedModal(false);
      }, 2500);
    } else {
      setAddGuests([...addGuests, newGuest]);
      setName("");
      setCountryCode("+52");
      setPhoneNumber("");
      setEmail("");
      setSaveCommunityMember(false);
      setChecked(false);
      handleCenterElementClick();
    }
  };

  const handleQuitGuest = (index: number) => {
    const newArray = [...addGuests];
    newArray.splice(index, 1);
    setAddGuests(newArray);
  };

  React.useEffect(() => {
    requestGuestList(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEvent, debouncedSearchText, guestsPage, guestsResults]);

  const requestGuestList = async (debouncedSearchText: string) => {
    if (idEvent?.id) {
      try {
        setIsLoadingGuest(true);
        const events: EventGuestList | null = await api.eventAccessKeysRequest(
          Number(idEvent?.id),
          debouncedSearchText,
          guestsPage,
          Number(guestsResults)
        );
        setGuestList(events);
        setUserEvent(true);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 404) {
          setUserEvent(false);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.wrong_parameters);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoadingGuest(false);
      }
    }
  };

  const addEventGuests = async () => {
    if (idEvent?.id && addGuests && addGuests.length > 0) {
      const payload: AddEventGuestsParams = {
        guests: addGuests,
      };
      try {
        setIsLoading(true);
        await api.addEventGuestRequest(idEvent?.id, payload);
        setSuccessCall(true);
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onDeleteAccessKey = async (accessKeyId: number) => {
    try {
      setIsLoading(true);
      await api.deleteAccessKey(accessKeyId);
      setSuccessCall(true);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={scrollContainer}
        style={{
          width: 500,
          height: `calc(100% - 99.5px)`,
          backgroundColor: "#f8f8f8",
          boxSizing: "border-box",
          padding: 20,
          overflow: "auto",
        }}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              ref={eventTitleRef}
              style={{
                width: "100%",
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: 30,
                borderRadius: 4,
                marginBottom: 10,
                boxShadow:
                  "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "bold",
                        fontSize: 20,
                        textTransform: "uppercase",
                      }}
                    >
                      {idEvent?.name}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: 14,
                        marginTop: 4,
                      }}
                    >
                      {idEvent?.description}
                    </p>
                  </div>
                  {/* <FaEdit
                    style={{ color: "#10418a", cursor: "pointer" }}
                    fontSize={30}
                  /> */}
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#e8e8e8",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <div className="w-100 flex align-items-baseline">
                  <p className="medium p-0 m-0">
                    {idEvent?.access_servers[0]?.time_zone_name
                      ? moment(idEvent?.start_date)
                          .tz(idEvent?.access_servers[0]?.time_zone_name)
                          .format("DD MMMM")
                      : moment(idEvent?.start_date).format("DD MMMM")}
                  </p>
                  <p className="m-0 p-0 ml-4">
                    {idEvent?.access_servers[0]?.time_zone_name
                      ? moment(idEvent?.start_date)
                          .tz(idEvent?.access_servers[0]?.time_zone_name)
                          .format("h:mma")
                      : moment(idEvent?.start_date).format("h:mma")}{" "}
                    -{" "}
                    {idEvent?.access_servers[0]?.time_zone_name
                      ? moment(idEvent?.end_date)
                          .tz(idEvent?.access_servers[0]?.time_zone_name)
                          .format("h:mma")
                      : moment(idEvent?.end_date).format("h:mma")}
                  </p>
                </div>
              </div>
            </div>
            {userEvent && (
              <div
                ref={addGuestScrollContainerRef}
                style={{
                  width: "100%",
                  height: `calc(100% - ${titleHeight}px - 25px)`,
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  padding: 30,
                  borderRadius: 4,
                  boxShadow:
                    "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    {labels.guests}: {idEvent?.number_of_guests}
                  </p>
                  {addGuest ? (
                    <Tooltip title={labels.cancel} arrow placement="top-end">
                      <div>
                        <BsFillXCircleFill
                          style={{
                            color: "#10418a",
                            cursor: "pointer",
                            margin: 0,
                          }}
                          fontSize={30}
                          onClick={() => setAddGuest(false)}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={labels.add_guest}
                      arrow
                      placement="top-start"
                    >
                      <div>
                        <BsFillPlusCircleFill
                          style={{
                            color: "#10418a",
                            cursor: "pointer",
                            margin: 0,
                          }}
                          fontSize={30}
                          onClick={() => setAddGuest(true)}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
                {addGuest ? (
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ width: "100%", marginBottom: 2 }}>
                      <p
                        className="title-color"
                        style={{ marginBottom: 10, padding: 0 }}
                      >
                        {labels.full_name} *
                      </p>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          ref={nameRef}
                          id="outlined"
                          placeholder={`${labels.full_name}`}
                          type="text"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          disabled={isLoading}
                          onBlur={() => onBlurTextField(name, setName)}
                        />
                      </FormControl>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: 2,
                      }}
                    >
                      <p
                        className="title-color"
                        style={{ marginBottom: 10, padding: 0 }}
                      >
                        {labels.phone_number} *
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            boxSizing: "border-box",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "35%", marginRight: 5 }}>
                            <FormControl fullWidth>
                              <Select
                                value={countryCode}
                                onChange={(e) => {
                                  setCountryCode(e.target.value);
                                }}
                                inputProps={{
                                  "aria-label": "Whithout label",
                                }}
                                disabled={isLoading}
                              >
                                <MenuItem value={"+52"}>MX +52</MenuItem>
                                <MenuItem value={"+1"}>US +1</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div style={{ width: "65%", marginLeft: 5 }}>
                            <FormControl variant="outlined" fullWidth>
                              <OutlinedInput
                                id="outlined"
                                placeholder={`${labels.phone_number} (${labels.ten_digits})`}
                                type="tel"
                                value={phoneNumber}
                                onChange={(e) => {
                                  setPhoneNumber(e.target.value);
                                }}
                                disabled={isLoading}
                                error={errorPhoneLabel}
                                onBlur={onBlurPhoneField}
                                onFocus={onFocusPhoneField}
                              />
                            </FormControl>
                            {errorPhoneLabel && (
                              <>
                                {errorPhoneDigits && (
                                  <p
                                    className="m-0 p-0 font-12 ml-4 mt-2"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {labels.ten_digits_error_label}
                                  </p>
                                )}
                                {errorPhoneLetters && (
                                  <p
                                    className="m-0 p-0 font-12 ml-4 mt-2"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {labels.only_numbers}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p
                        className="title-color"
                        style={{ marginBottom: 10, padding: 0 }}
                      >
                        {labels.email}
                      </p>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="outlined"
                          placeholder={labels.email}
                          type="text"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          disabled={isLoading}
                          error={errorEmail}
                          onBlur={onBlurEmailField}
                          onFocus={onFocusEmailField}
                        />
                      </FormControl>
                      {errorEmail && (
                        <p
                          className="m-0 p-0 font-12 ml-4 mt-2"
                          style={{ color: "#d32f2f" }}
                        >
                          {labels.error_email}
                        </p>
                      )}
                    </div>
                    <div
                      className="flex align-items-center"
                      style={{ marginTop: 10 }}
                    >
                      <Checkbox
                        checked={checked}
                        value={saveCommunityMember}
                        onChange={handleSaveCommunityMemberChange}
                        disabled={isLoading ? true : existedContact}
                      />
                      <div>
                        <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                          {labels.save_contact}
                        </p>
                        {existedContact && (
                          <p className="m-0 mt-0 mb-0 font-12 mt-2 subtitle-color">
                            {labels.existed_contact}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className="flex align-items-center"
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        variant="outlined"
                        size="large"
                        style={{ height: 56, width: 400 }}
                        onClick={handleAddGuest}
                        disabled={isLoading ? true : disabledAddGuestButton}
                      >
                        {labels.add_guest}
                      </Button>
                    </div>
                    {addGuests && addGuests.length > 0 && (
                      <>
                        <p
                          className="title-color"
                          style={{ marginBottom: 10, padding: 0 }}
                        >
                          {labels.guest_number}: {addGuests.length}
                        </p>
                        <div
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                            border: "1px #c4c4c4 solid",
                            borderRadius: 4,
                          }}
                        >
                          {addGuests.map((element, index) => (
                            <div
                              key={`${element.id}${index}`}
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderBottom:
                                  addGuests.length - 1 === index
                                    ? ""
                                    : "1px #f2f2f2 solid",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {element.save && (
                                  <div
                                    style={{
                                      minWidth: 28,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IoIosSave
                                      className="title-color"
                                      style={{ fontSize: 18 }}
                                    />
                                  </div>
                                )}
                                <p className="m-0 p-0">{element.name}</p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p className="m-0 p-0 mr-8 ml-8">
                                  {element.phone}
                                </p>
                                <IoIosCloseCircle
                                  className="primary-color"
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => handleQuitGuest(index)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    <div
                      className="flex align-items-center"
                      style={{ marginTop: 20 }}
                    >
                      <Button
                        ref={saveAddedGuestButtonRef}
                        variant="contained"
                        size="large"
                        style={{ height: 56, width: 400 }}
                        onClick={addEventGuests}
                        disabled={
                          isLoading
                            ? true
                            : addGuests.length === 0
                            ? true
                            : false
                        }
                      >
                        {labels.save_guests}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div style={{ marginTop: 16, marginBottom: 10 }}>
                      <SearchInputOutlined
                        value={searchValue}
                        setValue={setSearchValue}
                        size="small"
                      />
                    </div>
                    {isLoadingGuest ? (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div style={{ marginTop: 15, marginBottom: 15 }}>
                        {guestList?.access_keys &&
                        guestList?.access_keys.length > 0 ? (
                          guestList.access_keys.map(
                            ({ visitor_name, visitor_phone, id }) => (
                              <div
                                key={id}
                                style={{
                                  display: "flex",
                                  backgroundColor: "",
                                  boxSizing: "border-box",
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  borderBottomColor: "#e4e4e4",
                                  borderBottomWidth: 1,
                                  borderBottomStyle: "solid",
                                  cursor: "pointer",
                                }}
                                onMouseOver={onMouseOverFunc}
                                onMouseLeave={onMouseOutFunc}
                              >
                                <p style={{ marginRight: 10 }}>
                                  {visitor_name}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p className="m-0 p-0 mr-8 ml-8">
                                    {visitor_phone}
                                  </p>
                                  <IoIosCloseCircle
                                    className="primary-color"
                                    style={{ fontSize: 18, cursor: "pointer" }}
                                    onClick={() => onDeleteAccessKey(id)}
                                  />
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <p>{labels.no_results_found}</p>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {showContacts && (
        <div
          style={{
            width: 450,
            height: `calc(100% - 99.5px)`,
            backgroundColor: "#f8f8f8",
            boxSizing: "border-box",
            padding: 20,
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: 30,
              borderRadius: 4,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              overflow: "auto",
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="title-color"
                style={{ marginBottom: 10, padding: 0 }}
              >
                {labels.search_contact}
              </p>
              <SearchInputOutlined
                isLoading={isLoading}
                value={searchCommunityMember}
                setValue={setSearchCommunityMember}
                size="large"
              />
            </div>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {communityMembers && communityMembers.length > 0 ? (
                communityMembers.map((element, index) => (
                  <div
                    key={`${element.id}${index}`}
                    style={{
                      display: "flex",
                      backgroundColor: "",
                      boxSizing: "border-box",
                      paddingLeft: 10,
                      paddingRight: 10,
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottomColor: "#e4e4e4",
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      cursor: "pointer",
                    }}
                    onMouseOver={onMouseOverFunc}
                    onMouseLeave={onMouseOutFunc}
                    onClick={() =>
                      isLoading ? {} : onSelectCommunityMember(element)
                    }
                  >
                    <p style={{ marginRight: 10 }}>{element.name}</p>
                    <p>{element.phone}</p>
                  </div>
                ))
              ) : (
                <div>
                  <p>{labels.contact_not_found}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* COPIED NOT ADDED COMMUNITY MEMBER--------------------------------------------------->> */}
      {/* MODAL COMMUNITY MEMBER NO ANADIDO--------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={deniedModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-14 subtitle-color text-center pt-10 pb-10">
              {labels.have_guest_one}
            </p>
          </div>
        </Box>
      </Modal>

      {/* COPIED SENDED INVITATIONS----------------------------------------------------------->> */}
      {/* MODAL INVITACIONES ENVIADAS--------------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={successModal}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-center align-items-center">
            <p className="font-14 subtitle-color text-center pt-10 pb-10">
              {labels.sended_invitations}
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminDesktopEventDetails;
