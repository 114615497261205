import { CircularProgress, Container } from "@mui/material";
import React from "react";
import MainPagesHeader from "../../components/MainPagesHeader/MainPagesHeader";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { selectToken } from "../../slices/token";
import api, { NetworkGroupsParams } from "../../api";
import DepartmentsList from "../../components/DepartmentsList";
import Pagination from "../../components/Pagination/Pagination";

interface FileToExport {
  Nombre: string;
  "Puntos de acceso": any;
  Usuarios: any;
}

const DepartmentsRefactor = () => {
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [networkGroupsList, setNetworkGroupsList] =
    React.useState<NetworkGroupsParams | null>(null);

  console.log("networkGroupsList", networkGroupsList);

  const debouncedSearchText = useDebounce(searchInput, 500);

  React.useEffect(() => {
    setPage(1);
  }, [debouncedSearchText]);

  React.useEffect(() => {
    getNetworkGroups(debouncedSearchText);
    fileNetworkGroups(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, debouncedSearchText, page, rowsPerPage]);

  const getNetworkGroups = async (debouncedSearchText: string) => {
    try {
      setIsLoading(true);
      const response = await api.getNetworkGroups(
        debouncedSearchText,
        "",
        page,
        rowsPerPage
      );
      setNetworkGroupsList(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fileNetworkGroups = async (debouncedSearchText: string) => {
    try {
      const response = await api.getNetworkGroups(
        debouncedSearchText,
        "",
        1,
        100
      );
      const fileArray = response.network_groups.map(
        ({ name, access_servers, users }) => ({
          Nombre: name ? name : "",
          "Puntos de acceso": access_servers.map(({ name }) => name).join(", "),
          Usuarios: users.map(({ name }) => name).join(", "),
        })
      );
      setFileArray(fileArray);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container>
        <MainPagesHeader
          newIcon={false}
          // onPressNewIconAction={() => navigate(`/`)}
          mainTitle="departments"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={false}
          showFilters={false}
          setShowFilters={() => {}}
          downloadable={false}
          fileArray={fileArray ? fileArray : []}
          fileName=""
        />
        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {isLoading ? (
          <div
            className="flex justify-content-center align-items-center w-100"
            style={{
              position: "relative",
              top: 176,
              boxSizing: "border-box",
              paddingBottom: networkGroupsList?.total_pages ? 118 : 72,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className="w-100 flex flex-dir-col justify-content-center"
            style={{
              position: "relative",
              top: 116,
              boxSizing: "border-box",
              paddingBottom: networkGroupsList?.total_pages ? 118 : 72,
            }}
          >
            {networkGroupsList &&
            networkGroupsList?.network_groups?.length > 0 ? (
              networkGroupsList?.network_groups.map(
                ({ id, name, access_servers, users }, index) => (
                  <div
                    key={id}
                    className="w-100 flex justify-content-center mt-5"
                    style={{
                      marginBottom:
                        networkGroupsList.network_groups.length === index + 1
                          ? 12
                          : 0,
                    }}
                  >
                    <DepartmentsList
                      // onPress={() => navigate(`/edit-department/${id}`)}
                      onPress={() => {}}
                      departmentName={name}
                      usersNumber={users.length}
                      accessPointsNumber={access_servers.length}
                    />
                  </div>
                )
              )
            ) : (
              <div className="w-100 flex justify-content-center">
                <p className="font-16 subtitle-color">
                  {labels.no_results_found}
                </p>
              </div>
            )}
          </div>
        )}
      </Container>
      {networkGroupsList?.total_pages ? (
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={networkGroupsList?.total_pages}
          isDepartments={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DepartmentsRefactor;
