import React from "react";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import MobileLowerMenu from "../../components/MobileLowerMenu/MobileLowerMenu";

interface AdminMobileProps {
  children: any;
}

const AdminMobile = ({ children }: AdminMobileProps) => {
  return (
    <>
      <MobileMenu goToLatestVersion={() => {}} />
      <div className="mobile-container">{children}</div>
      <MobileLowerMenu />
    </>
  );
};

export default AdminMobile;
