import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import tokenReducer from "../slices/token";
import languageReducer from "../slices/language";
import logbookReducer from "../slices/logbook";
import providerReducer from "../slices/provider";
import registersReducer from "../slices/registers";
import invitationsReducer from "../slices/invitations";
import userReducer from "../slices/user";
import errorReducer from "../slices/error";
import usersListReducer from "../slices/usersList";
import strapiUserReducer from "../slices/strapiUser";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    token: tokenReducer,
    language: languageReducer,
    logbook: logbookReducer,
    provider: providerReducer,
    registers: registersReducer,
    invitations: invitationsReducer,
    user: userReducer,
    error: errorReducer,
    usersList: usersListReducer,
    strapiUser: strapiUserReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
