import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UsersList {
  user: {
    id: number;
    name: string;
    email: string;
    network_group: {
      name: string;
    };
  };
}

export interface UsersListState {
    data: UsersList[];
  }
  
  const initialState: UsersListState = {
    data: [],
  };
  
  export const usersListSlice = createSlice({
    name: "usersList",
    initialState,
    reducers: {
      getUsersList: (state, action) => {
        const newState = { ...state };
        newState.data = [...newState.data, action.payload];
        return newState;
      },
      setUsersList: (state, action) => {
        const newState = { ...state };
        newState.data = action.payload;
        return newState;
      },
    },
  });
  
  export const { getUsersList, setUsersList } = usersListSlice.actions;
  
  export const selectUsersList = (state: RootState) =>
    state.usersList.data;
  
  export default usersListSlice.reducer;
