import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  Select,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../app/hooks";
import { selectToken } from "../../slices/token";
import { selectUser } from "../../slices/user";
import { v4 as uuidv4 } from "uuid";
import { CgClose } from "react-icons/cg";
import { selectLabels } from "../../slices/language";

const style = {
  position: "absolute" as "absolute",
  boxSizing: "border-box",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  // maxHeight: "95%",
  overflow: "scroll",
  // borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  // paddingRight: 1,
  // paddingLeft: 1,
  // paddingBottom: 1,
};

const TemporarySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface CustomAttributesProps {
  isLoading?: boolean;
  type: string;
  name: string;
  required: boolean;
  value: any;
  setValue: (value: any) => void;
  listArray: any[] | null;
  isDesktop?: boolean;
  accessKeyId?: string;
  visitorNameReg?: string;
  hostNameReg?: string;
  hostIdReg?: number;
  departmentNameReg?: string;
  hostEmailReg?: string;
}

const CustomAttributes = ({
  isLoading,
  type,
  name,
  required,
  value,
  setValue,
  listArray,
  isDesktop,
  accessKeyId,
  visitorNameReg,
  hostNameReg,
  hostIdReg,
  departmentNameReg,
  hostEmailReg,
}: CustomAttributesProps) => {
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const closeContainerHeight = 40;
  const windowHeight = window.innerHeight;
  const modalHeight = windowHeight - closeContainerHeight;
  const userName = user?.name;
  const hostName = hostNameReg ? hostNameReg : "";
  const hostId = hostIdReg ? `${hostIdReg}` : "";
  const visitorName = visitorNameReg ? visitorNameReg : "";
  const departmentName = departmentNameReg ? departmentNameReg : "";
  const hostEmail = hostEmailReg ? `${hostEmailReg}` : "";
  const passtrackAccessKey = accessKeyId ? accessKeyId : "";
  const passtrackTempId = accessKeyId ? "" : uuidv4();

  const disabledCtpatButton =
    !hostName || !hostId || !visitorName || !departmentName || !hostEmail;

  const ctpatUrl = `https://form.jotform.com/231493965632868?passtrackAccessKey=${passtrackAccessKey}&passtrackTempId=${passtrackTempId}&guard_name=${userName}&host_name=${hostName}&visitor_name=${visitorName}&host_id=${hostId}&department_name=${departmentName}&host_email=${hostEmail}&passtrackToken=${token}`;

  return (
    <div>
      {name === "ctpat" ? (
        <div className="flex align-items-center">
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => setShowModal(true)}
            disabled={isLoading ? true : disabledCtpatButton}
          >
            {labels.open_ctpat}
          </Button>
        </div>
      ) : (
        <div className="flex align-items-center">
          {type === "string" && (
            <TextField
              required={required}
              value={value}
              label={isDesktop ? null : name}
              placeholder={isDesktop ? name : ""}
              variant="outlined"
              size={isDesktop ? "small" : "medium"}
              style={
                isDesktop ? { backgroundColor: "white", borderRadius: 4 } : {}
              }
              fullWidth
              onChange={(e) => {
                setValue(e.target.value);
              }}
              disabled={isLoading}
            />
          )}
          {type === "integer" && (
            <TextField
              type="number"
              required={required}
              value={value}
              label={isDesktop ? null : name}
              placeholder={isDesktop ? name : ""}
              variant="outlined"
              size={isDesktop ? "small" : "medium"}
              style={
                isDesktop ? { backgroundColor: "white", borderRadius: 4 } : {}
              }
              fullWidth
              onChange={(e) => {
                setValue(e.target.value);
              }}
              disabled={isLoading}
            />
          )}
          {type === "list" && listArray && listArray?.length > 0 && (
            <FormControl
              fullWidth
              size={isDesktop ? "small" : "medium"}
              style={
                isDesktop ? { backgroundColor: "white", borderRadius: 4 } : {}
              }
            >
              {!isDesktop && (
                <InputLabel required={required}>{name}</InputLabel>
              )}
              <Select
                labelId={name}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                label={isDesktop ? null : name}
                displayEmpty={isDesktop ? true : false}
                inputProps={{ "aria-label": "Whithout label" }}
                disabled={isLoading}
              >
                {isDesktop && (
                  <MenuItem value="">
                    <em>{name}</em>
                  </MenuItem>
                )}
                {listArray.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {type === "boolean" && (
            <div className="w-100 flex align-items-center justify-content-space-between m-0 p-0">
              <div className="flex align-items-center">
                <p className="subtitle-color font-18 my-4">
                  {name}
                  {required ? " *" : ""}
                </p>
              </div>
              <TemporarySwitch
                className="ml-4"
                onChange={(event: {
                  target: {
                    checked: boolean | ((prevState: boolean) => boolean);
                  };
                }) => setValue(event.target.checked)}
                disabled={isLoading}
              />
            </div>
          )}
          {type === "date" && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={name}
                value={value}
                onChange={(newDate: Date | null) => {
                  setValue(newDate);
                }}
                inputFormat="DD/MMM/YYYY"
                renderInput={(params: any) => (
                  <TextField fullWidth {...params} />
                )}
                disabled={isLoading}
              />
            </LocalizationProvider>
          )}
        </div>
      )}
      <Modal disableEscapeKeyDown open={showModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center"
          >
            <div
              style={{
                width: "100%",
                height: closeContainerHeight,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CgClose
                fontSize={24}
                style={{
                  margin: 0,
                  padding: 0,
                  marginTop: 6,
                  marginBottom: 6,
                  marginRight: 10,
                }}
                className="primary-color"
                onClick={() => setShowModal(false)}
              />
            </div>
            <iframe
              src={ctpatUrl}
              title="ctpat form"
              width="100%"
              height={modalHeight}
              style={{ border: "none" }}
              allow="camera"
            />
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomAttributes;
