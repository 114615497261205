import { InputBase, Paper } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaSearch } from "react-icons/fa";

interface SearchInputOutlinedProps {
  isLoading?: boolean;
  value: string;
  setValue: (value: string) => void;
  size: "small" | "large";
}

const SearchInputOutlined = ({
  value,
  setValue,
  size,
  isLoading,
}: SearchInputOutlinedProps) => {
  const labels = useAppSelector(selectLabels);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        width: "100%",
        height: size === "small" ? 40 : 56,
        borderRadius: 4,
        borderColor: "#c4c4c4",
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "white",
      }}
    >
      <FaSearch style={{ color: "#10418a", marginLeft: 8, fontSize: 22 }} />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${labels.search}...`}
        onChange={handleChange}
        value={value}
        disabled={isLoading}
      />
    </div>
  );
};

export default SearchInputOutlined;
