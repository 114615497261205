import React from "react";
import api, { AccessKey, AccessServerRes } from "../../api";
import QRCode from "qrcode.react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetUser, selectUser } from "../../slices/user";
import Logo from "../Logo/Logo";
import { selectLabels } from "../../slices/language";
import moment from "moment-timezone";
import { FaMapMarkerAlt } from "react-icons/fa";
import { showError } from "../../utils/helper";
import { resetToken } from "../../slices/token";
import MapComponent from "../MapComponent/MapComponent";
import { CircularProgress } from "@mui/material";
import { FaInfo, FaMinusCircle } from "react-icons/fa";

interface AdminDesktopInvitationDetailsProps {
  invitationId: number | null;
  accessKey: AccessKey | null;
  setAccessKey: (accessKey: AccessKey | null) => void;
  setInvitationValidFrom: (invitationValidFrom: string) => void;
  setInvitationUniqueId: (invitationUniqueId: string | undefined) => void;
  setInvitationVisitorEmail: (invitationVisitorEmail: string) => void;
  open: boolean;
}

const AdminDesktopInvitationDetails = ({
  invitationId,
  accessKey,
  setAccessKey,
  setInvitationValidFrom,
  setInvitationUniqueId,
  setInvitationVisitorEmail,
  open,
}: AdminDesktopInvitationDetailsProps) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [availableAccessServers, setAvailableAccessServers] = React.useState<
    AccessServerRes[] | null
  >(null);
  const [latitude, setLatitude] = React.useState<string>("");
  const [longitude, setLongitude] = React.useState<string>("");
  const [markerLabel, setMarkerLabel] = React.useState<string>("");
  const [showMap, setShowMap] = React.useState<boolean>(false);
  const [accessPointsTimeZone, setAccessPointsTimeZone] = React.useState<
    { id: number; timeZone: string }[]
  >([]);
  // const [accessKey, setAccessKey] = React.useState<AccessKey | null>(null);
  const [showRestrictions, setShowRestrictions] =
    React.useState<boolean>(false);

  const qrCode = accessKey?.unique_id;

  React.useEffect(() => {
    onCloseComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onCloseComponent = () => {
    if (!open) {
      setTimeout(() => {
        setShowRestrictions(false);
        setAvailableAccessServers(null);
        setLatitude("");
        setLongitude("");
        setMarkerLabel("");
        setShowMap(false);
        setAccessPointsTimeZone([]);
        setAccessKey(null);
      }, 400);
    } else {
      getInvitationById();
    }
  };

  React.useEffect(() => {
    getAccessServers();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessKey]);

  const fetchData = async () => {
    if (accessKey) {
      try {
        setIsLoading(true);
        const result = await Promise.all(
          accessKey?.access_servers.map(async ({ id }) => {
            const response = await api.getAccessServerById(Number(id));
            return response.access_server;
          })
        );
        setAvailableAccessServers(result);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAccessServers = async () => {
    try {
      setIsLoading(true);
      const response = await api.getAccessServers("", null, 1, 30);
      if (response) {
        const accessPointsArray = response.access_servers.map((element) => ({
          id: element.id,
          timeZone: element.time_zone_name,
        }));
        setAccessPointsTimeZone(accessPointsArray);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // React.useEffect(() => {
  //   getInvitationById();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invitationId]);

  const getInvitationById = () => {
    if (invitationId) {
      setIsLoading(true);
      setTimeout(async () => {
        try {
          const result = await api.invitationIdRequest(Number(invitationId));
          if (result) {
            setAccessKey(result);
            setInvitationValidFrom(result.valid_from ? result.valid_from : "");
            setInvitationUniqueId(result.unique_id ? result.unique_id : "");
            setInvitationVisitorEmail(
              result.visitor_email ? result.visitor_email : ""
            );
          }
        } catch (error: any) {
          console.error(error);
          if (error?.response?.status === 401) {
            showError(dispatch, labels.not_authorized);
          } else if (error?.response?.status === 422) {
            showError(dispatch, labels.unprocessable_entity);
          } else if (error?.response?.status === 403) {
            dispatch(resetToken());
            dispatch(resetUser());
          } else {
            showError(dispatch, labels.generic_error);
          }
        } finally {
          setIsLoading(false);
        }
      }, 400);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: 500,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <MapComponent
            showComponent={showMap}
            setShowComponent={setShowMap}
            latitude={Number(latitude)}
            longitude={Number(longitude)}
            markerLabel={markerLabel}
          />
          <div
            style={{
              width: 500,
              height: `calc(100% - 99.5px)`,
              boxSizing: "border-box",
              position: "relative",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  padding: 30,
                  borderRadius: 4,
                  marginBottom: 10,
                  boxShadow:
                    "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {accessKey?.deleted_at && (
                    <div className="w-100 flex justify-content-center align-items-center">
                      <p className="font-18 uppercase medium text-warning mb-6">
                        {labels.deleted_invitation}
                      </p>
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    {user?.network?.logo_url !== null ? (
                      <div style={{ marginBottom: 14 }}>
                        <img
                          src={user?.network?.logo_url}
                          alt={"NetworkLogo"}
                          className="admin-logo-passtrack"
                        />
                      </div>
                    ) : (
                      <div style={{ marginBottom: 14 }}>
                        <Logo
                          type="passtrackHeaderColor"
                          alt="Passtrack"
                          className="header-logo-size-2"
                        />
                      </div>
                    )}
                  </div>
                  <QRCode
                    value={`${qrCode}`}
                    size={200}
                    level={"H"}
                    renderAs="svg"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    marginTop: 50,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontWeight: "bold",
                      fontSize: 20,
                      textTransform: "uppercase",
                    }}
                  >
                    {accessKey?.visitor_name}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 14,
                      marginTop: 4,
                    }}
                  >
                    {labels.access_authorized_by}{" "}
                    <span style={{ textTransform: "uppercase" }}>
                      {accessKey?.user?.name}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#e8e8e8",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                  }}
                >
                  {accessKey?.reason_for_visit && (
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>
                      {accessKey?.reason_for_visit}
                    </p>
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginTop: accessKey?.reason_for_visit ? 6 : 0,
                    }}
                  >
                    {accessKey && accessPointsTimeZone.length > 0 && (
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 14,
                        }}
                      >
                        {moment(accessKey?.valid_from)
                          .tz(
                            `${
                              accessPointsTimeZone.filter(
                                ({ id }) =>
                                  id === accessKey?.access_servers[0]?.id
                              )[0]?.timeZone
                            }`
                          )
                          .format(`DD`)}
                        {" de "}
                        {moment(accessKey?.valid_from)
                          .tz(
                            `${
                              accessPointsTimeZone.filter(
                                ({ id }) =>
                                  id === accessKey?.access_servers[0]?.id
                              )[0]?.timeZone
                            }`
                          )
                          .format(`MMM`)}{" "}
                        {moment(accessKey?.valid_from)
                          .tz(
                            `${
                              accessPointsTimeZone.filter(
                                ({ id }) =>
                                  id === accessKey?.access_servers[0]?.id
                              )[0]?.timeZone
                            }`
                          )
                          .format(`HH:mm`)}
                        {" al "}
                        {moment(accessKey?.valid_until)
                          .tz(
                            `${
                              accessPointsTimeZone.filter(
                                ({ id }) =>
                                  id === accessKey?.access_servers[0]?.id
                              )[0]?.timeZone
                            }`
                          )
                          .format(`DD`)}
                        {" de "}
                        {moment(accessKey?.valid_until)
                          .tz(
                            `${
                              accessPointsTimeZone.filter(
                                ({ id }) =>
                                  id === accessKey?.access_servers[0]?.id
                              )[0]?.timeZone
                            }`
                          )
                          .format(`MMM`)}{" "}
                        {moment(accessKey?.valid_until)
                          .tz(
                            `${
                              accessPointsTimeZone.filter(
                                ({ id }) =>
                                  id === accessKey?.access_servers[0]?.id
                              )[0]?.timeZone
                            }`
                          )
                          .format(`HH:mm`)}
                      </p>
                    )}
                    {accessKey?.deleted_at && (
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontStyle: "italic",
                          color: "#ff0000",
                          marginLeft: 10,
                        }}
                      >
                        {labels.expired}
                      </p>
                    )}
                  </div>
                  {accessKey?.key_restriction && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: showRestrictions
                          ? "flex-end"
                          : "flex-start",
                        marginTop: 14,
                      }}
                    >
                      {!showRestrictions ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowRestrictions(true)}
                        >
                          <FaInfo className="primary-color" />
                          <p className="primary-color m-0 p-0">
                            {labels.show_restrictions}
                          </p>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowRestrictions(false)}
                          >
                            <FaMinusCircle className="primary-color" />
                            <p className="primary-color m-0 p-0 ml-2">
                              {labels.hide}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              marginTop: 10,
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              <p className="m-0 p-0 mb-3">
                                {labels.select_access_days}
                              </p>
                              {accessKey.key_restriction.days.length > 0 &&
                                accessKey.key_restriction.days.map(
                                  (element, index) => (
                                    <div key={index}>
                                      {element === "1" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.monday}
                                        </p>
                                      )}
                                      {element === "2" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.tuesday}
                                        </p>
                                      )}
                                      {element === "3" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.wednesday}
                                        </p>
                                      )}
                                      {element === "4" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.thursday}
                                        </p>
                                      )}
                                      {element === "5" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.friday}
                                        </p>
                                      )}
                                      {element === "6" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.saturday}
                                        </p>
                                      )}
                                      {element === "0" && (
                                        <p className="m-0 p-0 font-12 mb-1">
                                          - {labels.sunday}
                                        </p>
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                            <div style={{ width: "50%" }}>
                              {accessKey.key_restriction.time && (
                                <>
                                  <p className="m-0 p-0 mb-3">{labels.hours}</p>
                                  <p className="m-0 p-0 font-12 mb-1">
                                    {moment(accessKey.key_restriction.time)
                                      .tz(
                                        accessKey?.access_servers[0]
                                          ?.time_zone_name
                                      )
                                      .format("hh:mmA")}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {availableAccessServers &&
                availableAccessServers.length > 0 &&
                availableAccessServers.map(
                  (
                    { name, venue, id, address, latitude, longitude },
                    index
                  ) => (
                    <div
                      key={id}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        boxSizing: "border-box",
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 20,
                        paddingBottom: 20,
                        borderRadius: 4,
                        marginBottom: 10,
                        display: "flex",
                        boxShadow:
                          "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setLatitude(latitude);
                          setLongitude(longitude);
                          setMarkerLabel(name);
                          setShowMap(true);
                        }}
                      >
                        <FaMapMarkerAlt fontSize={28} color="#485056" />
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 10,
                            marginTop: 4,
                            color: "#485056",
                          }}
                        >
                          {labels.map}
                        </p>
                      </div>
                      <div
                        style={{
                          width: 1,
                          height: "auto",
                          backgroundColor: "#e8e8e8",
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontWeight: "bold",
                            fontSize: 14,
                            textTransform: "uppercase",
                          }}
                        >
                          {venue?.name}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 14,
                            marginTop: 4,
                          }}
                        >
                          {address}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 14,
                            marginTop: 4,
                          }}
                        >
                          {name}
                        </p>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDesktopInvitationDetails;
