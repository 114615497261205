import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import enLabels from '../../utils/translations/en.json'
import esLabels from '../../utils/translations/es.json'

const labels = {
    en: enLabels,
    es: esLabels
}

export interface LanguageState {
  language: string,
  labels: any;
}

const initialState: LanguageState = {
    language: navigator.language,
    labels: navigator.language.includes('es') ? labels.es : labels.en
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      state.labels = action.payload.includes('es') ? labels.es : labels.en
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export const selectLanguage = (state: RootState) => state.language.language;
export const selectLabels = (state: RootState) => state.language.labels;

export default languageSlice.reducer;
