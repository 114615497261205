import React from "react";
import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
  Switch,
  TextField,
  Modal,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Box,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import api, {
  AccessKey,
  CommunityMembers,
  CommunityMembersList,
  EditVisitInvitationParams,
} from "../../api";
import { showError } from "../../utils/helper";
import { resetUser, selectUser } from "../../slices/user";
import { resetToken } from "../../slices/token";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const EditInvitation = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const { id } = useParams();

  const [invitationId, setInvitationId] = React.useState<AccessKey | null>(
    null
  );
  const [addName, setAddName] = React.useState<string>("");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [visitorNameLabel, setVisitorNameLabel] = React.useState<string>("");
  const [visitorPhoneLabel, setVisitorPhoneLabel] = React.useState<string>("");
  const [visitorIdPhone, setVisitorIdPhone] = React.useState<string>("");
  const [visitorIdPhoneCode, setVisitorIdPhoneCode] =
    React.useState<string>("");
  const [viewVisitorEmailId, setViewVisitorEmailId] =
    React.useState<boolean>(true);
  const [finalEmailLabel, setFinalEmailLabel] = React.useState<string>("");
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<boolean>(false);
  const [communityMember, setCommunityMemberVar] = React.useState<
    string | null
  >(null);
  const [communityMembers, setCommunityMembers] =
    React.useState<CommunityMembersList | null>(null);
  const [communityMembersList, setCommunityMembersList] = React.useState<
    string[]
  >([]);
  const [filteredCommunityMemberName, setFilteredCommunityMemberName] =
    React.useState<string | null>("");
  const [inputAutocompleteCM, setInputAutocompleteCM] =
    React.useState<string>("");
  const [
    filteredCommunityMemberPhoneCode,
    setFilteredCommunityMemberPhoneCode,
  ] = React.useState<string | null>("+52");
  const [filteredCommunityMemberPhone, setFilteredCommunityMemberPhone] =
    React.useState<string | null>("");
  const [filteredCommunityMemberEmail, setFilteredCommunityMemberEmail] =
    React.useState<string | null>("");
  const [checked, setChecked] = React.useState(false);
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [filteredCommunityMemberId, setFilteredCommunityMemberId] =
    React.useState<Number | null>(null);
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [hour, setHour] = React.useState<string | null>(
    moment(date).format("HH")
  );
  const [minutes, setMinutes] = React.useState<string | null>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);

  console.log("filteredCommunityMemberId", filteredCommunityMemberId);

  const networkName = user?.network_group.name;

  const isoDate = `${moment(date).format(
    `YYYY-MM-DDT${hour}:${minutes}:ss.SSSZ`
  )}`;

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  const onClickViewEmail = () => {
    setViewVisitorEmailId(false);
  };

  React.useEffect(() => {
    getInitialMinutes();
  }, []);

  const getInitialMinutes = () => {
    if (
      moment(new Date()).format("mm") >= "00" &&
      moment(new Date()).format("mm") < "15"
    ) {
      setMinutes("00");
    } else if (
      moment(new Date()).format("mm") >= "15" &&
      moment(new Date()).format("mm") < "30"
    ) {
      setMinutes("15");
    } else if (
      moment(new Date()).format("mm") >= "30" &&
      moment(new Date()).format("mm") < "45"
    ) {
      setMinutes("30");
    } else {
      setMinutes("45");
    }
  };

  React.useEffect(() => {
    handleEmailLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId, viewVisitorEmailId, addEmail]);

  const handleEmailLabel = () => {
    if (viewVisitorEmailId) {
      if (addEmail === "") {
        if (invitationId) {
          if (invitationId?.visitor_email === "") {
            setFinalEmailLabel(labels.email);
          } else {
            setFinalEmailLabel(invitationId?.visitor_email);
          }
        } else {
          setFinalEmailLabel(labels.email);
        }
      } else {
        setFinalEmailLabel(labels.email);
      }
    } else {
      setFinalEmailLabel(labels.email);
    }
  };

  React.useEffect(() => {
    getCommunityMemberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityMember]);

  const getCommunityMemberData = () => {
    if (communityMember !== null) {
      const filteredCommunityMemberInv: CommunityMembers[] | undefined =
        communityMembers?.community_members.filter(
          ({ name }) => name === communityMember
        );
      if (filteredCommunityMemberInv) {
        setFilteredCommunityMemberId(filteredCommunityMemberInv[0].id);
        setFilteredCommunityMemberName(filteredCommunityMemberInv[0].name);
        setFilteredCommunityMemberEmail(filteredCommunityMemberInv[0].email);
        if (filteredCommunityMemberInv[0].phone.startsWith("+52")) {
          setFilteredCommunityMemberPhone(
            filteredCommunityMemberInv[0].phone.slice(3)
          );
          setFilteredCommunityMemberPhoneCode("+52");
        } else {
          setFilteredCommunityMemberPhone(
            filteredCommunityMemberInv[0].phone.slice(2)
          );
          setFilteredCommunityMemberPhoneCode("+1");
        }
      }
    } else {
      setFilteredCommunityMemberName("");
      setFilteredCommunityMemberEmail("");
      setFilteredCommunityMemberPhone("");
      setFilteredCommunityMemberPhoneCode("+52");
    }
  };

  React.useEffect(() => {
    getInvitationNameLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId, addName]);

  const getInvitationNameLabel = () => {
    if (invitationId) {
      if (addName === "") {
        setVisitorNameLabel(invitationId.visitor_name);
      } else {
        setVisitorNameLabel(labels.full_name);
      }
    } else {
      setVisitorNameLabel(labels.full_name);
    }
  };

  React.useEffect(() => {
    getInvitationIdPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId, addPhone]);

  const getInvitationIdPhone = () => {
    if (addPhone === "") {
      if (invitationId) {
        if (invitationId.visitor_phone.startsWith("+52")) {
          setCountryCode("+52");
          setVisitorPhoneLabel(invitationId.visitor_phone.slice(3));
        } else {
          setCountryCode("+1");
          setVisitorPhoneLabel(invitationId.visitor_phone.slice(2));
        }
      } else {
        setCountryCode("+52");
        setVisitorPhoneLabel(labels.phone_number);
      }
    } else {
      setCountryCode("+52");
      setVisitorPhoneLabel(labels.phone_number);
    }
  };

  React.useEffect(() => {
    if (networkName) {
      getCommunityMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkName]);

  const getCommunityMembers = async () => {
    if (networkName) {
      try {
        const result = await api.getCommunityMembersList(networkName);
        const cmName = Array.from(
          new Set(result.community_members.map(({ name }) => name))
        );
        setCommunityMembers(result);
        setCommunityMembersList(cmName);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.user_no_access);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  React.useEffect(() => {
    if (id) {
      getInvitationById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getInvitationById = async () => {
    if (id) {
      try {
        const result = await api.invitationIdRequest(Number(id));
        setInvitationId(result);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  React.useEffect(() => {
    getFinalPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  const getFinalPhone = () => {
    if (invitationId) {
      if (invitationId.visitor_phone.startsWith("+52")) {
        setVisitorIdPhone(invitationId.visitor_phone.slice(3));
        setVisitorIdPhoneCode("+52");
      } else {
        setVisitorPhoneLabel(invitationId.visitor_phone.slice(2));
        setVisitorIdPhoneCode("+1");
      }
    } else {
      setVisitorIdPhone("");
      setVisitorIdPhoneCode("+52");
    }
  };

  console.log("invitationId", invitationId);

  const saveAccessKey = async () => {
    const payload: EditVisitInvitationParams = {
      access_key: {
        visitor_name:
          communityMember !== null
            ? `${filteredCommunityMemberName}`
            : addName
            ? addName
            : invitationId
            ? invitationId?.visitor_name
            : "",
        visitor_email:
          communityMember !== null
            ? `${filteredCommunityMemberEmail}`
            : addEmail
            ? addEmail
            : viewVisitorEmailId
            ? invitationId?.visitor_email
              ? invitationId.visitor_email
              : ""
            : "",
        visitor_phone:
          communityMember !== null
            ? `${filteredCommunityMemberPhone}`
            : addPhone
            ? addPhone
            : visitorIdPhone,
        country_code:
          communityMember !== null
            ? `${filteredCommunityMemberPhoneCode}`
            : addPhone
            ? countryCode
            : visitorIdPhoneCode,
        reason_for_visit: invitationId ? invitationId?.reason_for_visit : "",
        notes: "",
        visitor_duration: hoursLater,
        valid_from: isoDate,
        community_member_id: saveCommunityMember
          ? `${filteredCommunityMemberId}`
          : null,
        access_servers: [Number(invitationId?.access_servers[0].id)],
        save_community_member: saveCommunityMember,
        passback_tracking: true,
      },
    };
    try {
      setIsLoading(true);
      await api.editVisitInvitation(Number(id), payload);
      setShowSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 419) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log("communityMember", communityMember);

  return (
    <Container className="w-100">
      {/* <div className="container-1 w-100">
        <Grid container>
          <Grid
            item
            xs={12}
            className="w-100 flex justify-content-space-between align-items-center"
          >
            <p className="my-6 font font-24 font-w-400 secondary-color">
              {labels.edit_invitation}
            </p>
            <IconButton
              onClick={() => navigate(`/invitations`)}
              className="m-0 p-0"
            >
              <CloseIcon className="m-0 p-0" />
            </IconButton>
          </Grid>
        </Grid>
      </div> */}
      <div className="w-100">
        <Grid container className="flex align-items-center pt-5">
          <Grid
            item
            xs={12}
            md={6}
            className="flex align-items-center justify-content-space-between"
          >
            <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
              {labels.edit_invitation}
            </p>
            <IconButton onClick={() => navigate(`/invitations`)}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </div>

      {/* USER DATA FIELD ------------------------------------------------------------------------------------------------------------------------------>> */}
      {/* CAMPO DATOS DEL USUARIO ---------------------------------------------------------------------------------------------------------------------->> */}
      <div className="flex justify-content-center">
        <Paper className="w-100 mt-4 mb-2" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.visitor_data}
            </p>
            {searchCommunityMember ? (
              <div
                onClick={() => {
                  setSearchCommunityMember(false);
                  setCommunityMemberVar(null);
                }}
                className="m-0 p-0 flex justify-content-center align-items-center button-style"
              >
                <CreateIcon
                  color="primary"
                  sx={{ fontSize: 35 }}
                  className="p-0 m-0"
                />
                <div className="flex flex-dir-col pr-3">
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.new}
                  </p>
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.register_button}
                  </p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setSearchCommunityMember(true);
                  setAddName("");
                  setAddEmail("");
                  setAddPhone("");
                  setCountryCode("+52");
                }}
                className="m-0 p-0 flex justify-content-center align-items-center button-style"
              >
                <SearchRoundedIcon
                  color="primary"
                  sx={{ fontSize: 35 }}
                  className="p-0 m-0"
                />
                <div className="flex flex-dir-col pr-3">
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.search_in}
                  </p>
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.favorites}
                  </p>
                </div>
              </div>
            )}
          </div>
          {searchCommunityMember ? (
            <>
              <div className="m-0 p-0 mb-6">
                <p className="subtitle-color m-0 p-0 mt-2 mb-5 ml-3">
                  {labels.search_in_favourites}
                </p>
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="x"
                  options={communityMembersList}
                  value={communityMember}
                  onChange={(event: any, newValue: string | null) => {
                    setCommunityMemberVar(newValue);
                  }}
                  inputValue={inputAutocompleteCM}
                  onInputChange={(event, newInputValue) => {
                    setInputAutocompleteCM(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={labels.add_favourite} />
                  )}
                />
              </div>
              {communityMember !== null && (
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} className="my-3">
                    <TextField
                      value={addName}
                      label={filteredCommunityMemberName}
                      fullWidth
                      disabled
                      onChange={(e) => {
                        setAddName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth className="mt-3">
                      <Select
                        value={filteredCommunityMemberPhoneCode}
                        disabled
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"+52"}>MX +52</MenuItem>
                        <MenuItem value={"+1"}>US +1</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={7} className="my-3">
                    <TextField
                      value={addPhone}
                      label={filteredCommunityMemberPhone}
                      disabled
                      fullWidth
                      onChange={(e) => {
                        setAddPhone(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="my-3">
                    <TextField
                      value={addEmail}
                      label={filteredCommunityMemberEmail}
                      disabled
                      fullWidth
                      onChange={(e) => {
                        setAddEmail(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid container columnSpacing={1}>
              <Grid item xs={12} className="my-3">
                <TextField
                  value={addName}
                  label={visitorNameLabel}
                  // label={labels.full_name}
                  fullWidth
                  onChange={(e) => {
                    setAddName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth className="mt-3">
                  <Select
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                  >
                    <MenuItem value={"+52"}>MX +52</MenuItem>
                    <MenuItem value={"+1"}>US +1</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7} className="my-3">
                <TextField
                  value={addPhone}
                  label={visitorPhoneLabel}
                  // label={labels.phone_number}
                  fullWidth
                  onChange={(e) => {
                    setAddPhone(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} className="my-3">
                <TextField
                  value={addEmail}
                  label={finalEmailLabel}
                  fullWidth
                  onChange={(e) => {
                    setAddEmail(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: invitationId?.visitor_email !== "" &&
                      viewVisitorEmailId && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="email-label"
                            onClick={onClickViewEmail}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                  }}
                />
              </Grid>
            </Grid>
          )}
          {!searchCommunityMember && (
            <>
              <div className="flex align-items-center mt-3">
                <Checkbox
                  checked={checked}
                  value={saveCommunityMember}
                  onChange={handleSaveCommunityMemberChange}
                />
                <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                  {labels.save_to_my_favorites}
                </p>
              </div>
            </>
          )}
        </Paper>
      </div>

      {/* VALIDITY FIELD ---------------------------------------------------------------------------------------------------------------------->> */}
      {/* CAMPO VIGENCIA ---------------------------------------------------------------------------------------------------------------------->> */}
      <div className="flex justify-content-center">
        <Paper className="w-100 my-2" sx={{ padding: "14px" }}>
          <p className="m-0 my-2 font-14 title-color">
            {labels.valid_for_the_day}
          </p>
          <Grid container>
            <Grid item xs={12} className="my-3">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label={labels.date}
                  value={date}
                  // onChange={(newDate: string | null) => {
                  //   setDate(moment(newDate).format("DD/MMM/YYYY"));
                  // }}
                  onChange={(newDate: Date | null) => {
                    setDate(newDate);
                  }}
                  inputFormat="DD/MMM/YYYY"
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container>
            <div className="w-100 flex justify-content-space-between">
              <div className="w-70 mr-4">
                <p className="m-0 my-2 font-14 subtitle-color">
                  {labels.allow_entry_from}
                </p>
                <div className="flex align-items-center">
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Select
                        value={hour}
                        onChange={(e) => {
                          setHour(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"00"}>00</MenuItem>
                        <MenuItem value={"01"}>01</MenuItem>
                        <MenuItem value={"02"}>02</MenuItem>
                        <MenuItem value={"03"}>03</MenuItem>
                        <MenuItem value={"04"}>04</MenuItem>
                        <MenuItem value={"05"}>05</MenuItem>
                        <MenuItem value={"06"}>06</MenuItem>
                        <MenuItem value={"07"}>07</MenuItem>
                        <MenuItem value={"08"}>08</MenuItem>
                        <MenuItem value={"09"}>09</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                        <MenuItem value={"17"}>17</MenuItem>
                        <MenuItem value={"18"}>18</MenuItem>
                        <MenuItem value={"19"}>19</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"21"}>21</MenuItem>
                        <MenuItem value={"22"}>22</MenuItem>
                        <MenuItem value={"23"}>23</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <span className="mx-3"> : </span>
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Select
                        value={minutes}
                        onChange={(e) => {
                          setMinutes(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"00"}>00</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"30"}>30</MenuItem>
                        <MenuItem value={"45"}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                {/* <p className="m-0 my-2 font-12 title-color">
                  {labels.select_entry_time}
                </p> */}
              </div>
              <div className="w-30 ml-4">
                <p className="m-0 my-2 font-14 subtitle-color">
                  {labels.until}
                </p>
                <div>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        value={hoursLater}
                        onChange={(e) => {
                          setHoursLater(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                        <MenuItem value={"17"}>17</MenuItem>
                        <MenuItem value={"18"}>18</MenuItem>
                        <MenuItem value={"19"}>19</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"21"}>21</MenuItem>
                        <MenuItem value={"22"}>22</MenuItem>
                        <MenuItem value={"23"}>23</MenuItem>
                        <MenuItem value={"24"}>24</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                <p className="m-0 my-2 font-12 title-color">
                  {labels.hours_later}
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </div>

      {/* BUTTON --------------------------------------------------------------------------------------------------------------------->> */}
      {/* BOTON ---------------------------------------------------------------------------------------------------------------------->> */}
      <div className="flex flex-dir-col w-100 align-items-center justify-content-center mt-6 mb-10">
        <Button
          variant="contained"
          size="large"
          className="py-6 w-80"
          sx={{ fontWeight: "normal" }}
          disableElevation
          onClick={() => {
            saveAccessKey();
          }}
        >
          {isLoading ? (
            <CircularProgress size={26.5} />
          ) : (
            labels.save_invitation
          )}
        </Button>
      </div>
      <div className="lower-div root-background" />

      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {labels.saved_invitation}
            </p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setShowSuccessModal(false);
                setTimeout(() => navigate("/invitations"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default EditInvitation;
