import React from "react";
import { useAppSelector, useWindowSize, useAppDispatch } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
} from "@mui/material";
import VisitRegister from "../../components/VisitRegister";
import ProviderRegister from "../../components/ProviderRegister";
import { resetUser, selectUser, setUser } from "../../slices/user";
import api, { RegisterCustomAttributes } from "../../api";
import { showError } from "../../utils/helper";
import { resetToken, selectToken } from "../../slices/token";

const Register = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const { isMobile } = useWindowSize();

  const [visitProvider, setVisitProvider] = React.useState<string>("visit");
  const [accessPoint, setAccessPoint] = React.useState<string | undefined>(
    user?.last_access_selected?.id
      ? `${user?.last_access_selected.id}`
      : undefined
  );
  const [accessDoor, setAccessDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(
        ({ type }) => type === "access"
      )[0]?.id
    }`
  );
  const [registerCustomAttributes, setRegisterCustomAttributes] =
    React.useState<RegisterCustomAttributes | null>(null);

  const handleVisitProviderChange = (event: SelectChangeEvent) => {
    setVisitProvider(event.target.value);
  };

  React.useEffect(() => {
    changeAccessDoor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const changeAccessDoor = () => {
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0].id
      }`
    );
  };

  const handleAccessPointChange = async (event: SelectChangeEvent) => {
    try {
      setAccessPoint(event.target.value);
      const newUserData = await api.changeAccessServerRequest({
        access_server: Number(event.target.value),
      });
      dispatch(setUser(newUserData));
      setAccessDoor(
        `${
          newUserData?.last_access_selected?.gates.filter(
            ({ type }) => type === "access"
          )[0]?.id
        }`
      );
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  const handleAccessDoorChange = (event: SelectChangeEvent) => {
    setAccessDoor(event.target.value);
  };

  return (
    <Container>
      {/* {user?.roles[0].name === "security_guard" ? (
        <div className="container-1 w-100">
          <Grid container>
            <Grid item md={9}>
              <p className="my-6 pt-5 font font-24 font-w-400 secondary-color">
                {labels.register_visit_provider}
              </p>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="container-1 w-100">
          <Grid container>
            <Grid item md={9}>
              <p className="my-6 font font-24 font-w-400 secondary-color">
                {labels.register_visit_provider}
              </p>
            </Grid>
          </Grid>
        </div>
      )} */}
      <div className="w-100">
        <Grid container className="flex align-items-center pt-5">
          <Grid item xs={12} md={6} className="flex align-items-center">
            <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
              {labels.register_visit_provider}
            </p>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container spacing={1.5} className="mt-1 mb-5">
        <Grid item md={5.5} xs={12}>
          <FormControl fullWidth>
            <InputLabel>{labels.visit_provider}</InputLabel>
            <Select
              labelId="select-visit"
              value={visitProvider}
              onChange={handleVisitProviderChange}
              displayEmpty
              label={labels.visit_provider}
              inputProps={{ "aria-label": "Whithout label" }}
            >
              <MenuItem value="visit">{labels.visit}</MenuItem>
              <MenuItem value="provider">{labels.provider}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* {!isMobile && <Grid item md={5} />} */}
        {/* <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="access-point-label">
              {labels.access_point}
            </InputLabel>
            <Select
              labelId="access-point-label"
              value={accessPoint}
              onChange={handleAccessPointChange}
              label={labels.access_point}
            >
              {user?.access_servers.map(({ name, id }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        {!isMobile && <Grid item md={1} />}
        <Grid item md={5.5} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-door-label">{labels.select_door}</InputLabel>
            <Select
              labelId="select-door-label"
              value={accessDoor}
              onChange={handleAccessDoorChange}
              label={labels.select_door}
            >
              {user?.last_access_selected?.gates
                .filter(({ type }) => type === "access")
                .map(({ name, id }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider />
      {visitProvider === "visit" ? (
        <VisitRegister accessDoor={Number(accessDoor)} />
      ) : (
        <ProviderRegister accessDoor={Number(accessDoor)} />
      )}
    </Container>
  );
};
export default Register;
