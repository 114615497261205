import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { useNavigate, useParams } from "react-router-dom";
import AddEventGuest from "../../components/AddEventGuest";
import { CircularProgress } from "@mui/material";

const AddEventGuestRefactor = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();

  const { eventId } = useParams();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  return (
    <div
      className="w-100 flex flex-dir-col align-items-center"
      style={{ paddingBottom: 70 }}
    >
      <div
        className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary"
        style={{ height: 60 }}
      >
        <div style={{ width: "20%" }}></div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="font-14 uppercase text-primary-color medium">
            {labels.add_guest}
          </p>
        </div>
        <div
          className="flex align-items-center justify-content-flex-end"
          style={{ width: "20%" }}
        >
          <CloseRoundedIcon
            className="primary-color"
            style={{ marginRight: 20 }}
            onClick={() => navigate(`/event-detail/${eventId}`)}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-100 flex flex-dir-col align-items-center">
          <div
            className="w-90 flex flex-dir-col justify-content-center align-items-center shadow-one"
            style={{
              marginRight: 10,
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
              padding: 8,
              backgroundColor: "white",
              borderRadius: 5,
              maxWidth: 500,
            }}
          >
            <AddEventGuest
              invId={Number(eventId)}
              actionReopen={() => navigate(`/event-detail/${eventId}`)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEventGuestRefactor;
