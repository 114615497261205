import { CircularProgress, Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import api, { UsersByDepartment } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import MainPagesHeader from "../../components/MainPagesHeader";
import Pagination from "../../components/Pagination";
import UsersList from "../../components/UsersList";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import { showError } from "../../utils/helper";

const MyUsersRefactor = () => {
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectLabels);
  const userRole = useAppSelector(selectUserRole);

  const [usersByDepartment, setUsersByDepartment] =
    React.useState<UsersByDepartment | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  //END POINT USERS BY DEPARTMENT------------------------------------------------------------------>>
  //END POINT USUARIOS POR DEPARTAMENTO------------------------------------------------------------>>
  React.useEffect(() => {
    requestUsersByDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, rowsPerPage, searchInput]);

  const requestUsersByDepartment = async () => {
    try {
      setIsLoading(true);
      if (token) {
        const usersNetwork: any = await api.usersByNetworkRequest(
          page,
          rowsPerPage,
          searchInput
        );
        setUsersByDepartment(usersNetwork);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 404) {
        showError(dispatch, labels.user_not_found);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container className="padding-out">
        <MainPagesHeader
          newIcon={userRole === "host" ? true : false}
          onPressNewIconAction={() => navigate("/new-user")}
          mainTitle="users"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={false}
          showFilters={false}
          setShowFilters={() => {}}
          downloadable={false}
          fileArray={[]}
          fileName=""
        />
        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {isLoading ? (
          <div
            className="flex justify-content-center align-items-center w-100"
            style={{ marginTop: 100 }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className="w-100 flex flex-dir-col justify-content-center"
            style={{ paddingBottom: usersByDepartment?.total_pages ? 145 : 74 }}
          >
            {usersByDepartment && usersByDepartment.users.length > 0 ? (
              usersByDepartment.users.map(({ name, phone, id }) => (
                <div
                  key={id}
                  className="w-100 flex justify-content-center mt-5"
                >
                  <UsersList
                    userName={name}
                    userPhone={phone}
                    onPress={() => navigate(`/user-detail/${id}`)}
                  />
                </div>
              ))
            ) : (
              <div className="w-100 flex justify-content-center">
                <p className="font-16 subtitle-color">
                  {labels.no_results_found}
                </p>
              </div>
            )}
          </div>
        )}
      </Container>
      {usersByDepartment?.total_pages ? (
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={usersByDepartment?.total_pages}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MyUsersRefactor;
