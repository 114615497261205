import "./button-bottom-component.scss";

interface ButtonBottomComponentProps {
  action: () => void;
  text: string;
}

const ButtonBottomComponent = ({
  action,
  text,
}: ButtonBottomComponentProps) => {
  return (
    <div className="button-bottom-container upper-shadow">
      <div
        className="background-primary-color flex shadow-one justify-content-center align-items-center"
        style={{
          width: "47.5%",
          height: 42,
          borderRadius: 4,
          maxWidth: 300,
        }}
        onClick={action}
      >
        <p className="text-white uppercase">{text}</p>
      </div>
    </div>
  );
};

export default ButtonBottomComponent;
