import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import api, { UserDetails } from "../../api";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { selectUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import CustomAttributes from "../CustomAttributes";
import PhotoButtons from "../PhotoButtons";

interface RegisterManualAccessDesktopProps {
  name: string;
  setName: (name: string) => void;
  host: string;
  setHost: (host: string) => void;
  reason: string;
  setReason: (reason: string) => void;
  remark: string;
  setRemark: (remark: string) => void;
  department: string | null;
  setDepartment: (department: string | null) => void;
  idPhoto: string | null;
  setIdPhoto: (idPhoto: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (platePhoto: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (personPhoto: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (otherPhoto: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (accreditationPhoto: string | null) => void;
  registerCustomAttributes: any | null;
  setRegisterCustomAttributes: (registerCustomattributes: any | null) => void;
  registerButtonAction: () => void;
  loadingButton: boolean;
}

const RegisterManualAccessDesktop = ({
  name,
  setName,
  host,
  setHost,
  reason,
  setReason,
  remark,
  setRemark,
  department,
  setDepartment,
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  registerCustomAttributes,
  setRegisterCustomAttributes,
  registerButtonAction,
  loadingButton,
}: RegisterManualAccessDesktopProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [usersInDepartment, setUsersInDepartment] = React.useState<
    { id: number | string; name: string; phone: string }[]
  >([]);
  const [showPhotosContainer, setShowPhotosContainer] =
    React.useState<boolean>(false);
  const [departmentsList, setDepartmentsList] = React.useState<string[]>([]);
  const [completeDepartmentsList, setCompleteDepartmentsList] = React.useState<
    UserDetails[]
  >([]);
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);
  const [buttonContainerHeight, setButtonContainerHeight] =
    React.useState<number>(0);

  const debouncedSearchText = useDebounce(department, 1000);

  const isDisabled =
    !name ||
    !reason ||
    !host ||
    (user?.last_access_selected?.settings.photo_id && !idPhoto) ||
    (user?.last_access_selected?.settings.front_plates && !frontPlatesPhoto) ||
    (user?.last_access_selected?.settings.back_plates && !backPlatesPhoto) ||
    (user?.last_access_selected?.settings.other && !otherPhoto) ||
    (user?.last_access_selected?.settings.accreditation && !accreditationPhoto);

  const isDisabledCA =
    !name ||
    !reason ||
    !host ||
    (user?.last_access_selected?.settings.photo_id && !idPhoto) ||
    (user?.last_access_selected?.settings.front_plates && !frontPlatesPhoto) ||
    (user?.last_access_selected?.settings.back_plates && !backPlatesPhoto) ||
    (user?.last_access_selected?.settings.other && !otherPhoto) ||
    (user?.last_access_selected?.settings.accreditation &&
      !accreditationPhoto) ||
    !customAttributesRequired;

  const mainContainer: any = React.useRef(null);

  const defaultProps = {
    options: departmentsList,
    getOptionLabel: (option: string) => option,
  };

  React.useEffect(() => {
    const height = mainContainer?.current?.offsetHeight;
    setButtonContainerHeight(height);
  }, []);

  console.log("departmentsList", departmentsList);

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = registerCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  React.useEffect(() => {
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      setShowPhotosContainer(true);
    } else {
      setShowPhotosContainer(false);
    }
  }, [user?.last_access_selected]);

  React.useEffect(() => {
    const usersInDepartment = completeDepartmentsList.filter(
      (user) => user.network_group?.name === department
    );
    setUsersInDepartment(usersInDepartment);
    setHost(usersInDepartment[0]?.id ? `${usersInDepartment[0]?.id}` : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  React.useEffect(() => {
    requestUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //END POINT USERS LIST REQUEST------------------------------------------------>>
  //END POINT SOLICITUD DE LISTA DE USUARIOS------------------------------------>>
  const requestUserList = async () => {
    try {
      const result = await api.usersListRequest("");
      const departments = Array.from(
        new Set(
          result.users
            .filter((user) => user.network_group)
            .map((user) => user.network_group?.name)
        )
      );
      setCompleteDepartmentsList(result.users);
      setDepartmentsList(departments);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: buttonContainerHeight,
        overflow: "auto",
      }}
    >
      <TextField
        required
        value={name}
        placeholder={`${labels.full_name} *`}
        fullWidth
        size="small"
        style={{ backgroundColor: "white", borderRadius: 4 }}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
        <Autocomplete
          disablePortal
          // disableCloseOnSelect
          fullWidth
          style={{ backgroundColor: "white", borderRadius: 4 }}
          id="x"
          size="small"
          options={departmentsList}
          value={department}
          // getOptionLabel={(option) => option}
          onChange={(event: any, newValue: string | null) => {
            setDepartment(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              placeholder={`${labels.department} *`}
            />
          )}
        />
        <div style={{ width: 16 }} />
        <FormControl
          fullWidth
          size="small"
          style={{ backgroundColor: "white", borderRadius: 4 }}
        >
          <Select
            labelId="host"
            value={host}
            onChange={(e) => {
              setHost(e.target.value);
            }}
            inputProps={{ "aria-label": "Whithout label" }}
            displayEmpty
          >
            <MenuItem value="">
              <em>{labels.host}</em>
            </MenuItem>
            {usersInDepartment.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {registerCustomAttributes && registerCustomAttributes.length > 0 && (
        <>
          {registerCustomAttributes.map(
            (
              { attribute_name, attribute_type, required, options, value }: any,
              index: number
            ) => (
              <div key={index} style={{ marginTop: 10 }}>
                <CustomAttributes
                  type={attribute_type}
                  name={attribute_name}
                  required={required}
                  value={value}
                  setValue={(newValue: any) => {
                    const newRegisterCustomAttributes = [
                      ...registerCustomAttributes,
                    ];
                    newRegisterCustomAttributes[index].value = newValue;
                    setRegisterCustomAttributes(newRegisterCustomAttributes);
                  }}
                  listArray={options}
                  isDesktop={true}
                />
              </div>
            )
          )}
        </>
      )}
      <TextField
        required
        value={reason}
        placeholder={`${labels.visit_reason} *`}
        variant="outlined"
        fullWidth
        size="small"
        style={{ backgroundColor: "white", marginTop: 10, borderRadius: 4 }}
        multiline
        onChange={(e) => {
          setReason(e.target.value);
        }}
      />
      <TextField
        value={remark}
        id="observation"
        placeholder={labels.notes}
        fullWidth
        size="small"
        style={{ backgroundColor: "white", marginTop: 10 }}
        multiline
        onChange={(e) => {
          setRemark(e.target.value);
        }}
      />
      {showPhotosContainer && (
        <div style={{ width: "100%", marginBottom: 8 }}>
          <div
            style={{
              width: "100%",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 18,
              marginBottom: 8,
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: 1,
                backgroundColor: "gray",
              }}
            />
            <p
              className="m-0 p-0 subtitle-color pl-5 pr-5"
              style={{ backgroundColor: "#f5f5f5", zIndex: 90 }}
            >
              {labels.required_photos} *
            </p>
          </div>
          <PhotoButtons
            idPhoto={idPhoto}
            setIdPhoto={setIdPhoto}
            frontPlatesPhoto={frontPlatesPhoto}
            setFrontPlatesPhoto={setFrontPlatesPhoto}
            backPlatesPhoto={backPlatesPhoto}
            setBackPlatesPhoto={setBackPlatesPhoto}
            otherPhoto={otherPhoto}
            setOtherPhoto={setOtherPhoto}
            accreditationPhoto={accreditationPhoto}
            setAccreditationPhoto={setAccreditationPhoto}
            desktopView={true}
          />
        </div>
      )}
      <div
        ref={mainContainer}
        style={{
          width: "100%",
          boxSizing: "border-box",
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          zIndex: 99,
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            fullWidth
            size="medium"
            onClick={registerButtonAction}
            disabled={
              registerCustomAttributes && registerCustomAttributes.length > 0
                ? isDisabledCA
                : isDisabled
            }
          >
            {loadingButton ? (
              <CircularProgress size={26.5} style={{ color: "white" }} />
            ) : (
              labels.check_in
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterManualAccessDesktop;
