import "./button-bottom.scss";
import { FaShareAlt } from "react-icons/fa";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

interface ButtonBottomProps {
  action: () => void;
  userRole: "guard" | "host";
}

const ButtonBottom = ({ action, userRole }: ButtonBottomProps) => {
  const labels = useAppSelector(selectLabels);

  return (
    <div className="button-container upper-shadow">
      <div
        className="background-primary-color flex shadow-one justify-content-center align-items-center"
        style={{
          width: "47.5%",
          height: 42,
          borderRadius: 4,
          maxWidth: 300,
        }}
        onClick={action}
      >
        {userRole === "guard" ? (
          <>
            <p className="text-white uppercase">{labels.check_in}</p>
          </>
        ) : (
          <>
            <p className="text-white uppercase">{labels.share}</p>
            <div className="flex align-items-center ml-4">
              <FaShareAlt
                className="text-white"
                style={{ fontSize: 18 }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ButtonBottom;
