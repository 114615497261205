import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Divider, IconButton } from "@mui/material";
import { FaPen } from "react-icons/fa";
import api, { EventGuestList, EventIdParams } from "../../api";
import { showError } from "../../utils/helper";
import { resetToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { HiPlus } from "react-icons/hi";
import SearchInputHeader from "../../components/SearchInputHeader";
import { AiFillCloseCircle } from "react-icons/ai";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import ModalOneButton from "../../components/ModalOneButton";

const EventDetailRefactor = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  var moment = require("moment-timezone");

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [eventIdRes, SetEventIdRes] = React.useState<EventIdParams | null>(
    null
  );
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [isLoadingGuest, setIsLoadingGuest] = React.useState<boolean>(false);
  const [guestsPage, setGuestsPage] = React.useState<number>(1);
  const [guestList, setGuestList] = React.useState<EventGuestList | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [nameToDelete, setNameToDelete] = React.useState<string>("");
  const [deleteGuestId, setDeleteGuestId] = React.useState<number>(0);
  const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
  const [noEdit, setNoEdit] = React.useState<boolean>(false);
  const guestsResults = 5;

  const { eventId } = useParams();

  const handleChangeUpPage = () => {
    setGuestsPage(guestsPage + 1);
  };

  const handleChangeDownPage = () => {
    setGuestsPage(guestsPage - 1);
  };

  React.useEffect(() => {
    setGuestsPage(1);
  }, [searchValue]);

  const onPressDeleteGuest = (name: string, id: number) => {
    setNameToDelete(name);
    setDeleteGuestId(id);
    setShowDeleteModal(true);
  };

  // React.useEffect(() => {
  //   if (networkName) {
  //     getCommunityMembers();
  //   }
  // }, [networkName]);

  // const getCommunityMembers = async () => {
  //   if (networkName) {
  //     try {
  //       setIsLoading(true);
  //       const result = await api.getCommunityMembersList(networkName);
  //       setCommunityMembers(result);
  //     } catch (error: any) {
  //       console.error(error);
  //       if (error.response.status === 401) {
  //         showError(dispatch, labels.errors.not_authorized);
  //       } else if (error.response.status === 422) {
  //         showError(dispatch, labels.errors.user_has_no_access_to_records);
  //       } else if (error.response.status === 403) {
  //         dispatch(resetToken());
  //         dispatch(resetUser());
  //       } else {
  //         showError(dispatch, labels.errors.generic_error);
  //       }
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  React.useEffect(() => {
    if (eventId) {
      getEventId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const getEventId = async () => {
    if (eventId) {
      try {
        setIsLoading(true);
        const result = await api.getEventId(Number(eventId));
        SetEventIdRes(result);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 404) {
          showError(dispatch, labels.not_found);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (eventId) {
      requestGuestList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, searchValue, guestsPage, guestsResults]);

  const requestGuestList = async () => {
    try {
      setIsLoadingGuest(true);
      const events: any = await api.eventAccessKeysRequest(
        Number(eventId),
        searchValue,
        guestsPage,
        Number(guestsResults)
      );
      setGuestList(events);
    } catch (error: any) {
      // console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 404) {
        if (user?.roles[0].name === "network_admin") {
          setNoEdit(true);
        } else {
          showError(dispatch, labels.not_found);
        }
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
    setIsLoadingGuest(false);
  };

  //END POINT DELETE INVITATION----------------------------------------------------------------------->>
  //END POINT BORRAR INVITACION----------------------------------------------------------------------->>
  const deleteInvitation = async () => {
    if (deleteGuestId) {
      try {
        setIsLoadingDelete(true);
        await api.deleteAccessKey(Number(deleteGuestId));
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.errors.not_authorized);
        } else if (error.response.stauts === 422) {
          showError(dispatch, labels.errors.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.errors.generic_error);
        }
      } finally {
        setShowDeleteModal(false);
        getEventId();
        requestGuestList();
        setIsLoadingDelete(false);
      }
    }
  };

  return (
    <div
      className="w-100 flex flex-dir-col align-items-center"
      style={{ paddingBottom: 70 }}
    >
      <ModalOneButton
        title={labels.delete_guest}
        bodyText={nameToDelete}
        buttonText={
          isLoadingDelete ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            labels.continue
          )
        }
        buttonAction={isLoadingDelete ? () => {} : deleteInvitation}
        showModal={showDeleteModal}
        closeAction={() => setShowDeleteModal(false)}
      />
      <div
        className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary"
        style={{ height: 60 }}
      >
        <div style={{ width: "20%" }}></div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="font-14 uppercase text-primary-color medium">
            {labels.event_details}
          </p>
        </div>
        <div
          className="flex align-items-center justify-content-flex-end"
          style={{ width: "20%" }}
        >
          <CloseRoundedIcon
            className="primary-color"
            style={{ marginRight: 20 }}
            onClick={() => navigate("/events")}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-100 flex flex-dir-col align-items-center">
          <div
            className="w-90 flex flex-dir-col justify-content-center align-items-center shadow-one"
            style={{
              marginRight: 10,
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
              padding: 8,
              backgroundColor: "white",
              borderRadius: 5,
              maxWidth: 500,
            }}
          >
            <div
              className="w-100 flex background-gray-color justify-content-space-between align-items-center"
              style={{
                minHeight: 45,
                maxHeight: 55,
              }}
            >
              <p className="medium font-18 ml-6">{eventIdRes?.event?.name}</p>
              {!noEdit && (
                <div className="flex justify-content-flex-end align-items-center mr-6">
                  <FaPen
                    style={{ fontSize: 20 }}
                    className="primary-color"
                    onClick={() => navigate(`/edit-event/${eventId}`)}
                  />
                </div>
              )}
            </div>
            <div className="w-100 pl-9 mb-3 mt-6">
              <p className="subtitle-color medium m-0 p-0">
                {eventIdRes?.event?.description}
              </p>
            </div>
            <div className="w-100 flex align-items-baseline pl-9 mt-3 mb-6">
              <p className="subtitle-color medium p-0 m-0">
                {eventIdRes?.event?.access_servers[0]?.time_zone_name
                  ? moment(eventIdRes?.event?.start_date)
                      .tz(eventIdRes?.event?.access_servers[0]?.time_zone_name)
                      .format("DD MMMM")
                  : moment(eventIdRes?.event?.start_date).format("DD MMMM")}
              </p>
              <p className="m-0 p-0 ml-4 subtitle-color">
                {eventIdRes?.event?.access_servers[0]?.time_zone_name
                  ? moment(eventIdRes?.event?.start_date)
                      .tz(eventIdRes?.event?.access_servers[0]?.time_zone_name)
                      .format("h:mma")
                  : moment(eventIdRes?.event?.start_date).format("h:mma")}{" "}
                -{" "}
                {eventIdRes?.event?.access_servers[0]?.time_zone_name
                  ? moment(eventIdRes?.event?.end_date)
                      .tz(eventIdRes?.event?.access_servers[0]?.time_zone_name)
                      .format("h:mma")
                  : moment(eventIdRes?.event?.end_date).format("h:mma")}
              </p>
            </div>
            {!noEdit && (
              <>
                <Divider className="w-100 mb-4" />
                <div
                  className="w-100 flex flex-dir-col background-gray-color justify-content-center align-items-center"
                  style={{
                    minHeight: 45,
                  }}
                >
                  <div className="w-100 flex justify-content-space-between aling-items-center">
                    <p className="medium ml-6">
                      {labels.guests}: {eventIdRes?.event?.number_of_guests}
                    </p>
                    <div className="flex justify-content-flex-end align-items-center mr-5">
                      <HiPlus
                        style={{ fontSize: 24 }}
                        className="primary-color"
                        onClick={() => navigate(`/add-event-guest/${eventId}`)}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <div
                      style={{
                        marginLeft: 12,
                        marginRight: 12,
                        marginBottom: 12,
                      }}
                    >
                      <SearchInputHeader
                        value={searchValue}
                        setValue={setSearchValue}
                      />
                    </div>
                  </div>
                </div>
                {isLoadingGuest ? (
                  <div className="flex justify-content-center align-items-center w-100 mt-13">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="w-100">
                    {guestList && guestList.access_keys.length > 0 ? (
                      guestList.access_keys.map(
                        ({ id, visitor_name, visitor_phone }) => (
                          <div
                            key={id}
                            className="w-100 flex justify-content-center align-items-center mt-2 mb-2"
                            style={{
                              borderColor: "#e8e8e8",
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderRadius: 4,
                            }}
                          >
                            <div className="w-100 flex justify-content-space-between align-items-center mr-6 ml-6 pt-5 pb-5">
                              <div className="w-75 flex flex-dir-col">
                                <p className="m-0 p-0 subtitle-color medium">
                                  {visitor_name}
                                </p>
                                <p className="m-0 p-0 subtitle-color mt-3">
                                  {visitor_phone}
                                </p>
                              </div>
                              <div className="w-25 flex justify-content-flex-end align-items-center">
                                <FaPen
                                  style={{ fontSize: 18 }}
                                  className="primary-color mr-6"
                                  onClick={() =>
                                    navigate(
                                      `/edit-event-guest/${eventId}/${id}`
                                    )
                                  }
                                />
                                <AiFillCloseCircle
                                  style={{ fontSize: 22 }}
                                  className="primary-color"
                                  onClick={() =>
                                    onPressDeleteGuest(visitor_name, id)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="w-100 flex justify-content-center">
                        <p className="font-16 subtitle-color">
                          {labels.no_results_found}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                {guestList && guestList?.access_keys.length > 0 ? (
                  <div
                    className="w-100 flex background-gray-color justify-content-flex-end align-items-center"
                    style={{
                      minHeight: 45,
                    }}
                  >
                    <p className="primary-color">
                      {guestsPage} {labels.of} {guestList.total_pages}
                    </p>
                    <div className="mx-2">
                      <IconButton
                        color="primary"
                        disabled={guestsPage <= 1}
                        onClick={handleChangeDownPage}
                      >
                        <NavigateBeforeRoundedIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        disabled={guestsPage >= guestList?.total_pages}
                        onClick={handleChangeUpPage}
                      >
                        <NavigateNextRoundedIcon />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetailRefactor;
