import { Box, Modal } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface ModalOneButtonProps {
  title: string;
  bodyText: any;
  buttonText: any;
  showModal: boolean;
  closeAction: () => void;
  buttonAction: () => void;
}

const ModalOneButton = ({
  title,
  bodyText,
  buttonText,
  showModal,
  buttonAction,
  closeAction,
}: ModalOneButtonProps) => {
  return (
    <>
      <Modal disableEscapeKeyDown open={showModal}>
        <Box sx={style}>
          <div
            className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary mt-4"
            style={{ height: 42 }}
          >
            <div style={{ width: "20%" }}></div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="font-14 uppercase text-primary-color medium">
                {title}
              </p>
            </div>
            <div
              className="flex align-items-center justify-content-flex-end"
              style={{ width: "20%" }}
            >
              <CloseRoundedIcon
                className="primary-color"
                onClick={closeAction}
              />
            </div>
          </div>
          <div className="w-100 flex justify-content-center aling-items-center">
            <p className="subtitle-color" style={{ textAlign: "center" }}>
              {bodyText}
            </p>
          </div>
          <div className="w-100 flex justify-content-center align-items-center mb-8">
            <div
              className="background-primary-color flex shadow-one justify-content-center align-items-center"
              style={{ width: 200, height: 42, borderRadius: 4 }}
              onClick={buttonAction}
            >
              <p className="text-white uppercase">{buttonText}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};

export default ModalOneButton;
