import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import api, { StrapiLoginPayload } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Logo from "../../components/Logo";
import { selectLabels } from "../../slices/language";
import { gettingToken, setToken } from "../../slices/token";
import { setUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import "./login.scss";
import { isMobile } from "react-device-detect";
import { setStrapiUser } from "../../slices/strapiUser";
import { StrapiUserPayload } from "../../strapiModels/strapiUser";

const Login = () => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState(localStorage.getItem("email") || "");
  const [password, setPassword] = React.useState(
    localStorage.getItem("password") || ""
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rememberPassword, setRememberPassword] = React.useState(
    !!localStorage.getItem("email") && !!localStorage.getItem("password")
  );
  const isMounted = React.useRef(true);

  const emailAdjusted = email.trim().toLowerCase();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onSubmit = async () => {
    dispatch(gettingToken());
    try {
      setIsLoading(true);
      const { meta, user } = await api.loginRequest({
        email: emailAdjusted,
        password,
      });
      if (isMounted.current) {
        if (rememberPassword) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
        const payload: StrapiUserPayload = {
          username: user?.name ? user?.name : "",
          email: emailAdjusted,
          password: password,
          role:
            user?.roles[0]?.name === "host"
              ? 3
              : user?.roles[0]?.name === "secondary_user"
              ? 5
              : user?.roles[0]?.name === "secondary_user"
              ? 4
              : 6,
          confirmed: true,
          phone: user?.phone ? user?.phone : "",
          network_id: user?.network?.id ? user?.network?.id : null,
          auth_token: meta.auth_token,
          network_group_id: user?.network_group?.id
            ? user?.network_group?.id
            : null,
          last_access_selected_id: user?.last_access_selected?.id,
          email_notification: user?.email_notification,
          push_notification: user?.push_notification,
          id_legacy: user?.id,
        };
        // getStrapiUserByToken(user?.id, emailAdjusted, password, payload);
        dispatch(setToken(meta.auth_token));
        dispatch(setUser(user));
        if (user.roles.find(({ name }) => name === "security_guard")) {
          navigate("/qr-scanner");
        } else if (
          user.roles.find(({ name }) => name === "network_admin") &&
          isMobile
        ) {
          navigate("/invitations");
        } else if (
          user.roles.find(({ name }) => name === "network_admin") &&
          !isMobile
        ) {
          navigate("/");
        } else if (user.roles.find(({ name }) => name === "host") && isMobile) {
          navigate("/invitations");
        } else if (
          user.roles.find(({ name }) => name === "host") &&
          !isMobile
        ) {
          navigate("/");
        } else {
          navigate("/invitations");
        }
      }
    } catch (error: any) {
      console.error(error);
      console.log("error", error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.invalidCredentials);
      } else if (error?.response?.status === 403) {
        showError(dispatch, labels.suspended_account);
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getStrapiUserByToken = async (
    id: number,
    email: string,
    password: string,
    userPayload: StrapiUserPayload
  ) => {
    const payload: StrapiLoginPayload = {
      identifier: email,
      password: password,
    };
    try {
      const response = await api.getStrapiUserById(id);
      if (response && response.length > 0) {
        const loginResponse = await api.strapiLoginRequest(payload);
        if (loginResponse) {
          dispatch(setStrapiUser(loginResponse.user));
        }
      } else {
        const createUserResponse = await api.createStrapiUser(userPayload);
        if (createUserResponse) {
          const loginResponse = await api.strapiLoginRequest(payload);
          if (loginResponse) {
            dispatch(setStrapiUser(loginResponse.user));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="xs" className="my-7">
      <Paper
        variant="outlined"
        className="p-10 vh-85 flex justify-content-center align-items-center flex-dir-col vertical-spacing-7"
      >
        <Logo type="passtrackLogo" alt="passtrack" className="logo" />
        <p className="subtitle-color medium font-28 mt-0">{labels.login}</p>
        <TextField
          className="mt-8"
          label={labels.email_address}
          variant="outlined"
          fullWidth
          placeholder="john@mail.com"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={showPassword ? "text" : "password"}
          label={labels.password}
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
        />
        <div className="full-width">
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberPassword}
                onChange={() => {
                  setRememberPassword(!rememberPassword);
                }}
              />
            }
            label={labels.remember_password}
          />
        </div>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          onClick={onSubmit}
          disabled={!email || !password || isLoading}
          className="py-6"
        >
          {isLoading ? <CircularProgress size={26.5} /> : labels.login}
        </Button>
        <p>
          <Link to="/restore-password">{labels.fogot_the_password}</Link>
        </p>
      </Paper>
    </Container>
  );
};

export default Login;
