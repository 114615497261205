import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface Size {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
}

export const SCREEN = {
  sm: 575,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: false,
    isTablet: false,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        ...windowSize,
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setWindowSize({
      ...windowSize,
      isMobile: windowSize.width < SCREEN.sm,
      isTablet: windowSize.width >= SCREEN.sm && windowSize.width < SCREEN.md,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  return windowSize;
};

export const useOnClickOutside = (ref: any, handler: any) => {
  React.useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return debouncedValue;
};
