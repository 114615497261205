import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import CreateIcon from "@mui/icons-material/Create";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import api, { CommunityMembersList, CreateEventParams } from "../../api";
import { selectUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
// import "./new-event.scss";
import { Box } from "@mui/system";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const EditEventGuests = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [eventName, setEventName] = React.useState<string>("");
  const [eventDescription, setEventDescription] = React.useState<string>("");
  const [minutes, setMinutes] = React.useState<string | null>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [hour, setHour] = React.useState<string | null>(
    moment(date).format("HH")
  );
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<boolean>(false);
  const [addName, setAddName] = React.useState<string>("");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [communityMembersList, setCommunityMembersList] = React.useState<
    string[]
  >([]);
  const [communityMember, setCommunityMember] = React.useState<string | null>(
    ""
  );
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [addGuests, setAddGuests] = React.useState<any[]>([]);
  const [deniedModal, setDeniedModal] = React.useState<boolean>(false);
  const [addAccessPoints, setAddAccessPoints] = React.useState<number[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);

  const networkName = user?.network_group.name;

  const [communityMembers, setCommunityMembers] =
    React.useState<CommunityMembersList | null>(null);

  const isoDate = `${moment(date).format(
    `YYYY-MM-DDT${hour}:${minutes}:ss.SSSZ`
  )}`;

  const disabled = !addName || !addPhone;

  const disabledCommunityMember = !communityMember;

  const disabledCreateEvent =
    !eventName ||
    !eventDescription ||
    addGuests.length <= 0 ||
    addAccessPoints.length <= 0;

  interface Guest {
    id?: number | null;
    community_member?: boolean;
    phone?: string | undefined;
    name?: string | undefined;
    email?: string;
    save?: boolean;
  }

  const handleAddGuest = () => {
    const newGuest: Guest = {};
    if (searchCommunityMember) {
      const filteredCommunityMember =
        communityMembers?.community_members.filter(
          ({ name }) => name === communityMember
        );
      newGuest.id = filteredCommunityMember && filteredCommunityMember[0].id;
      newGuest.community_member = true;
      newGuest.phone =
        filteredCommunityMember && filteredCommunityMember[0].phone;
      newGuest.name =
        filteredCommunityMember && filteredCommunityMember[0].name;
      newGuest.email =
        filteredCommunityMember && filteredCommunityMember[0].email;
      newGuest.save = false;
    } else {
      newGuest.community_member = false;
      newGuest.phone = countryCode + addPhone;
      newGuest.name = addName;
      newGuest.email = addEmail;
      newGuest.save = saveCommunityMember;
    }
    if (
      addGuests.find(({ name }) => name === newGuest.name) ||
      addGuests.find(({ phone }) => phone === newGuest.phone)
    ) {
      setDeniedModal(true);
    } else if (
      newGuest.email !== "" &&
      addGuests.find(({ email }) => email === newGuest.email)
    ) {
      setDeniedModal(true);
    } else {
      setAddGuests([...addGuests, newGuest]);
      setAddName("");
      setCountryCode("+52");
      setAddPhone("");
      setAddEmail("");
      setSearchCommunityMember(false);
      setCommunityMember("");
    }
  };

  const handleQuitGuest = (index: number) => {
    const newArray = [...addGuests];
    newArray.splice(index, 1);
    setAddGuests(newArray);
  };

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    if (networkName) {
      getCommunityMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkName]);

  const getCommunityMembers = async () => {
    if (networkName) {
      try {
        const result = await api.getCommunityMembersList(networkName);
        const cmName = Array.from(
          new Set(result.community_members.map(({ name }) => name))
        );
        setCommunityMembers(result);
        setCommunityMembersList(cmName);
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.generic_error);
      }
    }
  };

  //END POINT CREATE EVENT--------------------------------------------------------------------------------->>
  //END POINT CREAR EVENTO--------------------------------------------------------------------------------->>
  const generateEvent = async () => {
    const payload: CreateEventParams = {
      event: {
        name: eventName,
        description: eventDescription,
        start_date: isoDate,
        tolerance: Number(hoursLater),
        event_type_id: 2,
      },
      guests: addGuests,
      access_servers: addAccessPoints,
    };
    try {
      setIsLoading(true);
      await api.createEvent(payload);
      setSuccessModal(true);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("name", eventName);
  console.log("description", eventDescription);
  console.log("start_date", isoDate);
  console.log("tolerance", Number(hoursLater));
  console.log("guests", addGuests);
  console.log("access_servers", addAccessPoints);

  return (
    <Container>
      <div className="container-1 w-100">
        <Grid container>
          <Grid item xs={12} className="flex justify-content-space-between">
            <p className="my-6 font font-24 font-w-400 secondary-color">
              {labels.new_event}
            </p>
            <IconButton onClick={() => navigate("/events")}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      {/* <div className="flex justify-content-center">
          <Paper className="w-100 mt-5 mb-2" sx={{ padding: "14px" }}>
            <div className="flex align-items-center justify-content-space-between">
              <p className="title-color font-14 mt-4 mb-0 ml-4">
                {labels.select_event_type}
              </p>
            </div>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} className="my-3">
                <FormControl fullWidth className="mt-1">
                  <Select
                    value={eventType}
                    onChange={(e) => {
                      setEventType(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                  >
                    <MenuItem value={"passtrack"}>
                      {labels.passtrack_event}
                    </MenuItem>
                    <MenuItem value={"eventbrite"}>{labels.eventbrite}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </div> */}
      {/* {eventType === "passtrack" ? ( */}
      <div className="flex justify-content-center">
        <Paper className="w-100 mt-5 mb-2" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            {/* <p className="subtitle-color font-18 mt-4 mb-6">
                {labels.event_details}
              </p> */}
          </div>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} className="my-3">
              <TextField
                value={eventName}
                label={labels.event_name}
                fullWidth
                onChange={(e) => {
                  setEventName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} className="my-3">
              <TextField
                value={eventDescription}
                label={labels.event_description}
                fullWidth
                multiline
                rows={2}
                onChange={(e) => {
                  setEventDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="mb-3 mt-8">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label={labels.date}
                  value={date}
                  // onChange={(newDate: string | null) => {
                  //   setDate(moment(newDate).format("DD/MMM/YYYY"));
                  // }}
                  onChange={(newDate: Date | null) => {
                    setDate(newDate);
                  }}
                  inputFormat="DD/MMM/YYYY"
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container>
            <div className="w-100 flex justify-content-space-between">
              <div className="w-70 mr-4">
                <p className="m-0 my-2 font-14 subtitle-color">
                  {labels.allow_entry_from}
                </p>
                <div className="flex align-items-center">
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Select
                        value={hour}
                        onChange={(e) => {
                          setHour(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"00"}>00</MenuItem>
                        <MenuItem value={"01"}>01</MenuItem>
                        <MenuItem value={"02"}>02</MenuItem>
                        <MenuItem value={"03"}>03</MenuItem>
                        <MenuItem value={"04"}>04</MenuItem>
                        <MenuItem value={"05"}>05</MenuItem>
                        <MenuItem value={"06"}>06</MenuItem>
                        <MenuItem value={"07"}>07</MenuItem>
                        <MenuItem value={"08"}>08</MenuItem>
                        <MenuItem value={"09"}>09</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                        <MenuItem value={"17"}>17</MenuItem>
                        <MenuItem value={"18"}>18</MenuItem>
                        <MenuItem value={"19"}>19</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"21"}>21</MenuItem>
                        <MenuItem value={"22"}>22</MenuItem>
                        <MenuItem value={"23"}>23</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <span className="mx-3"> : </span>
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Select
                        value={minutes}
                        onChange={(e) => {
                          setMinutes(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"00"}>00</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"30"}>30</MenuItem>
                        <MenuItem value={"45"}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                {/* <p className="m-0 my-2 font-12 title-color">
                    {labels.select_entry_time}
                  </p> */}
              </div>
              <div className="w-30 ml-4">
                <p className="m-0 my-2 font-14 subtitle-color">
                  {labels.until}
                </p>
                <div>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        value={hoursLater}
                        onChange={(e) => {
                          setHoursLater(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                        <MenuItem value={"17"}>17</MenuItem>
                        <MenuItem value={"18"}>18</MenuItem>
                        <MenuItem value={"19"}>19</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"21"}>21</MenuItem>
                        <MenuItem value={"22"}>22</MenuItem>
                        <MenuItem value={"23"}>23</MenuItem>
                        <MenuItem value={"24"}>24</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                <p className="m-0 my-2 font-12 title-color">
                  {labels.hours_later}
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </div>
      <div className="flex justify-content-center">
        <Paper className="w-100 my-2" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 my-5">{labels.guests}</p>
            {searchCommunityMember ? (
              <div
                onClick={() => setSearchCommunityMember(false)}
                className="m-0 p-0 flex justify-content-center align-items-center button-style"
              >
                <CreateIcon
                  color="primary"
                  sx={{ fontSize: 35 }}
                  className="p-0 m-0"
                />
                <div className="flex flex-dir-col pr-3">
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.new}
                  </p>
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.register_button}
                  </p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setSearchCommunityMember(true)}
                className="m-0 p-0 flex justify-content-center align-items-center button-style"
              >
                <SearchRoundedIcon
                  color="primary"
                  sx={{ fontSize: 35 }}
                  className="p-0 m-0"
                />
                <div className="flex flex-dir-col pr-3">
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.search_in}
                  </p>
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.favorites}
                  </p>
                </div>
              </div>
            )}
          </div>
          {searchCommunityMember ? (
            <div className="m-0 p-0 mb-6">
              <p className="subtitle-color m-0 p-0 mt-2 mb-5 ml-3">
                {labels.search_in_favourites}
              </p>
              <Autocomplete
                disablePortal
                fullWidth
                id="x"
                options={communityMembersList}
                onChange={(event: any, newValue: string | null) => {
                  setCommunityMember(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={communityMember}
                    onChange={(e) => {
                      setCommunityMember(e.target.value);
                    }}
                    label={labels.add_favourite}
                  />
                )}
              />
            </div>
          ) : (
            <div>
              <p className="subtitle-color m-0 p-0 mt-2 mb-4 ml-3">
                {labels.new_guest}
              </p>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} className="my-3">
                  <TextField
                    required
                    value={addName}
                    label={labels.full_name}
                    fullWidth
                    onChange={(e) => {
                      setAddName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth className="mt-3">
                    <Select
                      value={countryCode}
                      onChange={(e) => {
                        setCountryCode(e.target.value);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                    >
                      <MenuItem value={"+52"}>MX +52</MenuItem>
                      <MenuItem value={"+1"}>US +1</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} className="my-3">
                  <TextField
                    required
                    value={addPhone}
                    label={labels.phone_number}
                    fullWidth
                    onChange={(e) => {
                      setAddPhone(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="my-3">
                  <TextField
                    value={addEmail}
                    label={labels.email}
                    fullWidth
                    onChange={(e) => {
                      setAddEmail(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <>
                {/* <p className="m-0 ml-4 mt-6 mb-0 font-14 medium subtitle-color">
                    {labels.save_to_my_favorites}
                  </p>
                  <div className="flex">
                    <Checkbox
                      checked={checked}
                      value={saveCommunityMember}
                      onChange={handleSaveCommunityMemberChange}
                    />
                    <p className="title-color font-12">
                      {labels.save_visitor_text}
                    </p>
                  </div> */}
                <div className="flex align-items-center mt-3">
                  <Checkbox
                    checked={checked}
                    value={saveCommunityMember}
                    onChange={handleSaveCommunityMemberChange}
                  />
                  <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                    {labels.save_to_my_favorites}
                  </p>
                </div>
              </>
            </div>
          )}
          <div className="w-100 m-0 p-0 my-4">
            <Button
              disabled={
                searchCommunityMember ? disabledCommunityMember : disabled
              }
              fullWidth
              variant="contained"
              size="large"
              disableElevation
              color="secondary"
              className="py-5 add-guest-button-style"
              startIcon={<AddRoundedIcon />}
              onClick={() => {
                handleAddGuest();
              }}
            >
              {labels.add_guest}
            </Button>
            <p className="subtitle-color text-center">
              {labels.guest_number}:{" "}
              <span className="text-secondary-color">{addGuests.length}</span>
            </p>
          </div>
          {addGuests.length > 0 && (
            <div>
              {addGuests.map(({ name, phone }, index) => (
                <div
                  key={name}
                  className="w-100 background-guest-div flex justify-content-space-between align-items-center my-3"
                >
                  <p className="subtitle-color ml-5">
                    {name} ({phone})
                  </p>
                  <IconButton
                    className="mr-3"
                    onClick={() => {
                      handleQuitGuest(index);
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </Paper>
      </div>
      <div className="flex justify-content-center">
        <Paper className="w-100 my-2" sx={{ padding: "14px" }}>
          <p className="subtitle-color font-18 mt-6 mb-6">
            {labels.event_access_points} *
          </p>
          <>
            {/* <p className="title-color font-12 m-0 p-0 mb-3">
                {labels.visitor_access_points}
              </p> */}
            {user?.access_servers.map(({ name, venue_name, id }, index) => (
              <div key={id} className="flex align-items-center mb-3">
                <Checkbox
                  onChange={() => {
                    let elementFind = addAccessPoints.find(
                      (element) => element === id
                    );
                    let finalIndex = addAccessPoints.indexOf(id);
                    const addAccessPointsClone = [...addAccessPoints];
                    if (!elementFind) {
                      addAccessPointsClone.push(id);
                    } else {
                      addAccessPointsClone.splice(finalIndex, 1);
                    }
                    setAddAccessPoints(addAccessPointsClone);
                  }}
                  color="secondary"
                />
                <div className="flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0">{name}</p>
                  <p className="title-color font-12 m-0 p-0">{venue_name}</p>
                </div>
              </div>
            ))}
          </>
        </Paper>
      </div>
      <Button
        disabled={disabledCreateEvent}
        fullWidth
        variant="contained"
        size="large"
        disableElevation
        color="primary"
        className="py-5 mt-6 mb-10 create-event-button"
        onClick={() => {
          generateEvent();
        }}
      >
        {labels.create_event}
      </Button>
      <div className="lower-div root-background" />

      {/* GUESTS MODAL--------------------------------------------------------------->> */}
      {/* MODAL INVITADOS------------------------------------------------------------>> */}
      <Modal disableEscapeKeyDown open={deniedModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5 text-center">
              {labels.have_guest_one}
            </p>
            <p className="title-color font-16 my-7 text-center">
              {labels.have_guest_two}
            </p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setDeniedModal(false);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* SUCCESS EVENT CREATED MODAL------------------------------------------------>> */}
      {/* MODAL EVENTO CREADO CON EXITO---------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={successModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5 text-center">
              {labels.created_event}
            </p>
            <p className="title-color font-16 my-7 text-center">
              {labels.created_event_text}
            </p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setSuccessModal(false);
                setTimeout(() => navigate("/events"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default EditEventGuests;
