export const NETWORKS_UUIDS = [
  {
    id: 21,
    uuid: "a9563b7f-7406-4829-89d2-f1277f741792",
  },
  {
    id: 23,
    uuid: "4ef2327c-1f94-408b-a935-b492bf1ee0b3",
  },
  {
    id: 25,
    uuid: "9b0f4900-7189-4d0c-82ff-1bf3c9c5a0de",
  },
  {
    id: 26,
    uuid: "5d17aaef-098f-473e-bb13-55584c55ea93",
  },
  {
    id: 27,
    uuid: "1e3f7e04-8ef9-4bf3-87ac-58db9be107ba",
  },
  {
    id: 28,
    uuid: "227d60b6-ac88-4d97-88fc-f2c10e72ab2d",
  },
  {
    id: 29,
    uuid: "227d60b6-ac88-4d97-88fc-f2c10e72ab2e",
  },
  {
    id: 30,
    uuid: "227d60b6-ac88-4d97-88fc-f2c10e72ab3a",
  },
  {
    id: 31,
    uuid: "6c181be1-981e-471d-83a4-4e98c2ecb00b",
  },
  {
    id: 33,
    uuid: "92df0775-1bce-4a00-be95-b4af3dd789c2",
  },
  {
    id: 34,
    uuid: "b5cd060c-3558-4e65-9947-2b7bf6f7c75b",
  },
  {
    id: 35,
    uuid: "cf595ecc-9217-47bc-83b5-9bb8b9b722b6",
  },
  {
    id: 36,
    uuid: "63c9c671-12c6-4149-a905-8a76aa8067f0",
  },
  {
    id: 37,
    uuid: "92988465-ee64-40b1-8035-eb759bcf4960",
  },
  {
    id: 38,
    uuid: "77000ccb-2dd7-4cb1-8924-318798a808c9",
  },
  {
    id: 39,
    uuid: "2fc5d98c-8efe-4913-9582-bc56bdcf4cca",
  },
  {
    id: 40,
    uuid: "c717d8f0-d11a-4ec5-84e3-91a69cd5f659",
  },
  {
    id: 41,
    uuid: "149a0a23-9c57-4f8c-a7a0-65769a1db94c",
  },
  {
    id: 42,
    uuid: "42838036-c618-457b-8cce-a1e3af6bf233",
  },
  {
    id: 44,
    uuid: "54403c03-0296-41ae-93aa-9e09135c5f79",
  },
  {
    id: 57,
    uuid: "3439449b-976b-4947-8877-cec36469489b",
  },
  {
    id: 58,
    uuid: "94f56fd2-3b24-4a4e-b132-5d5c4784104c",
  },
  {
    id: 60,
    uuid: "002ffa03-a685-43cb-94a1-c28aaf02ead4",
  },
  {
    id: 61,
    uuid: "45884fef-6746-455f-80c6-420b3cb008af",
  },
  {
    id: 62,
    uuid: "bc5697d8-71dd-44a0-85c2-5e8df1547053",
  },
  {
    id: 63,
    uuid: "3b8d741e-2fcd-41df-ba1a-6a13f1cbcdcd",
  },
  {
    id: 64,
    uuid: "023bab49-693e-4d02-bb5d-5c3a65220c0f",
  },
  {
    id: 65,
    uuid: "082238e3-c6b6-4539-96a4-5fca64f791a5",
  },
  {
    id: 66,
    uuid: "0a24e537-542a-48fd-b71d-6ffcc4cc2ae8",
  },
  {
    id: 67,
    uuid: "4f398c33-40ed-40b1-809e-9ba51f061cd6",
  },
  {
    id: 68,
    uuid: "4c1c8934-57c5-4d96-b64b-554a14006f26",
  },
  {
    id: 69,
    uuid: "c7ab1b0b-7331-467d-96e7-efc752bd84ed",
  },
  {
    id: 70,
    uuid: "7eb83c86-ee73-43db-9751-4263a4d3e2f2",
  },
  {
    id: 71,
    uuid: "0707b461-a0ed-4b85-9d10-afc8144eb5b0",
  },
  {
    id: 72,
    uuid: "2ad558ec-8c0a-4f60-afb6-7e56d6882227",
  },
  {
    id: 73,
    uuid: "08671257-febd-4c65-a7c0-980cfda3a394",
  },
  {
    id: 74,
    uuid: "56334e18-7ebb-435b-acf6-f2d5b4bef175",
  },
  {
    id: 75,
    uuid: "70ec8d55-dc81-4207-b1e9-788c9edf8e77",
  },
  {
    id: 76,
    uuid: "e1364476-a482-4018-bad6-919f34d1782c",
  },
  {
    id: 77,
    uuid: "c1b39575-7293-40be-b343-3038d4bc1d14",
  },
  {
    id: 79,
    uuid: "5e3b893e-8c1f-4f88-af03-8d7b79693d41",
  },
  {
    id: 81,
    uuid: "01bf431f-6e55-4953-a822-61e59ac9a0a6",
  },
];
