import React from "react";
import { IconButton, Modal, Box, Grid, Divider } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";
import Logo from "../../Logo";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const NotificationHelpModal = () => {
  const labels = useAppSelector(selectLabels);

  const [qrHelpModal, setQrHelpModal] = React.useState<boolean>(false);

  const handleOpen = () => {
    setQrHelpModal(true);
  };

  const handleClose = () => {
    setQrHelpModal(false);
  };

  return (
    <>
      <IconButton size="small" className="m-0 p-0" onClick={handleOpen}>
        <HelpIcon fontSize="small" className="m-0 p-0 title-color" />
      </IconButton>
      <Modal disableEscapeKeyDown open={qrHelpModal}>
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="flex justify-content-space-between align-items-center m-0 p-0"
            >
              <p className="subtitle-color font-18 m-0 p-0 text-justify">
                {labels.notifications}
              </p>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className="w-100" />
          <p className="subtitle-color mb-0 pb-0">{labels.notification_help_text}</p>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationHelpModal;
