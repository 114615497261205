import React from "react";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

interface PaginationProps {
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
  isDesktop?: boolean;
  isDepartments?: boolean;
}

const Pagination = ({
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  totalPages,
  isDesktop,
  isDepartments,
}: PaginationProps) => {
  const labels = useAppSelector(selectLabels);

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  return (
    <div
      className={
        isDesktop
          ? "pagination-container-nofixed"
          : "pagination-container upper-shadow"
      }
      style={
        isDesktop ? { boxShadow: "0px 2px 6px 1px rgba(0, 0, 0, 0.25)" } : {}
      }
    >
      {isDepartments && (
        <div />
      )}
      {!isDepartments && (
        <div className="flex align-items-center ml-8 mr-4">
          <p className="pr-2 primary-color">{labels.rows}</p>
          <FormControl style={{ backgroundColor: "#f5f5f5", borderRadius: 5 }}>
            <Select
              value={rowsPerPage}
              style={{ color: "#10418a" }}
              size="small"
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(1);
              }}
              inputProps={{ "aria-label": "Whithout label" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      <div className="flex">
        <div className="flex mx-2">
          <p className="primary-color">
            {labels.page} <span className="primary-color">{page}</span>{" "}
            {labels.of} <span className="primary-color">{totalPages}</span>
          </p>
        </div>
        <div className="mx-2">
          <IconButton
            color="primary"
            disabled={page <= 1}
            onClick={handleChangeDownPage}
          >
            <NavigateBeforeRoundedIcon />
          </IconButton>
          <IconButton
            color="primary"
            disabled={page >= totalPages}
            onClick={handleChangeUpPage}
          >
            <NavigateNextRoundedIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
