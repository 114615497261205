import React from "react";
import { Button, Container, Grid, Modal, Paper, TextField } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { selectLabels } from "../../slices/language";
import "./restore-password.scss";
import api, { ResetPasswordParams } from "../../api";
import { showError } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    maxHeight: "90%",
    overflow: "scroll",
    borderRadius: "4px",
    bgcolor: "white",
    boxShadow: 24,
    p: 2,
  };

const RestorePassword = () => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addPassword, setAddPassword] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState<boolean>(false);

  const disabled = !addPassword;

  const resetUserPassword = async () => {
      const payload: ResetPasswordParams = {
        email: addPassword,
      }
      try {
          setIsLoading(true);
          await api.resetPassword(payload);
      } catch (error) {
          console.error(error);
          showError(dispatch, labels.generic_error);
      } finally {
          setIsLoading(false);
          setShowSuccessModal(true);
      }
  }

  return (
    <Container maxWidth="xs" className="mt-7">
      <Paper
        variant="outlined"
        className="p-10 vh-85 flex justify-content-center align-items-center flex-dir-col vertical-spacing-7"
      >
        <Logo type="passtrackLogo" alt="passtrack" className="logo" />
        <p className="text-center subtitle-color medium font-22  mt-0">
          {labels.restore_password}
        </p>
        <p className="text-center secondary-color pb-5">
          {labels.restore_password_message}
        </p>
        <TextField
        value={addPassword}
          label={labels.your_email}
          variant="outlined"
          fullWidth
          placeholder="john_smith@gmail.com"
          onChange={(e) => {
            setAddPassword(e.target.value);
          }}
        ></TextField>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          className="py-6"
          onClick={resetUserPassword}
          disabled={disabled}
        >
          {labels.send_instructions}
        </Button>
        <p>
          <Link to="/login">{labels.login}</Link>
        </p>
      </Paper>

      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="title-color font-16 my-7 text-center">
              {labels.reset_password_text}
            </p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setShowSuccessModal(false);
                setTimeout(() => navigate("/login"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default RestorePassword;
