import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { IoQrCodeOutline } from "react-icons/io5";
import { Paper } from "@mui/material";
import { MdAssignmentAdd } from "react-icons/md";

interface InvitationListContainerProps {
  visitorName: string;
  hostName: string;
  department: string;
  date: string;
  startHour: string;
  endHour: string;
  invitationType: number;
  onPress: () => void;
}

const InvitationListContainer = ({
  visitorName,
  hostName,
  department,
  date,
  startHour,
  endHour,
  invitationType,
  onPress,
}: InvitationListContainerProps) => {
  const labels = useAppSelector(selectLabels);
  return (
    <Paper
      component="form"
      sx={{
        width: "100%",
        boxSizing: "border-box",
        padding: 1.8,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            className="primary-color medium"
            style={{ margin: 0, padding: 0, marginRight: 8 }}
          >
            {date}
          </p>
          {invitationType !== 2 ? (
            <p className="subtitle-color" style={{ margin: 0, padding: 0 }}>
              {`${startHour} - ${endHour}`}
            </p>
          ) : (
            <p className="subtitle-color" style={{ margin: 0, padding: 0 }}>
              {startHour}
            </p>
          )}
        </div>
        <div>
          {invitationType === 1 && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.visitor}
            </p>
          )}
          {invitationType === 2 && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.temporary}
            </p>
          )}
          {invitationType === 3 && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.event}
            </p>
          )}
          {invitationType === 4 && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.event}
            </p>
          )}
          {invitationType === 5 && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.provider}
            </p>
          )}
          {invitationType === 7 && (
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.user_key}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 12,
        }}
      >
        <div className="flex align-items-center">
          <div>
            {invitationType !== 5 && (
              <div className="flex justify-content-center align-items-center mr-5">
                <IoQrCodeOutline
                  fontSize="30px"
                  className="title-color m-0 p-0"
                />
              </div>
            )}
          </div>
          <div>
            <p
              className="primary-color font-18 medium"
              style={{ margin: 0, padding: 0, marginBottom: 2 }}
            >
              {visitorName}
            </p>
            <p
              className="subtitle-color"
              style={{ margin: 0, padding: 0, marginTop: 2 }}
            >{`${hostName} - ${department}`}</p>
          </div>
        </div>
        {invitationType === 5 && (
          <div
            className="flex align-items-center justify-content-center guard-regiter-inv-button"
            style={{
              minWidth: 36,
              height: 36,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={onPress}
          >
            <MdAssignmentAdd color="white" fontSize={18} />
          </div>
        )}
      </div>
    </Paper>
  );
};

export default InvitationListContainer;
