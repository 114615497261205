import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import api, { getGuestsInsideAccessServerParams } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetUser, selectUser, setUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import "./desktop-guard.scss";
import InvitationsDesktopGuard from "../../components/InvitationsDesktopGuard";
import RegistersDesktopGuard from "../../components/RegistersDesktopGuard";
import LogbookDesktopGuard from "../../components/LogbookDesktopGuard";
import UserDetailsDesktopModal from "../../components/UserDetailsDesktopModal";
import { resetToken, selectToken } from "../../slices/token";
import Logo from "../../components/Logo";
import { FaCommentDots } from "react-icons/fa";

interface DesktopGuardProps {
  backToPrevVersion: () => void;
}

const DesktopGuard = ({ backToPrevVersion }: DesktopGuardProps) => {
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();

  const [accessPoint, setAccessPoint] = React.useState<string>(
    user?.last_access_selected?.id
      ? `${user?.last_access_selected.id}`
      : `${user?.access_servers[0].id}`
  );
  const [guestsByAccessServer, setGuestsByAccessServer] =
    React.useState<getGuestsInsideAccessServerParams | null>(null);
  const [newRegister, setNewRegister] = React.useState<boolean>(false);
  const [invitationKey, setInvitationKey] = React.useState<string>("");
  const [invLastType, setInvitationLastType] = React.useState<string | null>(
    null
  );
  const [invHost, setInvHost] = React.useState<string | null>(null);
  const [invDepartment, setInvDepartment] = React.useState<string | null>(null);
  const [invGuest, setInvGuest] = React.useState<string>("");

  const chatLink = "https://tawk.to/chat/619b47ca6bb0760a4943b0e0/1fl390rh2";

  const handleAccessPointChange = async (event: SelectChangeEvent) => {
    try {
      setAccessPoint(event.target.value);
      const newUserData = await api.changeAccessServerRequest({
        access_server: Number(event.target.value),
      });
      dispatch(setUser(newUserData));
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  React.useEffect(() => {
    handleAccessPointChangeStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleAccessPointChangeStart = async () => {
    if (user) {
      if (user.last_access_selected?.id) {
        setAccessPoint(`${user.last_access_selected?.id}`);
      } else {
        setAccessPoint(`${user.access_servers[0]?.id}`);
      }
    }
  };

  // END POINT GUESTS INSIDE ACCESS SERVER----------------------------------------------------------------->>
  // END POINT INVITADOS DENTRO DEL ACCESS SERVER---------------------------------------------------------->>
  React.useEffect(() => {
    getAccessServerGuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessPoint, token]);

  const getAccessServerGuests = async () => {
    try {
      if (accessPoint !== null) {
        const result = await api.getGuestsInsideAccessServer(
          Number(accessPoint)
        );
        setGuestsByAccessServer(result);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div className="dg-header">
        <div
          style={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Logo
            type="passtrackHeaderWhite"
            alt="Passtrack"
            className="header-logo-size-2"
          />
        </div>
        <div
          style={{ width: "40%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: 300,
              maxWidth: 300,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth size="small">
              <Select
                labelId="access-point-label"
                className="text-primary-color header-selector"
                value={accessPoint}
                onChange={handleAccessPointChange}
              >
                {user?.access_servers.map(({ name, id }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <p
            className="dg-blue-color"
            style={{ fontSize: 16, marginLeft: 30, color: "white" }}
          >
            {labels.visitors_inside} {guestsByAccessServer?.guests}
          </p>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            disableElevation
            size="medium"
            style={{
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: 1,
              marginRight: 20,
            }}
            onClick={backToPrevVersion}
            disabled={false}
          >
            {labels.back_to_prev_version}
          </Button>
          {/* <div style={{ display: "flex", paddingRight: 18 }}>
            <a href={chatLink} target="_blank" rel="noopener noreferrer">
              <FaCommentDots
                fontSize={24}
                style={{
                  position: "relative",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </a>
          </div> */}
          <UserDetailsDesktopModal extraFunc={backToPrevVersion} />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          className="dg-main-containers"
          style={{
            borderRightColor: "white",
            borderRightStyle: "solid",
            borderRightWidth: 4,
          }}
        >
          <InvitationsDesktopGuard
            setInvKey={setInvitationKey}
            setLastTransactionType={setInvitationLastType}
            setInvDepartment={setInvDepartment}
            setInvHost={setInvHost}
            setInvGuest={setInvGuest}
          />
        </div>
        <div
          className="dg-main-containers"
          style={{
            borderLeftColor: "white",
            borderLeftStyle: "solid",
            borderLeftWidth: 2,
            borderRightColor: "white",
            borderRightStyle: "solid",
            borderRightWidth: 2,
          }}
        >
          <RegistersDesktopGuard
            newRegister={newRegister}
            setNewRegister={setNewRegister}
            invKey={invitationKey}
            setInvKey={setInvitationKey}
            invLastTransactionType={invLastType}
            setInvLastTransactionType={setInvitationLastType}
            invHost={invHost}
            setInvHost={setInvHost}
            invGuest={invGuest}
            setInvGuest={setInvGuest}
            invDepartment={invDepartment}
            setInvDepartment={setInvDepartment}
          />
        </div>
        <div
          className="dg-main-containers"
          style={{
            borderLeftColor: "white",
            borderLeftStyle: "solid",
            borderLeftWidth: 4,
          }}
        >
          <LogbookDesktopGuard
            newRegister={newRegister}
            setNewRegister={setNewRegister}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopGuard;
