import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { resetUser, selectUser } from "../../slices/user";
import api, { CreateUserParams } from "../../api";
import { showError } from "../../utils/helper";
import { Box } from "@mui/system";
import { resetToken } from "../../slices/token";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const NewUser = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const userRole = user?.roles.map(({ name }) => name) || [];

  let initialRole = "";
  const roleOptions: { value: string; label: string }[] = [];

  if (userRole?.length > 0) {
    if (userRole[0] === "host") {
      initialRole = "secondary_user";
      roleOptions.push({
        value: "secondary_user",
        label: labels.secondary_host,
      });
    } else if (userRole[0] === "network_admin") {
      initialRole = "network_admin";
      roleOptions.concat([
        { value: "host", label: labels.host },
        { value: "network_admin", label: labels.network_admin },
        { value: "security_guard", label: labels.security_guard },
      ]);
    }
  }

  const [addName, setAddName] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [addRole, setAddRole] = React.useState<string>(initialRole);
  const [isLoading, setIsLoading] = React.useState(false);
  const [addNetworkGroup, setAddNetworkGroup] = React.useState<string>("");
  const [addAccessServers, setAddAccessServers] = React.useState<string>("");
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);

  console.log("userRole", userRole);

  const isDisabled = !addName || !addPhone || !addEmail;
  const generateNewUser = async () => {
    const payload: CreateUserParams = {
      name: addName,
      email: addEmail,
      phone: addPhone,
      country_code: countryCode,
      role: userRole[0] === "host" ? "secondary_user" : addRole,
      network_group: Number(user?.network_group.id),
      access_servers: [],
    };
    try {
      setIsLoading(true);
      await api.createUser(payload);
      setShowSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        if (
          error.response.data.error.message ===
          "This email belongs to another account"
        ) {
          showError(dispatch, labels.this_email_belongs_to_another_account);
        } else {
          showError(dispatch, labels.wrong_parameters);
        }
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {/* <div className="container-1 w-100">
        <Grid container className="flex align-items-center">
          <Grid item xs={8}>
            <p className="my-6 font font-24 font-w-400 secondary-color">
              {labels.new_user}
            </p>
          </Grid>
          <Grid item xs={4} className="flex justify-content-flex-end">
            <IconButton
              aria-label="photo"
              size="large"
              onClick={() => navigate("/my-users")}
            >
              <CloseRoundedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </div> */}
      <div className="w-100">
        <Grid container className="flex align-items-center pt-5">
          <Grid
            item
            xs={12}
            md={6}
            className="flex align-items-center justify-content-space-between"
          >
            <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
              {labels.new_user}
            </p>
            <IconButton
              onClick={() =>
                userRole[0] === "network_admin"
                  ? navigate("/") //Identificar de donde viene para regresar la ruta
                  : navigate("/my-users")
              }
            >
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <div className="flex justify-content-center">
        <Paper className="w-100 mt-5 mb-2" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.new_user_data}
            </p>
          </div>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} className="my-3">
              <TextField
                required
                value={addName}
                label={labels.full_name}
                fullWidth
                onChange={(e) => {
                  setAddName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth className="mt-3">
                <Select
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Whithout label" }}
                >
                  <MenuItem value={"+52"}>MX +52</MenuItem>
                  <MenuItem value={"+1"}>US +1</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7} className="my-3">
              <TextField
                required
                value={addPhone}
                label={labels.phone_number}
                fullWidth
                onChange={(e) => {
                  setAddPhone(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} className="my-3">
              <TextField
                value={addEmail}
                label={labels.email}
                fullWidth
                onChange={(e) => {
                  setAddEmail(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className="flex justify-content-center">
        <Paper className="w-100 my-2" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.user_details}
            </p>
          </div>
          {/* {userRole[0] === "network_admin" ? (
            <Grid container columnSpacing={1}>
              <Grid item xs={12} className="mb-3">
                <p className="w-100 m-0 p-0 title-color mb-3 ml-3 font-14">
                  Selecciona el rol que tendrá el nuevo usuario
                </p>
                <FormControl fullWidth>
                  <Select
                    value={addRole}
                    // onChange={(e) => {
                    //   setAddRole(e.target.value);
                    // }}
                    inputProps={{ "aria-label": "Whithout label" }}
                  >
                    {roleOptions.map(({ value, label }) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : ( */}
          <div>
            <p className="m-0 p-0 title-color font-12">
              {labels.user_role_new}:{" "}
              <span className="subtitle-color font-14">
                {labels.secondary_host}
              </span>
            </p>
            <p className="m-0 p-0 title-color font-12 my-3">
              {labels.department}:{" "}
              <span className="subtitle-color font-14">
                {user?.network_group?.name}
              </span>
            </p>
            <p className="m-0 p-0 title-color font-12 mt-5 mb-3">
              {labels.access_points}:
            </p>
            {user?.access_servers.map(({ name, id, venue_name }) => (
              <div key={id}>
                <p className="m-0 p-0 subtitle-color font-14 ml-5 mt-3">
                  - {name}
                </p>
                <p className="m-0 p-0 title-color font-12 ml-5">{venue_name}</p>
              </div>
            ))}
          </div>
          {/* )} */}
        </Paper>
      </div>
      <Button
        fullWidth
        variant="contained"
        disableElevation
        disabled={isDisabled}
        size="large"
        className="mt-5 mb-10 py-5"
        onClick={() => {
          generateNewUser();
        }}
      >
        {labels.save_user}
      </Button>
      <div className="lower-div root-background" />

      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {labels.user_created_successfully}
            </p>
            <p className="title-color font-16 my-7 text-center">
              {labels.new_user_text}
            </p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setShowSuccessModal(false);
                setTimeout(() => navigate("/my-users"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default NewUser;
