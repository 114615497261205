import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import api, { Users, UsersByDepartment } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import { showError } from "../../utils/helper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import { FaPen, FaTrash } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  outline: 0,
  border: 0,
};

const UserDetailRefactor = () => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<Users | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const { uId } = useParams();

  //END POINT USERS BY DEPARTMENT------------------------------------------------------------------>>
  //END POINT USUARIOS POR DEPARTAMENTO------------------------------------------------------------>>
  React.useEffect(() => {
    requestUsersByDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const requestUsersByDepartment = async () => {
    try {
      setIsLoading(true);
      if (token) {
        const usersNetwork: UsersByDepartment | null =
          await api.usersByNetworkRequest(1, 100, "");
        const filteredUser = usersNetwork.users.filter(
          ({ id }) => id === Number(uId)
        );
        setUserId(filteredUser[0]);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 404) {
        showError(dispatch, labels.user_not_found);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //END POINT DELETE USER-------------------------------------------------------------------------->>
  //END POINT BORRAR USUARIO----------------------------------------------------------------------->>
  const deleteNetworkUser = async () => {
    if (uId) {
      try {
        setIsLoading(true);
        await api.deleteUser(Number(uId));
        navigate("/my-users");
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.stauts === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className="w-100 flex flex-dir-col align-items-center"
      style={{ paddingBottom: 70 }}
    >
      <div
        className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary"
        style={{ height: 60 }}
      >
        <div style={{ width: "20%" }}></div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="font-14 uppercase text-primary-color medium">
            {labels.user_details}
          </p>
        </div>
        <div
          className="flex align-items-center justify-content-flex-end"
          style={{ width: "20%" }}
        >
          <CloseRoundedIcon
            className="primary-color"
            style={{ marginRight: 20 }}
            onClick={() => navigate("/my-users")}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div
          className="w-90 flex flex-dir-col justify-content-center align-items-center shadow-one"
          style={{
            marginRight: 10,
            marginLeft: 10,
            marginTop: 10,
            marginBottom: 10,
            padding: 8,
            backgroundColor: "white",
            borderRadius: 5,
            maxWidth: 500,
          }}
        >
          <div className="w-100 flex flex-dir-row background-gray-color align-items-center justify-content-space-between py-5">
            <div className="w-85 flex flex-dir-col align-items-flex-start">
              <div className="ml-6">
                {userId?.roles[0]?.name === "host" && (
                  <p className="p-0 m-0 medium font-14">{labels.host}</p>
                )}
                {userId?.roles[0]?.name === "secondary_user" && (
                  <p className="p-0 m-0 medium font-14">
                    {labels.secondary_host}
                  </p>
                )}
              </div>
              <p className="medium font-18 mb-0 mt-2 ml-6 mr-6">
                {userId?.name}
              </p>
            </div>
            <div className="w-15 flex flex-dir-row justify-content-flex-end">
              {userRole === "host" && user?.name === userId?.name && (
                <FaPen
                  fontSize="20px"
                  className="primary-color m-0 p-0 mr-8"
                  onClick={() => navigate(`/edit-user/${uId}`)}
                />
              )}
              {userRole === "host" &&
                userId?.roles[0].name === "secondary_user" && (
                  <FaTrash
                    fontSize="20px"
                    className="primary-color m-0 p-0 mr-8"
                    onClick={() => setShowDeleteModal(true)}
                  />
                )}
            </div>
          </div>
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {labels.phone_number}:
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="subtitle-color font-14 m-0 p-0 medium">
                {userId?.phone}
              </p>
            </div>
          </div>
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {labels.email_user_detail}:
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="subtitle-color font-14 m-0 p-0 medium">
                {userId?.email}
              </p>
            </div>
          </div>
          <Divider className="w-100" />
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {labels.access_points}:
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              {userId?.access_servers.map(({ name, venue_name, id }, index) => (
                <div className="w-100 flex flex-dir-col" key={id}>
                  <p
                    className={
                      index === userId?.access_servers.length - 1
                        ? "subtitle-color font-14 m-0 mt-5 p-0 medium"
                        : "subtitle-color font-14 m-0 p-0 medium"
                    }
                  >
                    {name}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {venue_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <Divider className="w-100" />
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {labels.users.created_by}:
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="subtitle-color font-14 m-0 p-0 medium">
                {userId?.invited_by?.name}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* SELECT EXIT DOOR MODAL------------------------------------->>*/}
      {/* MODAL SELECCIONAR PUERTA DE SALIDA------------------------->>*/}
      <Modal disableEscapeKeyDown open={showDeleteModal}>
        <Box sx={style}>
          <Grid container className="vertical-spacing-4">
            <Grid
              item
              xs={12}
              className="w-100 flex justify-content-space-between align-items-center m-0 p-0 mb-2"
            >
              <div className="w-15" />
              <div className="w-70">
                <p
                  className="text-primary-color uppercase medium p-0 m-0"
                  style={{ textAlign: "center" }}
                >
                  {labels.delete_user_title}
                </p>
              </div>
              <div className="w-15 flex justify-content-flex-end">
                <IconButton
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                >
                  <CloseIcon className="primary-color" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <div
            className="w-100 background-primary-color"
            style={{ height: 4 }}
          />
          <Grid
            container
            className="w-100 align-items-center justify-content-center vertical-spacing-5"
          >
            <Grid item xs={12}>
              <p
                className="subtitle-color m-0 p-0 mt-7 mb-3"
                style={{ textAlign: "center" }}
              >
                {labels.delete_the_user} {userId?.name}
              </p>
            </Grid>
            <Button
              variant="contained"
              disableElevation
              onClick={deleteNetworkUser}
              className="mb-5 w-60"
            >
              {labels.delete}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default UserDetailRefactor;
