import { CircularProgress, Container } from "@mui/material";
import React from "react";
import MainPagesHeader from "../../components/MainPagesHeader/MainPagesHeader";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import moment from "moment";
import "moment/locale/es";
import AdminUsersList from "../../components/AdminUsersList/AdminUsersList";
import Pagination from "../../components/Pagination/Pagination";
import { selectToken } from "../../slices/token";
import api, { Users } from "../../api";

interface FileToExport {
  nombre: string;
  departamento: string;
  "correo electronico": string;
  telefono: string;
  rol: string;
  "creado por": string;
}

const UsersGuardsRefactor = () => {
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [usersList, setUsersList] = React.useState<Users[] | null>(null);
  const [filteredUsers, setFilteredUsers] = React.useState<Users[] | null>(
    null
  );
  const [filteredUsersBackup, setFilteredUsersBackup] = React.useState<
    Users[] | null
  >(null);
  const [pages, setPages] = React.useState<number | null>(null);

  const currentDate = new Date();
  const fileName = `${labels.authorized_guards} - ${moment(currentDate).format(
    "LL"
  )}`;

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentItems = filteredUsers
    ? filteredUsers.slice(startIndex, endIndex)
    : "";

  const debouncedSearchText = useDebounce(searchInput, 500);

  React.useEffect(() => {
    if (filteredUsers) {
      setPages(Math.ceil(filteredUsers?.length / rowsPerPage));
    }
  }, [filteredUsers, rowsPerPage]);

  React.useEffect(() => {
    handleInputChange();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const handleInputChange = () => {
    if (filteredUsers && filteredUsersBackup) {
      if (debouncedSearchText) {
        const filteredResults = filteredUsersBackup.filter(({ name }) =>
          name.toLowerCase().includes(debouncedSearchText.toLowerCase())
        );
        setFilteredUsers(filteredResults);
      } else {
        setFilteredUsers(filteredUsersBackup);
      }
    }
  };

  React.useEffect(() => {
    getFilteredUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  const getFilteredUsers = () => {
    if (usersList && usersList.length > 0) {
      const guardResponse = usersList.filter(
        ({ roles }) => roles[0]?.name === "security_guard"
      );
      setFilteredUsers(guardResponse);
      setFilteredUsersBackup(guardResponse);
      setFileArray(
        guardResponse.map(
          ({ id, network_group, name, email, phone, roles, invited_by }) => ({
            nombre: `${name}`,
            departamento: `${network_group}`,
            "correo electronico": `${email ? email : labels.no_email}`,
            telefono: `${phone ? phone : labels.no_phone}`,
            rol: labels.security_guard,
            "creado por": `${invited_by?.name}`,
          })
        )
      );
    }
  };

  React.useEffect(() => {
    getAuthorizedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getAuthorizedUsers = async () => {
    const pageSize = 100;
    let totalPages = 1;
    let allResults: Users[] = [];
    try {
      setIsLoading(true);
      const response = await api.usersByNetworkRequest(1, pageSize, "");
      allResults.push(...response.users);
      totalPages = response.total_pages;
      if (totalPages > 1) {
        for (let page = 2; page <= totalPages; page++) {
          const nextPageResponse = await api.usersByNetworkRequest(
            page,
            pageSize,
            ""
          );
          allResults.push(...nextPageResponse.users);
        }
      }
      setUsersList(allResults);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        <MainPagesHeader
          newIcon={false}
          // onPressNewIconAction={() => navigate(`/new-user`)}
          mainTitle="users_guards"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={false}
          showFilters={false}
          setShowFilters={() => {}}
          downloadable={true}
          fileArray={fileArray ? fileArray : []}
          fileName={fileName}
        />
        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {isLoading ? (
          <div
            className="flex justify-content-center align-items-center w-100"
            style={{
              position: "relative",
              top: 176,
              boxSizing: "border-box",
              paddingBottom: pages ? 118 : 72,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className="w-100 flex flex-dir-col justify-content-center"
            style={{
              position: "relative",
              top: 116,
              boxSizing: "border-box",
              paddingBottom: pages ? 118 : 72,
            }}
          >
            {currentItems && currentItems?.length > 0 ? (
              currentItems?.map(({ id, network_group, name }, index) => (
                <div
                  key={id}
                  className="w-100 flex justify-content-center mt-5"
                  style={{
                    marginBottom: currentItems.length === index + 1 ? 12 : 0,
                  }}
                >
                  <AdminUsersList
                    onPress={() => {}}
                    name={name}
                    department={network_group}
                  />
                </div>
              ))
            ) : (
              <div className="w-100 flex justify-content-center">
                <p className="font-16 subtitle-color">
                  {labels.no_results_found}
                </p>
              </div>
            )}
          </div>
        )}
      </Container>
      {pages ? (
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={pages}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UsersGuardsRefactor;
