import React from "react";
import { useAppDispatch, useAppSelector, useWindowSize } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  Container,
  Grid,
  Divider,
  Button,
  Paper,
  Modal,
  IconButton,
  CircularProgress,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router";
import QrReader from "react-qr-reader";
import api, {
  AccessKey,
  AccessKeysAddPhotosParams,
  RegisterVisitRequestParams,
  RegisterVisitResponseParams,
  ValidateKeyResponse,
} from "../../api";
import { useParams } from "react-router-dom";
import { resetUser, selectUser, setUser } from "../../slices/user";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CallIcon from "@mui/icons-material/Call";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { red, green } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";
import { showError } from "../../utils/helper";
import PhotoButtons from "../../components/PhotoButtons";
import CloseIcon from "@mui/icons-material/Close";
import LoadingScreen from "../../components/LoadingScreen";
import moment from "moment";
import PrintLabelModal from "../../components/Modals/PrintLabelModal";
import PhotoButtonsInvitations from "../../components/PhotoButtonsInvitations";
import { resetToken, selectToken } from "../../slices/token";
import CustomAttributes from "../../components/CustomAttributes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 2,
};

const ProviderTransaction = () => {
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { gateId } = useParams();

  console.log("id", id);
  console.log("gateId", gateId);

  const [scannedData, setScannedData] =
    React.useState<ValidateKeyResponse | null>(null);
  const [showScanner, setShowScanner] = React.useState(true);
  const [idPhoto, setIdPhoto] = React.useState<string | null>(null);
  const [frontPlatesPhoto, setFrontPlatesPhoto] = React.useState<string | null>(
    null
  );
  const [backPlatesPhoto, setBackPlatesPhoto] = React.useState<string | null>(
    null
  );
  const [otherPhoto, setOtherPhoto] = React.useState<string | null>(null);
  const [accreditationPhoto, setAccreditationPhoto] = React.useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] =
    React.useState<boolean>(false);
  const [showAccessDeniedModal, setShowAccessDeniedModal] =
    React.useState<boolean>(false);
  const [accessAllow, setAccessAllow] = React.useState<boolean>(true);
  const [reason, setReason] = React.useState<string>("");
  const [manualDeniedModal, setManualDeniedModal] =
    React.useState<boolean>(false);
  const [invitationId, setInvitationId] = React.useState<AccessKey | null>(
    null
  );
  const [accessPoint, setAccessPoint] = React.useState<string | undefined>(
    user?.last_access_selected?.id
      ? `${user?.last_access_selected.id}`
      : undefined
  );
  const [accessDoor, setAccessDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(
        ({ type }) => type === "access"
      )[0]?.id
    }`
  );

  const [exitDoor, setExitDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(({ type }) => type === "exit")[0]
        ?.id
    }`
  );

  const [doorType, setDoorType] = React.useState<string>("");
  const [notes, setNotes] = React.useState<string>("");
  const [registerResponse, setRegisterResponse] =
    React.useState<RegisterVisitResponseParams | null>(null);
  const [printPhotoModal, setPrintPhotoModal] = React.useState<boolean>(false);
  const [isLoadingSuccess, setIsLoadingSuccess] =
    React.useState<boolean>(false);
  const [registerVisitorName, setRegisterVisitorName] =
    React.useState<string>("");
  const [registerVisitorLastname, setRegisterVisitorLastname] =
    React.useState<string>("");
  const [registerAccessTime, setRegisterAccessTime] =
    React.useState<string>("");
  const [registerHostName, setRegisterHostName] = React.useState<string>("");
  const [registerDepartment, setRegisterDepartment] =
    React.useState<string>("");
  const [registerImageUrl, setRegisterImageUrl] = React.useState<string>("");
  const [registerCustomAttributes, setRegisterCustomAttributes] =
    React.useState<any | null>(null);
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);

  const finalRegisterAccessTime =
    moment(registerAccessTime).format("MM/DD/YY HH:mm");

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = registerCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  const qrKey = invitationId?.unique_id;
  const gate = user?.last_access_selected?.gates.find(
    ({ id }) => id === Number(gateId)
  );

  React.useEffect(() => {
    getGateType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateId]);

  const getGateType = () => {
    const gateType = user?.last_access_selected?.gates.filter(
      ({ id }) => id === Number(gateId)
    );
    if (gateType) {
      setDoorType(gateType[0].type);
    }
  };

  const isDisabled =
    // (user?.last_access_selected?.settings.photo_id && !idPhoto) ||
    // (user?.last_access_selected?.settings.front_plates && !frontPlatesPhoto) ||
    // (user?.last_access_selected?.settings.back_plates && !backPlatesPhoto) ||
    // (user?.last_access_selected?.settings.other && !otherPhoto) ||
    // (user?.last_access_selected?.settings.accreditation && !accreditationPhoto)
    (scannedData?.requirements.photo_id && !idPhoto) ||
    (scannedData?.requirements.front_plates && !frontPlatesPhoto) ||
    (scannedData?.requirements.back_plates && !backPlatesPhoto) ||
    (scannedData?.requirements.other && !otherPhoto) ||
    (scannedData?.requirements.accreditation && !accreditationPhoto);

  const isDisabledCA =
    (scannedData?.requirements.photo_id && !idPhoto) ||
    (scannedData?.requirements.front_plates && !frontPlatesPhoto) ||
    (scannedData?.requirements.back_plates && !backPlatesPhoto) ||
    (scannedData?.requirements.other && !otherPhoto) ||
    (scannedData?.requirements.accreditation && !accreditationPhoto) ||
    !customAttributesRequired;

  const showPhotoSection =
    scannedData?.requirements.photo_id === true ||
    scannedData?.requirements.front_plates === true ||
    scannedData?.requirements.back_plates === true ||
    scannedData?.requirements.other === true ||
    scannedData?.requirements.accreditation === true;

  const disabledDeniedButton = !reason;

  React.useEffect(() => {
    if (id) {
      getInvitationById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getInvitationById = async () => {
    if (id) {
      try {
        setIsLoading(true);
        const result = await api.invitationIdRequest(Number(id));
        setInvitationId(result);
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAccessPointChange = async (event: SelectChangeEvent) => {
    try {
      setAccessPoint(event.target.value);
      const newUserData = await api.changeAccessServerRequest({
        access_server: Number(event.target.value),
      });
      dispatch(setUser(newUserData));
      setAccessDoor(
        `${
          newUserData?.last_access_selected?.gates.filter(
            ({ type }) => type === "access"
          )[0]?.id
        }`
      );
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };
  console.log("qrKey", qrKey);

  const handleAccessDoorChange = (event: SelectChangeEvent) => {
    setAccessDoor(event.target.value);
  };

  const handleExitDoorChange = (event: SelectChangeEvent) => {
    setExitDoor(event.target.value);
  };

  React.useEffect(() => {
    validateKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateId, qrKey]);

  const validateKey = async () => {
    if (gateId && qrKey) {
      try {
        setIsLoading(true);
        const result = await api.validateKeyRequestNoTransaction(
          Number(gateId),
          `${qrKey}`
        );
        setScannedData(result);
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.invalid_qr_code);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const generateRegister = async () => {
    const payload: RegisterVisitRequestParams = {
      key: qrKey,
      gate_id: Number(gateId),
      // gate_id:
      //   invitationId?.last_transaction_type === null
      //     ? Number(accessDoor)
      //     : Number(exitDoor),
      access_allowed: accessAllow,
      // access_key_type_id: 5,
    };
    if (doorType === "access") {
      if (
        scannedData?.requirements.photo_id === true ||
        scannedData?.requirements.front_plates === true ||
        scannedData?.requirements.back_plates === true ||
        scannedData?.requirements.other === true ||
        scannedData?.requirements.accreditation === true
        // user?.last_access_selected?.settings.photo_id === true ||
        // user?.last_access_selected?.settings.front_plates === true ||
        // user?.last_access_selected?.settings.back_plates === true ||
        // user?.last_access_selected?.settings.other === true ||
        // user?.last_access_selected?.settings.accreditation === true
      ) {
        const photos: {
          id?: string;
          other?: string;
          back_plates?: string;
          front_plates?: string;
          accreditation?: string;
        } = {};
        if (scannedData?.requirements.photo_id === true && idPhoto) {
          // if (user?.last_access_selected?.settings.photo_id === true && idPhoto) {
          photos.id = idPhoto;
        }
        if (
          scannedData?.requirements.front_plates === true &&
          frontPlatesPhoto
        ) {
          // if (
          //   user?.last_access_selected?.settings.front_plates === true &&
          //   frontPlatesPhoto
          // ) {
          photos.front_plates = frontPlatesPhoto;
        }
        if (scannedData?.requirements.back_plates === true && backPlatesPhoto) {
          // if (
          //   user?.last_access_selected?.settings.back_plates === true &&
          //   backPlatesPhoto
          // ) {
          photos.back_plates = backPlatesPhoto;
        }
        if (scannedData?.requirements.other === true && otherPhoto) {
          // if (user?.last_access_selected?.settings.other === true && otherPhoto) {
          photos.other = otherPhoto;
        }
        if (
          scannedData?.requirements.accreditation === true &&
          accreditationPhoto
        ) {
          // if (
          //   user?.last_access_selected?.settings.accreditation === true &&
          //   accreditationPhoto
          // ) {
          photos.accreditation = accreditationPhoto;
        }
        payload.photos = photos;
      }
    }
    if (accessAllow === false) {
      payload.notes = reason;
    }
    if (accessAllow === true && notes) {
      payload.notes = notes;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          // if (value) {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
          // }
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      await api.registerVisitRequest(payload);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  const generateRegisterConfirm = async () => {
    const payload: RegisterVisitRequestParams = {
      key: qrKey,
      gate_id: Number(gateId),
      access_allowed: accessAllow,
    };
    if (
      scannedData?.requirements.photo_id === true ||
      scannedData?.requirements.front_plates === true ||
      scannedData?.requirements.back_plates === true ||
      scannedData?.requirements.other === true ||
      scannedData?.requirements.accreditation === true
      // user?.last_access_selected?.settings.photo_id === true ||
      // user?.last_access_selected?.settings.front_plates === true ||
      // user?.last_access_selected?.settings.back_plates === true ||
      // user?.last_access_selected?.settings.other === true ||
      // user?.last_access_selected?.settings.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (scannedData?.requirements.photo_id === true && idPhoto) {
        // if (user?.last_access_selected?.settings.photo_id === true && idPhoto) {
        photos.id = idPhoto;
      }
      if (scannedData?.requirements.front_plates === true && frontPlatesPhoto) {
        // if (
        //   user?.last_access_selected?.settings.front_plates === true &&
        //   frontPlatesPhoto
        // ) {
        photos.front_plates = frontPlatesPhoto;
      }
      if (scannedData?.requirements.back_plates === true && backPlatesPhoto) {
        // if (
        //   user?.last_access_selected?.settings.back_plates === true &&
        //   backPlatesPhoto
        // ) {
        photos.back_plates = backPlatesPhoto;
      }
      if (scannedData?.requirements.other === true && otherPhoto) {
        // if (user?.last_access_selected?.settings.other === true && otherPhoto) {
        photos.other = otherPhoto;
      }
      if (
        scannedData?.requirements.accreditation === true &&
        accreditationPhoto
      ) {
        // if (
        //   user?.last_access_selected?.settings.accreditation === true &&
        //   accreditationPhoto
        // ) {
        photos.accreditation = accreditationPhoto;
      }
      payload.photos = photos;
    }
    if (accessAllow === false) {
      payload.notes = reason;
    }
    if (accessAllow === true && notes) {
      payload.notes = notes;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          // if (value) {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
          // }
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setIsLoadingSuccess(true);
      const response = await api.registerVisitRequest(payload);
      setRegisterResponse(response);
      if (response.print_data) {
        setTimeout(() => {
          setIsLoadingSuccess(false);
          setPrintPhotoModal(true);
          if (response.print_data?.visitor_name) {
            setRegisterVisitorName(response.print_data?.visitor_name);
          }
          if (response.print_data?.created_at) {
            setRegisterAccessTime(response.print_data?.created_at);
          }
          if (response.print_data?.user_name) {
            setRegisterHostName(response.print_data?.user_name);
          }
          if (response.print_data?.user_network_group) {
            setRegisterDepartment(response.print_data.user_network_group);
          }
          if (response.print_data?.photo_url) {
            setRegisterImageUrl(response.print_data.photo_url);
          }
        }, 1000);
      } else {
        setTimeout(() => {
          setIsLoadingSuccess(false);
          setTimeout(() => navigate("/logbook"), 500);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  React.useEffect(() => {
    getRegisterCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getRegisterCustomAttributes = async () => {
    if (token) {
      try {
        const result = await api.getRegisterCustomAttributes();
        setRegisterCustomAttributes(
          result.custom_attributes.map((item) => ({
            ...item,
            value:
              item.attribute_type === "string"
                ? ""
                : item.attribute_type === "integer"
                ? ""
                : item.attribute_type === "boolean"
                ? false
                : item.attribute_type === "list"
                ? ""
                : item.attribute_type === "date" && new Date(),
          }))
        );
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  return (
    <Container className="z-101">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="w-100 pt-5">
          <Grid container>
            <Grid
              item
              xs={12}
              className="flex justify-content-center align-items-center mt-5"
            >
              {scannedData?.access_granted === false ? (
                <CancelRoundedIcon
                  className="mr-4"
                  sx={{
                    color: red[700],
                    fontSize: 40,
                  }}
                />
              ) : (
                <CheckCircleRoundedIcon
                  className="mr-4"
                  sx={{
                    color: green[900],
                    fontSize: 40,
                  }}
                />
              )}
              <p className="my-4 font font-24 font-w-400 title-color">
                {scannedData?.access_granted === false
                  ? gate?.type === "access"
                    ? labels.unauthorized_access
                    : labels.unauthorized_exit
                  : gate?.type === "access"
                  ? labels.authorized_access
                  : labels.authorized_exit}
              </p>
            </Grid>
          </Grid>
        </div>
      )}
      <>
        <Grid container className="w-100 h-100 flex justify-content-center">
          {scannedData && scannedData.access_granted === false && (
            <Grid item xs={12} className="text-center">
              <p className="text-warning font-14 m-0 mt-3 text-warning">
                {scannedData?.key_data.access_key_status.message ===
                  "Código QR eliminado" && labels.qr_code_deleted}
                {scannedData?.key_data.access_key_status.message ===
                  "Oops, el último acceso no fue registrado" &&
                  labels.last_access_not_registered}
                {scannedData?.key_data.access_key_status.message ===
                  "Oops, la última salida no fue registrada" &&
                  labels.last_exit_not_registered}
                {scannedData?.key_data.access_key_status.message ===
                  "Acceso NO autorizado" && labels.access_door_denied}
                {scannedData?.key_data.access_key_status.message ===
                  "Acceso fuera del periodo permitido" &&
                  labels.access_out_of_time}
                {scannedData?.key_data.access_key_status.message ===
                  "Acceso no autorizado" && labels.new_qr_code}
                {scannedData?.key_data.access_key_status.message ===
                  "Horario de acceso no autorizado" &&
                  labels.hour_access_unauthorized}
              </p>
            </Grid>
          )}
          <div className="background-border-grey w-100 flex flex-dir-col align-items-center mt-5">
            <Grid item xs={12} className="flex flex-dir-col align-items-center">
              <p className="subtitle-color font-20 mt-5 mb-5 bold">
                {scannedData?.key_data.visitor_name}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              className="w-90 flex justify-content-space-between align-items-center qr-tel-style"
            >
              <div className="ml-5">
                <p className="title-color font-14 mt-5 mb-0">{labels.host}</p>
                <p className="title-color font-18 medium mt-1 mb-4">
                  {scannedData?.key_data.user.name}
                </p>
              </div>
              <div>
                <a href={`tel:${scannedData?.key_data?.user?.phone}`}>
                  <IconButton className="call-icon-button-style-button mr-5">
                    <CallIcon className="call-icon-button-style-icon" />
                  </IconButton>
                </a>
              </div>
            </Grid>

            <Grid item xs={12} className="w-90 ml-9 mb-5 flex">
              <p className="title-color font-14 mt-4 mb-0">
                {labels.department}{" "}
                <span className="font-18 meduim">
                  {scannedData?.key_data.user.group}
                </span>
              </p>
            </Grid>
          </div>

          {/* CUSTOM ATTRIBUTES-------------------------------------------->>*/}
          {/* ATRIBUTOS PERSONALIZADOS------------------------------------->>*/}
          {registerCustomAttributes &&
            registerCustomAttributes.length > 0 &&
            scannedData?.access_granted === true &&
            gate?.type === "access" && (
              <>
                <Grid item xs={12} sx={{ marginTop: "15px" }}>
                  <Divider>
                    <Chip
                      label={labels.others}
                      sx={{ minWidth: "150px" }}
                      className="subtitle-color"
                    />
                  </Divider>
                </Grid>
                {registerCustomAttributes.map(
                  (
                    {
                      attribute_name,
                      attribute_type,
                      required,
                      options,
                      value,
                    }: any,
                    index: number
                  ) => (
                    <Grid item xs={12} key={index} style={{ marginTop: 12 }}>
                      <CustomAttributes
                        type={attribute_type}
                        name={attribute_name}
                        required={required}
                        value={value}
                        setValue={(newValue: any) => {
                          const newRegisterCustomAttributes = [
                            ...registerCustomAttributes,
                          ];
                          newRegisterCustomAttributes[index].value = newValue;
                          setRegisterCustomAttributes(
                            newRegisterCustomAttributes
                          );
                        }}
                        listArray={options}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12} sx={{ marginTop: "15px" }}>
                  <Divider></Divider>
                </Grid>
              </>
            )}

          {scannedData?.access_granted === true &&
            gate?.type === "access" &&
            showPhotoSection && (
              <>
                <Grid item xs={12}>
                  <p className="subtitle-color font-18 text-center m-0 my-5">
                    {labels.photographs_required_for_access}
                  </p>
                  {/* {isMobile ? (
                  <p className="subtitle-color font-20 m-0 ml-5 my-5">
                    {labels.photographs_required_for_access}
                  </p>
                ) : (
                  <p className="subtitle-color font-24 m-0 ml-5 my-5">
                    {labels.photographs_required_for_access}
                  </p>
                )} */}
                </Grid>
                {/* <Grid item xs={12}>
                <Divider className="mb-7" />
              </Grid> */}

                {/* COMPONENT - SHOW BUTTONS BY PHOTO TYPE----------------------------->>*/}
                {/* COMPONENTE - MOSTRAR BOTONES DE TIPO DE FOTO----------------------->>*/}
                <PhotoButtonsInvitations
                  idPhoto={idPhoto}
                  setIdPhoto={setIdPhoto}
                  frontPlatesPhoto={frontPlatesPhoto}
                  setFrontPlatesPhoto={setFrontPlatesPhoto}
                  backPlatesPhoto={backPlatesPhoto}
                  setBackPlatesPhoto={setBackPlatesPhoto}
                  otherPhoto={otherPhoto}
                  setOtherPhoto={setOtherPhoto}
                  accreditationPhoto={accreditationPhoto}
                  setAccreditationPhoto={setAccreditationPhoto}
                  idPhotoRequired={scannedData?.requirements.photo_id}
                  frontPlatesPhotoRequired={
                    scannedData?.requirements.front_plates
                  }
                  backPlatesPhotoRequired={
                    scannedData?.requirements.back_plates
                  }
                  otherPhotoRequired={scannedData?.requirements.other}
                  accreditationPhotoRequired={
                    scannedData?.requirements.accreditation
                  }
                />
              </>
            )}
          <Grid item xs={12}>
            <Divider className="my-7" />
          </Grid>

          {scannedData?.access_granted ? (
            <Grid
              item
              xs={12}
              className="flex flex-dir-col align-items-center justify-content-center"
            >
              <TextField
                fullWidth
                label={labels.notes_optional}
                // placeholder={labels.notes_optional}
                multiline
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                style={{ marginBottom: 6 }}
              />
              {/* BUTTON - OPEN AUTHORIZED ACCESS KEYS DETAILS------------------------------------->>*/}
              {/* BOTON - ABRE DETALLES DE INVITACIONES AUTORIZADAS-------------------------------->>*/}
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  // gate?.type === "access" && addPhotos();
                  // generateRegister();
                  // setTimeout(() => navigate("/logbook"), 500);
                  generateRegisterConfirm();
                }}
                className="my-4 mb-6"
                size="large"
                disabled={
                  scannedData?.access_granted === true &&
                  gate?.type === "access" &&
                  registerCustomAttributes &&
                  registerCustomAttributes.length > 0
                    ? isDisabledCA
                    : isDisabled
                }
                sx={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                {gate?.type === "access"
                  ? labels.confirm_access
                  : labels.confirm_exit}
              </Button>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  // gate?.type === "access" && addPhotos();
                  setAccessAllow(false);
                  setManualDeniedModal(true);
                }}
                className="mb-10"
                size="large"
                disabled={
                  scannedData?.access_granted === true &&
                  gate?.type === "access" &&
                  registerCustomAttributes &&
                  registerCustomAttributes.length > 0
                    ? isDisabledCA
                    : isDisabled
                }
                sx={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  width: "100%",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                }}
                color="error"
              >
                {gate?.type === "access"
                  ? labels.deny_access
                  : labels.deny_exit}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="flex flex-dir-col align-items-center">
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  generateRegister();
                  setTimeout(() => navigate("/logbook"), 500);
                }}
                className="my-4 mb-10"
                size="large"
                sx={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  marginTop: "10px",
                }}
              >
                {labels.ok}
              </Button>
            </Grid>
          )}

          <div className="lower-div root-background" />
        </Grid>
      </>

      {/* ACCESS DENIED MODAL---------------------------------------->>*/}
      {/* MODAL ACCESO DENEGADO-------------------------------------->>*/}
      <Modal disableEscapeKeyDown open={manualDeniedModal}>
        <Box sx={style}>
          <Grid container className="vertical-spacing-4">
            <Grid
              item
              xs={12}
              className="flex justify-content-flex-end align-items-center m-0 p-0"
            >
              <IconButton
                onClick={() => {
                  setManualDeniedModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className="w-100 align-items-center justify-content-center vertical-spacing-5"
          >
            <Grid item xs={12}>
              <TextField
                value={reason}
                id="observation"
                label={labels.reason}
                fullWidth
                multiline
                rows={3}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Grid>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                generateRegister();
                setManualDeniedModal(false);
                setTimeout(() => navigate("/logbook"), 500);
              }}
              disabled={disabledDeniedButton}
            >
              {labels.continue}
            </Button>
          </Grid>
        </Box>
      </Modal>
      <PrintLabelModal
        openModal={printPhotoModal}
        setOpenModal={setPrintPhotoModal}
        visitorName={registerVisitorName}
        visitorLastName={registerVisitorLastname}
        accessTime={finalRegisterAccessTime}
        hostName={registerHostName}
        department={registerDepartment}
        imageUrl={registerImageUrl}
        isDesktop={false}
      />
    </Container>
  );
};
export default ProviderTransaction;
