import React from "react";
import "./main-pages-header.scss";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import SearchInputHeader from "../SearchInputHeader";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PlaylistRemoveRoundedIcon from "@mui/icons-material/PlaylistRemoveRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import {
  FaEnvelopeOpenText,
  FaPlus,
  FaFileDownload,
  FaBook,
  FaHouseUser,
  FaUsers,
  FaChartArea,
} from "react-icons/fa";
import {
  FaDoorOpen,
  FaKeyboard,
  FaUser,
  FaUserCircle,
  FaUserCog,
  FaUserShield,
  FaCog,
} from "react-icons/fa";
import { HiSquares2X2 } from "react-icons/hi2";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import { HiPlus } from "react-icons/hi";
import { CSVLink } from "react-csv";

interface MainPagesHeaderProps {
  newIcon: boolean;
  onPressNewIconAction?: () => void;
  mainTitle:
    | "invitations"
    | "logbook"
    | "events"
    | "users"
    | "users_hosts"
    | "users_guards"
    | "users_admins"
    | "departments"
    | "access_points"
    | "intelligence";
  hasSearch: boolean;
  searchValue: string;
  searchAction: (searchValue: string) => void;
  hasFilters: boolean;
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;
  downloadable: boolean;
  fileArray: any[];
  fileName: string;
}

const MainPagesHeader = ({
  newIcon,
  onPressNewIconAction,
  mainTitle,
  hasSearch,
  hasFilters,
  showFilters,
  setShowFilters,
  downloadable,
  searchValue,
  searchAction,
  fileArray,
  fileName,
}: MainPagesHeaderProps) => {
  const labels = useAppSelector(selectLabels);

  return (
    <div className="main-container lower-shadow">
      <div
        className="title-container"
        style={{ marginTop: 16, marginBottom: 18 }}
      >
        {mainTitle === "intelligence" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaChartArea fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.intelligence}</p>
          </div>
        )}
        {mainTitle === "invitations" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaEnvelopeOpenText fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.invitations}</p>
          </div>
        )}
        {mainTitle === "logbook" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaBook fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.logbook}</p>
          </div>
        )}
        {mainTitle === "users" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaHouseUser fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.my_users}</p>
          </div>
        )}
        {mainTitle === "users_hosts" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaUser fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.users_admin}</p>
          </div>
        )}
        {mainTitle === "users_guards" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaUserShield fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.guards}</p>
          </div>
        )}
        {mainTitle === "users_admins" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaUserCog fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.admins}</p>
          </div>
        )}
        {mainTitle === "departments" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <HiSquares2X2 fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.unit}</p>
          </div>
        )}
        {mainTitle === "access_points" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaDoorOpen fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.access_points}</p>
          </div>
        )}
        {mainTitle === "events" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <FaUsers fontSize={24} style={{ color: "white" }} />
            <p className="title-text m-0 ml-6">{labels.events_label}</p>
          </div>
        )}
        {newIcon && (
          <HiPlus
            style={{ color: "white", fontSize: 28, marginRight: 18 }}
            onClick={onPressNewIconAction}
          />
        )}
      </div>
      <div className="title-container" style={{ marginBottom: 10 }}>
        <div style={{ width: "70%", marginLeft: 16 }}>
          {hasSearch && (
            <SearchInputHeader value={searchValue} setValue={searchAction} />
          )}
        </div>
        <div
          style={{
            width: "30%",
            height: 40,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {hasFilters &&
            (showFilters ? (
              <RiFilterOffFill
                onClick={() => setShowFilters(!showFilters)}
                style={{ color: "white", fontSize: 24, marginRight: 20 }}
              />
            ) : (
              <RiFilterFill
                onClick={() => setShowFilters(!showFilters)}
                style={{ color: "white", fontSize: 24, marginRight: 20 }}
              />
            ))}
          {downloadable && (
            <CSVLink data={fileArray}>
              <FaFileDownload
                style={{ color: "white", fontSize: 24, marginRight: 20 }}
              />
            </CSVLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPagesHeader;
