import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";

const TemporarySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface ValidityFormProps {
  isLoading?: boolean;
  date: Date | null;
  setDate: (date: Date | null) => void;
  hour: string | null;
  setHour: (hour: string | null) => void;
  minutes: string | null;
  setMinutes: (minutes: string | null) => void;
  hoursLater: string;
  setHoursLater: (hoursLater: string) => void;
  hoursLaterTemporary: string;
  setHoursLaterTemporary: (hoursLaterTemporary: string) => void;
  temporaryDuration: "days" | "weeks" | "months";
  setTemporaryDuration: (
    temporaryDuration: "days" | "weeks" | "months"
  ) => void;
  temporaryDurationValue: string;
  setTemporaryDurationValue: (temporaryDurationValue: string) => void;
  isTemporary: boolean;
  monday: boolean;
  setMonday: (monday: boolean) => void;
  tuesday: boolean;
  setTuesday: (tuesday: boolean) => void;
  wednesday: boolean;
  setWednesday: (wednesday: boolean) => void;
  thursday: boolean;
  setThursday: (thursday: boolean) => void;
  friday: boolean;
  setFriday: (friday: boolean) => void;
  saturday: boolean;
  setSaturday: (saturday: boolean) => void;
  sunday: boolean;
  setSunday: (sunday: boolean) => void;
  allowAllDay: boolean;
  setAllowAllDay: (allowAllDay: boolean) => void;
}

const ValidityForm = ({
  isLoading,
  date,
  setDate,
  hour,
  setHour,
  minutes,
  setMinutes,
  hoursLater,
  setHoursLater,
  hoursLaterTemporary,
  setHoursLaterTemporary,
  temporaryDuration,
  setTemporaryDuration,
  temporaryDurationValue,
  setTemporaryDurationValue,
  isTemporary,
  monday,
  setMonday,
  tuesday,
  setTuesday,
  wednesday,
  setWednesday,
  thursday,
  setThursday,
  friday,
  setFriday,
  saturday,
  setSaturday,
  sunday,
  setSunday,
  allowAllDay,
  setAllowAllDay,
}: ValidityFormProps) => {
  const labels = useAppSelector(selectLabels);

  const [numberArray, setNumberArray] = React.useState<
    { label: string; value: any }[]
  >([]);

  React.useEffect(() => {
    const daysArray = [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6",
        value: "6",
      },
      {
        label: "7",
        value: "7",
      },
      {
        label: "8",
        value: "8",
      },
      {
        label: "9",
        value: "9",
      },
      {
        label: "10",
        value: "10",
      },
      {
        label: "11",
        value: "11",
      },
      {
        label: "12",
        value: "12",
      },
      {
        label: "13",
        value: "13",
      },
      {
        label: "14",
        value: "14",
      },
      {
        label: "15",
        value: "15",
      },
      {
        label: "16",
        value: "16",
      },
      {
        label: "17",
        value: "17",
      },
      {
        label: "18",
        value: "18",
      },
      {
        label: "19",
        value: "19",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "21",
        value: "21",
      },
      {
        label: "22",
        value: "22",
      },
      {
        label: "23",
        value: "23",
      },
      {
        label: "24",
        value: "24",
      },
      {
        label: "25",
        value: "25",
      },
      {
        label: "26",
        value: "26",
      },
      {
        label: "27",
        value: "27",
      },
      {
        label: "28",
        value: "28",
      },
      {
        label: "29",
        value: "29",
      },
      {
        label: "30",
        value: "30",
      },
    ];
    const weeksArray = [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6",
        value: "6",
      },
      {
        label: "7",
        value: "7",
      },
      {
        label: "8",
        value: "8",
      },
      {
        label: "9",
        value: "9",
      },
      {
        label: "10",
        value: "10",
      },
    ];
    const monthsArray = [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6",
        value: "6",
      },
      {
        label: "7",
        value: "7",
      },
      {
        label: "8",
        value: "8",
      },
      {
        label: "9",
        value: "9",
      },
      {
        label: "10",
        value: "10",
      },
      {
        label: "11",
        value: "11",
      },
      {
        label: "12",
        value: "12",
      },
    ];
    if (temporaryDuration === "days") {
      setNumberArray(daysArray);
    }
    if (temporaryDuration === "weeks") {
      setNumberArray(weeksArray);
      if (Number(temporaryDurationValue) > weeksArray.length) {
        setTemporaryDurationValue(`${weeksArray.length}`);
      }
    }
    if (temporaryDuration === "months") {
      setNumberArray(monthsArray);
      if (Number(temporaryDurationValue) > monthsArray.length) {
        setTemporaryDurationValue(`${monthsArray.length}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryDuration]);

  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowAllDay(event.target.checked);
  };

  return (
    <>
      {!isTemporary ? (
        <div
          style={{ width: "100%", display: "flex", boxSizing: "border-box" }}
        >
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingRight: 10,
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="title-color"
                style={{ marginBottom: 10, padding: 0 }}
              >
                {labels.valid_for_the_date}
              </p>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={date}
                  onChange={(newDate: Date | null) => {
                    setDate(newDate);
                  }}
                  inputFormat="DD/MMM/YYYY"
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                  disabled={isLoading}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              display: "flex",
              paddingLeft: 10,
            }}
          >
            <div style={{ width: "60%", marginRight: 15 }}>
              <p
                className="title-color"
                style={{ marginBottom: 10, padding: 0 }}
              >
                {labels.allow_entry_from}
              </p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={hour}
                    onChange={(e) => {
                      setHour(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    disabled={isLoading}
                  >
                    <MenuItem value={"00"}>00</MenuItem>
                    <MenuItem value={"01"}>01</MenuItem>
                    <MenuItem value={"02"}>02</MenuItem>
                    <MenuItem value={"03"}>03</MenuItem>
                    <MenuItem value={"04"}>04</MenuItem>
                    <MenuItem value={"05"}>05</MenuItem>
                    <MenuItem value={"06"}>06</MenuItem>
                    <MenuItem value={"07"}>07</MenuItem>
                    <MenuItem value={"08"}>08</MenuItem>
                    <MenuItem value={"09"}>09</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"11"}>11</MenuItem>
                    <MenuItem value={"12"}>12</MenuItem>
                    <MenuItem value={"13"}>13</MenuItem>
                    <MenuItem value={"14"}>14</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"16"}>16</MenuItem>
                    <MenuItem value={"17"}>17</MenuItem>
                    <MenuItem value={"18"}>18</MenuItem>
                    <MenuItem value={"19"}>19</MenuItem>
                    <MenuItem value={"20"}>20</MenuItem>
                    <MenuItem value={"21"}>21</MenuItem>
                    <MenuItem value={"22"}>22</MenuItem>
                    <MenuItem value={"23"}>23</MenuItem>
                  </Select>
                </FormControl>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    marginRight: 4.5,
                    marginLeft: 4.5,
                  }}
                >
                  :
                </p>
                <FormControl fullWidth>
                  <Select
                    value={minutes}
                    onChange={(e) => {
                      setMinutes(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    disabled={isLoading}
                  >
                    <MenuItem value={"00"}>00</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"30"}>30</MenuItem>
                    <MenuItem value={"45"}>45</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={{ width: "40%", marginLeft: 15 }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <p
                  className="title-color"
                  style={{ marginBottom: 10, padding: 0 }}
                >
                  {labels.to}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={hoursLater}
                    onChange={(e) => {
                      setHoursLater(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    disabled={isLoading}
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"6"}>6</MenuItem>
                    <MenuItem value={"7"}>7</MenuItem>
                    <MenuItem value={"8"}>8</MenuItem>
                    <MenuItem value={"9"}>9</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"11"}>11</MenuItem>
                    <MenuItem value={"12"}>12</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <p
                  className="title-color"
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 12,
                    marginTop: 8,
                  }}
                >
                  {labels.hours_later}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: "100%", display: "flex", boxSizing: "border-box" }}
        >
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingRight: 10,
            }}
          >
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "100%" }}>
                <p
                  className="title-color"
                  style={{ marginBottom: 10, padding: 0 }}
                >
                  {labels.valid_from_date}
                </p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={date}
                    onChange={(newDate: Date | null) => {
                      setDate(newDate);
                    }}
                    inputFormat="DD/MMM/YYYY"
                    renderInput={(params: any) => (
                      <TextField fullWidth {...params} />
                    )}
                    disabled={isLoading}
                  />
                </LocalizationProvider>
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  marginTop: 14,
                }}
              >
                <p
                  className="title-color"
                  style={{ margin: 0, marginBottom: 10, padding: 0 }}
                >
                  {labels.select_access_days}
                </p>
                <div
                  style={{
                    width: "100%",
                    height: 56,
                    boxSizing: "border-box",
                    paddingRight: 10,
                    paddingLeft: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: monday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setMonday(!monday)}
                  >
                    {labels.mon_2}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: tuesday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setTuesday(!tuesday)}
                  >
                    {labels.tue_2}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: wednesday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setWednesday(!wednesday)}
                  >
                    {labels.wed_2}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: thursday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setThursday(!thursday)}
                  >
                    {labels.thu_2}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: friday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setFriday(!friday)}
                  >
                    {labels.fri_2}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: saturday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setSaturday(!saturday)}
                  >
                    {labels.sat_2}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      padding: 0,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: sunday ? "#10418a" : "#8a8c91",
                    }}
                    onClick={() => setSunday(!sunday)}
                  >
                    {labels.sun_2}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingLeft: 10,
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="title-color"
                style={{ marginBottom: 10, padding: 0 }}
              >
                {labels.to}
              </p>
              <div style={{ display: "flex", boxSizing: "border-box" }}>
                <FormControl fullWidth style={{ marginRight: 10 }}>
                  <Select
                    value={temporaryDurationValue}
                    onChange={(e) => {
                      setTemporaryDurationValue(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    disabled={isLoading}
                  >
                    {numberArray.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                    {/* <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"6"}>6</MenuItem>
                    <MenuItem value={"7"}>7</MenuItem>
                    <MenuItem value={"8"}>8</MenuItem>
                    <MenuItem value={"9"}>9</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"11"}>11</MenuItem>
                    <MenuItem value={"12"}>12</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    value={temporaryDuration}
                    onChange={(
                      e: SelectChangeEvent<"days" | "weeks" | "months">
                    ) => {
                      const selectedValue = e.target.value;
                      if (
                        selectedValue === "days" ||
                        selectedValue === "weeks" ||
                        selectedValue === "months"
                      ) {
                        setTemporaryDuration(selectedValue);
                      }
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    disabled={isLoading}
                  >
                    <MenuItem value={"days"}>{labels.days}</MenuItem>
                    <MenuItem value={"weeks"}>{labels.weeks}</MenuItem>
                    <MenuItem value={"months"}>{labels.months}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "60%", marginRight: 10 }}>
                <p
                  className="title-color"
                  style={{ marginBottom: 10, padding: 0 }}
                >
                  {labels.allow_entry_from}
                </p>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      value={hour}
                      onChange={(e) => {
                        setHour(e.target.value);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                      disabled={isLoading ? true : allowAllDay}
                    >
                      <MenuItem value={"00"}>00</MenuItem>
                      <MenuItem value={"01"}>01</MenuItem>
                      <MenuItem value={"02"}>02</MenuItem>
                      <MenuItem value={"03"}>03</MenuItem>
                      <MenuItem value={"04"}>04</MenuItem>
                      <MenuItem value={"05"}>05</MenuItem>
                      <MenuItem value={"06"}>06</MenuItem>
                      <MenuItem value={"07"}>07</MenuItem>
                      <MenuItem value={"08"}>08</MenuItem>
                      <MenuItem value={"09"}>09</MenuItem>
                      <MenuItem value={"10"}>10</MenuItem>
                      <MenuItem value={"11"}>11</MenuItem>
                      <MenuItem value={"12"}>12</MenuItem>
                      <MenuItem value={"13"}>13</MenuItem>
                      <MenuItem value={"14"}>14</MenuItem>
                      <MenuItem value={"15"}>15</MenuItem>
                      <MenuItem value={"16"}>16</MenuItem>
                      <MenuItem value={"17"}>17</MenuItem>
                      <MenuItem value={"18"}>18</MenuItem>
                      <MenuItem value={"19"}>19</MenuItem>
                      <MenuItem value={"20"}>20</MenuItem>
                      <MenuItem value={"21"}>21</MenuItem>
                      <MenuItem value={"22"}>22</MenuItem>
                      <MenuItem value={"23"}>23</MenuItem>
                    </Select>
                  </FormControl>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      marginRight: 4.5,
                      marginLeft: 4.5,
                    }}
                  >
                    :
                  </p>
                  <FormControl fullWidth>
                    <Select
                      value={minutes}
                      onChange={(e) => {
                        setMinutes(e.target.value);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                      disabled={isLoading ? true : allowAllDay}
                    >
                      <MenuItem value={"00"}>00</MenuItem>
                      <MenuItem value={"15"}>15</MenuItem>
                      <MenuItem value={"30"}>30</MenuItem>
                      <MenuItem value={"45"}>45</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ width: "40%", marginLeft: 10 }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    {labels.to}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      value={hoursLaterTemporary}
                      onChange={(e) => {
                        setHoursLaterTemporary(e.target.value);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                      disabled={isLoading ? true : allowAllDay}
                    >
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <p
                    className="title-color"
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 12,
                      marginTop: 8,
                    }}
                  >
                    {labels.hours_later}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="subtitle-color font-14 medium">
                  {labels.allow_access_all_day}
                </p>
              </div>
              <TemporarySwitch
                checked={allowAllDay}
                onChange={onChangeSwitch}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ValidityForm;
