import { providerExampleData } from "./mockData";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Provider {
  value: string;
  label: string;
}

export interface ProviderState {
  providersList: Provider[];
}

const initialState: ProviderState = {
  providersList: providerExampleData,
};

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    addProvider: (state, action) => {
      state.providersList = [...state.providersList, action.payload];
    },
  },
});

export const { addProvider } = providerSlice.actions;

export const selectProvidersList = (state: RootState) => state.provider.providersList;

export default providerSlice.reducer;