import moment from "moment";

export const registersExampleData = [
  {
    id: "0",
    date: moment().format('DD/MMM/YYYY'),
    hour: "12:00",
    invitationType: "Visita",
    visit: "Juan Perez",
    personWhoVisit: "Maria Gomez",
    department: "A34",
    entryPoint: "Acceso vehicular principal",
    type: "Entrada",
    photos: true,
    provider: "No",
  },
  {
    id: "1",
    date: "1/Dec/2021",
    hour: "10:32",
    invitationType: "Proveedor",
    visit: "Rodrigo Gutierrez",
    personWhoVisit: "Anahí Gomez",
    department: "A23",
    entryPoint: "Acceso vehicular principal",
    type: "Salida",
    photos: true,
    provider: "Uber",
  },
  {
    id: "2",
    date: "1/Dec/2021",
    hour: "10:14",
    invitationType: "Proveedor",
    visit: "Rodrigo Gutierrez",
    personWhoVisit: "Anahí Gomez",
    department: "A23",
    entryPoint: "Acceso vehicular principal",
    type: "Entrada",
    photos: true,
    provider: "Uber",
  },
  {
    id: "3",
    date: "15/Nov/2021",
    hour: "16:55",
    invitationType: "Visita",
    visit: "Maribel Andrade",
    personWhoVisit: "Jaime Ochoa",
    department: "B4",
    entryPoint: "Acceso vehicular principal",
    type: "Salida",
    photos: true,
    provider: "No",
  },
  {
    id: "4",
    date: "15/Nov/2021",
    hour: "14:40",
    invitationType: "Proveedor",
    visit: "Saul Hernandez",
    personWhoVisit: "Jaime Ochoa",
    department: "B4",
    entryPoint: "Acceso vehicular principal",
    type: "Salida",
    photos: true,
    provider: "DHL",
  },
  {
    id: "5",
    date: "15/Nov/2021",
    hour: "14:11",
    invitationType: "Proveedor",
    visit: "Saul Hernandez",
    personWhoVisit: "Jaime Ochoa",
    department: "B4",
    entryPoint: "Acceso vehicular principal",
    type: "Entrada",
    photos: true,
    provider: "DHL",
  },
  {
    id: "6",
    date: "29/Oct/2021",
    hour: "11:20",
    invitationType: "Visita",
    visit: "Andrea Garcia",
    personWhoVisit: "Aldo Guadarrama",
    department: "C12",
    entryPoint: "Acceso vehicular principal",
    type: "Salida",
    photos: true,
    provider: "No",
  },
  {
    id: "7",
    date: "29/Oct/2021",
    hour: "9:10",
    invitationType: "Visita",
    visit: "Andrea Garcia",
    personWhoVisit: "Aldo Guadarrama",
    department: "C12",
    entryPoint: "Acceso vehicular principal",
    type: "Entrada",
    photos: true,
    provider: "No",
  },
]