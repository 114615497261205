import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import api, {
  getGuestsInsideAccessServerParams,
  GetLogbookRegister,
  GetLogbookRegisterTransaction,
  LogExitRegisterParams,
} from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Filters from "../../components/Filters";
import MainPagesHeader from "../../components/MainPagesHeader";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import { showError } from "../../utils/helper";
import moment from "moment";
import "moment/locale/es";
import TransactionsList from "../../components/TransactionsList";
import Pagination from "../../components/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LaptopMacRoundedIcon from "@mui/icons-material/LaptopMacRounded";
import { red } from "@mui/material/colors";
import CustomIcon from "../../components/CustomIcon";
import CallIcon from "@mui/icons-material/Call";
import { FaSignInAlt, FaSignOutAlt, FaLock, FaEnvelope } from "react-icons/fa";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";

interface FileExport {
  fecha_hora: string;
  evento: string;
  tipo_de_acceso: string;
  punto_de_acceso: string;
  nombre_del_visitante: string;
  anfitrión: string;
  departamento: string;
  tipo_de_registro: string;
  fotos: string;
}

const LogbookRefactor = () => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [fileArray, setFileArray] = React.useState<null | FileExport[]>(null);
  const [arrayFilters, setArrayFilters] = React.useState<string>(
    labels.date_filter
  );
  const [filterContent, setFilterContent] = React.useState<any>(null);
  const [logbookList, setLogbookList] =
    React.useState<GetLogbookRegister | null>(null);
  const [date, setDate] = React.useState<Date | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [accessServer, setAccessServer] = React.useState<number | null>(
    userRole === "guard" ? Number(user?.last_access_selected?.id) : null
  );
  const [statusFilter, setStatusFilter] = React.useState<
    "access" | "exit" | null
  >(null);
  const [statusFilterParams, setStatusFilterParams] = React.useState<string>(
    labels.all
  );
  const [dateRefactor, setDateRefactor] = React.useState<string>("all");
  const [accessServerFilter, setAccessServerFilter] = React.useState<string>(
    userRole === "guard" ? user?.last_access_selected?.name : labels.all
  );
  const [guestsByAccessServer, setGuestsByAccessServer] =
    React.useState<getGuestsInsideAccessServerParams | null>(null);
  const [secUserTransactions, setSecUserTransactions] = React.useState<
    GetLogbookRegisterTransaction[] | null
  >(null);
  const [secUserTransactionsBackUp, setSecUserTransactionsBackUp] =
    React.useState<GetLogbookRegisterTransaction[] | null>(null);
  const [secUserPage, setSecUserPage] = React.useState<number>(1);
  const [secUserRowsPerPage, setSecUserRowsPerPage] =
    React.useState<number>(10);
  const [secUserTotalPages, setSecUserTotalPages] = React.useState<number>(0);

  const filtersArray: any[] = [
    labels.date_filter,
    labels.status_filter,
    labels.access_point_filter,
  ];

  const startIndex = (secUserPage - 1) * secUserRowsPerPage;
  const endIndex = startIndex + secUserRowsPerPage;
  const currentItems = secUserTransactionsBackUp
    ? secUserTransactionsBackUp.slice(startIndex, endIndex)
    : "";

  React.useEffect(() => {
    setAccessServerFilterInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected]);

  const setAccessServerFilterInitial = () => {
    if (userRole === "guard" && user?.last_access_selected) {
      setAccessServer(user?.last_access_selected?.id);
      setAccessServerFilter(user?.last_access_selected?.name);
    } else {
      setAccessServer(null);
      setAccessServerFilter(labels.all);
    }
  };

  React.useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilters]);

  const resetFilters = () => {
    if (!showFilters) {
      setStatusFilterParams(labels.all);
      setStatusFilter(null);
      setDateRefactor("all");
      setDate(null);
      setAccessServerFilter(
        userRole === "guard" ? user?.last_access_selected?.name : labels.all
      );
      setAccessServer(
        userRole === "guard" ? Number(user?.last_access_selected?.id) : null
      );
    }
  };

  const handleAccessServerFilterChange = (event: SelectChangeEvent) => {
    const filteredAccessServer = user?.access_servers.filter(
      ({ name }) => name === event.target.value
    );
    setAccessServerFilter(event.target.value);
    if (event.target.value === labels.all) {
      setAccessServer(null);
    } else {
      if (filteredAccessServer) {
        setAccessServer(filteredAccessServer[0]?.id);
      }
    }
  };
  const setAccessServerFilterFunction = () => {
    setFilterContent(
      <>
        <FormControl fullWidth>
          <Select
            size="small"
            sx={{ bgcolor: "white" }}
            style={{ color: "#10418a" }}
            value={accessServerFilter}
            onChange={handleAccessServerFilterChange}
          >
            <MenuItem value={labels.all}>{labels.all}</MenuItem>
            {user?.access_servers?.map(({ name, id }) => (
              <MenuItem key={id} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const handleInvitationStatusChange = (event: SelectChangeEvent) => {
    setStatusFilterParams(event.target.value);
    if (event.target.value === labels.all) {
      setStatusFilter(null);
    }
    if (event.target.value === "access") {
      setStatusFilter("access");
    }
    if (event.target.value === "exit") {
      setStatusFilter("exit");
    }
  };

  const setStatusFilterFunction = () => {
    setFilterContent(
      <>
        <FormControl fullWidth>
          <Select
            size="small"
            sx={{ bgcolor: "white" }}
            style={{ color: "#10418a" }}
            value={statusFilterParams}
            onChange={handleInvitationStatusChange}
          >
            <MenuItem value={labels.all}>{labels.all}</MenuItem>
            <MenuItem value={"access"}>
              <div className="flex align-items-center">
                {
                  <FaSignInAlt
                    className="primary-color mr-4"
                    style={{ fontSize: 14 }}
                  />
                }
                {labels.with_access_register}
              </div>
            </MenuItem>
            <MenuItem value={"exit"}>
              <div className="flex align-items-center">
                {
                  <FaSignOutAlt
                    className="primary-color mr-4"
                    style={{ fontSize: 14 }}
                  />
                }
                {labels.with_exit_register}
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  };

  const dateChange = (event: SelectChangeEvent) => {
    setDateRefactor(event.target.value);
    if (event.target.value === "all") {
      setDate(null);
    }
    if (event.target.value === "today") {
      setDate(new Date());
    }
    if (event.target.value === "calendar") {
      setDate(null);
    }
  };

  const setDateFilterFunction = () => {
    let datePicker = (
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            value={date}
            onChange={(newDate: Date | null) => {
              setDate(newDate);
              setPage(1);
              setSecUserPage(1);
            }}
            inputFormat="DD/MMM"
            className="primary-color"
            renderInput={({ inputRef, inputProps }) => (
              <input
                type={"button"}
                ref={inputRef}
                {...inputProps}
                className={
                  date !== null
                    ? "m-0 p-0 py-4 date-filter font-14 primary-color"
                    : "m-0 p-0 py-4 date-filter-null font-14"
                }
              />
            )}
          />
        </LocalizationProvider>
        {date === null && (
          <div className="no-date-filter font-14 primary-color">
            {labels.choose}
          </div>
        )}
      </div>
    );
    setFilterContent(
      <>
        {dateRefactor !== "calendar" ? (
          <FormControl fullWidth>
            <Select
              size="small"
              sx={{ bgcolor: "white", borderColor: "blue" }}
              style={{ color: "#10418a" }}
              value={dateRefactor}
              onChange={dateChange}
            >
              <MenuItem value={"all"}>{labels.all}</MenuItem>
              <MenuItem value={"today"}>{labels.today}</MenuItem>
              <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <div className="flex flex-dir-row justify-content-space-between align-items-center">
            <div style={{ width: "47.5%" }}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{ bgcolor: "white", borderColor: "blue" }}
                  style={{ color: "#10418a" }}
                  value={dateRefactor}
                  onChange={dateChange}
                >
                  <MenuItem value={"all"}>{labels.all}</MenuItem>
                  <MenuItem value={"today"}>{labels.today}</MenuItem>
                  <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              className="flex justify-content-flex-end"
              style={{ width: "47.5%" }}
            >
              {datePicker}
            </div>
          </div>
        )}
      </>
    );
  };

  React.useEffect(() => {
    rightContentManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    arrayFilters,
    statusFilter,
    date,
    dateRefactor,
    accessServer,
    accessServerFilter,
  ]);

  const rightContentManager = () => {
    if (arrayFilters === labels.date_filter) {
      setDateFilterFunction();
    }
    if (arrayFilters === labels.status_filter) {
      setStatusFilterFunction();
    }
    if (arrayFilters === labels.access_point_filter) {
      setAccessServerFilterFunction();
    }
  };

  React.useEffect(() => {
    if (secUserTransactionsBackUp) {
      setSecUserTotalPages(
        Math.ceil(secUserTransactionsBackUp?.length / secUserRowsPerPage)
      );
    }
  }, [secUserTransactionsBackUp, secUserRowsPerPage]);

  // END POINT TRANSACTIONS LIST--------------------------------------------------------------------------->>
  // END POINT LISTA DE TRANSACCIONES---------------------------------------------------------------------->>
  React.useEffect(() => {
    getLogbookListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    page,
    rowsPerPage,
    date,
    accessServer,
    searchInput,
    statusFilter,
    user?.last_access_selected,
    // handleChangeAccessGuard,
  ]);

  const getLogbookListData = async () => {
    try {
      if (userRole === "secondary_host") {
        if (token) {
          setIsLoading(true);
          const result = await api.logbookRequestTwo(
            1,
            500,
            date !== null ? moment(date).format("YYYY-MM-DD") : "",
            accessServer !== null ? accessServer : "",
            searchInput,
            statusFilter !== null ? statusFilter : ""
          );
          const filteredResult = result.transactions.filter(
            (item) => item?.user?.name === user?.name
          );
          setSecUserTransactions(filteredResult.slice(0, secUserRowsPerPage));
          setSecUserTransactionsBackUp(filteredResult);
          setSecUserTotalPages(
            Math.round(filteredResult.length / secUserRowsPerPage)
          );
        }
      }
      if (userRole !== "secondary_host") {
        if (token) {
          setIsLoading(true);
          const result = await api.logbookRequestTwo(
            page,
            rowsPerPage,
            date !== null ? moment(date).format("YYYY-MM-DD") : "",
            accessServer !== null ? accessServer : "",
            searchInput,
            statusFilter !== null ? statusFilter : ""
          );
          setLogbookList(result);
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // END POINT TRANSACTIONS LIST--------------------------------------------------------------------------->>
  // END POINT LISTA DE TRANSACCIONES---------------------------------------------------------------------->>
  React.useEffect(() => {
    getLogbookListForFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    date,
    accessServer,
    searchInput,
    statusFilter,
    user?.last_access_selected,
  ]);

  const getLogbookListForFile = async () => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.logbookRequestTwo(
          1,
          100,
          date !== null ? moment(date).format("YYYY-MM-DD") : "",
          accessServer !== null ? accessServer : "",
          searchInput,
          statusFilter !== null ? statusFilter : ""
        );
        setFileArray(
          result.transactions.map(
            ({
              user,
              transaction_photos,
              created_at,
              access_type,
              access_server,
              visitor_name,
              access_key_type,
              access_allowed,
            }) => ({
              fecha_hora: moment(created_at).format("ddd, D MMM YY - H:mma"),
              evento: access_type === "exit" ? "salida" : "entrada",
              tipo_de_acceso:
                access_allowed === true ? "autorizado" : "no autorizado",
              punto_de_acceso: access_server?.name,
              nombre_del_visitante: visitor_name,
              anfitrión: user?.name,
              departamento: user?.group,
              tipo_de_registro: access_key_type,
              fotos: transaction_photos.map(({ url }) => url).join(", "),
            })
          )
        );
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // END POINT GUESTS INSIDE ACCESS SERVER----------------------------------------------------------------->>
  // END POINT INVITADOS DENTRO DEL ACCESS SERVER---------------------------------------------------------->>
  React.useEffect(() => {
    getAccessServerGuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessServer, token]);

  const getAccessServerGuests = async () => {
    try {
      if (accessServer !== null) {
        // setIsLoading(true);
        const result = await api.getGuestsInsideAccessServer(
          Number(accessServer)
        );
        setGuestsByAccessServer(result);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
    // finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <>
      <Container className={!showFilters ? "padding-out" : "padding-in"}>
        <MainPagesHeader
          newIcon={userRole === "guard" ? true : false}
          onPressNewIconAction={() => navigate(`/register`)}
          mainTitle="logbook"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={true}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          downloadable={userRole === "guard" ? false : true}
          fileArray={fileArray ? fileArray : []}
          fileName=""
        />

        <Filters
          show={showFilters}
          array={filtersArray}
          value={arrayFilters}
          setValue={setArrayFilters}
          rightContent={filterContent}
        />

        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {userRole === "secondary_host" ? (
          <>
            {isLoading ? (
              <div
                className="flex justify-content-center align-items-center w-100"
                style={{ marginTop: 100 }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                className="w-100 flex flex-dir-col justify-content-center"
                style={{ paddingBottom: secUserTotalPages ? 200 : 74 }}
              >
                {currentItems && currentItems?.length > 0 ? (
                  currentItems.map(
                    ({
                      access_allowed,
                      access_granted,
                      access_key_type,
                      access_type,
                      created_at,
                      related_transaction_id,
                      visitor_name,
                      user,
                      id,
                    }) => (
                      <div
                        key={id}
                        className="w-100 flex justify-content-center mt-5"
                      >
                        <TransactionsList
                          transactionDate={moment(created_at).format("DD MMM")}
                          transactionHour={moment(created_at).format("hh:mma")}
                          accessAllowed={access_allowed}
                          accessGranted={access_granted}
                          type={access_type}
                          relatedTransaction={related_transaction_id}
                          userName={user?.name}
                          department={user?.group}
                          visitorName={visitor_name}
                          accessKeyType={access_key_type}
                          onPress={() => navigate(`/transaction-detail/${id}`)}
                        />
                      </div>
                    )
                  )
                ) : (
                  <div className="w-100 flex justify-content-center">
                    <p className="font-16 subtitle-color">
                      {labels.no_results_found}
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <div
                className="flex justify-content-center align-items-center w-100"
                style={{ marginTop: 100 }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                className="w-100 flex flex-dir-col justify-content-center"
                style={{ paddingBottom: logbookList?.total_pages ? 200 : 74 }}
              >
                {userRole === "guard" && accessServer !== null && (
                  <>
                    <p className="font-16 subtitle-color medium ml-4">
                      {labels.guests_by_access_server}:{" "}
                      {guestsByAccessServer?.guests}
                    </p>
                    <Divider />
                  </>
                )}
                {logbookList && logbookList?.transactions?.length > 0 ? (
                  logbookList?.transactions.map(
                    ({
                      access_allowed,
                      access_granted,
                      access_key_type,
                      access_type,
                      created_at,
                      related_transaction_id,
                      visitor_name,
                      user,
                      id,
                    }) => (
                      <div
                        key={id}
                        className="w-100 flex justify-content-center mt-5"
                      >
                        <TransactionsList
                          transactionDate={moment(created_at).format("DD MMM")}
                          transactionHour={moment(created_at).format("hh:mma")}
                          accessAllowed={access_allowed}
                          accessGranted={access_granted}
                          type={access_type}
                          relatedTransaction={related_transaction_id}
                          userName={user?.name}
                          department={user?.group}
                          visitorName={visitor_name}
                          accessKeyType={access_key_type}
                          onPress={() => navigate(`/transaction-detail/${id}`)}
                        />
                      </div>
                    )
                  )
                ) : (
                  <div className="w-100 flex justify-content-center">
                    <p className="font-16 subtitle-color">
                      {labels.no_results_found}
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Container>
      {userRole === "secondary_host" ? (
        <>
          {secUserTotalPages ? (
            <Pagination
              rowsPerPage={secUserRowsPerPage}
              setRowsPerPage={setSecUserRowsPerPage}
              page={secUserPage}
              setPage={setSecUserPage}
              totalPages={secUserTotalPages}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {logbookList?.total_pages ? (
            <Pagination
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalPages={logbookList?.total_pages}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default LogbookRefactor;
