import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Button,
  TextField,
  Checkbox,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken } from "../../slices/token";
import api, {
  AddEventGuestsParams,
  CommunityMembers,
  CommunityMembersList,
} from "../../api";
import { showError } from "../../utils/helper";
import { Box } from "@mui/system";
import { resetUser, selectUser } from "../../slices/user";
import CloseIcon from "@mui/icons-material/Close";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CreateIcon from "@mui/icons-material/Create";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

interface AddEventGuestProps {
  invId: number;
  actionReopen: () => void;
}

const AddEventGuest = ({ invId, actionReopen }: AddEventGuestProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [searchCommunityMember, setSearchCommunityMember] =
    React.useState<boolean>(false);
  const [addName, setAddName] = React.useState<string>("");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("+52");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [communityMembersList, setCommunityMembersList] = React.useState<
    string[]
  >([]);
  const [communityMember, setCommunityMember] = React.useState<string | null>(
    ""
  );
  const [saveCommunityMember, setSaveCommunityMember] =
    React.useState<boolean>(false);
  const [communityMembers, setCommunityMembers] =
    React.useState<CommunityMembersList | null>(null);
  const [filteredCommunityMemberName, setFilteredCommunityMemberName] =
    React.useState<string | null>("");
  const [
    filteredCommunityMemberPhoneCode,
    setFilteredCommunityMemberPhoneCode,
  ] = React.useState<string | null>("+52");
  const [filteredCommunityMemberPhone, setFilteredCommunityMemberPhone] =
    React.useState<string | null>("");
  const [filteredCommunityMemberEmail, setFilteredCommunityMemberEmail] =
    React.useState<string | null>("");
  const [filteredCommunityMemberId, setFilteredCommunityMemberId] =
    React.useState<Number | null>(null);
  const [communityMemberPhone, setCommunityMemberPhone] =
    React.useState<string>("");

  const networkName = user?.network_group?.name;

  const handleSaveCommunityMemberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!checked) {
      setSaveCommunityMember(true);
    } else {
      setSaveCommunityMember(false);
    }
    setChecked(event.target.checked);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSearchCommunityMember(false);
    setCommunityMember("");
    setAddName("");
    setAddEmail("");
    setAddPhone("");
    setCountryCode("+52");
  };

  React.useEffect(() => {
    if (networkName) {
      getCommunityMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkName]);

  const getCommunityMembers = async () => {
    if (networkName) {
      try {
        const result = await api.getCommunityMembersList(networkName);
        const cmName = Array.from(
          new Set(result.community_members.map(({ name }) => name))
        );
        setCommunityMembers(result);
        setCommunityMembersList(cmName);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.user_no_access);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  React.useEffect(() => {
    getCommunityMemberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityMember]);

  const getCommunityMemberData = () => {
    if (communityMember !== null) {
      const filteredCommunityMemberInv: CommunityMembers[] | undefined =
        communityMembers?.community_members.filter(
          ({ name }) => name === communityMember
        );
      if (filteredCommunityMemberInv) {
        setFilteredCommunityMemberId(filteredCommunityMemberInv[0]?.id);
        setFilteredCommunityMemberName(filteredCommunityMemberInv[0]?.name);
        setFilteredCommunityMemberEmail(filteredCommunityMemberInv[0]?.email);
        setFilteredCommunityMemberId(filteredCommunityMemberInv[0]?.id);
        setCommunityMemberPhone(filteredCommunityMemberInv[0]?.phone);
        if (filteredCommunityMemberInv[0]?.phone.startsWith("+52")) {
          setFilteredCommunityMemberPhone(
            filteredCommunityMemberInv[0]?.phone.slice(3)
          );
          setFilteredCommunityMemberPhoneCode("+52");
        } else {
          setFilteredCommunityMemberPhone(
            filteredCommunityMemberInv[0]?.phone.slice(2)
          );
          setFilteredCommunityMemberPhoneCode("+1");
        }
      }
    } else {
      setFilteredCommunityMemberName("");
      setFilteredCommunityMemberEmail("");
      setFilteredCommunityMemberPhone("");
      setFilteredCommunityMemberPhoneCode("+52");
      setFilteredCommunityMemberId(null);
    }
  };

  const finalPhone = addPhone ? countryCode + addPhone : "";

  const addNewGuest = async () => {
    const payload: AddEventGuestsParams = {
      guests: communityMember
        ? [
            {
              id: Number(filteredCommunityMemberId),
              community_member: true,
              phone: communityMemberPhone,
              name: `${filteredCommunityMemberName}`,
              email: `${filteredCommunityMemberEmail}`,
              save: saveCommunityMember,
            },
          ]
        : [
            {
              community_member: false,
              phone: finalPhone,
              name: addName,
              email: addEmail,
              save: saveCommunityMember,
            },
          ],
    };
    try {
      setIsLoading(true);
      await api.addEventGuestRequest(invId, payload);
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        if (
          error.response.data.error.message ===
          "This email belongs to another account"
        ) {
          showError(dispatch, labels.this_email_belongs_to_another_account);
        } else {
          showError(dispatch, labels.wrong_parameters);
        }
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
      handleClose();
      actionReopen();
    }
  };

  return (
    <React.Fragment>
      {/* <IconButton
        onClick={handleOpen}
        size="small"
        style={{ backgroundColor: "#10418a" }}
      >
        <AddRoundedIcon style={{ fontSize: 22, color: "white" }} />
      </IconButton>
      <Modal disableEscapeKeyDown open={open}>
        <Box sx={style}> */}
      {/* <Grid container>
            <Grid
              item
              xs={12}
              className="flex justify-content-space-between align-items-center m-0 p-0 mb-4"
            >
              <div>
                <p className="uppercase ml-5 font-14 title-color">
                  {labels.add_guest}
                </p>
              </div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <div className="w-100 m-0 divider-div-style" />
          </Grid> */}
      <div className="flex justify-content-center mt-4">
        <div className="w-100 my-2">
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 my-5">{labels.visitor_data}</p>
            {searchCommunityMember ? (
              <div
                onClick={() => {
                  setSearchCommunityMember(false);
                  setCommunityMember("");
                  setAddName("");
                  setAddEmail("");
                  setAddPhone("");
                  setCountryCode("+52");
                }}
                className="m-0 p-0 flex justify-content-center align-items-center button-style"
              >
                <CreateIcon
                  color="primary"
                  sx={{ fontSize: 35 }}
                  className="p-0 m-0"
                />
                <div className="flex flex-dir-col pr-3">
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.new}
                  </p>
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.register_button}
                  </p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setSearchCommunityMember(true)}
                className="m-0 p-0 flex justify-content-center align-items-center button-style"
              >
                <SearchRoundedIcon
                  color="primary"
                  sx={{ fontSize: 35 }}
                  className="p-0 m-0"
                />
                <div className="flex flex-dir-col pr-3">
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.search_in}
                  </p>
                  <p className="text-center p-0 m-0 font-12 text-primary-color">
                    {labels.favorites}
                  </p>
                </div>
              </div>
            )}
          </div>
          {searchCommunityMember ? (
            <div className="m-0 p-0 mb-6">
              <Autocomplete
                disablePortal
                fullWidth
                id="x"
                options={communityMembersList}
                onChange={(event: any, newValue: string | null) => {
                  setCommunityMember(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={communityMember}
                    onChange={(e) => {
                      setCommunityMember(e.target.value);
                    }}
                    label={labels.add_favourite}
                  />
                )}
                style={{ zIndex: 101 }}
              />
              <Grid container columnSpacing={1}>
                <Grid item xs={12} className="my-3">
                  <TextField
                    value={""}
                    label={
                      filteredCommunityMemberName
                        ? filteredCommunityMemberName
                        : labels.name
                    }
                    fullWidth
                    disabled
                    onChange={(e) => {
                      setAddName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth className="mt-3">
                    <Select
                      value={
                        communityMember
                          ? filteredCommunityMemberPhoneCode
                            ? filteredCommunityMemberPhoneCode
                            : "+52"
                          : "+52"
                      }
                      disabled
                      inputProps={{ "aria-label": "Whithout label" }}
                    >
                      <MenuItem value={"+52"}>MX +52</MenuItem>
                      <MenuItem value={"+1"}>US +1</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} className="my-3">
                  <TextField
                    value={""}
                    label={
                      filteredCommunityMemberPhone
                        ? filteredCommunityMemberPhone
                        : labels.phone_number
                    }
                    disabled
                    fullWidth
                    onChange={(e) => {
                      setAddPhone(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="my-3">
                  <TextField
                    value={""}
                    label={
                      communityMember
                        ? filteredCommunityMemberEmail
                          ? filteredCommunityMemberEmail
                          : labels.email_not_registered
                        : labels.email
                    }
                    disabled
                    fullWidth
                    onChange={(e) => {
                      setAddEmail(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} className="my-3">
                  <TextField
                    value={addName}
                    label={labels.name}
                    fullWidth
                    onChange={(e) => {
                      setAddName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth className="mt-3">
                    <Select
                      value={countryCode}
                      onChange={(e) => {
                        setCountryCode(e.target.value);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                    >
                      <MenuItem value={"+52"}>MX +52</MenuItem>
                      <MenuItem value={"+1"}>US +1</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} className="my-3">
                  <TextField
                    value={addPhone}
                    label={labels.phone_number}
                    fullWidth
                    onChange={(e) => {
                      setAddPhone(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="my-3">
                  <TextField
                    value={addEmail}
                    label={labels.email}
                    fullWidth
                    onChange={(e) => {
                      setAddEmail(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <>
                <div className="flex align-items-center mt-3">
                  <Checkbox
                    checked={checked}
                    value={saveCommunityMember}
                    onChange={handleSaveCommunityMemberChange}
                  />
                  <p className="m-0 mt-0 mb-0 font-14 medium subtitle-color">
                    {labels.save_to_my_favorites}
                  </p>
                </div>
              </>
            </div>
          )}
          <div className="w-100 m-0 p-0 my-4">
            <Button
              fullWidth
              variant="contained"
              size="large"
              disableElevation
              color="primary"
              className="py-5 add-guest-button-style"
              startIcon={isLoading ? <></> : <AddRoundedIcon />}
              onClick={
                isLoading
                  ? () => {}
                  : () => {
                      addNewGuest();
                    }
              }
            >
              {isLoading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                labels.add_guest
              )}
            </Button>
          </div>
        </div>
      </div>
      {/* </Box>
      </Modal> */}
    </React.Fragment>
  );
};

export default AddEventGuest;
