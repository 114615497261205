import React from "react";
import { useAppDispatch, useAppSelector, useWindowSize } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import "./qrscanner.scss";
import {
  Container,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Divider,
  InputLabel,
  Grid,
  Paper,
  Chip,
  IconButton,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { red, green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { selectUser, setUser } from "../../slices/user";
import api from "../../api";
import { showError } from "../../utils/helper";
import HelpIcon from "@mui/icons-material/Help";
import QrScannerHelpModal from "../../components/Modals/QrScannerHelpModal";

const QrScanner = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const { isMobile } = useWindowSize();

  const [accessPoint, setAccessPoint] = React.useState<string | undefined>(
    user?.last_access_selected?.id
      ? `${user?.last_access_selected?.id}`
      : undefined
  );

  console.log(user?.last_access_selected?.gates[0].id);
  console.log(user?.last_access_selected?.gates[0].name);

  const accessPointPhotoRequiered = () => {
    let requirePhotos = false;
    if (user?.last_access_selected) {
      const { settings } = user.last_access_selected;
      if (
        settings?.back_plates === true ||
        settings?.front_plates === true ||
        settings?.photo_id === true ||
        settings?.other === true
      ) {
        requirePhotos = true;
      }
    }
    return requirePhotos;
  };

  const handleAccessPointChange = async (event: SelectChangeEvent) => {
    try {
      setAccessPoint(event.target.value);
      const newUserData = await api.changeAccessServerRequest({
        access_server: Number(event.target.value),
      });
      dispatch(setUser(newUserData));
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  const navigate = useNavigate();

  return (
    <Container>
      {/* <div className="container-1 w-100">
        <Grid container>
          <Grid item xs={12} className="flex align-items-center my-6 pt-5">
            <p className="font font-24 font-w-400 title-color m-0 p-0 mr-4">
              {labels.visitor_control}
            </p>
            <QrScannerHelpModal />
          </Grid>
        </Grid>
      </div> */}
      <div className="w-100">
        <Grid container className="flex align-items-center pt-5">
          <Grid item xs={12} md={6} className="flex align-items-center">
            <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
              {labels.visitor_control}
            </p>
            <QrScannerHelpModal />
          </Grid>
        </Grid>
        <Divider />
      </div>

      <div className=" w-100 h-100">
        {/* <Grid container>
          {!isMobile && <Grid item md={8} />}
          <Grid item xs={12}>
            <FormControl className="w-100">
              <InputLabel id="access-point-label">
                {labels.access_point}
              </InputLabel>
              <Select
                labelId="access-point-label"
                value={accessPoint}
                onChange={handleAccessPointChange}
                label={labels.access_point}
              >
                {user?.access_servers.map(({ name, id }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid> */}
        <Grid container className="flex justify-content-center">
          <Grid item xs={12}>
            <p
              className="subtitle-color font-20 m-0 ml-5 my-5"
              // className={
              //   isMobile
              //     ? "subtitle-color font-20 m-0 ml-5 my-5"
              //     : "subtitle-color font-24 m-0 ml-5 my-5"
              // }
            >
              {labels.select_a_door}
            </p>
          </Grid>
          {/* <Grid item xs={12}>
            <Divider>
              <Chip label={labels.qr_code_reader} />
            </Divider>
          </Grid> */}
          <div className=" flex flex-dir-col  background-border-grey w-90 py-4 mt-3">
            {user?.last_access_selected?.gates.map(({ name, type, id }) => (
              <Grid
                item
                key={id}
                xs={12}
                className="flex justify-content-center my-9"
              >
                <Paper
                  elevation={1}
                  sx={{
                    borderRadius: 2,
                    ":hover": {
                      boxShadow: "inset 0 0 0 6px #dfdfdf",
                    },
                  }}
                  className="button-paper-style w-70 clickable py-6"
                  onClick={() => navigate(`/qr-scanner/camera/${id}`)}
                >
                  <Grid container className="flex align-items-center">
                    <Grid item xs={7}>
                      <div className="button-paper-style flex flex-dir-col justify-content-center pl-10">
                        <div className="flex w-100">
                          <div className="mr-8">
                            {type === "access" ? (
                              <LoginIcon
                                sx={{ color: green[900], fontSize: 25 }}
                                // sx={
                                //   isMobile
                                //     ? { color: green[900], fontSize: 35 }
                                //     : { color: green[900], fontSize: 60 }
                                // }
                              />
                            ) : (
                              <LogoutIcon
                                sx={{ color: red[900], fontSize: 25 }}
                                // sx={
                                //   isMobile
                                //     ? { color: red[900], fontSize: 35 }
                                //     : { color: red[900], fontSize: 60 }
                                // }
                              />
                            )}
                          </div>
                          <div>
                            {type === "access" && accessPointPhotoRequiered() && (
                              <CameraAltIcon
                                sx={{ fontSize: 25 }}
                                // sx={
                                //   isMobile ? { fontSize: 35 } : { fontSize: 60 }
                                // }
                              />
                            )}
                          </div>
                        </div>
                        <p className="w-100 paper-text-mobile">{name}</p>
                        {/* {isMobile ? (
                        <p className="w-100 paper-text-mobile">{name}</p>
                      ) : (
                        <p className="w-100 paper-text">{name}</p>
                      )} */}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div className="button-paper-style flex justify-content-center align-items-center">
                        <QrCodeScannerIcon sx={{ fontSize: 60 }} />
                        {/* {isMobile ? (
                        <QrCodeScannerIcon sx={{ fontSize: 80 }} />
                      ) : (
                        <QrCodeScannerIcon sx={{ fontSize: 120 }} />
                      )} */}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </div>
        </Grid>
      </div>
      <div className="lower-div root-background" />
    </Container>
  );
};
export default QrScanner;
