// import { ReactNode } from "hoist-non-react-statics/node_modules/@types/react";
import React from "react";
import { useWindowSize } from "../../app/hooks";
import MobileMenu from "../MobileMenu";
import ProfileMenu from "../ProfileMenu";
import SideMenu from "../SideMenu";
import "./menu-container.scss";
import MobileLowerMenu from "../MobileLowerMenu";

interface Props {
  children: any;
  goToLatestVersion: () => void;
}

const MenuContainer = ({ children, goToLatestVersion }: Props) => {
  // const { isMobile } = useWindowSize();
  // if (isMobile) {
  //   return (
  //     <>
  //       <MobileMenu />
  //       <div className="mobile-container">{children}</div>
  //       <MobileLowerMenu />
  //     </>
  //   );
  // }
  // return (
  //   <div className="flex">
  //     <SideMenu />
  //     <div className="content-container">
  //       <ProfileMenu />
  //       {children}
  //     </div>
  //   </div>
  // );

  console.log("children", children);
  return (
    <>
      <MobileMenu goToLatestVersion={goToLatestVersion} />
      <div className="mobile-container">{children}</div>
      <MobileLowerMenu />
    </>
  );
};

export default MenuContainer;
