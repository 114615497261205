import React from "react";
import AdminDesktopHeader from "../AdminDesktopHeader";
import SearchInputOutlined from "../SearchInputOutlined/SearchInputOutlined";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { resetToken, selectToken } from "../../slices/token";
import { showError } from "../../utils/helper";
import { selectLabels } from "../../slices/language";
import { resetUser } from "../../slices/user";
import api, { GetCommunityMembers } from "../../api";
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";

interface FileToExport {
  nombre: string;
  teléfono: string;
  correo_electronico: string;
  usuario: string;
}

const AdminDesktopCommunityMembers = () => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [communityMembersList, setCommunityMembersList] =
    React.useState<GetCommunityMembers | null>(null);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const debouncedSearchText = useDebounce(searchInput, 500);

  React.useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add = tableHeaderHeight + tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
  }, []);

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  React.useEffect(() => {
    getCMData(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, debouncedSearchText]);

  const getCMData = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.communityMembersRequest(
          page,
          rowsPerPage,
          debouncedSearchText
        );
        setCommunityMembersList(result);
        setFileArray(
          result.community_members.map(({ email, name, id, phone, user }) => ({
            nombre: name,
            teléfono: phone,
            correo_electronico: email,
            usuario: user.name,
          }))
        );
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
        // } else {
        //   showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <AdminDesktopHeader
        title={"Visitantes registrados"}
        buttonLabel={"NUEVA INVITACION"}
        refObject={headerRef}
      />
      <div
        ref={filtersRef}
        style={{
          width: "100%",
          boxSizing: "border-box",
          minHeight: 60,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: "35%" }}>
          <SearchInputOutlined
            value={searchInput}
            setValue={setSearchInput}
            size="small"
          />
        </div>
        <div style={{ marginRight: 6, marginLeft: 20 }}>
          {fileArray && fileArray?.length > 0 ? (
            <CSVLink data={fileArray}>
              <FaFileDownload
                fontSize={32}
                color="#10418a"
                style={{ cursor: "pointer" }}
              />
            </CSVLink>
          ) : (
            <FaFileDownload fontSize={32} color="#999999" />
          )}
        </div>
      </div>
      <div
        style={{
          height: `calc(100% - ${mainContentHeight}px - 25px)`,
          backgroundColor: "white",
          boxSizing: "border-box",
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 4,
          position: "relative",
          boxShadow:
            "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          overflow: "auto",
        }}
      >
        <div ref={tableHeader} style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                height: "auto",
                backgroundColor: "#e8e8e8",
              }}
            >
              <tr>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "25%",
                  }}
                >
                  {labels.name}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "25%",
                  }}
                >
                  {labels.phone}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "25%",
                  }}
                >
                  {labels.email}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "25%",
                  }}
                >
                  {labels.created_by}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containersHeight}px)`,
            overflowY: "auto",
            backgroundColor: "#f8f8f8",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table style={{ width: "100%" }}>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
              >
                {communityMembersList?.community_members &&
                  communityMembersList.community_members.length > 0 &&
                  communityMembersList.community_members.map(
                    ({ id, name, email, phone, user }) => (
                      <tr key={id}>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "25%",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "25%",
                            textAlign: "center",
                          }}
                        >
                          {phone ? (
                            <p>{phone}</p>
                          ) : (
                            <p style={{ color: "#999999" }}>
                              {labels.no_phone}
                            </p>
                          )}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "25%",
                          }}
                        >
                          {email ? (
                            <p>{email}</p>
                          ) : (
                            <p style={{ color: "#999999" }}>
                              {labels.no_email}
                            </p>
                          )}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "25%",
                          }}
                        >
                          {user.name}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          )}
        </div>
        <div
          ref={tableFooter}
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#e8e8e8",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex align-items-center ml-8 mr-4">
            <p className="pr-4 primary-color">{labels.rows}</p>
            <FormControl
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                width: 100,
              }}
            >
              <Select
                value={rowsPerPage}
                style={{ color: "#10418a" }}
                size="small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div>
          {communityMembersList && (
            <div className="flex" style={{ alignItems: "center" }}>
              <div className="flex mx-2">
                <p className="primary-color">
                  {labels.page} <span className="primary-color">{page}</span>{" "}
                  {labels.of}{" "}
                  <span className="primary-color">
                    {communityMembersList?.total_pages}
                  </span>
                </p>
              </div>
              <div className="mx-2">
                <IconButton
                  color="primary"
                  disabled={page <= 1}
                  onClick={handleChangeDownPage}
                >
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={page >= communityMembersList?.total_pages}
                  onClick={handleChangeUpPage}
                >
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDesktopCommunityMembers;
