import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  InputLabel,
  Switch,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate, useParams } from "react-router-dom";
import { resetToken, selectToken } from "../../slices/token";
import api, { EditUserParams, UsersByDepartment } from "../../api";
import { showError } from "../../utils/helper";
import {
  resetUser,
  selectUser,
  selectUserRole,
  setUser,
} from "../../slices/user";
import NotificationHelpModal from "../../components/Modals/NotificationHelpModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const EditUser = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUserRole);

  const [usersByDepartment, setUsersByDepartment] =
    React.useState<UsersByDepartment | null>(null);
  const [notifications, setNotifications] = React.useState<boolean>(false);

  const { uId } = useParams();

  const filteredUser = usersByDepartment?.users.filter(
    ({ id }) => id === Number(uId)
  );

  React.useEffect(() => {
    requestUsersByDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const requestUsersByDepartment = async () => {
    try {
      if (token) {
        const usersNetwork: any = await api.usersListRequest(
          `${user?.network_group?.name}`
        );
        setUsersByDepartment(usersNetwork);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.user_has_no_access_to_records);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  const userFinalPhoneMX =
    uId !== ":uId" &&
    filteredUser &&
    filteredUser[0]?.phone.length === 13 &&
    filteredUser[0]?.phone.slice(3);
  const userFinalPhoneUS =
    uId !== ":uId" &&
    filteredUser &&
    filteredUser[0]?.phone.length !== 13 &&
    filteredUser[0]?.phone.slice(2);

  const userFinalCodeMX =
    uId !== ":uId" &&
    filteredUser &&
    filteredUser[0]?.phone.length === 13 &&
    filteredUser[0]?.phone.slice(0, 3);
  const userFinalCodeUS =
    uId !== ":uId" &&
    filteredUser &&
    filteredUser[0]?.phone.length !== 13 &&
    filteredUser[0]?.phone.slice(0, 2);

  const [addName, setAddName] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("");
  const [addPhone, setAddPhone] = React.useState<string>("");
  const [addEmail, setAddEmail] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [addNetworkGroup, setAddNetworkGroup] = React.useState<string>("");
  const [addAccessServers, setAddAccessServers] = React.useState<string>("");
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [finalPhone, setFinalPhone] = React.useState<string>("");

  const userNameUnchanged = filteredUser && filteredUser[0]?.name;
  const userPhoneUnchanged = filteredUser && filteredUser[0]?.phone;
  const userEmailUnchanged = filteredUser && filteredUser[0]?.email;

  React.useEffect(() => {
    finalPhoneChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPhone, countryCode]);

  const finalPhoneChange = () => {
    if (countryCode) {
      if (!addPhone) {
        if (filteredUser && filteredUser[0]?.phone.length === 13) {
          setFinalPhone(countryCode + userFinalPhoneMX);
        } else {
          setFinalPhone(countryCode + userFinalPhoneUS);
        }
      } else {
        setFinalPhone(countryCode + addPhone);
      }
    } else if (addPhone) {
      if (!countryCode) {
        if (filteredUser && filteredUser[0]?.phone.length === 13) {
          setFinalPhone(userFinalCodeMX + addPhone);
        } else {
          setFinalPhone(userFinalCodeUS + addPhone);
        }
      } else {
        setFinalPhone(countryCode + addPhone);
      }
    }
  };

  React.useEffect(() => {
    checkEmailNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkEmailNotifications = () => {
    if (user) {
      setNotifications(user?.email_notification);
    } else {
      setNotifications(false);
    }
  };

  const handleChangeNotifications = () => {
    setNotifications(!notifications);
  };

  const EditNetworkUser = async () => {
    const payload: EditUserParams = {
      user: {
        name: addName !== "" ? `${addName}` : `${userNameUnchanged}`,
        // email: addEmail !== "" ? `${addEmail}` : `${userEmailUnchanged}`,
        // email: `${userEmailUnchanged}`,
        phone: addPhone || countryCode ? finalPhone : `${userPhoneUnchanged}`,
        email_notification: notifications,
      },
    };
    try {
      setIsLoading(true);
      await api.editUser(Number(uId), payload);
      if (addName) {
        dispatch(setUser({ ...user, name: addName }));
      }
      // if (addEmail) {
      //   dispatch(setUser({ ...user, email: addEmail }));
      // }
      if (addPhone || countryCode) {
        dispatch(setUser({ ...user, phone: finalPhone }));
      }
      dispatch(setUser({ ...user, email_notification: notifications }));
      setShowSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.unauthorizaed_user);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.network_not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    if (userRole === "secondary_host") {
      navigate("/invitations");
    }
    if (userRole !== "secondary_host") {
      navigate("/my-users");
    }
  };

  return (
    <Container>
      <div className="w-100">
        <Grid container className="flex align-items-center pt-5">
          <Grid
            item
            xs={12}
            md={12}
            className="flex align-items-center justify-content-space-between"
          >
            <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
              {labels.edit_user}
            </p>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <div className="flex justify-content-center mb-0">
        <Paper className="w-100 mt-5" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.new_user_data}
            </p>
          </div>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} className="my-3">
              <TextField
                value={addName}
                label={filteredUser && filteredUser[0]?.name}
                fullWidth
                onChange={(e) => {
                  setAddName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth className="mt-3">
                <InputLabel id="phone code">
                  {filteredUser && filteredUser[0]?.phone.length === 13
                    ? "MX " + userFinalCodeMX
                    : "US " + userFinalCodeUS}
                </InputLabel>
                <Select
                  value={countryCode}
                  label={
                    filteredUser && filteredUser[0]?.phone.length === 13
                      ? "MX " + userFinalCodeMX
                      : "US " + userFinalCodeUS
                  }
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                >
                  <MenuItem value={"+52"}>MX +52</MenuItem>
                  <MenuItem value={"+1"}>US +1</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7} className="my-3">
              <TextField
                value={addPhone}
                label={
                  filteredUser && filteredUser[0]?.phone.length === 13
                    ? userFinalPhoneMX
                    : userFinalPhoneUS
                }
                fullWidth
                onChange={(e) => {
                  setAddPhone(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      {userRole !== "guard" && (
        <div className="flex justify-content-center mt-0">
          <Paper className="w-100 mt-5 mb-2" sx={{ padding: "14px" }}>
            <div className="flex align-items-center justify-content-space-between">
              <div className="flex align-items-center">
                <p className="text-primary-color medium m-0 p-0 ml-3 mr-4 uppercase">
                  {labels.notifications}
                </p>
                <NotificationHelpModal />
              </div>
              <Switch
                checked={notifications}
                onChange={handleChangeNotifications}
              />
            </div>
          </Paper>
        </div>
      )}
      <Divider className="w-100 my-4" />
      <div className="w-100 flex justify-content-space-evenly mt-8">
        <Button
          variant="contained"
          className="py-5"
          fullWidth
          disableElevation
          size="large"
          onClick={EditNetworkUser}
        >
          {labels.save}
        </Button>
      </div>

      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {labels.success_changes}
            </p>
            <p className="title-color font-16 my-7 text-center">
              {labels.update_user_text}
            </p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setShowSuccessModal(false);
                setTimeout(() => onClose(), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default EditUser;
