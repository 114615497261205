import ApartmentIcon from "@mui/icons-material/Apartment";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import NoteAltRoundedIcon from "@mui/icons-material/NoteAltRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {
  FaEnvelopeOpenText,
  FaUsers,
  FaBook,
  FaHouseUser,
  FaClipboard,
  FaQrcode,
} from "react-icons/fa";

export const MENU_ITEMS = (labels: any, userRole: string) => [
  {
    label: labels.qr_scanner,
    icon: FaQrcode,
    path: "/qr-scanner",
    hide: userRole !== "guard",
  },
  {
    label: labels.register,
    icon: FaClipboard,
    path: "/register",
    hide: userRole !== "guard",
  },
  {
    label: labels.invitations,
    icon: FaEnvelopeOpenText,
    path: "/invitations",
    // hide: userRole === "admin",
  },
  {
    label: labels.logbook,
    icon: FaBook,
    path: "/logbook",
    // hide: userRole === "admin",
  },
  // {
  //   label: labels.logbook,
  //   icon: EventNoteRoundedIcon,
  //   path: "/link-admin-app",
  //   hide: userRole !== "admin"
  // },
  // {
  //   label: labels.access_points,
  //   icon: ApartmentIcon,
  //   path: "/access-points",
  //   hide: userRole !== "admin",
  // },
  {
    label: labels.events_label,
    icon: FaUsers,
    path: "/events",
    // hide: userRole === "admin" || userRole === "guard",
    hide:userRole === "guard",
  },
  {
    label: labels.my_users,
    icon: FaHouseUser,
    path: "/my-users",
    hide: userRole !== "host",
  },
];
