import React from "react";
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
import { Box, Modal } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 600,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  outline: 0,
  border: 0,
};

interface MapContentProps {
  showComponent: boolean;
  setShowComponent: (showComponent: boolean) => void;
  latitude: number;
  longitude: number;
  markerLabel: string;
}

const MapComponent = ({
  latitude,
  longitude,
  showComponent,
  setShowComponent,
  markerLabel,
}: MapContentProps) => {
  const labels = useAppSelector(selectLabels);
  const googleAPIKey = "AIzaSyCxuo6aFRHzt74w5hEeiHcTEpL40TzOaXk";

  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultZoom = 15;

  const defaultCenter = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <Modal disableEscapeKeyDown open={showComponent}>
      <Box sx={style}>
        <LoadScript googleMapsApiKey={googleAPIKey}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={defaultZoom}
            center={defaultCenter}
          >
            {/* Marcador en la ubicación predeterminada */}
            <Marker position={defaultCenter} />
          </GoogleMap>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              className="primary-color m-0 p-0 pt-7 pb-2 bold uppercase"
              style={{ cursor: "pointer" }}
              onClick={() => setShowComponent(false)}
            >
              {labels.close}
            </p>
          </div>
        </LoadScript>
      </Box>
    </Modal>
  );
};

export default MapComponent;
