import { CircularProgress, Container } from "@mui/material";
import React from "react";
import MainPagesHeader from "../../components/MainPagesHeader/MainPagesHeader";
import { useNavigate } from "react-router-dom";
import api, { AccessServersResponse } from "../../api";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectToken } from "../../slices/token";
import { selectLabels } from "../../slices/language";
import AccessPointsList from "../../components/AccessPointsList/AccessPointsList";
import Pagination from "../../components/Pagination/Pagination";

interface FileToExport {
  tipo_de_invitación: string;
  vigencia: string;
  nombre_del_visitante: string;
  anfitrión: string;
  departamento: string;
  estatus: string;
  puntos_de_acceso: string;
  eliminada: string;
}

const AccessPointsRefactor = () => {
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [accessServersList, setAccessServersList] =
    React.useState<AccessServersResponse | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const debouncedSearchText = useDebounce(searchInput, 500);

  React.useEffect(() => {
    getAccessServers(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, debouncedSearchText, page, rowsPerPage]);

  const getAccessServers = async (debouncedSearchText: string) => {
    try {
      setIsLoading(true);
      const response = await api.getAccessServers(
        debouncedSearchText,
        null,
        page,
        rowsPerPage
      );
      setAccessServersList(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        <MainPagesHeader
          newIcon={false}
          onPressNewIconAction={() => {}}
          mainTitle="access_points"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={false}
          showFilters={false}
          setShowFilters={() => {}}
          downloadable={false}
          fileArray={fileArray ? fileArray : []}
          fileName=""
        />
        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {isLoading ? (
          <div
            className="flex justify-content-center align-items-center w-100"
            style={{
              position: "relative",
              top: 176,
              boxSizing: "border-box",
              paddingBottom: accessServersList?.total_pages ? 118 : 72,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className="w-100 flex flex-dir-col justify-content-center"
            style={{
              position: "relative",
              top: 116,
              boxSizing: "border-box",
              paddingBottom: accessServersList?.total_pages ? 118 : 72,
            }}
          >
            {accessServersList &&
            accessServersList?.access_servers?.length > 0 ? (
              accessServersList?.access_servers.map(
                ({ id, name, address, venue, gates }, index) => (
                  <div
                    key={id}
                    className="w-100 flex justify-content-center mt-5"
                    style={{
                      marginBottom:
                        accessServersList.access_servers.length === index + 1
                          ? 12
                          : 0,
                    }}
                  >
                    <AccessPointsList
                      onPress={() => {}}
                      name={name}
                      address={address}
                      venue={venue.name}
                      gates={gates.length}
                    />
                  </div>
                )
              )
            ) : (
              <div className="w-100 flex justify-content-center">
                <p className="font-16 subtitle-color">
                  {labels.no_results_found}
                </p>
              </div>
            )}
          </div>
        )}
      </Container>
      {accessServersList?.total_pages ? (
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={accessServersList?.total_pages}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AccessPointsRefactor;
