import { Button, Grid } from "@mui/material";
import React from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import PhotoModal from "../PhotoModal";
import { useAppSelector, useWindowSize } from "../../app/hooks";
import { selectUser } from "../../slices/user";
import { selectLabels } from "../../slices/language";
import { FaIdCard, FaCarAlt, FaUserAlt, FaLaptop } from "react-icons/fa";
import { MdSubtitles } from "react-icons/md";
import { BACK_ID_NETWORKS } from "../../utils/constants";
interface Props {
  idPhoto: string | null;
  setIdPhoto: (value: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (value: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (value: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (value: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (value: string | null) => void;
  desktopView?: boolean;
}

const PhotoButtons = ({
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  desktopView,
}: Props) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);

  const { isMobile } = useWindowSize();

  const [open, setOpen] = React.useState<
    "id" | "frontPlates" | "backPlates" | "other" | "accreditation" | null
  >(null);
  const [isForPrint, setIsForPrint] = React.useState<boolean>(false);

  const handleOpen = (
    photoType: "id" | "frontPlates" | "backPlates" | "other" | "accreditation"
  ) => setOpen(photoType);
  const handleClose = () => setOpen(null);

  return (
    <>
      <Grid
        container
        className={
          desktopView
            ? "flex justify-content-space-evenly align-items-center mt-4"
            : "flex justify-content-center background-border-grey py-6"
        }
      >
        {/* SHOW ID PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT--------------------->>*/}
        {/* MUESTRA BOTON DE FOTO ID SI ES REQUERIDA POR EL PUNTO DE ACCESO------------->>*/}
        {user?.last_access_selected?.settings.photo_id === true && (
          <Grid item xs={desktopView ? 2 : 10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !desktopView ? (
                  !idPhoto ? (
                    <CameraAltIcon />
                  ) : (
                    <CheckIcon sx={{ color: green[900] }} />
                  )
                ) : null
              }
              variant={!idPhoto ? "contained" : "outlined"}
              sx={desktopView ? {} : { padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("id");
              }}
              className="my-4"
            >
              {desktopView ? (
                <FaIdCard fontSize={24} />
              ) : (
                labels.identification_photo
              )}
            </Button>
          </Grid>
        )}

        {/* SHOW BACK ID PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT AND NETWORK 64----------------->>*/}
        {/* MUESTRA BOTON DE FOTO ID POSTERIOR SI ES REQUERIDA POR EL PUNTO DE ACCESO Y NETWORK 64------>>*/}
        {user?.network.id === BACK_ID_NETWORKS && (
          <>
            {user?.last_access_selected?.settings.back_plates === true && (
              <Grid item xs={desktopView ? 2 : 10}>
                <Button
                  fullWidth
                  disableElevation
                  startIcon={
                    !desktopView ? (
                      !backPlatesPhoto ? (
                        <CameraAltIcon />
                      ) : (
                        <CheckIcon sx={{ color: green[900] }} />
                      )
                    ) : null
                  }
                  variant={!backPlatesPhoto ? "contained" : "outlined"}
                  sx={desktopView ? {} : { padding: "16px 0px" }}
                  // sx={
                  //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
                  // }
                  onClick={() => {
                    handleOpen("backPlates");
                  }}
                  className="my-4"
                >
                  {desktopView ? (
                    <MdSubtitles fontSize={26} />
                  ) : (
                    labels.id_back_photo
                  )}
                </Button>
              </Grid>
            )}
          </>
        )}

        {/* SHOW CAR PLATES PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT------------->>*/}
        {/* MUESTRA BOTON DE FOTO PLACAS SI ES REQUERIDA POR EL PUNTO DE ACCESO--------->>*/}
        {user?.last_access_selected?.settings.front_plates === true && (
          <Grid item xs={desktopView ? 2 : 10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !desktopView ? (
                  !frontPlatesPhoto ? (
                    <CameraAltIcon />
                  ) : (
                    <CheckIcon sx={{ color: green[900] }} />
                  )
                ) : null
              }
              variant={!frontPlatesPhoto ? "contained" : "outlined"}
              sx={desktopView ? {} : { padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("frontPlates");
              }}
              className="my-4"
            >
              {desktopView ? <FaCarAlt fontSize={24} /> : labels.plate_photo}
            </Button>
          </Grid>
        )}

        {/* SHOW PERSON PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT----------------->>*/}
        {/* MUESTRA BOTON DE FOTO PERSONA SI ES REQUERIDA POR EL PUNTO DE ACCESO-------->>*/}
        {user?.network.id !== BACK_ID_NETWORKS && (
          <>
            {user?.last_access_selected?.settings.back_plates === true && (
              <Grid item xs={desktopView ? 2 : 10}>
                <Button
                  fullWidth
                  disableElevation
                  startIcon={
                    !desktopView ? (
                      !backPlatesPhoto ? (
                        <CameraAltIcon />
                      ) : (
                        <CheckIcon sx={{ color: green[900] }} />
                      )
                    ) : null
                  }
                  variant={!backPlatesPhoto ? "contained" : "outlined"}
                  sx={desktopView ? {} : { padding: "16px 0px" }}
                  // sx={
                  //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
                  // }
                  onClick={() => {
                    handleOpen("backPlates");
                  }}
                  className="my-4"
                >
                  {desktopView ? (
                    <FaUserAlt fontSize={24} />
                  ) : (
                    labels.personal_photo
                  )}
                </Button>
              </Grid>
            )}
          </>
        )}

        {/* SHOW EQUIPMENT PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT-------------->>*/}
        {/* MUESTRA BOTON DE FOTO EQUIPO SI ES REQUERIDA POR EL PUNTO DE ACCESO--------->>*/}
        {user?.last_access_selected?.settings.other === true && (
          <Grid item xs={desktopView ? 2 : 10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !desktopView ? (
                  !otherPhoto ? (
                    <CameraAltIcon />
                  ) : (
                    <CheckIcon sx={{ color: green[900] }} />
                  )
                ) : null
              }
              variant={!otherPhoto ? "contained" : "outlined"}
              sx={desktopView ? {} : { padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("other");
              }}
              className="my-4"
            >
              {desktopView ? (
                <FaLaptop fontSize={24} />
              ) : (
                labels.equipment_photo
              )}
            </Button>
          </Grid>
        )}

        {/* SHOW ACCREDITATION PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT---------------->>*/}
        {/* MUESTRA BOTON DE FOTO ACREDITACION SI ES REQUERIDA POR EL PUNTO DE ACCESO--------->>*/}
        {user?.last_access_selected?.settings.accreditation === true && (
          <Grid item xs={desktopView ? 2 : 10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !desktopView ? (
                  !accreditationPhoto ? (
                    <CameraAltIcon />
                  ) : (
                    <CheckIcon sx={{ color: green[900] }} />
                  )
                ) : null
              }
              variant={!accreditationPhoto ? "contained" : "outlined"}
              sx={desktopView ? {} : { padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("accreditation");
                setIsForPrint(true);
              }}
              className="my-4"
            >
              {desktopView ? (
                <FaUserAlt fontSize={24} />
              ) : (
                labels.accreditation_photo
              )}
            </Button>
          </Grid>
        )}
      </Grid>

      {/* COMPONENT - PHOTO RECORD MODAL------------------------------------------>>*/}
      {/* COMPONENTE - MODAL REGISTRO FOTOGRAFICO -------------------------------->>*/}
      <PhotoModal
        open={open}
        handleClose={handleClose}
        idPhoto={idPhoto}
        setIdPhoto={setIdPhoto}
        frontPlatesPhoto={frontPlatesPhoto}
        setFrontPlatesPhoto={setFrontPlatesPhoto}
        backPlatesPhoto={backPlatesPhoto}
        setBackPlatesPhoto={setBackPlatesPhoto}
        otherPhoto={otherPhoto}
        setOtherPhoto={setOtherPhoto}
        accreditationPhoto={accreditationPhoto}
        setAccreditationPhoto={setAccreditationPhoto}
        forPrint={isForPrint}
      />
    </>
  );
};

export default PhotoButtons;
