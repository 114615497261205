import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

interface AccessPointsListProps {
  onPress: () => void;
  name: string;
  venue: string;
  address: string;
  gates: number;
}

const AccessPointsList = ({
  onPress,
  name,
  venue,
  address,
  gates,
}: AccessPointsListProps) => {
  const labels = useAppSelector(selectLabels);
  return (
    <>
      <div
        className="w-100 m-0 py-5 px-6 main-list-container"
        onClick={onPress}
      >
        <div className="flex justify-content-space-between align-items-baseline">
          <p className="p-0 m-0 primary-color font-14 medium">{venue}</p>
          <p className="p-0 m-0 primary-color font-14 medium">
            {labels.gates}: {gates}
          </p>
        </div>
        <p className="p-0 m-0 primary-color font-18 medium mt-5 mb-2">{name}</p>
        <p className="p-0 m-0 subtitle-color font-14">{address}</p>
      </div>
    </>
  );
};

export default AccessPointsList;
