const passtrackLogo = require("../../assets/logo-passtrack.png").default;
const idImage = require("../../assets/id-min.png").default;
const idBackImage = require("../../assets/id_back.png").default;
const plateImage = require("../../assets/plate-min.png").default;
const computerImage = require("../../assets/computer-min.png").default;
const personImage = require("../../assets/person-min.png").default;
const qrCodeExample = require("../../assets/codigo_qr_ejemplo.png").default;
const registerDetailOne = require("../../assets/Detalles_01.jpeg").default;
const lenovoLogo = require("../../assets/lenovo.png").default;
const passtrackHeaderColor =
  require("../../assets/passtrack_header_color.png").default;
const passtrackHeaderWhite =
  require("../../assets/passtrack_header_white.png").default;

const LOGOS: { [key: string]: string } = {
  passtrackLogo,
  idImage,
  idBackImage,
  plateImage,
  computerImage,
  personImage,
  qrCodeExample,
  registerDetailOne,
  lenovoLogo,
  passtrackHeaderColor,
  passtrackHeaderWhite,
};

export default LOGOS;
