import { Button, Grid } from "@mui/material";
import React from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import PhotoModal from "../PhotoModal";
import { useAppSelector, useWindowSize } from "../../app/hooks";
import { selectUser } from "../../slices/user";
import { selectLabels } from "../../slices/language";

interface Props {
  idPhoto: string | null;
  setIdPhoto: (value: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (value: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (value: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (value: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (value: string | null) => void;
  idPhotoRequired: boolean;
  frontPlatesPhotoRequired: boolean;
  backPlatesPhotoRequired: boolean;
  otherPhotoRequired: boolean;
  accreditationPhotoRequired: boolean;
}

const PhotoButtonsInvitations = ({
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  idPhotoRequired,
  frontPlatesPhotoRequired,
  backPlatesPhotoRequired,
  otherPhotoRequired,
  accreditationPhotoRequired,
}: Props) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);

  const { isMobile } = useWindowSize();

  const [open, setOpen] = React.useState<
    "id" | "frontPlates" | "backPlates" | "other" | "accreditation" | null
  >(null);
  const [isForPrint, setIsForPrint] = React.useState<boolean>(false);

  const handleOpen = (
    photoType: "id" | "frontPlates" | "backPlates" | "other" | "accreditation"
  ) => setOpen(photoType);
  const handleClose = () => setOpen(null);

  return (
    <>
      <Grid
        container
        className=" flex justify-content-center background-border-grey py-6"
      >
        {/* SHOW ID PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT--------------------->>*/}
        {/* MUESTRA BOTON DE FOTO ID SI ES REQUERIDA POR EL PUNTO DE ACCESO------------->>*/}
        {idPhotoRequired && (
          <Grid item xs={10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !idPhoto ? (
                  <CameraAltIcon />
                ) : (
                  <CheckIcon sx={{ color: green[900] }} />
                )
              }
              variant={!idPhoto ? "contained" : "outlined"}
              sx={{ padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("id");
              }}
              className="my-4"
            >
              {labels.identification_photo}
            </Button>
          </Grid>
        )}

        {/* SHOW CAR PLATES PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT------------->>*/}
        {/* MUESTRA BOTON DE FOTO PLACAS SI ES REQUERIDA POR EL PUNTO DE ACCESO--------->>*/}
        {frontPlatesPhotoRequired && (
          <Grid item xs={10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !frontPlatesPhoto ? (
                  <CameraAltIcon />
                ) : (
                  <CheckIcon sx={{ color: green[900] }} />
                )
              }
              variant={!frontPlatesPhoto ? "contained" : "outlined"}
              sx={{ padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("frontPlates");
              }}
              className="my-4"
            >
              {labels.plate_photo}
            </Button>
          </Grid>
        )}

        {/* SHOW PERSON PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT----------------->>*/}
        {/* MUESTRA BOTON DE FOTO PERSONA SI ES REQUERIDA POR EL PUNTO DE ACCESO-------->>*/}
        {backPlatesPhotoRequired && (
          <Grid item xs={10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !backPlatesPhoto ? (
                  <CameraAltIcon />
                ) : (
                  <CheckIcon sx={{ color: green[900] }} />
                )
              }
              variant={!backPlatesPhoto ? "contained" : "outlined"}
              sx={{ padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("backPlates");
              }}
            >
              {labels.personal_photo}
            </Button>
          </Grid>
        )}

        {/* SHOW EQUIPMENT PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT-------------->>*/}
        {/* MUESTRA BOTON DE FOTO EQUIPO SI ES REQUERIDA POR EL PUNTO DE ACCESO--------->>*/}
        {otherPhotoRequired && (
          <Grid item xs={10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !otherPhoto ? (
                  <CameraAltIcon />
                ) : (
                  <CheckIcon sx={{ color: green[900] }} />
                )
              }
              variant={!otherPhoto ? "contained" : "outlined"}
              sx={{ padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("other");
              }}
            >
              {labels.equipment_photo}
            </Button>
          </Grid>
        )}

        {/* SHOW ACCREDITATION PHOTO BUTTON IF IS REQUIRED BY THE ACCESS POINT---------------->>*/}
        {/* MUESTRA BOTON DE FOTO ACREDITACION SI ES REQUERIDA POR EL PUNTO DE ACCESO--------->>*/}
        {accreditationPhotoRequired && (
          <Grid item xs={10}>
            <Button
              fullWidth
              disableElevation
              startIcon={
                !otherPhoto ? (
                  <CameraAltIcon />
                ) : (
                  <CheckIcon sx={{ color: green[900] }} />
                )
              }
              variant={!accreditationPhoto ? "contained" : "outlined"}
              sx={{ padding: "16px 0px" }}
              // sx={
              //   isMobile ? { padding: "16px 0px" } : { padding: "37.75px 0px" }
              // }
              onClick={() => {
                handleOpen("accreditation");
                setIsForPrint(true);
              }}
            >
              {labels.accreditation_photo}
            </Button>
          </Grid>
        )}
      </Grid>

      {/* COMPONENT - PHOTO RECORD MODAL------------------------------------------>>*/}
      {/* COMPONENTE - MODAL REGISTRO FOTOGRAFICO -------------------------------->>*/}
      <PhotoModal
        open={open}
        handleClose={handleClose}
        idPhoto={idPhoto}
        setIdPhoto={setIdPhoto}
        frontPlatesPhoto={frontPlatesPhoto}
        setFrontPlatesPhoto={setFrontPlatesPhoto}
        backPlatesPhoto={backPlatesPhoto}
        setBackPlatesPhoto={setBackPlatesPhoto}
        otherPhoto={otherPhoto}
        setOtherPhoto={setOtherPhoto}
        accreditationPhoto={accreditationPhoto}
        setAccreditationPhoto={setAccreditationPhoto}
        forPrint={isForPrint}
      />
    </>
  );
};

export default PhotoButtonsInvitations;
