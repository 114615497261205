import React from 'react';
import { Container, Paper, TextField, Grid, FormControlLabel, FormControl, InputLabel, Checkbox, Button, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";

const SignUp = () => {
    const [country, setCountry] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
      setCountry(event.target.value as string);
    };
    return (
        <Container maxWidth="xs" className="mt-11">
            <Paper
                variant="outlined"
                className="p-10 pb-13 flex justify-content-center align-items-center flex-dir-col vertical-spacing-7"
            >
                <Logo type="passtrackLogo" alt="passtrack" className="logo" />
                <h2>Crear cuenta PassTrack</h2>
                <p>
                    ¿Ya tienes cuenta? <Link to="/login">Iniciar sesión</Link>
                </p>
                <TextField
                    label="Tu nombre completo"
                    variant="outlined"
                    fullWidth
                    placeholder="John Smith"
                />
                <TextField 
                    label="Tu correo electrónico"
                    variant="outlined"
                    fullWidth
                    placeholder="john_smith@gmail.com"
                />
                <TextField 
                    label="Nombre de tu empresa o locación"
                    variant="outlined"
                    fullWidth
                    placeholder="México"
                />
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">País</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={country}
                                label="País"
                                onChange={handleChange}
                            >
                                <MenuItem value={10}>MX +52</MenuItem>
                                <MenuItem value={20}>USA +1</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            label="Teléfono de contacto"
                            variant="outlined"
                            fullWidth
                            placeholder="55********"
                        />
                    </Grid>
                </Grid>
                <TextField 
                    label="Elegir contraseña"
                    variant="outlined"
                    fullWidth
                />
                <TextField 
                    label="Confirmar contraseña"
                    variant="outlined"
                    fullWidth
                />  
                <div className="full-width">
                    <FormControlLabel 
                        control={<Checkbox />}
                        label="Acepto los términos de uso"
                    />
                </div>
                <Button variant="contained" disableElevation fullWidth size="large">
                    Crear cuenta PassTrack
                </Button>
            </Paper>
        </Container>    
    );
};

export default SignUp;