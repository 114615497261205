import { CircularProgress, IconButton, Paper } from "@mui/material";
import React from "react";
import { ValidateKeyResponse } from "../../api";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { FaCarAlt, FaIdCard, FaUser, FaLaptop } from "react-icons/fa";
import ModalPhotoEnlarge from "../ModalPhotoEnlarge";
import moment from "moment-timezone";
import "moment-timezone/node_modules/moment/locale/es";
import CallIcon from "@mui/icons-material/Call";

interface InvitationCredentialProps {
  scannedResponse: ValidateKeyResponse | null;
  idPhoto: string | null;
  frontPlatesPhoto: string | null;
  backPlatesPhoto: string | null;
  otherPhoto: string | null;
  accreditationPhoto: string | null;
  registerCustomAttributes: any | null;
}

const InvitationCredential = ({
  scannedResponse,
  idPhoto,
  frontPlatesPhoto,
  backPlatesPhoto,
  otherPhoto,
  accreditationPhoto,
  registerCustomAttributes,
}: InvitationCredentialProps) => {
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showPhotoModal, setShowPhotoModal] = React.useState<boolean>(false);
  const [accessKeyPhoto, setAccessKeyPhoto] = React.useState<string>("");
  const [photoType, setPhotoType] = React.useState<
    "id" | "front_plates" | "back_plates" | "other" | "accreditation" | ""
  >("");

  const hasPhotos =
    idPhoto ||
    frontPlatesPhoto ||
    backPlatesPhoto ||
    otherPhoto ||
    accreditationPhoto;

  const momentLang = () => {
    if (navigator.language.includes("es")) {
      moment.locale("es");
      moment.localeData("es");
    } else {
      moment.locale("en");
      moment.localeData("en");
    }
  };

  React.useEffect(() => {
    momentLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getInitialPhotoType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idPhoto,
    frontPlatesPhoto,
    backPlatesPhoto,
    otherPhoto,
    accreditationPhoto,
  ]);

  const getInitialPhotoType = () => {
    if (idPhoto) {
      setPhotoType("id");
    } else if (frontPlatesPhoto) {
      setPhotoType("front_plates");
    } else if (backPlatesPhoto) {
      setPhotoType("back_plates");
    } else if (otherPhoto) {
      setPhotoType("other");
    } else if (accreditationPhoto) {
      setPhotoType("accreditation");
    }
  };

  React.useEffect(() => {
    if (photoType === "id" && idPhoto) {
      setAccessKeyPhoto(idPhoto);
    }
    if (photoType === "front_plates" && frontPlatesPhoto) {
      setAccessKeyPhoto(frontPlatesPhoto);
    }
    if (photoType === "back_plates" && backPlatesPhoto) {
      setAccessKeyPhoto(backPlatesPhoto);
    }
    if (photoType === "other" && otherPhoto) {
      setAccessKeyPhoto(otherPhoto);
    }
    if (photoType === "accreditation" && accreditationPhoto) {
      setAccessKeyPhoto(accreditationPhoto);
    }
  }, [
    photoType,
    idPhoto,
    frontPlatesPhoto,
    backPlatesPhoto,
    otherPhoto,
    accreditationPhoto,
  ]);

  console.log("accessKeyPhoto", accessKeyPhoto);

  return (
    <Paper
      component="form"
      sx={{
        width: "100%",
        boxSizing: "border-box",
        padding: 1.8,
        // minHeight: scannedResponse?.access_granted ? 150 : null,
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {scannedResponse?.access_granted === false ? (
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="p-0 m-0 medium font-18">
                {scannedResponse?.key_data?.visitor_name}
              </p>
              <p className="p-0 m-0 subtitle-color mb-5">
                {`${scannedResponse?.key_data?.user?.name} - ${scannedResponse?.key_data?.user?.group}`}
              </p>
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                <div>
                  <p className="p-0 m-0 subtitle-color medium">
                    {scannedResponse?.key_data?.user?.name}
                  </p>
                  <p className="p-0 m-0 subtitle-color">
                    {scannedResponse?.key_data?.user?.group}
                  </p>
                </div>
                <div>
                  <a href={`tel:${scannedResponse?.key_data?.user?.phone}`}>
                    <IconButton className="call-icon-button-style-button ml-10">
                      <CallIcon
                        className="call-icon-button-style-icon"
                        fontSize="small"
                      />
                    </IconButton>
                  </a>
                </div>
              </div> */}
              <div>
                <p className="p-0 m-0 subtitle-color medium">
                  {labels.authorized_access_points}:
                </p>
                {scannedResponse.key_data.access_servers.map(({ name, id }) => (
                  <p key={id} className="p-0 m-0 subtitle-color ml-6">
                    - {name}
                  </p>
                ))}
              </div>
              {/* <div>
                <p className="p-0 m-0 subtitle-color medium">
                  {labels.access_hours_allowed}:
                </p>
                <p className="p-0 m-0 subtitle-color ml-6">
                  {`${moment(scannedResponse?.key_data?.valid_from).format(
                    "h:mma"
                  )} - ${moment(scannedResponse?.key_data?.valid_until).format(
                    "h:mma"
                  )}`}
                </p>
              </div> */}
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
              }}
            >
              {hasPhotos && (
                <div
                  style={{
                    width: "45%",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="background-gray-color"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      padding: 4,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        boxSizing: "border-box",
                        backgroundColor: "black",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPhotoModal(true)}
                    >
                      <img
                        src={accessKeyPhoto}
                        alt="captured_photo_id"
                        width={"100%"}
                        height={"100%"}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div className="w-100 flex justify-content-center align-items-center mt-5 mb-2">
                      <div>
                        {idPhoto && (
                          <FaIdCard
                            fontSize="24px"
                            className={
                              photoType === "id"
                                ? "primary-color m-0 p-0 mx-5"
                                : "title-color m-0 p-0 mx-5"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => setPhotoType("id")}
                          />
                        )}
                        {frontPlatesPhoto && (
                          <FaCarAlt
                            fontSize="24px"
                            className={
                              photoType === "front_plates"
                                ? "primary-color m-0 p-0 mx-5"
                                : "title-color m-0 p-0 mx-5"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => setPhotoType("front_plates")}
                          />
                        )}
                        {backPlatesPhoto && (
                          <FaUser
                            fontSize="24px"
                            className={
                              photoType === "back_plates"
                                ? "primary-color m-0 p-0 mx-5"
                                : "title-color m-0 p-0 mx-5"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => setPhotoType("back_plates")}
                          />
                        )}
                        {otherPhoto && (
                          <FaLaptop
                            fontSize="24px"
                            className={
                              photoType === "other"
                                ? "primary-color m-0 p-0 mx-5"
                                : "title-color m-0 p-0 mx-5"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => setPhotoType("other")}
                          />
                        )}
                        {accreditationPhoto && (
                          <FaUser
                            fontSize="24px"
                            className={
                              photoType === "accreditation"
                                ? "primary-color m-0 p-0 mx-5"
                                : "title-color m-0 p-0 mx-5"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => setPhotoType("accreditation")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={
                  hasPhotos
                    ? {
                        width: "55%",
                        boxSizing: "border-box",
                        paddingLeft: 14,
                      }
                    : { width: "100%", boxSizing: "border-box" }
                }
              >
                <p className="p-0 m-0 medium font-18">
                  {scannedResponse?.key_data?.visitor_name}
                </p>
                <p className="p-0 m-0 subtitle-color mt-6">
                  {scannedResponse?.key_data?.user?.group}
                </p>
                <p className="p-0 m-0 subtitle-color medium">
                  {scannedResponse?.key_data?.user?.name}
                </p>
              </div>
            </div>
          )}
        </>
      )}
      <ModalPhotoEnlarge
        showModal={showPhotoModal}
        setShowModal={setShowPhotoModal}
        transactionPhoto={accessKeyPhoto}
      />
    </Paper>
  );
};

export default InvitationCredential;
