import { Button, CircularProgress } from "@mui/material";
import React from "react";
import api, {
  GetLogbookRegister,
  GetLogbookRegisterTransaction,
  ValidateKeyResponse,
} from "../../api";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { showError } from "../../utils/helper";
import LogbookDesktopGuardCredential from "../LogbookDesktopGuardCredential";
import Pagination from "../Pagination";
import SearchInputHeader from "../SearchInputHeader";
import TransactionListContainer from "../TransactionListContainer";
import moment from "moment";
import { resetUser, selectUser } from "../../slices/user";
import RegisterQrScannedData from "../RegisterQrScannedData/RegisterQrScannedData";

interface RegisterManualExitDesktopProps {
  debouncedSearchText: string;
  transactionId: number | null;
  setTransactionId: (transactionId: number | null) => void;
  notes: string;
  setNotes: (notes: string) => void;
  registerCustomAttributes: any | null;
  setRegisterCustomAttributes: (RegisterCustomAttributes: any | null) => void;
  buttonConfirmLoading: boolean;
  buttonConfirmAction: () => void;
  buttonDeniedAction: () => void;
  buttonNotAuthorizedAction: () => void;
  onClickExit: () => void;
  onClickExitAccessKey: () => void;
  providerManualKey: string;
  setProviderManualKey: (providerManualKey: string) => void;
  scannedData: ValidateKeyResponse | null;
  setScannedData: (scannedData: ValidateKeyResponse | null) => void;
  gateId: number;
}

const RegisterManualExitDesktop = ({
  debouncedSearchText,
  transactionId,
  setTransactionId,
  notes,
  setNotes,
  registerCustomAttributes,
  setRegisterCustomAttributes,
  buttonConfirmLoading,
  buttonConfirmAction,
  buttonDeniedAction,
  buttonNotAuthorizedAction,
  onClickExit,
  onClickExitAccessKey,
  providerManualKey,
  setProviderManualKey,
  scannedData,
  setScannedData,
  gateId,
}: RegisterManualExitDesktopProps) => {
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectLabels);

  // const [searchInput, setSearchInput] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [logbookList, setLogbookList] =
    React.useState<GetLogbookRegister | null>(null);
  const [filteredLogbookList, setFilteredLogbookList] = React.useState<
    GetLogbookRegisterTransaction[] | null
  >(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [filteredTransactionId, setFilteredTransactionId] = React.useState<
    number | null
  >(null);
  const [searchContainerHeight, setSearchContainerHeight] =
    React.useState<number>(0);
  const [lbListErrorCode, setLbListErrorCode] = React.useState<number | null>(
    null
  );
  const [lbListErrorCount, setLbListErrorCount] = React.useState<number>(0);
  const [expandedCredential, setExpandedCredential] = React.useState<any>(null);
  const [accessKeyCode, setAccessKeyCode] = React.useState<string>("");

  const handleCredentialClick = (credentialId: number) => {
    if (expandedCredential === credentialId) {
      setExpandedCredential(null);
    } else {
      setExpandedCredential(credentialId);
    }
  };

  // const debouncedSearchText = useDebounce(searchInput, 500);

  // const searchContainer: any = React.useRef(null);
  const scrollContainer: any = React.useRef(null);

  React.useEffect(() => {
    if (lbListErrorCount !== 5) {
      if (lbListErrorCode === 500) {
        setLbListErrorCount(lbListErrorCount + 1);
        getLogbookListData(debouncedSearchText);
      } else if (lbListErrorCode === 200) {
        setLbListErrorCount(0);
      }
    } else {
      showError(dispatch, labels.generic_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lbListErrorCode]);

  // React.useEffect(() => {
  //   const height = searchContainer.current.offsetHeight;
  //   setSearchContainerHeight(height);
  // }, []);

  // const scrollToTop = () => {
  //   scrollContainer.current.scrollTop = 0;
  // };

  const scrollToTop = () => {
    const container = scrollContainer?.current;

    const scrollToTopAnimation = () => {
      if (container.scrollTop > 0) {
        container.scrollTo(0, container.scrollTop - 50);
        window.requestAnimationFrame(scrollToTopAnimation);
      }
    };

    window.requestAnimationFrame(scrollToTopAnimation);
  };

  React.useEffect(() => {
    setPage(1);
    setFilteredTransactionId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected]);

  React.useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  // END POINT TRANSACTIONS LIST--------------------------------------------------------------------------->>
  // END POINT LISTA DE TRANSACCIONES---------------------------------------------------------------------->>
  React.useEffect(() => {
    getLogbookListData(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    page,
    rowsPerPage,
    debouncedSearchText,
    user?.last_access_selected?.id,
  ]);

  const getLogbookListData = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.logbookRequestTwo(
          page, //página
          500, //registros por página
          "", //fecha (todos)
          "", //punto de acceso
          debouncedSearchText, //search
          "access" //tipo
        );
        const filteredList = result.transactions.filter(
          ({
            access_allowed,
            access_granted,
            access_key_id,
            access_key_type,
            related_transaction_id,
          }) => {
            const providersAccessKeys = !!access_key_id
              ? access_key_type === "provider"
              : access_key_type === "visit";
            return (
              access_allowed !== false &&
              access_granted !== false &&
              providersAccessKeys &&
              // !access_key_id &&
              !related_transaction_id
            );
          }
        );
        setLogbookList(result);
        setFilteredLogbookList(filteredList);
        setLbListErrorCode(200);
      }
    } catch (error: any) {
      console.error(error);
      setLbListErrorCode(error.response.status);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 404) {
        showError(dispatch, labels.community_member_not_found);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const exitAction = () => {
    onClickExit();
    setTimeout(() => {
      getLogbookListData(debouncedSearchText);
    }, 500);
  };

  const exitAccessKeyAction = () => {
    onClickExitAccessKey();
    setTimeout(() => {
      getLogbookListData(debouncedSearchText);
    }, 500);
  };

  const getInvitationById = async (invitationId: number) => {
    try {
      const result = await api.invitationIdRequest(Number(invitationId));
      if (result) {
        handleVerify(result.unique_id);
        setProviderManualKey(result.unique_id);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.unprocessable_entity);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  const handleVerify = async (uniqueId: string) => {
    try {
      setIsLoading(true);
      const newCodeScan = await api.validateKeyRequestNoTransaction(
        gateId,
        uniqueId
      );
      setScannedData(newCodeScan);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      {scannedData ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              // backgroundColor: "red"
            }}
          >
            <RegisterQrScannedData
              scannedData={scannedData}
              setScannedData={setScannedData}
              accessType={"exit"}
              idPhoto={""}
              setIdPhoto={() => {}}
              frontPlatesPhoto={""}
              setFrontPlatesPhoto={() => {}}
              backPlatesPhoto={""}
              setBackPlatesPhoto={() => {}}
              otherPhoto={""}
              setOtherPhoto={() => {}}
              accreditationPhoto={""}
              setAccreditationPhoto={() => {}}
              notes={notes}
              setNotes={setNotes}
              registerCustomAttributes={registerCustomAttributes}
              setRegisterCustomAttributes={setRegisterCustomAttributes}
              buttonConfirmLoading={buttonConfirmLoading}
              buttonConfirmAction={buttonConfirmAction}
              buttonDeniedAction={buttonDeniedAction}
              buttonNotAuthorizedAction={buttonNotAuthorizedAction}
            />
          </div>
        </>
      ) : (
        <div
          ref={scrollContainer}
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            overflow: "scroll",
          }}
        >
          {isLoading ? (
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 50,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              className="w-100 flex flex-dir-col pb-6"
              style={{
                boxSizing: "border-box",
              }}
            >
              {filteredLogbookList && filteredLogbookList?.length > 0 ? (
                filteredLogbookList?.map(
                  ({
                    access_allowed,
                    access_granted,
                    access_key_id,
                    access_key_type,
                    access_type,
                    created_at,
                    related_transaction_id,
                    visitor_name,
                    user,
                    id,
                  }) => (
                    <div
                      key={id}
                      className="w-100 flex justify-content-center mt-5"
                    >
                      <TransactionListContainer
                        transactionDate={`${moment(created_at).format(
                          `DD`
                        )} ${moment(created_at)
                          .format(`MMM`)
                          .replace(
                            /^./,
                            moment(created_at).format(`MMM`)[0].toUpperCase()
                          )}`}
                        transactionHour={moment(created_at).format("hh:mma")}
                        accessAllowed={access_allowed}
                        accessGranted={access_granted}
                        type={access_type}
                        relatedTransaction={related_transaction_id}
                        userName={user?.name}
                        department={user?.group}
                        visitorName={visitor_name}
                        accessKeyType={access_key_type}
                        isAccessKey={access_key_id ? true : false}
                        onPress={() => {
                          setTransactionId(id);
                          handleCredentialClick(id);
                        }}
                        expanded={expandedCredential === id}
                        hasExitButton={true}
                        idTransaction={id}
                        onClickExit={exitAction}
                        onClickExitAccessKey={() =>
                          getInvitationById(Number(access_key_id))
                        }
                      />
                    </div>
                  )
                )
              ) : (
                <div className="w-100 flex justify-content-center">
                  <p className="font-16 subtitle-color">
                    {labels.no_results_found}
                  </p>
                </div>
              )}
              {logbookList?.total_pages ? (
                <div style={{ marginTop: 14 }}>
                  <Pagination
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                    setPage={setPage}
                    totalPages={logbookList?.total_pages}
                    isDesktop={true}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RegisterManualExitDesktop;
