import React from "react";
import { ValidateKeyResponse } from "../../api";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { green } from "@mui/material/colors";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import PhotoButtons from "../PhotoButtons";
import { selectUser } from "../../slices/user";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import CustomAttributes from "../CustomAttributes";
import InvitationCredential from "../InvitationCredential";

interface RegisterQrScannedDataProps {
  scannedData: ValidateKeyResponse | null;
  setScannedData: (scannedData: ValidateKeyResponse | null) => void;
  accessType: string;
  idPhoto: string | null;
  setIdPhoto: (idPhoto: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (platePhoto: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (personPhoto: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (otherPhoto: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (accreditationPhoto: string | null) => void;
  notes: string;
  setNotes: (notes: string) => void;
  registerCustomAttributes: any | null;
  setRegisterCustomAttributes: (RegisterCustomAttributes: any | null) => void;
  buttonConfirmLoading: boolean;
  buttonConfirmAction: () => void;
  buttonDeniedAction: () => void;
  buttonNotAuthorizedAction: () => void;
}

const RegisterQrScannedData = ({
  scannedData,
  setScannedData,
  accessType,
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  notes,
  setNotes,
  registerCustomAttributes,
  setRegisterCustomAttributes,
  buttonConfirmLoading,
  buttonConfirmAction,
  buttonDeniedAction,
  buttonNotAuthorizedAction,
}: RegisterQrScannedDataProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const [showPhotosContainer, setShowPhotosContainer] =
    React.useState<boolean>(false);
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);
  const [buttonContainerHeight, setButtonContainerHeight] =
    React.useState<number>(0);
  const [scrollContainerHeight, setScrollContainerHeight] =
    React.useState<number>(0);

  const isDisabledScan =
    (scannedData?.requirements.photo_id && !idPhoto) ||
    (scannedData?.requirements.front_plates && !frontPlatesPhoto) ||
    (scannedData?.requirements.back_plates && !backPlatesPhoto) ||
    (scannedData?.requirements.other && !otherPhoto) ||
    (scannedData?.requirements.accreditation && !accreditationPhoto);

  const isDisabledCAScan =
    (scannedData?.requirements.photo_id && !idPhoto) ||
    (scannedData?.requirements.front_plates && !frontPlatesPhoto) ||
    (scannedData?.requirements.back_plates && !backPlatesPhoto) ||
    (scannedData?.requirements.other && !otherPhoto) ||
    (scannedData?.requirements.accreditation && !accreditationPhoto) ||
    !customAttributesRequired;

  const scrollContainer: any = React.useRef(null);
  const mainContainer: any = React.useRef(null);

  console.log("scannedData", scannedData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    const height = mainContainer?.current?.offsetHeight;
    const scHeight = scrollContainer?.current?.offsetHeight;
    setButtonContainerHeight(height);
    setScrollContainerHeight(scHeight);
  });

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = registerCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  React.useEffect(() => {
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      setShowPhotosContainer(true);
    } else {
      setShowPhotosContainer(false);
    }
  }, [user?.last_access_selected]);

  const onClickConfirm = () => {
    buttonConfirmAction();
    setScannedData(null);
  };

  const onClickNotAuthorized = () => {
    buttonNotAuthorizedAction();
    setScannedData(null);
  };

  const onClickDenied = () => {
    buttonDeniedAction();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={scrollContainer}
        style={{ width: "100%", boxSizing: "border-box" }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "#e8e8e8",
            borderBottomColor:
              scannedData?.access_granted === false ? "#d9d9d9" : "",
            borderBottomWidth: scannedData?.access_granted === false ? 1 : 0,
            borderBottomStyle:
              scannedData?.access_granted === false ? "solid" : "none",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 40,
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {scannedData?.access_granted === false ? (
              <CancelRoundedIcon
                className="mr-4 text-warning"
                sx={{
                  fontSize: 28,
                }}
              />
            ) : (
              <CheckCircleRoundedIcon
                className="mr-4"
                sx={{
                  color: green[900],
                  fontSize: 28,
                }}
              />
            )}
            <p className="m-0 p-0 font font-18 font-w-500 subtitle-color">
              {scannedData?.access_granted === false
                ? accessType === "entrance"
                  ? labels.unauthorized_access
                  : labels.unauthorized_exit
                : accessType === "entrance"
                ? labels.authorized_access
                : labels.authorized_exit}
            </p>
          </div>
        </div>
        {scannedData && scannedData?.access_granted === false && (
          <div
            className="text-center"
            style={{
              width: "100%",
              boxSizing: "border-box",
              paddingRight: 20,
              paddingLeft: 20,
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <p className="text-warning font-14 m-0 text-warning">
              {labels.status}:{" "}
              {scannedData?.key_data.access_key_status.message ===
                "Código QR eliminado" && labels.qr_code_deleted}
              {scannedData?.key_data.access_key_status.message ===
                "Oops, el último acceso no fue registrado" &&
                labels.last_access_not_registered}
              {scannedData?.key_data.access_key_status.message ===
                "Oops, la última salida no fue registrada" &&
                labels.last_exit_not_registered}
              {scannedData?.key_data.access_key_status.message ===
                "Acceso NO autorizado" && labels.access_door_denied}
              {scannedData?.key_data.access_key_status.message ===
                "Acceso fuera del periodo permitido" &&
                labels.access_out_of_time}
              {scannedData?.key_data.access_key_status.message ===
                "Acceso no autorizado" && labels.new_qr_code}
              {scannedData?.key_data.access_key_status.message ===
                "Horario de acceso no autorizado" &&
                labels.hour_access_unauthorized}
            </p>
          </div>
        )}
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: scannedData?.access_granted === true ? 0 : 10,
            backgroundColor:
              scannedData?.access_granted === true ? "#e8e8e8" : "",
            borderBottomColor:
              scannedData?.access_granted === true ? "#d9d9d9" : "",
            borderBottomWidth: scannedData?.access_granted === true ? 1 : 0,
            borderBottomStyle:
              scannedData?.access_granted === true ? "solid" : "none",
            paddingBottom: scannedData?.access_granted === true ? 10 : 0,
          }}
        >
          <InvitationCredential
            scannedResponse={scannedData}
            idPhoto={idPhoto}
            frontPlatesPhoto={frontPlatesPhoto}
            backPlatesPhoto={backPlatesPhoto}
            otherPhoto={otherPhoto}
            accreditationPhoto={accreditationPhoto}
            registerCustomAttributes={registerCustomAttributes}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: `calc(100% - ${scrollContainerHeight}px)`,
          boxSizing: "border-box",
          overflow: "scroll",
          paddingBottom: buttonContainerHeight,
        }}
      >
        {registerCustomAttributes &&
          registerCustomAttributes.length > 0 &&
          scannedData?.access_granted === true &&
          accessType === "entrance" && (
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              {registerCustomAttributes.map(
                (
                  {
                    attribute_name,
                    attribute_type,
                    required,
                    options,
                    value,
                  }: any,
                  index: number
                ) => (
                  <div key={index} style={{ marginTop: 10 }}>
                    <CustomAttributes
                      type={attribute_type}
                      name={attribute_name}
                      required={required}
                      value={value}
                      setValue={(newValue: any) => {
                        const newRegisterCustomAttributes = [
                          ...registerCustomAttributes,
                        ];
                        newRegisterCustomAttributes[index].value = newValue;
                        setRegisterCustomAttributes(
                          newRegisterCustomAttributes
                        );
                      }}
                      listArray={options}
                      isDesktop={true}
                    />
                  </div>
                )
              )}
            </div>
          )}
        {scannedData?.access_granted && (
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 10,
            }}
          >
            <TextField
              fullWidth
              // label={labels.notes_optional}
              placeholder={labels.notes_optional}
              multiline
              size="small"
              style={{ backgroundColor: "white" }}
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </div>
        )}
        {scannedData?.access_granted === true &&
          accessType === "entrance" &&
          showPhotosContainer && (
            <>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 18,
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: 1,
                    backgroundColor: "gray",
                  }}
                />
                <p
                  className="m-0 p-0 subtitle-color pl-5 pr-5"
                  style={{ backgroundColor: "#f5f5f5", zIndex: 90 }}
                >
                  {labels.required_photos} *
                </p>
              </div>
              <PhotoButtons
                idPhoto={idPhoto}
                setIdPhoto={setIdPhoto}
                frontPlatesPhoto={frontPlatesPhoto}
                setFrontPlatesPhoto={setFrontPlatesPhoto}
                backPlatesPhoto={backPlatesPhoto}
                setBackPlatesPhoto={setBackPlatesPhoto}
                otherPhoto={otherPhoto}
                setOtherPhoto={setOtherPhoto}
                accreditationPhoto={accreditationPhoto}
                setAccreditationPhoto={setAccreditationPhoto}
                desktopView={true}
              />
            </>
          )}
        {scannedData?.access_granted ? (
          <div
            ref={mainContainer}
            style={{
              width: "100%",
              boxSizing: "border-box",
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            }}
          >
            {/* BUTTON - OPEN AUTHORIZED ACCESS KEYS DETAILS------------------------------------->>*/}
            {/* BOTON - ABRE DETALLES DE INVITACIONES AUTORIZADAS-------------------------------->>*/}
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 20,
                paddingTop: 20,
              }}
            >
              <div style={{ width: "50%", marginRight: 6, marginLeft: 20 }}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={() => {
                    // gate?.type === "access" && addPhotos();
                    // setAccessAllow(false);
                    // setManualDeniedModal(true);
                    onClickDenied();
                  }}
                  size="medium"
                  disabled={
                    buttonConfirmLoading
                      ? true
                      : scannedData?.access_granted === true &&
                        accessType === "entrance" &&
                        registerCustomAttributes &&
                        registerCustomAttributes.length > 0
                      ? isDisabledCAScan
                      : isDisabledScan
                  }
                  color="error"
                  // style={{ backgroundColor: "#a60300" }}
                >
                  {accessType === "entrance"
                    ? labels.deny_access
                    : labels.deny_exit}
                </Button>
              </div>
              <div
                style={{
                  width: "50%",
                  marginLeft: 6,
                  marginRight: 20,
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={() => {
                    // generateRegisterConfirm();
                    onClickConfirm();
                  }}
                  size="medium"
                  disabled={
                    buttonConfirmLoading
                      ? true
                      : scannedData?.access_granted === true &&
                        accessType === "entrance" &&
                        registerCustomAttributes &&
                        registerCustomAttributes.length > 0
                      ? isDisabledCAScan
                      : isDisabledScan
                  }
                >
                  {buttonConfirmLoading ? (
                    <CircularProgress size={26.5} />
                  ) : accessType === "entrance" ? (
                    labels.confirm_access
                  ) : (
                    labels.confirm_exit
                  )}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 12,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              fullWidth
              onClick={() => {
                // generateRegister();
                // setTimeout(() => navigate("/logbook"), 500);
                onClickNotAuthorized();
              }}
              size="medium"
            >
              {labels.continue}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterQrScannedData;
