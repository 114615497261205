import React from "react";
import { IconButton, Modal, Box, Grid, Divider, Button } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";
import TuneIcon from "@mui/icons-material/Tune";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import NoMeetingRoomRoundedIcon from "@mui/icons-material/NoMeetingRoomRounded";
import { red, green } from "@mui/material/colors";
import { selectUserRole } from "../../../slices/user";
import Logo from "../../Logo";
import LabelPrint from "../../LabelPrint";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 400,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

interface PrintLabelModalProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  visitorName: string;
  accessTime: string;
  hostName: string;
  department: string;
  imageUrl: string;
  visitorLastName: string;
  isDesktop: boolean;
}

const PrintLabelModal = ({
  openModal,
  setOpenModal,
  visitorName,
  accessTime,
  hostName,
  department,
  imageUrl,
  visitorLastName,
  isDesktop,
}: PrintLabelModalProps) => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();

  return (
    <>
      <Modal disableEscapeKeyDown open={openModal}>
        <Box sx={style}>
          <div className="w-100 flex flex-dir-col mv-4">
            <LabelPrint
              visitorName={visitorName}
              accessTime={accessTime}
              hostName={hostName}
              department={department}
              imageUrl={imageUrl}
              visitorLastname={visitorLastName}
            />
            {isDesktop ? (
              <Button
                variant="contained"
                disableElevation
                size="large"
                fullWidth
                className="mt-6"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                {labels.continue}
              </Button>
            ) : (
              <Button
                variant="contained"
                disableElevation
                size="large"
                fullWidth
                className="mt-6"
                onClick={() => {
                  setTimeout(() => navigate("/logbook"), 500);
                }}
              >
                {labels.continue}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PrintLabelModal;
