import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { selectLabels } from "../../slices/language";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import api, { CreateNetworkGroupRequest } from "../../api";
import { showError } from "../../utils/helper";
import { selectUser } from "../../slices/user";

const EditDepartment = () => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [departmentName, setDepartmentName] = React.useState<string>("");
  const [accessPointsArray, setAccessPointArray] = React.useState<
    { venue_name: string; access: { name: string; id: number }[] }[] | null
  >(null);
  const [finalAccessPointTemporary, setFinalAccessPointTemporary] =
    React.useState<number[]>([]);

  const disabledButton =
    !departmentName || finalAccessPointTemporary.length < 1;

  const handleCheckboxChange = (id: number) => {
    if (finalAccessPointTemporary.includes(id)) {
      setFinalAccessPointTemporary(
        finalAccessPointTemporary.filter((item) => item !== id)
      );
    } else {
      setFinalAccessPointTemporary([...finalAccessPointTemporary, id]);
    }
  };

  React.useEffect(() => {
    getAccessPointsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAccessPointsArray = () => {
    const nuevoArreglo: any = [];
    const array1 = user?.access_servers;

    if (array1) {
      for (const obj of array1) {
        const { name, venue_name } = obj;

        const index = nuevoArreglo.findIndex(
          (item: any) => item.venue_name === venue_name
        );

        if (index !== -1) {
          nuevoArreglo[index].access.push({ name, id: obj.id });
        } else {
          nuevoArreglo.push({ venue_name, access: [{ name, id: obj.id }] });
        }
      }
      setAccessPointArray(nuevoArreglo);
    }
  };

  const onUpdateDepartment = async () => {
    if (id) {
      const payload: CreateNetworkGroupRequest = {
        network_group: {
          access_servers: finalAccessPointTemporary,
          name: departmentName,
        },
      };
      try {
        setIsLoading(true);
        const response = await api.updateNetworkGroup(Number(id), payload);
        if (response) {
          navigate("/departments");
        }
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div style={{ paddingBottom: 40 }}>
      <div
        className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary"
        style={{ height: 60 }}
      >
        <div style={{ width: "20%" }}></div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="font-14 uppercase text-primary-color medium">
            {labels.edit_unit}
          </p>
        </div>
        <div
          className="flex align-items-center justify-content-flex-end"
          style={{ width: "20%" }}
        >
          <CloseRoundedIcon
            className="primary-color"
            style={{ marginRight: 20 }}
            onClick={() => navigate("/departments")}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-100 flex flex-dir-col align-items-center">
          <div
            style={{
              width: "90%",
              marginTop: 20,
              backgroundColor: "white",
              borderRadius: 4,
              boxSizing: "border-box",
              padding: 20,
              boxShadow:
                "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <div style={{ width: "100%" }}>
              <p
                className="title-color mt-0"
                style={{ marginBottom: 10, padding: 0 }}
              >
                Nombre de la unidad *
              </p>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="outlined"
                  // placeholder={name ? name : labels.name}
                  placeholder={labels.name}
                  type="text"
                  value={departmentName}
                  onChange={(e) => {
                    setDepartmentName(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <div>
                <div style={{ width: "100%", marginBottom: 6 }}>
                  <p
                    className="title-color"
                    style={{ marginBottom: 10, padding: 0 }}
                  >
                    Puntos de acceso permitidos *
                  </p>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    {accessPointsArray &&
                      accessPointsArray.length > 0 &&
                      accessPointsArray.map(({ access, venue_name }, index) => (
                        <div
                          key={index}
                          className="w-100 flex flex-dir-col"
                          style={{
                            alignItems: "flex-start",
                            borderRadius: 4,
                            borderWidth: 1,
                            boxSizing: "border-box",
                            borderStyle: "solid",
                            borderColor: "#c4c4c4",
                            padding: 10,
                            marginBottom:
                              index === accessPointsArray.length - 1 ? 0 : 10,
                          }}
                        >
                          <div className="w-100">
                            <p className="title-color desktop-tables-font m-0 p-0 mt-5">
                              {venue_name}
                            </p>
                          </div>
                          <div className="w-100">
                            {access.map(({ name, id }) => (
                              <div
                                key={id}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  onChange={() => handleCheckboxChange(id)}
                                  checked={finalAccessPointTemporary.includes(
                                    id
                                  )}
                                  color="secondary"
                                  disabled={isLoading ? true : false}
                                />
                                <p
                                  key={id}
                                  className="desktop-tables-font"
                                  style={{ margin: 0, padding: 0 }}
                                >
                                  {name}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "90%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 20,
              paddingBottom: 50,
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{ height: 56, width: 400 }}
              disabled={disabledButton}
              onClick={onUpdateDepartment}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDepartment;
