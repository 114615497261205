import { Box, Chip, Divider, Grid, IconButton, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CaptureRegisterImage from "../CaptureRegisterImage";
import IdSvgImage from "../IdSvgImage";
import PlateSvgImage from "../PlateSvgImage";
import PersonSvgImage from "../PersonSvgImage";
import ComputerSvgImage from "../ComputerSvgImage";
import { useAppSelector, useWindowSize } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import Logo from "../Logo";
import "./photoModal.scss";
import { selectUser } from "../../slices/user";
import { BACK_ID_NETWORKS } from "../../utils/constants";

interface Props {
  open: "id" | "frontPlates" | "backPlates" | "other" | "accreditation" | null;
  handleClose: () => void;
  idPhoto: string | null;
  setIdPhoto: (value: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (value: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (value: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (value: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (value: string | null) => void;
  forPrint?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const styleTwo = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const PhotoModal = ({
  open,
  handleClose,
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  forPrint,
}: Props) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);

  const { width } = useWindowSize();

  return (
    <Modal disableEscapeKeyDown open={!!open}>
      <Box sx={width >= 1200 ? (forPrint ? styleTwo : style) : style}>
        <Grid
          container
          className="flex flex-dir-row align-items-center justify-content-space-between"
        >
          <Grid item>
            {user?.network.id !== BACK_ID_NETWORKS ? (
              <p className="title-color font-24 m-4">
                {open === "id" && labels.identification_photo}
                {open === "frontPlates" && labels.plate_photo}
                {open === "backPlates" && labels.personal_photo}
                {open === "other" && labels.equipment_photo}
                {open === "accreditation" && labels.accreditation_photo}
              </p>
            ) : (
              <p className="title-color font-24 m-4">
                {open === "id" && labels.identification_photo}
                {open === "frontPlates" && labels.plate_photo}
                {open === "backPlates" && labels.id_back_photo}
                {open === "other" && labels.equipment_photo}
                {open === "accreditation" && labels.accreditation_photo}
              </p>
            )}
          </Grid>
          <Grid>
            <IconButton onClick={handleClose}>
              <CloseIcon className="title-color" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        {open === "id" && (
          <CaptureRegisterImage
            handleClose={handleClose}
            photo={idPhoto}
            setPhoto={setIdPhoto}
            imageReference={
              <>
                <div className="w-100 p-0 m-0 main-container-bg my-4">
                  <Grid container>
                    <Grid item xs={12} className="flex justify-content-center">
                      <Chip
                        label={labels.example}
                        className="mt-5 px-10 subtitle-color"
                      />
                    </Grid>
                    <div className="flex flex-dir-col align-items-center justify-content-center w-100 mt-3 mb-5">
                      <div className="example-window-div-4">
                        <div className="example-window-div-3">
                          <div className="example-window-div-2">
                            <div className="background">
                              <div className="example-window-div-1 w-90">
                                {/* <IdSvgImage /> */}
                                <Logo type="idImage" alt="id" className="id" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </>
            }
          />
        )}
        {open === "frontPlates" && (
          <CaptureRegisterImage
            handleClose={handleClose}
            photo={frontPlatesPhoto}
            setPhoto={setFrontPlatesPhoto}
            imageReference={
              <>
                <div className="w-100 p-0 m-0 main-container-bg my-4">
                  <Grid container>
                    <Grid item xs={12} className="flex justify-content-center">
                      <Chip
                        label={labels.example}
                        className="mt-5 px-10 subtitle-color"
                      />
                    </Grid>
                    <div className="flex flex-dir-col align-items-center justify-content-center w-100 mt-3 mb-5">
                      <div className="example-window-div-4">
                        <div className="example-window-div-3">
                          <div className="example-window-div-2">
                            <div className="background">
                              <div className="example-window-div-1 w-90">
                                {/* <PlateSvgImage /> */}
                                <Logo
                                  type="plateImage"
                                  alt="plate"
                                  className="plate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </>
            }
          />
        )}
        {open === "backPlates" && (
          <CaptureRegisterImage
            handleClose={handleClose}
            photo={backPlatesPhoto}
            setPhoto={setBackPlatesPhoto}
            imageReference={
              <>
                <div className="w-100 p-0 m-0 main-container-bg my-4">
                  <Grid container>
                    <Grid item xs={12} className="flex justify-content-center">
                      <Chip
                        label={labels.example}
                        className="mt-5 px-10 subtitle-color"
                      />
                    </Grid>
                    <div className="flex flex-dir-col align-items-center justify-content-center w-100 mt-3 mb-5">
                      <div className="example-window-div-4">
                        <div className="example-window-div-3">
                          <div className="example-window-div-2">
                            <div className="background">
                              <div
                                className={`example-window-div-1 ${
                                  user?.network.id !== BACK_ID_NETWORKS
                                    ? "w-55"
                                    : "w-90"
                                }`}
                              >
                                {/* <PersonSvgImage /> */}
                                {user?.network.id !== BACK_ID_NETWORKS ? (
                                  <Logo
                                    type="personImage"
                                    alt="person"
                                    className="person"
                                  />
                                ) : (
                                  <Logo
                                    type="idBackImage"
                                    alt="backId"
                                    className="back-id"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </>
            }
          />
        )}
        {open === "other" && (
          <CaptureRegisterImage
            handleClose={handleClose}
            photo={otherPhoto}
            setPhoto={setOtherPhoto}
            imageReference={
              <>
                <div className="w-100 p-0 m-0 main-container-bg my-4">
                  <Grid container>
                    <Grid item xs={12} className="flex justify-content-center">
                      <Chip
                        label={labels.example}
                        className="mt-5 px-10 subtitle-color"
                      />
                    </Grid>
                    <div className="flex flex-dir-col align-items-center justify-content-center w-100 mt-3 mb-5">
                      <div className="example-window-div-4">
                        <div className="example-window-div-3">
                          <div className="example-window-div-2">
                            <div className="background">
                              <div className="example-window-div-1 w-90">
                                {/* <ComputerSvgImage /> */}
                                <Logo
                                  type="computerImage"
                                  alt="computer"
                                  className="computer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </>
            }
          />
        )}
        {open === "accreditation" && (
          <CaptureRegisterImage
            handleClose={handleClose}
            photo={accreditationPhoto}
            setPhoto={setAccreditationPhoto}
            imageReference={
              <>
                <div className="w-100 p-0 m-0 main-container-bg my-4">
                  <Grid container>
                    <Grid item xs={12} className="flex justify-content-center">
                      <Chip
                        label={labels.example}
                        className="mt-5 px-10 subtitle-color"
                      />
                    </Grid>
                    <div className="flex flex-dir-col align-items-center justify-content-center w-100 mt-3 mb-5">
                      <div className="example-window-div-4">
                        <div className="example-window-div-3">
                          <div className="example-window-div-2">
                            <div className="background">
                              <div className="example-window-div-1 w-55">
                                {/* <PersonSvgImage /> */}
                                <Logo
                                  type="personImage"
                                  alt="person"
                                  className="person"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </>
            }
            ticketPhoto={true}
          />
        )}
      </Box>
    </Modal>
  );
};

export default PhotoModal;
