import React from "react";

interface UsersListProps {
  onPress: () => void;
  userName: string;
  userPhone: string;
}

const UsersList = ({ onPress, userName, userPhone }: UsersListProps) => {
  return (
    <>
      <div
        className="w-100 m-0 py-6 px-6 main-list-container"
        onClick={onPress}
      >
        <div className="w-100 flex justify-content-space-between align-items-center">
          <p className="text-primary-color medium font-16 m-0 p-0">{userName}</p>
          <p className="subtitle-color m-0 p-0">{userPhone}</p>
        </div>
      </div>
    </>
  );
};

export default UsersList;
