import React from "react";
import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Button,
  TextField,
  Modal,
  CircularProgress,
  Box,
  Divider,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import api, { EditEventParams, EventIdParams } from "../../api";
import { showError } from "../../utils/helper";
import { resetUser, selectUser } from "../../slices/user";
import { resetToken, selectToken } from "../../slices/token";
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const EditEvent = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);

  const { id } = useParams();

  const [eventName, setEventName] = React.useState<string>("");
  const [eventNameLabel, setEventNameLabel] = React.useState<string>("");
  const [eventDescription, setEventDescription] = React.useState<string>("");
  const [eventDescriptionLabel, setEventDescriptionLabel] =
    React.useState<string>("");
  const [minutes, setMinutes] = React.useState<string | null>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [hour, setHour] = React.useState<string | null>(
    moment(date).format("HH")
  );
  const [addAccessPoints, setAddAccessPoints] = React.useState<number[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [eventId, setEventId] = React.useState<EventIdParams | null>(null);

  const disabledCreateEvent = addAccessPoints.length <= 0;

  const isoDate = `${moment(date).format(
    `YYYY-MM-DDT${hour}:${minutes}:ss.SSSZ`
  )}`;

  React.useEffect(() => {
    requestEventId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  const requestEventId = async () => {
    try {
      if (token) {
        const events: any = await api.getEventId(Number(id));
        setEventId(events);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 404) {
        showError(dispatch, labels.not_found);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.unprocessable_entity);
      } else if (error?.response?.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  React.useEffect(() => {
    getEventIdName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, eventName]);

  const getEventIdName = () => {
    if (eventId) {
      if (eventName === "") {
        setEventNameLabel(eventId?.event?.name);
      } else {
        setEventNameLabel(labels.event_name);
      }
    } else {
      setEventNameLabel(labels.event_name);
    }
  };

  React.useEffect(() => {
    getEventIdDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, eventDescription]);

  const getEventIdDescription = () => {
    if (eventId) {
      if (eventDescription === "") {
        setEventDescriptionLabel(eventId.event.description);
      } else {
        setEventDescriptionLabel(labels.event_description);
      }
    } else {
      setEventDescriptionLabel(labels.event_description);
    }
  };

  //END POINT SAVE EVENT------------------------------------------------------------------------------------->>
  //END POINT GUARDAR EVENTO--------------------------------------------------------------------------------->>
  const saveEvent = async () => {
    const payload: EditEventParams = {
      event: {
        name: eventName ? eventName : `${eventId?.event?.name}`,
        description: eventDescription
          ? eventDescription
          : `${eventId?.event?.description}`,
        start_date: isoDate,
        tolerance: Number(hoursLater),
      },
      access_servers: addAccessPoints,
    };
    try {
      setIsLoading(true);
      await api.editEvent(Number(id), payload);
      setShowSuccessModal(true);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="w-100">
      {/* <div className="container-1 w-100">
        <Grid container>
          <Grid
            item
            xs={12}
            className="w-100 flex justify-content-space-between align-items-center"
          >
            <p className="my-6 font font-24 font-w-400 secondary-color">
              {labels.edit_event}
            </p>
            <IconButton onClick={() => navigate(`/events`)} className="m-0 p-0">
              <CloseIcon className="m-0 p-0" />
            </IconButton>
          </Grid>
        </Grid>
      </div> */}
      <div className="w-100">
        <Grid container className="flex align-items-center pt-5">
          <Grid
            item
            xs={12}
            md={6}
            className="flex align-items-center justify-content-space-between"
          >
            <p className="m-0 mr-4 py-4 font font-24 font-w-400 secondary-color">
              {labels.edit_event}
            </p>
            <IconButton onClick={() => navigate(`/event-detail/${id}`)}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <div className="flex justify-content-center">
        <Paper className="w-100 mt-5 mb-2" sx={{ padding: "14px" }}>
          <div className="flex align-items-center justify-content-space-between">
            <p className="subtitle-color font-18 mt-4 mb-6">
              {labels.event_details}
            </p>
          </div>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} className="my-3">
              <TextField
                value={eventName}
                label={eventNameLabel}
                fullWidth
                onChange={(e) => {
                  setEventName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} className="my-3">
              <TextField
                value={eventDescription}
                label={eventDescriptionLabel}
                fullWidth
                multiline
                rows={2}
                onChange={(e) => {
                  setEventDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="mb-3 mt-8">
              {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label={labels.date}
                  value={date}
                  // onChange={(newDate: string | null) => {
                  //   setDate(moment(newDate).format("DD/MMM/YYYY"));
                  // }}
                  onChange={(newDate: Date | null) => {
                    setDate(newDate);
                  }}
                  // disableMaskedInput
                  format="DD/MMM/YYYY"
                  inputRef={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  // label={labels.date}
                  value={date}
                  // onChange={(newDate: string | null) => {
                  //   setTemporaryDate(moment(newDate).format("DD/MMM/YYYY"));
                  // }}
                  onChange={(newDate: Date | null) => {
                    setDate(newDate);
                  }}
                  inputFormat="DD/MMM/YYYY"
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container>
            <div className="w-100 flex justify-content-space-between">
              <div className="w-70 mr-4">
                <p className="m-0 my-2 font-14 subtitle-color">
                  {labels.allow_entry_from}
                </p>
                <div className="flex align-items-center">
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Select
                        value={hour}
                        onChange={(e) => {
                          setHour(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"00"}>00</MenuItem>
                        <MenuItem value={"01"}>01</MenuItem>
                        <MenuItem value={"02"}>02</MenuItem>
                        <MenuItem value={"03"}>03</MenuItem>
                        <MenuItem value={"04"}>04</MenuItem>
                        <MenuItem value={"05"}>05</MenuItem>
                        <MenuItem value={"06"}>06</MenuItem>
                        <MenuItem value={"07"}>07</MenuItem>
                        <MenuItem value={"08"}>08</MenuItem>
                        <MenuItem value={"09"}>09</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                        <MenuItem value={"17"}>17</MenuItem>
                        <MenuItem value={"18"}>18</MenuItem>
                        <MenuItem value={"19"}>19</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"21"}>21</MenuItem>
                        <MenuItem value={"22"}>22</MenuItem>
                        <MenuItem value={"23"}>23</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <span className="mx-3"> : </span>
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Select
                        value={minutes}
                        onChange={(e) => {
                          setMinutes(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"00"}>00</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"30"}>30</MenuItem>
                        <MenuItem value={"45"}>45</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                <p className="m-0 my-2 font-12 title-color">
                  {labels.select_entry_time}
                </p>
              </div>
              <div className="w-30 ml-4">
                <p className="m-0 my-2 font-14 subtitle-color">
                  {labels.until}
                </p>
                <div>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        value={hoursLater}
                        onChange={(e) => {
                          setHoursLater(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Whithout label" }}
                      >
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                        <MenuItem value={"17"}>17</MenuItem>
                        <MenuItem value={"18"}>18</MenuItem>
                        <MenuItem value={"19"}>19</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"21"}>21</MenuItem>
                        <MenuItem value={"22"}>22</MenuItem>
                        <MenuItem value={"23"}>23</MenuItem>
                        <MenuItem value={"24"}>24</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                <p className="m-0 my-2 font-12 title-color">
                  {labels.hours_later}
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </div>
      <div className="flex justify-content-center">
        <Paper className="w-100 my-2" sx={{ padding: "14px" }}>
          <p className="subtitle-color font-18 mt-6 mb-6">
            {labels.access_points}
          </p>
          <>
            <p className="title-color font-12 m-0 p-0 mb-3">
              {labels.visitor_access_points}
            </p>
            {user?.access_servers.map(({ name, venue_name, id }, index) => (
              <div key={id} className="flex align-items-center mb-3">
                <Checkbox
                  onChange={() => {
                    let elementFind = addAccessPoints.find(
                      (element) => element === id
                    );
                    let finalIndex = addAccessPoints.indexOf(id);
                    const addAccessPointsClone = [...addAccessPoints];
                    if (!elementFind) {
                      addAccessPointsClone.push(id);
                    } else {
                      addAccessPointsClone.splice(finalIndex, 1);
                    }
                    setAddAccessPoints(addAccessPointsClone);
                  }}
                  color="secondary"
                />
                <div className="flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0">{name}</p>
                  <p className="title-color font-12 m-0 p-0">{venue_name}</p>
                </div>
              </div>
            ))}
          </>
        </Paper>
      </div>

      <Button
        disabled={disabledCreateEvent}
        fullWidth
        variant="contained"
        size="large"
        disableElevation
        color="primary"
        className="py-5 mt-6 mb-10 create-event-button"
        onClick={
          isLoading
            ? () => {}
            : () => {
                saveEvent();
              }
        }
      >
        {isLoading ? (
          <CircularProgress size={26.5} style={{ color: "white" }} />
        ) : (
          labels.save_event
        )}
      </Button>
      <div className="lower-div root-background" />

      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">{labels.event_saved}</p>
            <Button
              variant="contained"
              disableElevation
              className={"my-5 w-60 py-5"}
              onClick={() => {
                setShowSuccessModal(false);
                setTimeout(() => navigate("/events"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default EditEvent;
