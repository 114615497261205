import {
  Container,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  Paper,
  Select,
  MenuItem,
  IconButton,
  Modal,
  Divider,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import api, { GetCommunityMember, GetCommunityMembers } from "../../api";
import { selectLabels } from "../../slices/language";
import SearchIcon from "@mui/icons-material/Search";
import "./my-favorites.scss";
import { showError } from "../../utils/helper";
import { orange } from "@mui/material/colors";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { selectToken } from "../../slices/token";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { Box } from "@mui/system";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const MyFavorites = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const [communityMembersList, setCommunityMembersList] =
    React.useState<GetCommunityMembers | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [selectedRow, setSelectedRow] =
    React.useState<GetCommunityMember | null>(null);
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);

  //FUNCTION CHANGE PAGE (UP)-------------------------------------------------->>
  //FUNCION CAMBIAR PAGINA (ARRIBA)-------------------------------------------->>
  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  //FUNCTION CHANGE PAGE (DOWN)------------------------------------------------>>
  //FUNCION CAMBIAR PAGINA (ABAJO)--------------------------------------------->>
  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  //FUNCTION CHANGE TEXT (SEARCH)---------------------------------------------->>
  //FUNCION CAMBIAR TEXTO (BUSQUEDA)------------------------------------------->>
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    setPage(1);
  };

  //FUNCTION OPEN CLOSE DETAILS MODAL------------------------------------------>>
  //FUNCION ABRIR Y CERRAR MODAL DETALES--------------------------------------->>
  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);

  // END POINT COMMUNITY MEMBERS LIST---------------------------------------------------------->>
  // END POINT LISTA DE COMMUNITY MEMBERS------------------------------------------------------>>
  React.useEffect(() => {
    getCommunityMembersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, rowsPerPage, searchInput]);

  const getCommunityMembersData = async () => {
    try {
      if (token) {
        const result = await api.communityMembersRequest(
          page,
          rowsPerPage,
          searchInput
        );
        setCommunityMembersList(result);
      }
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.invalidCredentials);
    }
  };

  console.log("selectedRow", selectedRow);

  return (
    <Container>
      <div className="container-1 w-100">
        <Grid container className="flex align-items-center">
          <Grid item xs={7}>
            <p className="my-6 font font-24 font-w-400 secondary-color">
              {labels.my_favorites}
            </p>
          </Grid>
          <Grid item xs={5} className="flex justify-content-flex-end">
            <div
              onClick={() => navigate("/new-invitation/:cmId")}
              className="m-0 p-0 flex justify-content-center align-items-center button-style"
            >
              <MailOutlineRoundedIcon
                color="primary"
                sx={{ fontSize: 35 }}
                className="p-0 m-0"
              />
              <div className="flex flex-dir-col pr-3">
                <p className="text-center p-0 m-0 font-12 text-primary-color">
                  {labels.new_invitation}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} className="mt-4">
          <p className="title-color m-0 p-0 ml-3 mt-1 mb-4">
            {labels.search_visitor_by}
          </p>
          <FormControl fullWidth>
            <TextField
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchInput}
              onChange={handleSearchChange}
              label={labels.search}
              placeholder={labels.search_by}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Paper className="mt-5 pb-3">
        <table className="table-style">
          <thead className="w-100">
            <tr className="table-title-style">
              <th className="visitor-column">
                <p className="py-3">{labels.visitor}</p>
              </th>
              <th className="title-cell host-column">
                <p className="py-3">{labels.host}</p>
              </th>
              <th className="trash-column">
                <EmailRoundedIcon />
              </th>
            </tr>
          </thead>
          <tbody>
            {communityMembersList?.community_members && communityMembersList.community_members.length > 0 ? (
              communityMembersList?.community_members.map((row) => (
                <tr
                  key={row.id}
                  className="table-row-style"
                  onClick={() => {
                    setSelectedRow(row);
                    handleOpenDetails();
                  }}
                >
                  <td className="px-2">
                    <p className="p-0 m-0 mt-4 mb-3 font-14 subtitle-color text-center">
                      {row.name}
                    </p>
                    <p className="p-0 m-0 mb-4 mt-2 font-12 title-color text-center">
                      {row.phone}
                    </p>
                  </td>
                  <td className="px-2 cell-name-body py-3">
                    <p className="p-0 m-0 text-center">{row.user.name}</p>
                  </td>
                  <td className="px-2 text-center">
                    <ContactMailIcon
                      sx={{ fontSize: "30px", color: orange[700] }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/new-invitation/${row.id}`);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <p className="subtitle-color my-8 text-center">
                    {labels.noItems}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* PAGINATION-------------------------------------------------------------------------->> */}
        {/* PAGINACION-------------------------------------------------------------------------->> */}
        {communityMembersList?.total_pages ? (
          <div className="w-100 flex align-items-center justify-content-flex-end my-4">
            <div className="flex align-items-center mr-4">
              <p className="pr-2">{labels.rows}</p>
              <FormControl>
                <Select
                  value={rowsPerPage}
                  size="small"
                  onChange={(e) => {
                    setRowsPerPage(Number(e.target.value));
                    setPage(1);
                  }}
                  inputProps={{ "aria-label": "Whithout label" }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex mx-2">
              <p>
                {labels.page}{" "}
                <span className="text-secondary-color">{page}</span> {labels.of}{" "}
                <span className="text-secondary-color">
                  {communityMembersList?.total_pages}
                </span>
              </p>
            </div>
            <div className="mx-2">
              <IconButton disabled={page <= 1} onClick={handleChangeDownPage}>
                <NavigateBeforeRoundedIcon />
              </IconButton>
              <IconButton
                disabled={page >= communityMembersList?.total_pages}
                onClick={handleChangeUpPage}
              >
                <NavigateNextRoundedIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <div />
        )}
      </Paper>
      <div className="lower-div mt-8 root-background" />

      {/* DETAILS MODAL----------------------------------------------------------------------->> */}
      {/* MODAL DETALLES---------------------------------------------------------------------->> */}
      <Modal disableEscapeKeyDown open={openDetails}>
        <Box sx={style}>
          <div className="w-100 flex justify-content-space-between align-items-center">
            <p className="uppercase ml-5 font-14 title-color">{selectedRow?.user.network_group.name}</p>
            <IconButton
              size="large"
              aria-label="photo"
              onClick={() => {
                handleCloseDetails();
              }}
            >
              <CloseRoundedIcon fontSize="inherit" />
            </IconButton>
          </div>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center"
          >
            {/* <Divider className="w-100 m-0 divider-style" /> */}
            <div className="w-100 m-0 divider-div-style" />
            <div className="w-100 background-border-grey py-8 mt-5 mb-5">
              <p className="text-primary-color font-18 mb-5 mt-0 text-center">
                {selectedRow?.name}
              </p>
              <p className="title-color font-16 m-0 p-0 my-3 text-center">
                {labels.phone} {selectedRow?.phone}
              </p>
              <p className="title-color font-16 mt-0 mb-0 text-center">
                {selectedRow?.email}
              </p>
            </div>
            <p className="title-color font-14 m-0 p-0 mt-3 mb-1 text-center">
              {labels.saved_by_user}
            </p>
            <p className="text-secondary-color font-16 m-0 p-0 mt-3 mb-1 text-center">
              {selectedRow?.user.name}
            </p>
            {/* <div className="w-100 my-6 divider-div-style" /> */}
            <Divider className="w-100 mt-6 mb-2" />
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default MyFavorites;
