import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { selectLabels } from "../../slices/language";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import api, {
  GetLogbookRegisterTransaction,
  LogExitRegisterParams,
} from "../../api";
import { showError } from "../../utils/helper";
import { resetToken } from "../../slices/token";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import "./transaction-id.scss";
import { FaCarAlt, FaIdCard, FaUser, FaLaptop } from "react-icons/fa";
import moment from "moment-timezone";
import ButtonBottomComponent from "../../components/ButtonBottomComponent";
import CloseIcon from "@mui/icons-material/Close";
import { IoIosCloseCircle } from "react-icons/io";
import { MdSubtitles } from "react-icons/md";
import { BACK_ID_NETWORKS } from "../../utils/constants";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  outline: 0,
  border: 0,
};

const TransactionIdDetail = () => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const user = useAppSelector(selectUser);

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transactionId, setTransactionId] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [findTransactionId, setFindTransactionId] =
    React.useState<GetLogbookRegisterTransaction | null>(null);
  const [transactionPhoto, setTransactionPhoto] = React.useState<string>("");
  const [photoType, setPhotoType] = React.useState<
    "id" | "front_plates" | "back_plates" | "other" | "accreditation" | ""
  >("");
  const [registerButton, setRegisterButton] = React.useState<boolean>(false);
  const [selectExitDoorModal, setSelectExitDoorModal] =
    React.useState<boolean>(false);
  const [exitDoor, setExitDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(({ type }) => type === "exit")[0]
        ?.id
    }`
  );
  const [showSuccessExitModal, setShowSuccessExitModal] =
    React.useState<boolean>(false);

  const customAttributesArray: any[] = transactionId?.custom_attrs
    ? Object.entries(transactionId?.custom_attrs)
    : [];

  const disabledExitContinueButton = !exitDoor;

  const handleExitDoorChange = (event: SelectChangeEvent) => {
    setExitDoor(event.target.value);
  };

  const registerModalAction = () => {
    setSelectExitDoorModal(false);
    if (transactionId?.access_key_id === null) {
      generateExitRegister();
    } else {
      if (transactionId?.access_key_type === "provider") {
        onValidateExitInvitation();
      }
    }
  };

  const onValidateExitInvitation = () => {
    setTimeout(
      () =>
        navigate(
          `/provider-transaction/${transactionId?.access_key_id}/${exitDoor}`
        ),
      500
    );
  };

  const onContinueButtonAction = () => {
    setShowSuccessExitModal(false);
    setTimeout(() => navigate("/logbook"), 500);
  };

  React.useEffect(() => {
    getRegisterButtonInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const getRegisterButtonInitialState = () => {
    if (userRole === "guard") {
      if (transactionId?.access_type === "access") {
        if (transactionId?.access_granted !== false) {
          if (transactionId.access_allowed !== false) {
            if (transactionId.related_transaction_id === null) {
              setRegisterButton(true);
            }
          }
        }
      }
    }
  };

  const registerExitButtonAction = () => {
    if (transactionId?.access_key_id !== null) {
      if (transactionId?.access_key_type === "provider") {
        setSelectExitDoorModal(true);
      } else {
        setTimeout(() => navigate("/qr-scanner"), 100);
      }
    } else {
      setSelectExitDoorModal(true);
    }
  };

  React.useEffect(() => {
    getInitialPhotoType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, transactionId, findTransactionId]);

  const getInitialPhotoType = () => {
    if (findTransactionId && transactionId?.access_type === "exit") {
      const idTransactionPhoto = findTransactionId?.transaction_photos?.find(
        ({ access_server_photo_type }) => access_server_photo_type === "id"
      );
      const frontPlatesTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "front_plates"
        );
      const backPlatesTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "back_plates"
        );
      const otherTransactionPhoto = findTransactionId?.transaction_photos?.find(
        ({ access_server_photo_type }) => access_server_photo_type === "other"
      );
      const accreditationTransactionPhoto =
        findTransactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) =>
            access_server_photo_type === "accreditation"
        );
      if (idTransactionPhoto) {
        setPhotoType("id");
      } else if (frontPlatesTransactionPhoto) {
        setPhotoType("front_plates");
      } else if (backPlatesTransactionPhoto) {
        setPhotoType("back_plates");
      } else if (otherTransactionPhoto) {
        setPhotoType("other");
      } else if (accreditationTransactionPhoto) {
        setPhotoType("accreditation");
      }
    } else {
      if (transactionId && transactionId?.access_type === "access") {
        const idTransactionPhoto = transactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) => access_server_photo_type === "id"
        );
        const frontPlatesTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "front_plates"
          );
        const backPlatesTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "back_plates"
          );
        const otherTransactionPhoto = transactionId?.transaction_photos?.find(
          ({ access_server_photo_type }) => access_server_photo_type === "other"
        );
        const accreditationTransactionPhoto =
          transactionId?.transaction_photos?.find(
            ({ access_server_photo_type }) =>
              access_server_photo_type === "accreditation"
          );
        if (idTransactionPhoto) {
          setPhotoType("id");
        } else if (frontPlatesTransactionPhoto) {
          setPhotoType("front_plates");
        } else if (backPlatesTransactionPhoto) {
          setPhotoType("back_plates");
        } else if (otherTransactionPhoto) {
          setPhotoType("other");
        } else if (accreditationTransactionPhoto) {
          setPhotoType("accreditation");
        }
      }
    }
  };

  React.useEffect(() => {
    getTransactionPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoType, findTransactionId, transactionId]);

  const getTransactionPhoto = () => {
    if (findTransactionId && transactionId?.access_type === "exit") {
      const filteredTransactionPhoto =
        findTransactionId?.transaction_photos?.filter(
          ({ access_server_photo_type }) =>
            access_server_photo_type === photoType
        );
      setTransactionPhoto(filteredTransactionPhoto[0]?.url);
    } else {
      if (transactionId && transactionId?.access_type === "access") {
        const filteredTransactionPhoto =
          transactionId?.transaction_photos?.filter(
            ({ access_server_photo_type }) =>
              access_server_photo_type === photoType
          );
        setTransactionPhoto(filteredTransactionPhoto[0]?.url);
      }
    }
  };

  // END POINT GET TRANSACTION ID-------------------------------------------------------------------------->>
  // END POINT TOMAR ID DE TRANSACCION--------------------------------------------------------------------->>
  React.useEffect(() => {
    if (id) {
      getTransactionId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getTransactionId = async () => {
    try {
      setIsLoading(true);
      const result = await api.transactionIdRequest(Number(id));
      setTransactionId(result);
      if (result?.related_transaction_id) {
        const resultRelatedTransaction = await api.transactionIdRequest(
          result?.related_transaction_id
        );
        setFindTransactionId(resultRelatedTransaction);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.unprocessable_entity);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // END POINT GENERATE EXIT TRANSACTION------------------------------------------------------------------->>
  // END POINT GENERAR REGISTRO DE SALIDA------------------------------------------------------------------>>
  const generateExitRegister = async () => {
    try {
      const payload: LogExitRegisterParams = {
        transaction_id: Number(transactionId?.id),
        gate_id: Number(exitDoor),
      };
      await api.logExitRegisterRequest(payload);
      setShowSuccessExitModal(true);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  return (
    <div
      className="w-100 flex flex-dir-col align-items-center"
      style={{ paddingBottom: registerButton ? 130 : 70 }}
    >
      <div
        className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary"
        style={{ height: 60 }}
      >
        <div style={{ width: "20%" }}></div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="font-14 uppercase text-primary-color medium">
            {labels.register_details}
          </p>
        </div>
        <div
          className="flex align-items-center justify-content-flex-end"
          style={{ width: "20%" }}
        >
          <CloseRoundedIcon
            className="primary-color"
            style={{ marginRight: 20 }}
            onClick={() => navigate("/logbook")}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div
          className="w-90 flex flex-dir-col justify-content-center align-items-center shadow-one"
          style={{
            marginRight: 10,
            marginLeft: 10,
            marginTop: 10,
            marginBottom: 10,
            padding: 8,
            backgroundColor: "white",
            borderRadius: 5,
            maxWidth: 500,
          }}
        >
          {transactionId?.access_allowed === false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex py-5">
                <div className="w-100 flex flex-dir-col justify-content-center ml-4">
                  <div className="flex align-items-center">
                    <IoIosCloseCircle
                      fontSize="28px"
                      className="text-warning m-0 p-0"
                    />
                    <p className="uppercase font-18 medium subtitle-color m-0 p-0 ml-3">
                      {labels.access_denied}
                    </p>
                  </div>
                  <p className="font-14 text-warning m-0 p-0 mt-8 ml-3">
                    {labels.reason}: {transactionId?.notes}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_granted === false && (
            <div className="w-100 flex py-5">
              <div className="w-100 flex flex-dir-col justify-content-center ml-4">
                <div className="flex align-items-center">
                  <IoIosCloseCircle
                    fontSize="30px"
                    className="text-warning m-0 p-0"
                  />
                  <p className="uppercase font-18 medium subtitle-color m-0 p-0 ml-3">
                    {labels.unauthorized_access}
                  </p>
                </div>
                <p className="font-14 text-warning m-0 p-0 mt-8 ml-3">
                  {labels.status}:{" "}
                  {transactionId?.access_key_status?.description ===
                    "access_removed" && labels.qr_code_deleted}
                  {transactionId?.access_key_status?.description ===
                    "not_in_time" && labels.access_out_of_time}
                  {transactionId?.access_key_status?.description ===
                    "door_not_included" && labels.access_door_denied}
                  {transactionId?.access_key_status?.description ===
                    "only_access" && labels.last_access_not_registered}
                  {transactionId?.access_key_status?.description ===
                    "only_exit" && labels.last_exit_not_registered}
                  {transactionId?.access_key_status?.description ===
                    "visitor_key" && labels.new_qr_code}
                  {transactionId?.access_key_status?.description ===
                    "not_during_scheduled_hour" &&
                    labels.hour_access_unauthorized}
                </p>
              </div>
            </div>
          )}
          <div className="w-100 flex flex-dir-col background-gray-color align-items-flex-start py-5">
            <div className="ml-6">
              {transactionId?.access_key_type === "visit" && (
                <p className="p-0 m-0 medium font-14">{labels.visitor}</p>
              )}
              {transactionId?.access_key_type === "provider" && (
                <p className="p-0 m-0 medium font-14">{labels.provider}</p>
              )}
              {transactionId?.access_key_type === "personal" && (
                <p className="p-0 m-0 medium font-14">{labels.host}</p>
              )}
              {transactionId?.access_key_type === "temporary" && (
                <p className="p-0 m-0 medium font-14">{labels.temporary}</p>
              )}
              {transactionId?.access_key_type === "visitor" && (
                <p className="p-0 m-0 medium font-14">{labels.visitor}</p>
              )}
              {transactionId?.access_key_type === "eventbrite" && (
                <p className="p-0 m-0 medium font-14">{labels.event}</p>
              )}
              {transactionId?.access_key_type === "passtrack_event" && (
                <p className="p-0 m-0 medium font-14">{labels.event}</p>
              )}
            </div>
            <p className="medium font-18 mb-0 mt-2 ml-6 mr-6">
              {transactionId?.visitor_name}
            </p>
          </div>
          {transactionId?.access_type === "exit"
            ? findTransactionId &&
              findTransactionId?.transaction_photos.length > 0 && (
                <div className="w-100 background-gray-color flex flex-dir-col justify-content-center align-items-center mt-4 mb-4 pt-4 pb-4">
                  <div className="w-100 flex justify-content-center align-items-center">
                    <img
                      src={transactionPhoto}
                      alt="captured_photo_id"
                      className="transaction-photo-style"
                    />
                  </div>
                  <div className="w-100 flex justify-content-center align-items-center mt-5 mb-2">
                    {findTransactionId?.transaction_photos.map(
                      ({ access_server_photo_type }) => (
                        <div key={access_server_photo_type}>
                          {access_server_photo_type === "id" && (
                            <FaIdCard
                              fontSize="24px"
                              className={
                                photoType === "id"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("id")}
                            />
                          )}
                          {access_server_photo_type === "front_plates" && (
                            <FaCarAlt
                              fontSize="24px"
                              className={
                                photoType === "front_plates"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("front_plates")}
                            />
                          )}
                          {access_server_photo_type === "back_plates" &&
                            user?.network.id !== BACK_ID_NETWORKS && (
                              <FaUser
                                fontSize="24px"
                                className={
                                  photoType === "back_plates"
                                    ? "primary-color m-0 p-0 mx-5"
                                    : "title-color m-0 p-0 mx-5"
                                }
                                onClick={() => setPhotoType("back_plates")}
                              />
                            )}
                          {access_server_photo_type === "back_plates" &&
                            user?.network.id === BACK_ID_NETWORKS && (
                              <MdSubtitles
                                fontSize="24px"
                                className={
                                  photoType === "back_plates"
                                    ? "primary-color m-0 p-0 mx-5"
                                    : "title-color m-0 p-0 mx-5"
                                }
                                onClick={() => setPhotoType("back_plates")}
                              />
                            )}
                          {access_server_photo_type === "other" && (
                            <FaLaptop
                              fontSize="24px"
                              className={
                                photoType === "other"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("other")}
                            />
                          )}
                          {access_server_photo_type === "accreditation" && (
                            <FaUser
                              fontSize="24px"
                              className={
                                photoType === "accreditation"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("accreditation")}
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )
            : transactionId &&
              transactionId?.transaction_photos.length > 0 && (
                <div className="w-100 background-gray-color flex flex-dir-col justify-content-center align-items-center mt-4 mb-4 pt-4 pb-4">
                  <div className="w-100 flex justify-content-center align-items-center">
                    <img
                      src={transactionPhoto}
                      alt="captured_photo_id"
                      className="transaction-photo-style"
                    />
                  </div>
                  <div className="w-100 flex justify-content-center align-items-center mt-5 mb-2">
                    {transactionId?.transaction_photos.map(
                      ({ access_server_photo_type }) => (
                        <div key={access_server_photo_type}>
                          {access_server_photo_type === "id" && (
                            <FaIdCard
                              fontSize="24px"
                              className={
                                photoType === "id"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("id")}
                            />
                          )}
                          {access_server_photo_type === "front_plates" && (
                            <FaCarAlt
                              fontSize="24px"
                              className={
                                photoType === "front_plates"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("front_plates")}
                            />
                          )}
                          {access_server_photo_type === "back_plates" &&
                            user?.network?.id !== BACK_ID_NETWORKS && (
                              <FaUser
                                fontSize="24px"
                                className={
                                  photoType === "back_plates"
                                    ? "primary-color m-0 p-0 mx-5"
                                    : "title-color m-0 p-0 mx-5"
                                }
                                onClick={() => setPhotoType("back_plates")}
                              />
                            )}
                          {access_server_photo_type === "back_plates" &&
                            user?.network?.id === BACK_ID_NETWORKS && (
                              <MdSubtitles
                                fontSize="24px"
                                className={
                                  photoType === "back_plates"
                                    ? "primary-color m-0 p-0 mx-5"
                                    : "title-color m-0 p-0 mx-5"
                                }
                                onClick={() => setPhotoType("back_plates")}
                              />
                            )}
                          {access_server_photo_type === "other" && (
                            <FaLaptop
                              fontSize="24px"
                              className={
                                photoType === "other"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("other")}
                            />
                          )}
                          {access_server_photo_type === "accreditation" && (
                            <FaUser
                              fontSize="24px"
                              className={
                                photoType === "accreditation"
                                  ? "primary-color m-0 p-0 mx-5"
                                  : "title-color m-0 p-0 mx-5"
                              }
                              onClick={() => setPhotoType("accreditation")}
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
          <Divider className="w-100" />
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {transactionId?.access_allowed === false &&
                  transactionId?.access_granted !== false &&
                  labels.host}
                {transactionId?.access_granted === false && labels.host}
                {transactionId?.access_allowed !== false &&
                  transactionId?.access_granted !== false &&
                  labels.visited}
                :
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="font-14 m-0 p-0 medium">
                {transactionId?.user?.name}
              </p>
              <p className="subtitle-color font-12 m-0 p-0 mt-2">
                {transactionId?.user?.group}
              </p>
            </div>
          </div>
          {transactionId?.related_transaction_id &&
            transactionId?.access_type === "exit" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_access_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(findTransactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {findTransactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_type === "exit" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_exit_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(transactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {transactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_type === "access" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_access_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(transactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {transactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.related_transaction_id &&
            transactionId?.access_type === "access" &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.with_exit_register}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(findTransactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {findTransactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )}
          {transactionId?.access_granted !== false ? (
            transactionId?.access_allowed === false && (
              <div className="w-100 flex my-5">
                <div className="w-30 flex justify-content-flex-start px-6">
                  <p className="subtitle-color font-14 medium m-0 p-0">
                    {labels.date_hour}:
                  </p>
                </div>
                <div className="w-70 flex flex-dir-col">
                  <p className="subtitle-color font-14 m-0 p-0 medium">
                    {moment(transactionId?.created_at).format(
                      "dddd, DD MMM YY - hh:mma"
                    )}
                  </p>
                  <p className="subtitle-color font-12 m-0 p-0 mt-2">
                    {transactionId?.access_server?.name}
                  </p>
                </div>
              </div>
            )
          ) : (
            <div className="w-100 flex my-5">
              <div className="w-30 flex justify-content-flex-start px-6">
                <p className="subtitle-color font-14 medium m-0 p-0">
                  {labels.date_hour}:
                </p>
              </div>
              <div className="w-70 flex flex-dir-col">
                <p className="subtitle-color font-14 m-0 p-0 medium">
                  {moment(transactionId?.created_at).format(
                    "dddd, DD MMM YY - hh:mma"
                  )}
                </p>
                <p className="subtitle-color font-12 m-0 p-0 mt-2">
                  {transactionId?.access_server?.name}
                </p>
              </div>
            </div>
          )}
          <Divider className="w-100" />
          {transactionId?.custom_attrs &&
            customAttributesArray.length > 0 &&
            transactionId?.access_allowed !== false &&
            transactionId?.access_granted !== false && (
              <div className="w-100">
                {customAttributesArray.map((item, index) => (
                  <div className="w-100 flex my-6" key={index}>
                    <div className="w-30 flex justify-content-flex-start px-6">
                      <p className="subtitle-color font-14 medium m-0 p-0">
                        {item[0]}:
                      </p>
                    </div>
                    <div className="w-70 flex flex-dir-col">
                      <p className="subtitle-color font-14 m-0 p-0 medium">
                        {item[1]}
                      </p>
                    </div>
                  </div>
                ))}
                <Divider className="w-100" />
              </div>
            )}
          <div className="w-100 flex my-5">
            <div className="w-30 flex justify-content-flex-start px-6">
              <p className="subtitle-color font-14 medium m-0 p-0">
                {labels.record_created_by}
              </p>
            </div>
            <div className="w-70 flex flex-dir-col">
              <p className="font-14 m-0 p-0 medium">
                {transactionId?.created_by_user
                  ? transactionId?.created_by_user?.name
                  : labels.na}
              </p>
            </div>
          </div>
        </div>
      )}
      {registerButton && (
        <ButtonBottomComponent
          text={labels.check_out}
          action={registerExitButtonAction}
        />
      )}

      {/* SELECT EXIT DOOR MODAL------------------------------------->>*/}
      {/* MODAL SELECCIONAR PUERTA DE SALIDA------------------------->>*/}
      <Modal disableEscapeKeyDown open={selectExitDoorModal}>
        <Box sx={style}>
          <Grid container className="vertical-spacing-4">
            <Grid
              item
              xs={12}
              className="flex justify-content-space-between align-items-center m-0 p-0 mb-2 mt-3"
            >
              <p className="subtitle-color font-16">
                {labels.select_exit_door}
              </p>
              <IconButton
                onClick={() => {
                  setSelectExitDoorModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className="w-100 align-items-center justify-content-center vertical-spacing-5"
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-door-label">{labels.door}</InputLabel>
                <Select
                  labelId="select-door-label"
                  value={exitDoor}
                  onChange={handleExitDoorChange}
                  label={labels.door}
                >
                  {user?.last_access_selected?.gates
                    .filter(({ type }) => type === "exit")
                    .map(({ name, id }) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Button
              variant="contained"
              disableElevation
              onClick={registerModalAction}
              disabled={disabledExitContinueButton}
              className="mb-8"
            >
              {labels.continue}
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* MODAL - SUCCESS REGISTER------------------------------------------------>> */}
      {/* MODAL - REGISTRO EXITOSO------------------------------------------------>> */}
      <Modal disableEscapeKeyDown open={showSuccessExitModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {labels.success_register}
            </p>
            <Button
              variant="contained"
              disableElevation
              onClick={onContinueButtonAction}
            >
              {labels.continue}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default TransactionIdDetail;
