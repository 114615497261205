import { Box, Modal } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FaCopy, FaPrint, FaEnvelope } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";

interface ModalShareProps {
  title: string;
  showModal: boolean;
  closeAction: () => void;
  copyAction: () => void;
  printAction: () => void;
  mailAction: () => void;
  copyLink: any;
  hasEmail: boolean;
}

const ModalShare = ({
  title,
  showModal,
  copyAction,
  printAction,
  mailAction,
  closeAction,
  copyLink,
  hasEmail,
}: ModalShareProps) => {
  return (
    <>
      <Modal disableEscapeKeyDown open={showModal}>
        <Box sx={style}>
          <div
            className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary mt-4"
            style={{ height: 42 }}
          >
            <div style={{ width: "20%" }}></div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="font-14 uppercase text-primary-color medium">
                {title}
              </p>
            </div>
            <div
              className="flex align-items-center justify-content-flex-end"
              style={{ width: "20%" }}
            >
              <CloseRoundedIcon
                className="primary-color"
                onClick={closeAction}
              />
            </div>
          </div>
          <div className="w-100 flex justify-content-center aling-items-center mt-10 mb-10">
            <div
              className="w-80 flex align-items-center justify-content-space-evenly"
              style={{ maxWidth: 300 }}
            >
              <CopyToClipboard text={copyLink}>
                <FaCopy
                  className="primary-color"
                  style={{ fontSize: 28 }}
                  onClick={copyAction}
                />
              </CopyToClipboard>
              <FaPrint
                className="primary-color"
                style={{ fontSize: 28 }}
                onClick={printAction}
              />
              {hasEmail ? (
                <FaEnvelope
                  className="primary-color"
                  style={{ fontSize: 28 }}
                  onClick={mailAction}
                />
              ) : (
                <FaEnvelope
                  className="text-disabled-color"
                  style={{ fontSize: 28 }}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};

export default ModalShare;
