import {
  Container,
  Grid,
  IconButton,
  Modal,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import api, { AccessKey } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { showError } from "../../utils/helper";
import moment from "moment-timezone";
import "moment-timezone/node_modules/moment/locale/es";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import QRCode from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import { resetToken } from "../../slices/token";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  FaSignInAlt,
  FaSignOutAlt,
  FaLock,
  FaEnvelope,
  FaEdit,
  FaTrash,
  FaPhone,
} from "react-icons/fa";
import ModalOneButton from "../../components/ModalOneButton";
import ModalShare from "../../components/ModalShare";
import ButtonBottom from "../../components/ButtonBottom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
};

const InvitationIdDetail = () => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  // var moment = require("moment-timezone");

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [invitationId, setInvitationId] = React.useState<AccessKey | null>(
    null
  );
  const [copiedLinkModal, setCopiedLinkModal] = React.useState<boolean>(false);
  const [accessDoor, setAccessDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(
        ({ type }) => type === "access"
      )[0]?.id
    }`
  );
  const [selectDoorModal, setSelectDoorModal] = React.useState<boolean>(false);
  const [exitDoor, setExitDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(({ type }) => type === "exit")[0]
        ?.id
    }`
  );
  const [selectExitDoorModal, setSelectExitDoorModal] =
    React.useState<boolean>(false);
  const [validFromText, setValidFromText] = React.useState<string>("");
  const [validUntilText, setValidUntilText] = React.useState<string>("");
  const [validFromHour, setValidFromHour] = React.useState<string>("");
  const [validUntilHour, setValidUntilHour] = React.useState<string>("");
  const [qrCodeContainerWidth, setQrCodeContainerWidth] =
    React.useState<number>(0);
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [shareModal, setShareModal] = React.useState<boolean>(false);

  const ref: any = React.useRef(null);

  React.useLayoutEffect(() => {
    if (invitationId?.access_key_type_id !== 5) {
      setQrCodeContainerWidth(ref.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledContinueButton = !accessDoor;
  const disabledExitContinueButton = !exitDoor;

  const emailSubject = React.useMemo(() => {
    return `Passtrack - ${labels.invitation_text_email} ${moment(
      invitationId?.valid_from
    ).format("DD/MM/YYYY")}`;
  }, [labels.invitation_text_email, invitationId?.valid_from]);

  const handleAccessDoorChange = (event: SelectChangeEvent) => {
    setAccessDoor(event.target.value);
  };

  const handleExitDoorChange = (event: SelectChangeEvent) => {
    setExitDoor(event.target.value);
  };

  React.useEffect(() => {
    changeAccessDoor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const changeAccessDoor = () => {
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0].id
      }`
    );
  };

  React.useEffect(() => {
    changeExitDoor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const changeExitDoor = () => {
    setExitDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "exit"
        )[0].id
      }`
    );
  };

  const onValidateAccessInvitation = () => {
    setTimeout(
      () => navigate(`/provider-transaction/${id}/${accessDoor}`),
      500
    );
  };

  const onValidateExitInvitation = () => {
    setTimeout(() => navigate(`/provider-transaction/${id}/${exitDoor}`), 500);
  };

  const onCloseCopiedModal = () => {
    setCopiedLinkModal(false);
  };

  const onCopyInvitationLink = () => {
    setCopiedLinkModal(true);
    setTimeout(onCloseCopiedModal, 1500);
  };

  const momentLang = () => {
    if (navigator.language.includes("es")) {
      moment.locale("es");
      moment.localeData("es");
    } else {
      moment.locale("en");
      moment.localeData("en");
    }
  };

  React.useEffect(() => {
    momentLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (id) {
      getInvitationById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getInvitationById = async () => {
    if (id) {
      try {
        setIsLoading(true);
        const result = await api.invitationIdRequest(Number(id));
        setInvitationId(result);
        setValidFromText(
          moment(result.valid_from)
            .tz(result.access_servers[0]?.time_zone_name)
            .format("DD MMMM")
        );
        setValidUntilText(
          moment(result.valid_until)
            .tz(result.access_servers[0]?.time_zone_name)
            .format("DD MMMM")
        );
        setValidFromHour(
          moment(result.valid_from)
            .tz(result.access_servers[0]?.time_zone_name)
            .format("h:mma")
        );
        setValidUntilHour(
          moment(result.valid_until)
            .tz(result.access_servers[0]?.time_zone_name)
            .format("h:mma")
        );
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const qrCode = invitationId?.unique_id;
  const invitationLink =
    "https://app.passtrack.mx/my_access_key?locale=es&uuid=";
  const invitationMailLink = "https://app.passtrack.mx/my_access_key?locale=es";
  const finalLink = `${invitationLink}${qrCode}`;

  const onClickEditInvitation = () => {
    setTimeout(() => navigate(`/edit-invitation/${id}`), 500);
  };

  //END POINT DELETE INVITATION----------------------------------------------------------------------->>
  //END POINT BORRAR INVITACION----------------------------------------------------------------------->>
  const deleteInvitation = async () => {
    if (invitationId) {
      try {
        await api.deleteAccessKey(Number(invitationId?.id));
        setDeleteModal(false);
        navigate(`/invitations`);
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.errors.not_authorized);
        } else if (error.response.stauts === 422) {
          showError(dispatch, labels.errors.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.errors.generic_error);
        }
      }
    }
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const openShareModal = () => {
    setShareModal(true);
  };

  const onCloseShareModal = () => {
    setShareModal(false);
  };

  const printAction = () => {
    onCloseShareModal();
    setTimeout(() => {
      window.print();
    }, 500);
  };

  const customAttributesArray: any[] = invitationId?.custom_attrs
    ? Object.entries(invitationId?.custom_attrs)
    : [];

  return (
    <div style={{ paddingBottom: 40 }}>
      <ModalOneButton
        title={labels.delete_access_key}
        bodyText={`${labels.delete_invitation} ${invitationId?.visitor_name}`}
        showModal={deleteModal}
        buttonText={labels.delete}
        buttonAction={deleteInvitation}
        closeAction={onCloseDeleteModal}
      />

      <ModalShare
        title={labels.share}
        showModal={shareModal}
        closeAction={onCloseShareModal}
        copyAction={onCopyInvitationLink}
        printAction={printAction}
        mailAction={() =>
          (window.location.href = `mailto:${invitationId?.visitor_email}?subject=${emailSubject}&body=${invitationMailLink}%26uuid=${qrCode}`)
        }
        copyLink={finalLink}
        hasEmail={!!invitationId?.visitor_email}
      />

      <div
        className="w-100 flex flex-dir-row justify-content-space-between align-items-center border-bottom-primary"
        style={{ height: 60 }}
      >
        <div style={{ width: "20%" }}></div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="font-14 uppercase text-primary-color medium">
            {labels.invitation_details}
          </p>
        </div>
        <div
          className="flex align-items-center justify-content-flex-end"
          style={{ width: "20%" }}
        >
          <CloseRoundedIcon
            className="primary-color"
            style={{ marginRight: 20 }}
            onClick={() => navigate("/invitations")}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-content-center align-items-center w-100 mt-13">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-100 flex flex-dir-col align-items-center">
          {invitationId?.deleted_at && (
            <div className="w-100 flex justify-content-center align-items-center">
              <p className="font-18 uppercase medium text-warning mb-2">
                {labels.deleted_invitation}
              </p>
            </div>
          )}
          <div
            className="w-90 flex flex-dir-col justify-content-center align-items-center shadow-one"
            style={{
              marginRight: 10,
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
              padding: 8,
              backgroundColor: "white",
              borderRadius: 5,
              maxWidth: 500,
            }}
            ref={ref}
          >
            <div
              className="w-100 flex background-gray-color justify-content-space-between align-items-center"
              style={{
                minHeight: 45,
                maxHeight: 55,
              }}
            >
              <p className="medium font-18 ml-6">
                {invitationId?.visitor_name}
              </p>
              <div className="flex justify-content-flex-end align-items-center mr-6">
                {userRole !== "guard" &&
                  invitationId?.access_key_type_id === 1 &&
                  invitationId?.deleted_at === null &&
                  invitationId?.last_transaction_type !== "exit" && (
                    <FaEdit
                      style={{ fontSize: 24 }}
                      className="primary-color mr-8"
                      onClick={onClickEditInvitation}
                    />
                  )}
                {userRole !== "guard" &&
                  invitationId?.deleted_at === null &&
                  invitationId?.access_key_type_id === 1 &&
                  invitationId?.last_transaction_type !== "exit" && (
                    <FaTrash
                      style={{ fontSize: 20 }}
                      className="primary-color"
                      onClick={openDeleteModal}
                    />
                  )}
                {userRole !== "guard" &&
                  invitationId?.deleted_at === null &&
                  invitationId?.access_key_type_id === 2 && (
                    <FaTrash
                      style={{ fontSize: 20 }}
                      className="primary-color"
                      onClick={openDeleteModal}
                    />
                  )}
                {userRole !== "guard" &&
                  invitationId?.deleted_at === null &&
                  invitationId?.access_key_type_id === 5 &&
                  invitationId?.last_transaction_type !== "exit" && (
                    <FaTrash
                      style={{ fontSize: 20 }}
                      className="primary-color"
                      onClick={openDeleteModal}
                    />
                  )}
                {userRole !== "guard" &&
                  invitationId?.deleted_at === null &&
                  invitationId?.access_key_type_id === 4 &&
                  invitationId?.last_transaction_type !== "exit" && (
                    <FaTrash
                      style={{ fontSize: 20 }}
                      className="primary-color"
                      onClick={openDeleteModal}
                    />
                  )}
              </div>
            </div>
            <div
              className="w-100 flex align-items-center justify-content-space-between"
              style={{
                minHeight: 10,
                marginRight: 8,
                marginLeft: 8,
                marginTop: 14,
                marginBottom: 14,
              }}
            >
              {userRole !== "guard" && invitationId?.access_key_type_id !== 5 && (
                <div
                  className="w-40 flex flex-dir-col justify-content-center align-items-center"
                  style={{
                    marginLeft: 10,
                    maxWidth: 250,
                  }}
                >
                  <QRCode
                    value={`${qrCode}`}
                    size={(qrCodeContainerWidth * 35) / 100}
                    level={"H"}
                    // includeMargin={true}
                    renderAs="svg"
                  />
                  <p className="p-0 m-0 mt-4 bold">ID: {invitationId?.id}</p>
                </div>
              )}
              <div
                className={
                  userRole !== "guard" && invitationId?.access_key_type_id !== 5
                    ? "w-55"
                    : "w-100"
                }
                style={{
                  marginLeft:
                    userRole !== "guard" &&
                    invitationId?.access_key_type_id !== 5
                      ? 0
                      : 10,
                }}
              >
                {invitationId?.access_key_type_id === 1 && (
                  <p className="font-14 bold m-0 p-0">
                    {labels.visitor}
                  </p>
                )}
                {invitationId?.access_key_type_id === 2 && (
                  <p className="font-14 bold m-0 p-0">
                    {labels.temporary}
                  </p>
                )}
                {invitationId?.access_key_type_id === 3 && (
                  <p className="font-14 bold m-0 p-0">
                    {labels.event}
                  </p>
                )}
                {invitationId?.access_key_type_id === 4 && (
                  <p className="font-14 bold m-0 p-0">
                    {labels.event}
                  </p>
                )}
                {invitationId?.access_key_type_id === 5 && (
                  <p className="font-14 bold m-0 p-0">
                    {labels.provider}
                  </p>
                )}
                {validFromText === validUntilText ? (
                  <p className="font-14 bold m-0 p-0 mt-5">
                    {validUntilText}
                  </p>
                ) : (
                  <p className="font-14 bold m-0 p-0 mt-5">
                    {validFromText} - {validUntilText}
                  </p>
                )}
                <p className="subtitle-color medium font14 m-0 p-0 mt-1">
                  {validFromHour} - {validUntilHour}
                </p>
                {userRole === "guard" ? (
                  <div className="flex align-items-center mt-5 justify-content-space-between">
                    <div>
                      <p className="font-14 bold m-0 p-0">
                        {invitationId?.user?.name}
                      </p>
                      <p className="subtitle-color medium font14 m-0 p-0 mt-1">
                        {invitationId?.user?.group}
                      </p>
                    </div>
                    <div className="px-4 flex align-items-center">
                      <a href={`tel:${invitationId?.user?.phone}`}>
                        <div
                          className="flex justify-content-center align-items-center background-primary-color"
                          style={{ width: 30, height: 30, borderRadius: 15 }}
                        >
                          <FaPhone
                            style={{ fontSize: 14 }}
                            className="text-white"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    <p className="font-14 bold m-0 p-0 mt-5">
                      {invitationId?.user?.name}
                    </p>
                    <p className="subtitle-color medium font14 m-0 p-0 mt-1">
                      {invitationId?.user?.group}
                    </p>
                  </>
                )}
              </div>
            </div>
            <Divider className="w-100" />
            <div
              className="w-100 flex flex-dir-col align-items-flex-start"
              style={{ minHeight: 10, margin: 8 }}
            >
              <div className="w-100 flex justify-content-space-between">
                <div
                  className="w-40 flex"
                  style={{
                    marginLeft: 10,
                    maxWidth: 250,
                  }}
                >
                  <p className="subtitle-color font-14 medium">
                    {labels.status}
                  </p>
                </div>
                <div className="w-55">
                  <div className="flex align-items-center">
                    <p className="mr-4 medium">
                      {invitationId?.last_transaction_type === "access" &&
                        labels.access}
                      {invitationId?.last_transaction_type === "exit" &&
                        labels.exit}
                      {invitationId?.last_transaction_type === "try" &&
                        labels.with_unauthorized_attempts}
                      {invitationId?.last_transaction_type === null &&
                        labels.invitations_with_not_registration}
                    </p>
                    {invitationId?.last_transaction_type === "access" && (
                      <FaSignInAlt
                        fontSize="18px"
                        className="m-0 p-0"
                      />
                    )}
                    {invitationId?.last_transaction_type === "exit" && (
                      <FaSignOutAlt
                        fontSize="18px"
                        className="m-0 p-0"
                      />
                    )}
                    {invitationId?.last_transaction_type === "try" && (
                      <FaLock
                        fontSize="18px"
                        className="m-0 p-0"
                      />
                    )}
                    {invitationId?.last_transaction_type === null && (
                      <FaEnvelope
                        fontSize="18px"
                        className="m-0 p-0"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 flex justify-content-space-between">
                <div
                  className="w-40 flex"
                  style={{
                    marginLeft: 10,
                    maxWidth: 250,
                  }}
                >
                  <p className="subtitle-color font-14 medium">
                    {labels.access_points}
                  </p>
                </div>
                <div className="w-55">
                  {invitationId?.access_servers.map(
                    ({ name, venue_name, id }) => (
                      <div key={id}>
                        <p className="subtitle-color font-14 medium mb-0">
                          {name}
                        </p>
                        <p className="subtitle-color font-12 mt-1">
                          {venue_name}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              {invitationId?.key_restriction && (
                <div className="w-100 flex align-items-center justify-content-space-between">
                  <div
                    className="w-40 flex"
                    style={{
                      marginLeft: 10,
                      maxWidth: 250,
                    }}
                  >
                    <p className="subtitle-color font-14 medium">
                      {labels.allowed_days}
                    </p>
                  </div>
                  <div className="w-55">
                    <div
                      className="w-100 flex flex-dir-row justify-content-space-evenly align-items-center background-gray-color"
                      style={{ height: 35, borderRadius: 4 }}
                    >
                      <p
                        className={
                          invitationId.key_restriction.days.includes("1")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.mon}
                      </p>
                      <p
                        className={
                          invitationId.key_restriction.days.includes("2")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.tue}
                      </p>
                      <p
                        className={
                          invitationId.key_restriction.days.includes("3")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.wed}
                      </p>
                      <p
                        className={
                          invitationId.key_restriction.days.includes("4")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.thu}
                      </p>
                      <p
                        className={
                          invitationId.key_restriction.days.includes("5")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.fri}
                      </p>
                      <p
                        className={
                          invitationId.key_restriction.days.includes("6")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.sat}
                      </p>
                      <p
                        className={
                          invitationId.key_restriction.days.includes("0")
                            ? "primary-color medium font-16"
                            : "title-color medium font-16"
                        }
                      >
                        {labels.sun}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {customAttributesArray.length > 0 && (
              <>
                <Divider className="w-100" />
                <div
                  className="w-100 flex flex-dir-col align-items-flex-start"
                  style={{ minHeight: 10, margin: 8 }}
                >
                  {customAttributesArray.map((item, index) => (
                    <div
                      className="w-100 flex align-items-flex-start justify-content-space-between"
                      key={index}
                    >
                      <div
                        className="w-40 flex"
                        style={{
                          marginLeft: 10,
                          maxWidth: 250,
                        }}
                      >
                        <p className="subtitle-color font-14 medium">
                          {item[0]}
                        </p>
                      </div>
                      <div className="w-55">
                        <p className="subtitle-color font-14 mb-0">{item[1]}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {userRole === "guard"
            ? invitationId?.access_key_type_id === 5 &&
              invitationId.last_transaction_type === null &&
              invitationId.deleted_at === null && (
                <ButtonBottom
                  action={() => setSelectDoorModal(true)}
                  userRole="guard"
                />
              )
            : invitationId?.access_key_type_id !== 5 && (
                <ButtonBottom action={openShareModal} userRole="host" />
              )}
        </div>
      )}
      <Container>
        <div className="lower-div mt-8 root-background" />

        {/* COPIED LINK MODAL------------------------------------------------------------------->> */}
        {/* MODAL LINK COPIADO------------------------------------------------------------------>> */}
        <Modal disableEscapeKeyDown open={copiedLinkModal}>
          <Box sx={style}>
            <div className="w-100 flex justify-content-center align-items-center">
              <p className="font-14 subtitle-color text-center">
                {labels.invite_link}
              </p>
            </div>
          </Box>
        </Modal>

        {/* SELECT ACCESS DOOR MODAL----------------------------------->>*/}
        {/* MODAL SELECCIONAR PUERTA DE ACCESO------------------------->>*/}
        <Modal disableEscapeKeyDown open={selectDoorModal}>
          <Box sx={style}>
            <Grid container className="vertical-spacing-4">
              <Grid
                item
                xs={12}
                className="flex justify-content-space-between align-items-center m-0 p-0 mb-2 mt-3"
              >
                <p className="subtitle-color font-16">
                  {labels.select_access_door}
                </p>
                <IconButton
                  onClick={() => {
                    setSelectDoorModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              className="w-100 align-items-center justify-content-center vertical-spacing-5"
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-door-label">{labels.door}</InputLabel>
                  <Select
                    labelId="select-door-label"
                    value={accessDoor}
                    onChange={handleAccessDoorChange}
                    label={labels.door}
                  >
                    {user?.last_access_selected?.gates
                      .filter(({ type }) => type === "access")
                      .map(({ name, id }) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setSelectDoorModal(false);
                  onValidateAccessInvitation();
                }}
                disabled={disabledContinueButton}
                className="mb-8"
              >
                {labels.continue}
              </Button>
            </Grid>
          </Box>
        </Modal>

        {/* SELECT EXIT DOOR MODAL------------------------------------->>*/}
        {/* MODAL SELECCIONAR PUERTA DE SALIDA------------------------->>*/}
        <Modal disableEscapeKeyDown open={selectExitDoorModal}>
          <Box sx={style}>
            <Grid container className="vertical-spacing-4">
              <Grid
                item
                xs={12}
                className="flex justify-content-space-between align-items-center m-0 p-0 mb-2 mt-3"
              >
                <p className="subtitle-color font-16">
                  {labels.select_exit_door}
                </p>
                <IconButton
                  onClick={() => {
                    setSelectExitDoorModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              className="w-100 align-items-center justify-content-center vertical-spacing-5"
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-door-label">{labels.door}</InputLabel>
                  <Select
                    labelId="select-door-label"
                    value={exitDoor}
                    onChange={handleExitDoorChange}
                    label={labels.door}
                  >
                    {user?.last_access_selected?.gates
                      .filter(({ type }) => type === "exit")
                      .map(({ name, id }) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setSelectExitDoorModal(false);
                  onValidateExitInvitation();
                }}
                disabled={disabledExitContinueButton}
                className="mb-8"
              >
                {labels.continue}
              </Button>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </div>
  );
};

export default InvitationIdDetail;
