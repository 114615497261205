import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { StrapiUser } from "../../strapiModels/strapiUser";

export interface StrapiUserState {
  user: StrapiUser | null;
}

const initialState: StrapiUserState = {
  user: null,
};

export const strapiUserSlice = createSlice({
  name: "strapi_user",
  initialState,
  reducers: {
    getStrapiUser: (state) => {
      const newState = { ...state };
      const currentUser = localStorage.getItem("strapi_user");
      if (currentUser) {
        newState.user = JSON.parse(currentUser);
      }
      return newState;
    },
    setStrapiUser: (state, action) => {
      const newState = { ...state };
      newState.user = action.payload;
      // remove when user info endpoint available
      localStorage.setItem("strapi_user", JSON.stringify(action.payload));
      return newState;
    },
    resetStrapiUser: (state) => {
      const newState = { ...state };
      newState.user = null;
      localStorage.removeItem("strapi_user");
      return newState;
    },
  },
});

export const { setStrapiUser, getStrapiUser, resetStrapiUser } =
  strapiUserSlice.actions;

export const selectStrapiUser = (state: RootState) => state.strapiUser;

export default strapiUserSlice.reducer;
