import React from "react";
import AdminDesktopHeader from "../AdminDesktopHeader";
import api, { NGAccessServers, NetworkGroupsParams } from "../../api";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectToken } from "../../slices/token";
import SearchInputOutlined from "../SearchInputOutlined/SearchInputOutlined";
import { CSVLink } from "react-csv";
import { FaFileDownload, FaEdit } from "react-icons/fa";
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { selectLabels } from "../../slices/language";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import AdminDesktopDropView from "../AdminDesktopDropView/AdminDesktopDropView";
import AdminDesktopNewDepartment from "../AdminDesktopNewDepartment/AdminDesktopNewDepartment";
import AdminDesktopEditDepartment from "../AdminDesktopEditDepartment/AdminDesktopEditDepartment";
import moment from "moment";
import "moment/locale/es";

interface FileToExport {
  Nombre: string;
  "Puntos de acceso": any;
  Usuarios: any;
}

const AdminDesktopDepartments = () => {
  const token = useAppSelector(selectToken);
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [networkGroupsList, setNetworkGroupsList] =
    React.useState<NetworkGroupsParams | null>(null);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  const [newDepartmentWindow, setNewDepartmentWindow] =
    React.useState<boolean>(false);
  const [editDepartmentWindow, setEditDepartmentWindow] =
    React.useState<boolean>(false);
  const [depName, setDepName] = React.useState<string>("");
  const [accServer, setAccServer] = React.useState<NGAccessServers[] | null>(
    null
  );
  const [depId, setDepId] = React.useState<number | null>(null);

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const debouncedSearchText = useDebounce(searchInput, 500);

  const currentDate = new Date();
  const fileName = `${labels.units} - ${moment(currentDate).format("LL")}`;

  // const closeTags = ( tag: string ) => {
  //   const
  // }

  const onClickEditDEpartment = (
    name: string,
    array: NGAccessServers[] | null,
    id: number
  ) => {
    setDepName(name);
    setAccServer(array);
    setDepId(id);
    setEditDepartmentWindow(true);
  };

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  React.useEffect(() => {
    setPage(1);
  }, [debouncedSearchText]);

  React.useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add = tableHeaderHeight + tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
  }, []);

  React.useEffect(() => {
    getNetworkGroups(debouncedSearchText);
    fileNetworkGroups(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, debouncedSearchText, page, rowsPerPage]);

  const getNetworkGroups = async (debouncedSearchText: string) => {
    try {
      setIsLoading(true);
      const response = await api.getNetworkGroups(
        debouncedSearchText,
        "",
        page,
        rowsPerPage
      );
      setNetworkGroupsList(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fileNetworkGroups = async (debouncedSearchText: string) => {
    try {
      const response = await api.getNetworkGroups(
        debouncedSearchText,
        "",
        1,
        100
      );
      const fileArray = response.network_groups.map(
        ({ name, access_servers, users }) => ({
          Nombre: name ? name : "",
          "Puntos de acceso": access_servers.map(({ name }) => name).join(", "),
          Usuarios: users.map(({ name }) => name).join(", "),
        })
      );
      setFileArray(fileArray);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <AdminDesktopHeader
        title={"Unidades"}
        buttonLabel={""}
        refObject={headerRef}
      />
      <div
        ref={filtersRef}
        style={{
          width: "100%",
          boxSizing: "border-box",
          minHeight: 60,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: "35%" }}>
          <SearchInputOutlined
            value={searchInput}
            setValue={setSearchInput}
            size="small"
          />
        </div>
        <div style={{ marginRight: 6, marginLeft: 20 }}>
          {fileArray && fileArray?.length > 0 ? (
            <CSVLink data={fileArray} filename={fileName}>
              <FaFileDownload
                fontSize={32}
                color="#10418a"
                style={{ cursor: "pointer" }}
              />
            </CSVLink>
          ) : (
            <FaFileDownload fontSize={32} color="#999999" />
          )}
        </div>
        <Tooltip title="Nueva unidad" arrow placement="top-start">
          <div style={{ marginRight: 6, marginLeft: 20 }}>
            <BsFillPlusCircleFill
              fontSize={32}
              color="#10418a"
              onClick={() => setNewDepartmentWindow(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Tooltip>
      </div>
      <div
        style={{
          height: `calc(100% - ${mainContentHeight}px - 25px)`,
          backgroundColor: "white",
          boxSizing: "border-box",
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 4,
          position: "relative",
          boxShadow:
            "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          overflow: "auto",
        }}
      >
        <div ref={tableHeader} style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                height: "auto",
                backgroundColor: "#e8e8e8",
              }}
            >
              <tr>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "30%",
                  }}
                >
                  {labels.name}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "30%",
                  }}
                >
                  {labels.access_points}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "30%",
                  }}
                >
                  {labels.users_admin}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containersHeight}px)`,
            overflowY: "auto",
            backgroundColor: "#f8f8f8",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table style={{ width: "100%" }}>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
              >
                {networkGroupsList?.network_groups &&
                  networkGroupsList.network_groups.length > 0 &&
                  networkGroupsList.network_groups.map(
                    ({ id, name, access_servers, users }) => (
                      <tr key={id}>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "30%",
                            verticalAlign: "text-top",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "30%",
                            verticalAlign: "text-top",
                          }}
                        >
                          {access_servers.map(({ name, id }) => (
                            <p key={id}>{name}</p>
                          ))}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "30%",
                            verticalAlign: "text-top",
                          }}
                        >
                          {users.map(({ name, id }) => (
                            <p key={id}>{name}</p>
                          ))}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 10px",
                            width: "10%",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <FaEdit
                            fontSize={20}
                            color="#10418a"
                            onClick={() =>
                              onClickEditDEpartment(name, access_servers, id)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          )}
        </div>
        <div
          ref={tableFooter}
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#e8e8e8",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div />
          {/* <div className="flex align-items-center ml-8 mr-4">
            <p className="pr-4 primary-color">{labels.rows}</p>
            <FormControl
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                width: 100,
              }}
            >
              <Select
                value={rowsPerPage}
                style={{ color: "#10418a" }}
                size="small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {networkGroupsList && (
            <div className="flex" style={{ alignItems: "center" }}>
              <div className="flex mx-2">
                <p className="primary-color">
                  {labels.page} <span className="primary-color">{page}</span>{" "}
                  {labels.of}{" "}
                  <span className="primary-color">
                    {networkGroupsList?.total_pages}
                  </span>
                </p>
              </div>
              <div className="mx-2">
                <IconButton
                  color="primary"
                  disabled={page <= 1}
                  onClick={handleChangeDownPage}
                >
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={page >= networkGroupsList?.total_pages}
                  onClick={handleChangeUpPage}
                >
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminDesktopDropView
        showView={newDepartmentWindow}
        setShowView={setNewDepartmentWindow}
        content={
          <AdminDesktopNewDepartment
            open={newDepartmentWindow}
            setOpen={setNewDepartmentWindow}
            onCloseFunction={() => {
              getNetworkGroups(debouncedSearchText);
              fileNetworkGroups(debouncedSearchText);
            }}
          />
        }
        title={labels.new_unit}
        extraFunc={false}
      />
      <AdminDesktopDropView
        showView={editDepartmentWindow}
        setShowView={setEditDepartmentWindow}
        content={
          <AdminDesktopEditDepartment
            name={depName}
            array={accServer}
            id={depId}
            open={editDepartmentWindow}
            setOpen={setEditDepartmentWindow}
            onCloseFunction={() => {
              getNetworkGroups(debouncedSearchText);
              fileNetworkGroups(debouncedSearchText);
            }}
          />
        }
        title={labels.edit_unit}
        extraFunc={false}
      />
    </div>
  );
};

export default AdminDesktopDepartments;
