import React from "react";
import AdminDesktopHeader from "../AdminDesktopHeader/AdminDesktopHeader";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { CSVLink } from "react-csv";
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import SearchInputOutlined from "../SearchInputOutlined/SearchInputOutlined";
import { FaFileDownload } from "react-icons/fa";
import { selectLabels } from "../../slices/language";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import api, { AccessServerResponse, AccessServersResponse } from "../../api";
import { selectToken } from "../../slices/token";
import AdminDesktopDropView from "../AdminDesktopDropView/AdminDesktopDropView";
import AdminDesktopEditAccessPoint from "../AdminDesktopEditAccessPoint/AdminDesktopEditAccessPoint";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface FileToExport {
  nombre: string;
  teléfono: string;
  correo_electronico: string;
  usuario: string;
}

const AdminDesktopAccessPoints = () => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const googleAPIKey = "AIzaSyCxuo6aFRHzt74w5hEeiHcTEpL40TzOaXk";

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [accessServersList, setAccessServersList] =
    React.useState<AccessServersResponse | null>(null);
  const [accessServerId, setAccessServerId] =
    React.useState<AccessServerResponse | null>(null);
  const [editAccessPoint, setEditAccessPoint] = React.useState<boolean>(false);
  const [coordinates, setCoordinates] = React.useState<any>([]);
  const [map, setMap] = React.useState<any>(null);

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const debouncedSearchText = useDebounce(searchInput, 500);

  const mapStyles = {
    height: "250px",
    width: "100%",
  };

  const onMouseOverFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  React.useLayoutEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add =
      tableHeaderHeight +
      Number(mapStyles.height.slice(0, -2)) +
      tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getAccessServers(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, debouncedSearchText, page, rowsPerPage]);

  const getAccessServers = async (debouncedSearchText: string) => {
    try {
      setIsLoading(true);
      const response = await api.getAccessServers(
        debouncedSearchText,
        null,
        page,
        rowsPerPage
      );
      const getCoordinates = response?.access_servers.map((element) => ({
        lat: element?.latitude,
        lng: element?.longitude,
      }));
      setAccessServersList(response);
      setCoordinates(getCoordinates);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAccessServerId = async (id: number) => {
    try {
      if (id) {
        setEditAccessPoint(true);
        const accessServer = await api.getAccessServerById(id);
        setAccessServerId(accessServer);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onMapLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  React.useEffect(() => {
    if (map && coordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coordinate: any) => {
        bounds.extend(
          new window.google.maps.LatLng(coordinate.lat, coordinate.lng)
        );
      });
      map.fitBounds(bounds);
    }
  }, [map, coordinates]);

  console.log("accessServersList", accessServersList);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <AdminDesktopHeader
        title={"Puntos de acceso"}
        buttonLabel={"NUEVA INVITACION"}
        refObject={headerRef}
      />
      <div
        ref={filtersRef}
        style={{
          width: "100%",
          boxSizing: "border-box",
          minHeight: 60,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: "35%" }}>
          <SearchInputOutlined
            value={searchInput}
            setValue={setSearchInput}
            size="small"
          />
        </div>
        <div style={{ marginRight: 6, marginLeft: 20 }}>
          {fileArray && fileArray?.length > 0 ? (
            <CSVLink data={fileArray}>
              <FaFileDownload
                fontSize={32}
                color="#10418a"
                style={{ cursor: "pointer" }}
              />
            </CSVLink>
          ) : (
            <FaFileDownload fontSize={32} color="#999999" />
          )}
        </div>
      </div>
      <div
        style={{
          height: `calc(100% - ${mainContentHeight}px - 25px)`,
          backgroundColor: "white",
          boxSizing: "border-box",
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 4,
          position: "relative",
          boxShadow:
            "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          // overflow: "auto",
        }}
      >
        <div style={{ width: "100%", height: mapStyles.height }}>
          <LoadScript googleMapsApiKey={googleAPIKey}>
            <GoogleMap
              mapContainerStyle={mapStyles}
              // zoom={defaultZoom}
              // center={defaultCenter ? defaultCenter : null}
              onLoad={onMapLoad}
            >
              {/* Marcador en la ubicación predeterminada */}
              {coordinates.map((coordinate: any, index: any) => (
                <Marker key={index} position={coordinate} />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
        <div ref={tableHeader} style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                height: "auto",
                backgroundColor: "#e8e8e8",
              }}
            >
              <tr>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "30%",
                  }}
                >
                  {labels.name}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "30%",
                  }}
                >
                  {labels.location}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "30%",
                  }}
                >
                  {labels.address}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.gates}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containersHeight}px)`,
            overflowY: "auto",
            backgroundColor: "#f8f8f8",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table style={{ width: "100%" }}>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
              >
                {accessServersList?.access_servers &&
                  accessServersList.access_servers.length > 0 &&
                  accessServersList.access_servers.map(
                    ({ id, name, address, venue, gates }) => (
                      <tr
                        key={id}
                        style={{ cursor: "pointer" }}
                        onClick={() => getAccessServerId(id)}
                        onMouseOver={onMouseOverFunc}
                        onMouseLeave={onMouseOutFunc}
                      >
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "14px 10px",
                            width: "30%",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "14px 10px",
                            width: "30%",
                          }}
                        >
                          {venue?.name}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "14px 10px",
                            width: "30%",
                            verticalAlign: "text-top",
                          }}
                        >
                          {address}
                        </td>
                        <td
                          className="desktop-tables-font"
                          style={{
                            boxSizing: "border-box",
                            padding: "14px 10px",
                            width: "10%",
                            textAlign: "center",
                          }}
                        >
                          {gates.length}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          )}
        </div>
        <div
          ref={tableFooter}
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#e8e8e8",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex align-items-center ml-8 mr-4">
            <p className="pr-4 primary-color">{labels.rows}</p>
            <FormControl
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                width: 100,
              }}
            >
              <Select
                value={rowsPerPage}
                style={{ color: "#10418a" }}
                size="small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div>
          {accessServersList && (
            <div className="flex" style={{ alignItems: "center" }}>
              <div className="flex mx-2">
                <p className="primary-color">
                  {labels.page} <span className="primary-color">{page}</span>{" "}
                  {labels.of}{" "}
                  <span className="primary-color">
                    {accessServersList?.total_pages}
                  </span>
                </p>
              </div>
              <div className="mx-2">
                <IconButton
                  color="primary"
                  disabled={page <= 1}
                  onClick={handleChangeDownPage}
                >
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={page >= accessServersList?.total_pages}
                  onClick={handleChangeUpPage}
                >
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminDesktopDropView
        showView={editAccessPoint}
        setShowView={setEditAccessPoint}
        content={
          <AdminDesktopEditAccessPoint
            accessServerId={accessServerId}
            open={editAccessPoint}
            setOpen={setEditAccessPoint}
            onCloseFunction={() => {
              getAccessServers(debouncedSearchText);
            }}
          />
        }
        title={
          accessServerId
            ? accessServerId?.access_server?.name
            : "Punto de acceso"
        }
        extraFunc={false}
      />
    </div>
  );
};

export default AdminDesktopAccessPoints;
