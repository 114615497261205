import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import "./filters.scss";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

interface FiltersProps {
  show: boolean;
  array: any[];
  value: any;
  setValue: (value: any) => void;
  rightContent: any;
}

const Filters = ({
  show,
  array,
  value,
  setValue,
  rightContent,
}: FiltersProps) => {
  const [showContainer, setShowContainer] = React.useState<boolean>(false);

  React.useEffect(() => {
    onShowFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onShowFilters = () => {
    if (show) {
      setShowContainer(true);
    } else {
      setTimeout(() => {
        setShowContainer(false);
      }, 200);
    }
  };

  return (
    <>
      {showContainer && (
        <>
          <div
            className={
              show
                ? "filters-main-container lower-shadow"
                : "filters-main-container-out lower-shadow"
            }
          >
            <div style={{ width: "45%", marginLeft: 14 }}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{ bgcolor: "white" }}
                  value={value}
                  style={{ color: "#10418a" }}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                >
                  {array.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ width: "45%", marginRight: 14 }}>{rightContent}</div>
          </div>
        </>
      )}
    </>
  );
};

export default Filters;
