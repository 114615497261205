import { Alert, Snackbar } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectError, selectErrorMessage } from "../../slices/error";

const Error = () => {
  const error = useAppSelector(selectError);
  const errorMessage = useAppSelector(selectErrorMessage);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={error}
    >
      <Alert variant="filled" severity="error">{errorMessage}</Alert>
    </Snackbar>
  );
};

export default Error;
