import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  FaDoorOpen,
  FaKeyboard,
  FaUser,
  FaUserCircle,
  FaUserCog,
  FaUserShield,
  FaCog,
  FaChartArea,
} from "react-icons/fa";
import { HiSquares2X2 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

interface AdminMobileSecMenuProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const AdminMobileSecMenu = ({ show, setShow }: AdminMobileSecMenuProps) => {
  const labels = useAppSelector(selectLabels);
  const navigate = useNavigate();

  const settingsOptions = [
    { id: 10, label: labels.intelligence, value: "inteligence" },
    { id: 5, label: labels.access_points, value: "access-points" },
    { id: 1, label: labels.unit, value: "departments" },
    { id: 2, label: labels.users_admin, value: "users-hosts" },
    { id: 3, label: labels.guards, value: "users-guards" },
    { id: 4, label: labels.admins, value: "users-admins" },
    // { id: 3, label: labels.users_event, value: "users" },
    // { id: 4, label: labels.custom_attributes, value: "custom_attributes" },
  ];

  const onSelectOption = (route: string) => {
    setShow(false);
    navigate(`/${route}`);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: show ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0)",
          transition: "background-color 0.4s ease",
          pointerEvents: show ? "all" : "none",
          zIndex: 100,
        }}
        onClick={() => setShow(false)}
      />
      <div
        style={{
          width: 280,
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#f8f8f8",
          boxSizing: "border-box",
          boxShadow: show ? `2px 0px 6px 4px rgba(0,0,0,0.3)` : "",
          transform: show ? "translateX(0)" : "translateX(-100%)",
          transition: "transform 0.4s ease",
          // overflow: "auto",
          zIndex: 101,
        }}
      >
        <div
          className="flex align-items-center px-8 background-primary-color"
          style={{ height: 60 }}
          onClick={() => setShow(!show)}
        >
          <FaCog
            fontSize={24}
            style={{
              cursor: "pointer",
              color: "white",
            }}
          />
          <p className="font-18 p-0 m-0 ml-5" style={{ color: "white" }}>
            {labels.settings}
          </p>
        </div>
        <div className="mt-8">
          {settingsOptions.map(({ id, value, label }) => (
            <div
              key={id}
              className="px-8"
              style={{
                width: "100%",
                minHeight: 50,
                boxSizing: "border-box",
                margin: 0,
                padding: 10,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => onSelectOption(value)}
            >
              {value === "inteligence" && (
                <FaChartArea fontSize={20} color={"#10418a"} />
              )}
              {value === "users-hosts" && (
                <FaUser fontSize={20} color={"#10418a"} />
              )}
              {value === "users-admins" && (
                <FaUserCog fontSize={20} color={"#10418a"} />
              )}
              {value === "users-guards" && (
                <FaUserShield fontSize={20} color={"#10418a"} />
              )}
              {value === "access-points" && (
                <FaDoorOpen fontSize={20} color={"#10418a"} />
              )}
              {value === "departments" && (
                <HiSquares2X2 fontSize={20} color={"#10418a"} />
              )}
              {value === "custom_attributes" && (
                <FaKeyboard fontSize={20} color={"#10418a"} />
              )}
              {value === "profile" && (
                <FaUserCircle fontSize={20} color={"#10418a"} />
              )}
              <p className="m-0 p-0 ml-5" style={{ color: "#10418a" }}>
                {label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminMobileSecMenu;
