import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../slices/user";
import {
  FaAddressCard,
  FaAddressBook,
  FaBook,
  FaChartArea,
  FaDoorOpen,
  FaKeyboard,
  FaUsers,
  FaUser,
  FaUserCircle,
  FaShieldAlt,
  FaQrcode,
  FaHouseUser,
  FaSignOutAlt,
  FaUserAlt,
  FaUserCog,
  FaUserShield,
} from "react-icons/fa";
import UserDetailsDesktopModal from "../UserDetailsDesktopModal";

interface AdminDesktopHeaderProps {
  title: string;
  buttonLabel: string;
  refObject: any;
  buttonAction?: () => void;
}

const AdminDesktopHeader = ({
  title,
  buttonLabel,
  refObject,
  buttonAction,
}: AdminDesktopHeaderProps) => {
  const user = useAppSelector(selectUser);

  return (
    <div
      ref={refObject}
      style={{
        width: "100%",
        display: "flex",
        backgroundColor: "white",
        boxSizing: "border-box",
        // borderTopLeftRadius: 6,
        // borderTopRightRadius: 6,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomColor: "rgba(196, 196, 196, 0.5)",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        // boxShadow:
        // "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        // "0px 1px 2px 0px rgba(0,0,0,0.1)",
      }}
    >
      <p
        className="m-0 p-0"
        style={{ fontSize: 24, fontWeight: 300, color: "#8a8c91" }}
      >
        {title}
      </p>
      {/* {buttonLabel && (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={buttonAction}
        >
          {buttonLabel === "NUEVO USUARIO" && <AiOutlinePlus fontSize={18} />}
          <p className="m-0 p-0 ml-2">{buttonLabel}</p>
        </div>
      )} */}
      <div style={{ display: "flex", alignItems: "center", marginRight: 10 }}>
        <p style={{ fontSize: 16, marginRight: 14, fontWeight: 300 }}>
          {user?.email}
        </p>
        {/* <FaUserCircle
          fontSize={24}
          color="#10418a"
          style={{ cursor: "pointer" }}
        /> */}
        <UserDetailsDesktopModal />
      </div>
    </div>
  );
};

export default AdminDesktopHeader;
