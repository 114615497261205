import { Button } from "@mui/material";
import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { useNavigate } from "react-router-dom";

interface UserDetailsDesktopModalProps {
  extraFunc?: () => void;
}

const UserDetailsDesktopModal = ({
  extraFunc,
}: UserDetailsDesktopModalProps) => {
  const user = useAppSelector(selectUser);
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  console.log("user", user);

  const [isAccountOpen, setIsAccountOpen] = React.useState<boolean>(false);

  const concernedElement: any = React.useRef(null);

  React.useEffect(() => {
    const func = (event: MouseEvent) => {
      if (isAccountOpen) {
        if (!concernedElement.current.contains(event.target)) {
          setIsAccountOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", func);
    return () => document.removeEventListener("mousedown", func);
  });

  const onLogout = () => {
    if (extraFunc) {
      extraFunc();
    }
    dispatch(resetToken());
    dispatch(resetUser());
    navigate("/");
  };

  return (
    <div
      ref={concernedElement}
      style={{ width: 24, height: 24, position: "relative", display: "flex" }}
    >
      <div>
        <FaUserCircle
          fontSize={24}
          style={{
            position: "relative",
            cursor: "pointer",
            color:
              user?.roles[0].name === "security_guard" ? "white" : "#10418a",
          }}
          // style={{ position: "relative", cursor: "pointer", color: "#10418a" }}
          onClick={() => {
            setIsAccountOpen(true);
          }}
        />
      </div>
      {isAccountOpen && (
        <div
          style={{
            width: 350,
            height: "auto",
            boxSizing: "border-box",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 30,
            paddingBottom: 30,
            borderRadius: 4,
            position: "absolute",
            top: 30,
            right: -4,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 2px 6px 1px rgba(0, 0, 0, 0.5)",
            zIndex: 99,
          }}
        >
          {/* {user?.network?.logo_url !== null ? (
            <div>
              <img
                src={user?.network?.logo_url}
                alt={"NetworkLogo"}
                className="desktop-logo"
              />
            </div>
          ) : (
            <Logo
              type="passtrackLogo"
              alt="Passtrack"
              className="desktop-logo"
            />
          )} */}
          <p
            className="font-18 medium"
            style={{ margin: 0, padding: 0, textAlign: "center" }}
          >
            {user?.name}
          </p>
          <p
            className="subtitle-color medium mt-2"
            style={{ margin: 0, padding: 0, textAlign: "center" }}
          >
            {user?.email}
          </p>
          <p
            className="primary-color medium mt-8"
            style={{ margin: 0, padding: 0, textAlign: "center" }}
          >
            {/* {labels.guard} */}
            {labels.network_admin}
          </p>
          {/* <p
            className="medium mt-8"
            style={{ margin: 0, padding: 0, textAlign: "center" }}
          >
            {labels.access_points}:
          </p>
          {user?.access_servers.map(({ name, id }) => (
            <p
              key={id}
              className="subtitle-color font-12"
              style={{ margin: 0, padding: 0, textAlign: "center" }}
            >
              {name}
            </p>
          ))} */}
          <Button
            variant="contained"
            disableElevation
            onClick={onLogout}
            disabled={false}
            className="mb-8 mt-8"
          >
            {labels.logout}
          </Button>
          <a
            href="https://passtrack.mx/aviso-de-privacidad/"
            target="_blank"
            rel="noreferrer"
            className="uppercase"
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                textTransform: "uppercase",
                textDecoration: "underline",
              }}
            >
              {labels.privacy_notice}
            </p>
          </a>
        </div>
      )}
    </div>
  );
};

export default UserDetailsDesktopModal;
