import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React from "react";
import AdminDesktopHeader from "../AdminDesktopHeader";
import SearchInputOutlined from "../SearchInputOutlined";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { showError } from "../../utils/helper";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import api, {
  EventIdEventParams,
  EventsList,
  UsersByDepartment,
} from "../../api";
import moment from "moment";
import "moment/locale/es";
import { BsFillPlusCircleFill } from "react-icons/bs";
import AdminDesktopDropView from "../AdminDesktopDropView/AdminDesktopDropView";
import { RiEyeCloseLine, RiEyeFill } from "react-icons/ri";
import AdminDesktopNewEvent from "../AdminDesktopNewEvent/AdminDesktopNewEvent";
import { FaFileDownload } from "react-icons/fa";
import { CSVLink } from "react-csv";
import AdminDesktopEventDetails from "../AdminDesktopEventDetails/AdminDesktopEventDetails";

interface FileToExport {
  nombre_del_evento: string;
  fecha_de_inicio: string;
  fecha_de_termino: string;
  numero_de_invitados: string;
  descripción: string;
  puntos_de_acceso: string;
  tipo_de_evento: string;
}

const AdminDesktopEventsContent = () => {
  const labels = useAppSelector(selectLabels);
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [containersHeight, setContainersHeight] = React.useState<number>(0);
  const [eventsList, setEventsList] = React.useState<EventsList | null>(null);
  const [userId, setUserId] = React.useState<number | null>(null);
  const [accessServer, setAccessServer] = React.useState<number | null>(null);
  const [accessServerFilter, setAccessServerFilter] = React.useState<string>(
    labels.all
  );
  const [usersArray, setUsersArray] = React.useState<any[]>([labels.all_users]);
  const [usersByDepartment, setUsersByDepartment] =
    React.useState<UsersByDepartment | null>(null);
  const [usersFilter, setUsersFilter] = React.useState<string>(
    labels.all_users
  );
  const [newEvent, setNewEvent] = React.useState<boolean>(false);
  const [showContacts, setShowContacts] = React.useState<boolean>(false);
  const [showContactsDisabled, setShowContactsDisabled] =
    React.useState<boolean>(true);
  const [fileArray, setFileArray] = React.useState<null | FileToExport[]>(null);
  const [showEventDetails, setShowEventDetails] =
    React.useState<boolean>(false);
  const [eventId, setEventId] = React.useState<EventIdEventParams | null>(null);
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(0);

  const headerRef: any = React.useRef(null);
  const filtersRef: any = React.useRef(null);
  const tableHeader: any = React.useRef(null);
  const tableFooter: any = React.useRef(null);

  const debouncedSearchText = useDebounce(searchInput, 300);

  const handleChangeUpPage = () => {
    setPage(page + 1);
  };

  const handleChangeDownPage = () => {
    setPage(page - 1);
  };

  React.useEffect(() => {
    setPage(1);
  }, [accessServer, debouncedSearchText, userId, rowsPerPage]);

  React.useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const filtersHeight = filtersRef?.current?.offsetHeight;
    const tableHeaderHeight = tableHeader?.current?.offsetHeight;
    const tableFooterHeight = tableFooter?.current?.offsetHeight;
    const add = tableHeaderHeight + tableFooterHeight;
    const contentsubtraction = headerHeight + filtersHeight;
    setContainersHeight(add);
    setMainContentHeight(contentsubtraction);
  }, []);

  const handleAccessServerFilterChange = (event: SelectChangeEvent) => {
    const filteredAccessServer = user?.access_servers.filter(
      ({ name }) => name === event.target.value
    );
    setAccessServerFilter(event.target.value);
    if (event.target.value === labels.all) {
      setAccessServer(null);
    } else {
      if (filteredAccessServer) {
        setAccessServer(filteredAccessServer[0]?.id);
      }
    }
  };

  const handleUsersFilterChange = (event: SelectChangeEvent) => {
    const filteredUser = usersByDepartment?.users.filter(
      ({ name }) => name === event.target.value
    );
    setUsersFilter(event.target.value);
    if (event.target.value === labels.all_users) {
      setUserId(null);
    } else {
      if (filteredUser) {
        setUserId(filteredUser[0]?.id);
      }
    }
  };

  const onMouseOverFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "#f8f8f8";
  };

  const onMouseOutFunc = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const box = event.currentTarget;
    box.style.backgroundColor = "";
  };

  React.useEffect(() => {
    requestUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  const requestUsersList = async () => {
    try {
      if (token) {
        const response = await api.usersByNetworkRequest(1, 200, "");
        let newArray = [...response.users.map(({ name }) => name)];
        newArray.unshift(labels.all_users);
        setUsersArray(newArray);
        setUsersByDepartment(response);
      }
    } catch (error: any) {
      setUsersArray([labels.all_users]);
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.user_has_no_access_to_records);
      } else if (error?.response?.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  React.useEffect(() => {
    requestEventsList(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, rowsPerPage, userId, accessServer, debouncedSearchText]);

  const requestEventsList = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const events = await api.eventListRequest(
          page,
          rowsPerPage,
          userId !== null ? userId : "",
          debouncedSearchText,
          accessServer !== null ? accessServer : ""
        );
        setEventsList(events);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    eventsListFile(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId, accessServer, debouncedSearchText]);

  const eventsListFile = async (debouncedSearchText: string) => {
    try {
      if (token) {
        const events = await api.eventListRequest(
          1,
          100,
          userId !== null ? userId : "",
          debouncedSearchText,
          accessServer !== null ? accessServer : ""
        );
        setFileArray(
          events.events.map(
            ({
              id,
              name,
              start_date,
              end_date,
              number_of_guests,
              description,
              access_servers,
              event_type_id,
            }) => ({
              nombre_del_evento: `${name}`,
              fecha_de_inicio: `${moment(start_date).format("D/MMM/YY")}`,
              fecha_de_termino: `${moment(end_date).format("D/MMM/YY")}`,
              numero_de_invitados: `${number_of_guests}`,
              descripción: `${description}`,
              puntos_de_acceso: access_servers
                .map(({ name }) => name)
                .join(", "),
              tipo_de_evento: event_type_id === 2 ? "Passtrack" : "Eventbrite",
            })
          )
        );
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <AdminDesktopHeader
        title={"Eventos"}
        buttonLabel={"NUEVA INVITACION"}
        refObject={headerRef}
      />
      <div
        ref={filtersRef}
        style={{
          width: "100%",
          boxSizing: "border-box",
          minHeight: 60,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "30%", marginRight: 10 }}>
          <FormControl fullWidth>
            <Select
              size="small"
              sx={{ bgcolor: "white" }}
              style={{ color: "#10418a" }}
              value={usersFilter}
              onChange={handleUsersFilterChange}
            >
              {usersArray.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: "30%", marginRight: 10 }}>
          <FormControl fullWidth>
            <Select
              size="small"
              sx={{ bgcolor: "white" }}
              style={{ color: "#10418a" }}
              value={accessServerFilter}
              onChange={handleAccessServerFilterChange}
            >
              <MenuItem value={labels.all}>{labels.all_access_points}</MenuItem>
              {user?.access_servers?.map(({ name, id }) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: "35%" }}>
          <SearchInputOutlined
            value={searchInput}
            setValue={setSearchInput}
            size="small"
          />
        </div>
        <div style={{ marginRight: 6, marginLeft: 20 }}>
          {fileArray && fileArray?.length > 0 ? (
            <CSVLink data={fileArray}>
              <FaFileDownload
                fontSize={32}
                color="#10418a"
                style={{ cursor: "pointer" }}
              />
            </CSVLink>
          ) : (
            <FaFileDownload fontSize={32} color="#999999" />
          )}
        </div>
        <Tooltip title={labels.new_event} arrow placement="top-start">
          <div style={{ marginRight: 6, marginLeft: 20 }}>
            <BsFillPlusCircleFill
              fontSize={32}
              color="#10418a"
              onClick={() => setNewEvent(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Tooltip>
      </div>
      <div
        style={{
          height: `calc(100% - ${mainContentHeight}px - 25px)`,
          backgroundColor: "white",
          boxSizing: "border-box",
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 4,
          position: "relative",
          boxShadow:
            "rgba(60, 64, 67, 0.05) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px, 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          overflow: "auto",
        }}
      >
        <div ref={tableHeader} style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                height: "auto",
                backgroundColor: "#e8e8e8",
              }}
            >
              <tr>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "20%",
                  }}
                >
                  {labels.name}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.events.start_date}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.events.end_date}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.guest_number}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "20%",
                  }}
                >
                  {labels.description}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "20%",
                  }}
                >
                  {labels.access_points}
                </th>
                <th
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontWeight: 500,
                    width: "10%",
                  }}
                >
                  {labels.event_type}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containersHeight}px)`,
            overflowY: "auto",
            backgroundColor: "#f8f8f8",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table style={{ width: "100%" }}>
              <tbody
                style={{
                  backgroundColor: "white",
                }}
              >
                {eventsList?.events && eventsList.events.length > 0 ? (
                  eventsList.events.map((event) => (
                    <tr
                      key={event?.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEventId(event);
                        setShowEventDetails(true);
                      }}
                      onMouseOver={onMouseOverFunc}
                      onMouseLeave={onMouseOutFunc}
                    >
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "20%",
                        }}
                      >
                        {event?.name}
                      </td>
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {moment(event?.start_date).format("D/MMM/YY")}
                      </td>
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {moment(event?.end_date).format("D/MMM/YY")}
                      </td>
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {event?.number_of_guests}
                      </td>
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "20%",
                        }}
                      >
                        {event?.description}
                      </td>
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "20%",
                        }}
                      >
                        {event?.access_servers &&
                          event?.access_servers.length > 0 &&
                          event?.access_servers.map(({ name }, index) => (
                            <p key={index}>{name}</p>
                          ))}
                      </td>
                      <td
                        className="desktop-tables-font"
                        style={{
                          boxSizing: "border-box",
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {event?.event_type_id === 2 && (
                          <p>{labels.passtrack}</p>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="flex justify-content-center py-7">
                    <td>{labels.no_results_found}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div
          ref={tableFooter}
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#e8e8e8",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex align-items-center ml-8 mr-4">
            <p className="pr-4 primary-color">{labels.rows}</p>
            <FormControl
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 5,
                width: 100,
              }}
            >
              <Select
                value={rowsPerPage}
                style={{ color: "#10418a" }}
                size="small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div>
          {eventsList && (
            <div className="flex" style={{ alignItems: "center" }}>
              <div className="flex mx-2">
                <p className="primary-color">
                  {labels.page} <span className="primary-color">{page}</span>{" "}
                  {labels.of}{" "}
                  <span className="primary-color">
                    {eventsList?.total_pages}
                  </span>
                </p>
              </div>
              <div className="mx-2">
                <IconButton
                  color="primary"
                  disabled={page <= 1}
                  onClick={handleChangeDownPage}
                >
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={page >= eventsList?.total_pages}
                  onClick={handleChangeUpPage}
                >
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminDesktopDropView
        showView={newEvent}
        setShowView={setNewEvent}
        content={
          <AdminDesktopNewEvent
            showContacts={showContacts}
            setShowContacts={setShowContacts}
            open={newEvent}
            setOpen={setNewEvent}
            setShowContactsDisabled={setShowContactsDisabled}
            onCloseFunction={() => {
              requestEventsList(debouncedSearchText);
              eventsListFile(debouncedSearchText);
            }}
          />
        }
        title={labels.new_event}
        extraFunc={true}
        extraFuncElement={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: !showContactsDisabled ? "pointer" : "default",
            }}
            onClick={() =>
              !showContactsDisabled ? setShowContacts(!showContacts) : {}
            }
          >
            <p
              className={`${
                !showContactsDisabled ? "primary-color" : "text-disabled-color"
              }`}
              style={{
                padding: 0,
                margin: 0,
                marginRight: 10,
              }}
            >
              {labels.contacts}
            </p>
            {showContacts ? (
              <RiEyeFill
                className={`${
                  !showContactsDisabled
                    ? "primary-color"
                    : "text-disabled-color"
                }`}
                fontSize={24}
              />
            ) : (
              <RiEyeCloseLine
                className={`${
                  !showContactsDisabled
                    ? "primary-color"
                    : "text-disabled-color"
                }`}
                fontSize={24}
              />
            )}
          </div>
        }
      />
      <AdminDesktopDropView
        showView={showEventDetails}
        setShowView={setShowEventDetails}
        content={
          <AdminDesktopEventDetails
            idEvent={eventId}
            setIdEvent={setEventId}
            showContacts={showContacts}
            setShowContacts={setShowContacts}
            open={showEventDetails}
            setShowContactsDisabled={setShowContactsDisabled}
            actionOnSuccessEndpoints={() => {
              requestEventsList(debouncedSearchText);
              eventsListFile(debouncedSearchText);
            }}
          />
        }
        title={labels.event_details}
        extraFunc={true}
        extraFuncElement={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: !showContactsDisabled ? "pointer" : "default",
            }}
            onClick={() =>
              !showContactsDisabled ? setShowContacts(!showContacts) : {}
            }
          >
            <p
              className={`${
                !showContactsDisabled ? "primary-color" : "text-disabled-color"
              }`}
              style={{
                padding: 0,
                margin: 0,
                marginRight: 10,
              }}
            >
              {labels.contacts}
            </p>
            {showContacts ? (
              <RiEyeFill
                className={`${
                  !showContactsDisabled
                    ? "primary-color"
                    : "text-disabled-color"
                }`}
                fontSize={24}
              />
            ) : (
              <RiEyeCloseLine
                className={`${
                  !showContactsDisabled
                    ? "primary-color"
                    : "text-disabled-color"
                }`}
                fontSize={24}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default AdminDesktopEventsContent;
