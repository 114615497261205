import { CircularProgress, Container } from "@mui/material";
import React from "react";
import MainPagesHeader from "../../components/MainPagesHeader/MainPagesHeader";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import moment from "moment";
import "moment/locale/es";
import AdminUsersList from "../../components/AdminUsersList/AdminUsersList";
import Pagination from "../../components/Pagination/Pagination";
import { selectToken } from "../../slices/token";
import api, { Users } from "../../api";
import { NETWORKS_UUIDS } from "../../components/AdminDesktopIntelligenceContent/uuids";
import { selectUser } from "../../slices/user";

const Intelligence = () => {
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [networkUuid, setNetworkUuid] = React.useState<string>("");
  const [showIframe, setShowIframe] = React.useState<boolean>(false);

  const intelligenceLink = `https://nimanic.viurdata.com/#/public/753b10e5-a508-44f8-af3b-414ad0b3a2ee?a_uuid=${networkUuid}`;

  React.useEffect(() => {
    getNetworkUUID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (networkUuid) {
      setShowIframe(true);
    }
  }, [networkUuid]);

  const getNetworkUUID = () => {
    const filteredValue = NETWORKS_UUIDS.filter(
      (network) => network.id === user?.network?.id
    );
    if (filteredValue) {
      setNetworkUuid(filteredValue[0]?.uuid);
    } else {
      setNetworkUuid("");
    }
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <Container>
        <MainPagesHeader
          newIcon={false}
          onPressNewIconAction={() => {}}
          mainTitle={"intelligence"}
          hasSearch={false}
          hasFilters={false}
          showFilters={false}
          setShowFilters={() => {}}
          downloadable={false}
          searchValue={""}
          searchAction={() => {}}
          fileArray={[]}
          fileName={""}
        />
        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        <div
          className="w-100 flex flex-dir-col justify-content-center"
          style={{
            position: "relative",
            top: 116,
            boxSizing: "border-box",
            paddingBottom: 72,
          }}
        >
          {isLoading && (
            <div
              style={{
                width: "100%",
                height: `100%`,
                position: "absolute",
                top: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {showIframe && (
            <iframe
              src={intelligenceLink}
              title={labels.intelligence}
              width={"100%"}
              height={"3300px"}
              onLoad={handleIframeLoad}
              style={{
                border: "1px rgba(196, 196, 196, 0.5) solid",
                opacity: isLoading ? 0 : 1,
              }}
            ></iframe>
          )}
        </div>
      </Container>
    </>
  );
};

export default Intelligence;
