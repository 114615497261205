import React from "react";
import LOGOS from "./logos";

interface Props {
  type: string;
  alt?: string;
  className?: string;
}

const Logo = ({ type, alt, className }: Props) => (
  <img src={LOGOS[type]} alt={alt || ""} className={className || ""} />
);

export default Logo;
