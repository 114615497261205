import {
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import api, { EventsList, UsersList } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import "moment/locale/es";
import EventsListComponent from "../../components/EventsListComponent";
import Filters from "../../components/Filters";
import MainPagesHeader from "../../components/MainPagesHeader";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser, selectUserRole } from "../../slices/user";
import { showError } from "../../utils/helper";
import Pagination from "../../components/Pagination";

const EventsRefactor = () => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [arrayFilters, setArrayFilters] = React.useState<string>(
    labels.access_point_filter
  );
  const [filterContent, setFilterContent] = React.useState<any>(null);
  const [usersByDepartment, setUsersByDepartment] =
    React.useState<UsersList | null>(null);
  const [usersArray, setUsersArray] = React.useState<any[]>([]);
  const [usersFilter, setUsersFilter] = React.useState<string>(labels.all);
  const [userId, setUserId] = React.useState<number | null>(null);
  const [accessPointsArray, setAccessPointsArray] = React.useState<any[]>([]);
  const [accessPointsFilter, setAccessPointsFilter] = React.useState<string>(
    labels.access_point_filter
  );
  const [accessServer, setAccessServer] = React.useState<number | null>(null);
  const [eventsList, setEventsList] = React.useState<EventsList | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const filtersArray: any[] =
    userRole === "secondary_host"
      ? [labels.access_point_filter]
      : [labels.access_point_filter, labels.by_user];

  const currentDate = new Date();
  const fileName = `${labels.events_label} - ${moment(currentDate).format(
    "LL"
  )}`;

  React.useEffect(() => {
    initialPage();
  }, [accessServer, searchInput, userId]);

  const initialPage = () => {
    setPage(1);
  };

  React.useEffect(() => {
    if (user) {
      let newArray = [...user.access_servers.map(({ name }) => name)];
      newArray.unshift(labels.all);
      setAccessPointsArray(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    requestUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const requestUsersList = async () => {
    try {
      if (token) {
        setIsLoading(true);
        const response: UsersList = await api.usersListRequest(
          `${user?.network_group?.name}`
        );
        let newArray = [...response?.users.map(({ name }) => name)];
        newArray.unshift(labels.all);
        setUsersArray(newArray);
        setUsersByDepartment(response);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.user_has_no_access_to_records);
      } else if (error?.response?.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    rightContentManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    arrayFilters,
    usersFilter,
    accessPointsFilter,
    usersArray,
    accessPointsArray,
  ]);

  const rightContentManager = () => {
    if (arrayFilters === labels.by_user) {
      setUsersFilterFunction();
    }
    if (arrayFilters === labels.access_point_filter) {
      setAccessPointsFilterFunction();
    }
  };

  const handleAccessServerFilterChange = (event: SelectChangeEvent) => {
    const filteredAccessServer = user?.access_servers.filter(
      ({ name }) => name === event.target.value
    );
    setAccessPointsFilter(event.target.value);
    if (event.target.value === labels.all) {
      setAccessServer(null);
    } else {
      if (filteredAccessServer) {
        setAccessServer(filteredAccessServer[0]?.id);
      }
    }
  };

  const setAccessPointsFilterFunction = () => {
    setFilterContent(
      <>
        <FormControl fullWidth>
          <Select
            size="small"
            sx={{ bgcolor: "white" }}
            style={{ color: "#10418a" }}
            value={accessPointsFilter}
            onChange={handleAccessServerFilterChange}
          >
            {accessPointsArray.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const handleUsersFilterChange = (event: SelectChangeEvent) => {
    const filteredUser = usersByDepartment?.users.filter(
      ({ name }) => name === event.target.value
    );
    setUsersFilter(event.target.value);
    if (event.target.value === labels.all) {
      setUserId(null);
    } else {
      if (filteredUser) {
        setUserId(filteredUser[0]?.id);
      }
    }
  };

  const setUsersFilterFunction = () => {
    setFilterContent(
      <>
        <FormControl fullWidth>
          <Select
            size="small"
            sx={{ bgcolor: "white" }}
            style={{ color: "#10418a" }}
            value={usersFilter}
            onChange={handleUsersFilterChange}
          >
            {usersArray.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  React.useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilters]);

  const resetFilters = () => {
    if (!showFilters) {
      setArrayFilters(labels.access_point_filter);
      setUsersFilter(labels.all);
      setAccessPointsFilter(labels.all);
      setUserId(null);
      setAccessServer(null);
    }
  };

  React.useEffect(() => {
    requestEventsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, rowsPerPage, userId, accessServer, searchInput]);

  const requestEventsList = async () => {
    try {
      if (token) {
        setIsLoading(true);
        if (userRole === "secondary_host") {
          const events = await api.eventListRequest(
            page,
            rowsPerPage,
            `${user?.id}`,
            searchInput,
            accessServer !== null ? accessServer : ""
          );
          setEventsList(events);
        }
        if (userRole !== "secondary_host") {
          const events = await api.eventListRequest(
            page,
            rowsPerPage,
            userId !== null ? userId : "",
            searchInput,
            accessServer !== null ? accessServer : ""
          );
          setEventsList(events);
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error.response.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error.response.status === 403) {
        dispatch(resetUser());
        dispatch(resetToken());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container className={!showFilters ? "padding-out" : "padding-in"}>
        <MainPagesHeader
          newIcon={true}
          onPressNewIconAction={() => navigate("/new-event")}
          mainTitle="events"
          hasSearch={true}
          searchValue={searchInput}
          searchAction={setSearchInput}
          hasFilters={true}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          downloadable={false}
          fileArray={[]}
          fileName={fileName}
        />
        <Filters
          show={showFilters}
          array={filtersArray}
          value={arrayFilters}
          setValue={setArrayFilters}
          rightContent={filterContent}
        />

        {/* CONTENT LIST----------------------------------------------------------------------------------------->> */}
        {/* CONTENIDO LISTA-------------------------------------------------------------------------------------->> */}
        {isLoading ? (
          <div
            className="flex justify-content-center align-items-center w-100"
            style={{ marginTop: 100 }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className="w-100 flex flex-dir-col justify-content-center"
            style={{ paddingBottom: eventsList?.total_pages ? 200 : 74 }}
          >
            {eventsList && eventsList.events.length > 0 ? (
              eventsList.events.map(
                ({
                  id,
                  name,
                  description,
                  number_of_guests,
                  start_date,
                  end_date,
                }) => (
                  <div
                    key={id}
                    className="w-100 flex justify-content-center mt-5"
                  >
                    <EventsListComponent
                      onPress={() => navigate(`/event-detail/${id}`)}
                      eventName={name}
                      eventDate={moment(start_date).format("DD MMM")}
                      eventStartHour={moment(start_date).format("hh:mma")} //TODO: add 1 hour
                      eventEndHour={moment(end_date).format("hh:mma")} //TODO: add 1 hour
                      guestsNumber={number_of_guests}
                      eventDescription={description}
                    />
                  </div>
                )
              )
            ) : (
              <div className="w-100 flex justify-content-center">
                <p className="font-16 subtitle-color">
                  {labels.no_results_found}
                </p>
              </div>
            )}
          </div>
        )}
      </Container>
      {eventsList?.total_pages ? (
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={eventsList?.total_pages}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default EventsRefactor;
