import React from "react";
import { useAppDispatch, useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import SearchInputHeader from "../SearchInputHeader";
import { RiFilterFill, RiFilterOffFill, RiRefreshLine } from "react-icons/ri";
import moment from "moment-timezone";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser } from "../../slices/user";
import api, { GetAccessKeys } from "../../api";
import { showError } from "../../utils/helper";
import InvitationListContainer from "../InvitationListContainer";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Pagination from "../Pagination";

interface InvitationsDesktopGuardProps {
  setInvKey: (invKey: string) => void;
  setLastTransactionType: (lastTransactionType: string | null) => void;
  setInvHost: (invHost: string | null) => void;
  setInvDepartment: (invDepartment: string | null) => void;
  setInvGuest: (invGuest: string) => void;
}

const InvitationsDesktopGuard = ({
  setInvKey,
  setLastTransactionType,
  setInvHost,
  setInvDepartment,
  setInvGuest,
}: InvitationsDesktopGuardProps) => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [invitationsList, setInvitationsList] =
    React.useState<GetAccessKeys | null>(null);
  const [date, setDate] = React.useState<
    Date | null | "last_week" | "last_month"
  >(null);
  const [dateFilter, setDateFilter] = React.useState<string>("all");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [invitationType, setInvitationType] = React.useState<string>("all");
  const [filters, setFilters] = React.useState<string>("date");

  const debouncedSearchText = useDebounce(searchInput, 500);

  const dateChange = (event: SelectChangeEvent) => {
    setDateFilter(event.target.value);
    if (event.target.value === "all") {
      setDate(null);
    }
    if (event.target.value === "today") {
      setDate(new Date());
    }
    if (event.target.value === "last week") {
      setDate("last_week");
    }
    if (event.target.value === "last month") {
      setDate("last_month");
    }
    if (event.target.value === "calendar") {
      setDate(null);
    }
  };

  React.useEffect(() => {
    setPage(1);
  }, [date, debouncedSearchText, invitationType]);

  // END POINT INVITATIONS LIST------------------------------------------------>>
  // END POINT LISTA DE INVITACIONES------------------------------------------->>
  React.useEffect(() => {
    getInvitationsData(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, rowsPerPage, debouncedSearchText, date, invitationType]);

  React.useEffect(() => {
    if (!showFilters) {
      const intervalId = setInterval(() => {
        getInvitationsData(debouncedSearchText);
      }, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInvitationsData = async (debouncedSearchText: string) => {
    try {
      if (token) {
        setIsLoading(true);
        const result = await api.invitationsRequest(
          page,
          rowsPerPage,
          debouncedSearchText,
          date ? `${date}` : "",
          "unknown",
          invitationType !== "all" ? invitationType : ""
        );
        setInvitationsList(result);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        borderRightColor: "#d9d9d9",
        borderRightStyle: "solid",
        borderRightWidth: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: 60,
          backgroundColor: "#d9d9d9",
          boxSizing: "border-box",
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: 18, margin: 0, padding: 0, fontWeight: "500" }}>
          {labels.invitations}
        </p>
        <div
          style={{
            width: "70%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SearchInputHeader value={searchInput} setValue={setSearchInput} />
          {showFilters ? (
            <RiFilterOffFill
              onClick={() => setShowFilters(!showFilters)}
              style={{ fontSize: 34, marginLeft: 14, cursor: "pointer" }}
              className="dg-blue-color"
            />
          ) : (
            <RiFilterFill
              onClick={() => setShowFilters(!showFilters)}
              style={{ fontSize: 34, marginLeft: 14, cursor: "pointer" }}
              className="dg-blue-color"
            />
          )}
          <RiRefreshLine
            onClick={() => getInvitationsData(debouncedSearchText)}
            style={{ fontSize: 34, marginLeft: 14, cursor: "pointer" }}
            className="dg-blue-color"
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#e8e8e8",
          borderBottomColor: "#d9d9d9",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        }}
      >
        {showFilters && (
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "50%", boxSizing: "border-box", marginRight: 4 }}
            >
              <FormControl
                fullWidth
                size="small"
                style={{ backgroundColor: "white", borderRadius: 4 }}
              >
                <Select
                  labelId="filters"
                  value={filters}
                  onChange={(e) => {
                    setFilters(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Whithout label" }}
                  displayEmpty
                >
                  <MenuItem value="date">{labels.date_filter}</MenuItem>
                  <MenuItem value="type">{labels.type_filter}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              style={{ width: "50%", boxSizing: "border-box", marginLeft: 4 }}
            >
              {filters === "date" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ backgroundColor: "white", borderRadius: 4 }}
                  >
                    <Select
                      labelId="date"
                      value={dateFilter}
                      onChange={(e) => {
                        dateChange(e);
                      }}
                      inputProps={{ "aria-label": "Whithout label" }}
                      displayEmpty
                    >
                      <MenuItem value={"all"}>{labels.all}</MenuItem>
                      <MenuItem value={"today"}>{labels.today}</MenuItem>
                      <MenuItem value={"last week"}>
                        {labels.last_week}
                      </MenuItem>
                      <MenuItem value={"last month"}>
                        {labels.last_month}
                      </MenuItem>
                      <MenuItem value={"calendar"}>{labels.calendar}</MenuItem>
                    </Select>
                  </FormControl>
                  {dateFilter === "calendar" && (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 8,
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                          value={date}
                          onChange={(newDate: Date | null) => {
                            setDate(newDate);
                            setPage(1);
                          }}
                          inputFormat="DD/MMM"
                          className="primary-color"
                          renderInput={({ inputRef, inputProps }) => (
                            <input
                              style={{ cursor: "pointer" }}
                              type={"button"}
                              ref={inputRef}
                              {...inputProps}
                              className={
                                date !== null
                                  ? "m-0 p-0 py-4 date-filter font-14 primary-color"
                                  : "m-0 p-0 py-4 date-filter-null font-14"
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {date === null && (
                        <div className="no-date-filter font-14 primary-color">
                          {labels.choose}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {filters === "type" && (
                <FormControl
                  fullWidth
                  size="small"
                  style={{ backgroundColor: "white", borderRadius: 4 }}
                >
                  <Select
                    labelId="type"
                    value={invitationType}
                    onChange={(e) => {
                      setInvitationType(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Whithout label" }}
                    displayEmpty
                  >
                    <MenuItem value="all">{labels.all}</MenuItem>
                    <MenuItem value="visitor">{labels.visitor}</MenuItem>
                    <MenuItem value="temporary">{labels.temporary}</MenuItem>
                    <MenuItem value="provider">{labels.provider}</MenuItem>
                    <MenuItem value="passtrack_event">{labels.event}</MenuItem>
                    <MenuItem value="personal">{labels.user_key}</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="w-100 flex flex-dir-col pb-6"
          style={{
            boxSizing: "border-box",
            paddingLeft: 20,
            paddingRight: 20,
            overflow: "scroll",
          }}
        >
          {invitationsList && invitationsList?.access_keys?.length > 0 ? (
            invitationsList?.access_keys.map(
              ({
                visitor_name,
                user,
                valid_from,
                valid_until,
                access_key_type_id,
                time_zone_name,
                id,
                unique_id,
                last_transaction_type,
              }) => (
                <div
                  key={id}
                  className="w-100 flex justify-content-center mt-5"
                >
                  <InvitationListContainer
                    visitorName={visitor_name}
                    hostName={`${user?.name}`}
                    department={`${user?.network_group?.name}`}
                    date={
                      moment(valid_from).tz(time_zone_name).format("DD MMM") ===
                      moment(valid_until).tz(time_zone_name).format("DD MMM")
                        ? `${moment(valid_until)
                            .tz(time_zone_name)
                            .format(`DD`)} ${moment(valid_until)
                            .tz(time_zone_name)
                            .format(`MMM`)
                            .replace(
                              /^./,
                              moment(valid_until)
                                .tz(time_zone_name)
                                .format(`MMM`)[0]
                                .toUpperCase()
                            )}`
                        : `${moment(valid_from)
                            .tz(time_zone_name)
                            .format(`DD`)} ${moment(valid_from)
                            .tz(time_zone_name)
                            .format(`MMM`)
                            .replace(
                              /^./,
                              moment(valid_from)
                                .tz(time_zone_name)
                                .format(`MMM`)[0]
                                .toUpperCase()
                            )} - ${moment(valid_until)
                            .tz(time_zone_name)
                            .format(`DD`)} ${moment(valid_until)
                            .tz(time_zone_name)
                            .format(`MMM`)
                            .replace(
                              /^./,
                              moment(valid_until)
                                .tz(time_zone_name)
                                .format(`MMM`)[0]
                                .toUpperCase()
                            )}`
                    }
                    invitationType={access_key_type_id}
                    onPress={() => {
                      setInvKey(unique_id);
                      setLastTransactionType(last_transaction_type);
                      setInvHost(user?.name);
                      setInvDepartment(user?.network_group?.name);
                      setInvGuest(visitor_name);
                    }}
                    startHour={moment(valid_from)
                      .tz(time_zone_name)
                      .format("hh:mma")}
                    endHour={moment(valid_until)
                      .tz(time_zone_name)
                      .format("hh:mma")}
                  />
                </div>
              )
            )
          ) : (
            <div className="w-100 flex justify-content-center">
              <p className="font-16 subtitle-color">
                {labels.no_results_found}
              </p>
            </div>
          )}
          {invitationsList?.total_pages ? (
            <div style={{ marginTop: 14 }}>
              <Pagination
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
                totalPages={invitationsList?.total_pages}
                isDesktop={true}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default InvitationsDesktopGuard;
