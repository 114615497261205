import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

interface EventsListComponentProps {
  onPress: () => void;
  eventDate: string;
  eventStartHour: string;
  eventEndHour: string;
  eventName: string;
  guestsNumber: number;
  eventDescription: string;
}

const EventsListComponent = ({
  onPress,
  eventDate,
  eventEndHour,
  eventStartHour,
  eventName,
  guestsNumber,
  eventDescription,
}: EventsListComponentProps) => {
  const labels = useAppSelector(selectLabels);

  return (
    <>
      <div
        className="w-100 m-0 py-5 px-6 main-list-container"
        onClick={onPress}
      >
        <div className="w-100 flex justify-content-space-between align-items-center">
          <div className="w-70 flex">
            <p className="p-0 m-0 primary-color medium font-14">{eventDate}</p>
            <p className="p-0 m-0 subtitle-color font-14 ml-3">
              {eventStartHour} - {eventEndHour}
            </p>
          </div>
          <div className="w-30 flex justify-content-flex-end">
            <p className="p-0 m-0 primary-color medium font-14">
              {labels.guests}: {guestsNumber}
            </p>
          </div>
        </div>
        <div className="flex flex-dir-col justify-content-space-between align-items-flex-start mt-5">
          <p className="p-0 m-0 primary-color font-18 medium mb-1">
            {eventName}
          </p>
          <p className="p-0 m-0 subtitle-color font-14">
            {eventDescription}
          </p>
        </div>
      </div>
    </>
  );
};

export default EventsListComponent;
