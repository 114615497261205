import React from "react";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Box, Grid, Divider, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import api, { UserKey } from "../../api";
import { resetToken, selectToken } from "../../slices/token";
import { showError } from "../../utils/helper";
import { resetUser } from "../../slices/user";
import QRCode from "qrcode.react";
import moment from "moment";
import "./dynamic-key.scss";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { IoQrCodeSharp } from "react-icons/io5";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 350,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const DynamicKey = () => {
  const labels = useAppSelector(selectLabels);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [dynamicKeyModal, setDynamicKeyModal] = React.useState<boolean>(false);
  const [showQrCode, setShowQrCode] = React.useState<boolean>(false);
  const [dynamicKey, setDynamicKey] = React.useState<UserKey | null>(null);
  const [timerMinutes, setTimerMinutes] = React.useState<any>(0);
  const [timerSeconds, setTimerSeconds] = React.useState<any>(0);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [isExpired, setIsExpired] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const qrCode = dynamicKey?.access_key?.unique_id;
  console.log("qrCode", qrCode);

  let interval: any = null;

  // React.useEffect(() => {
  //   endCountdown();
  // }, [endDate]);

  const endCountdown = (time: any) => {
    const finalTime = Number(time) * 60 * 1000 + 500;
    setTimeout(() => {
      setIsExpired(true);
      setTimeout(() => {
        setShowQrCode(false);
        setIsExpired(false);
      }, 2000);
    }, finalTime);
  };

  // const onClickGenerateDynamicKey = () => {
  //   if (timerSeconds === 0) {
  //     getUserAccessKey();
  //   } else {
  //     openDynamicKeyModal();
  //   }
  // };

  React.useEffect(() => {
    if (dynamicKeyModal === true) {
      if (timerSeconds === 0) {
        setTimeout(() => getUserAccessKey(), 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerSeconds]);

  const onClickGenerateDynamicKey = () => {
    if (timerSeconds === 0) {
      getUserAccessKey();
    } else {
      openDynamicKeyModal();
    }
  };

  const startTimer = (endDate: any) => {
    const countdownDate = new Date(endDate).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
        // setDynamicKeyModal(false);
      } else {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  console.log("timerSeconds", timerSeconds);

  React.useEffect(() => {
    startTimer(endDate);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const openDynamicKeyModal = () => {
    setDynamicKeyModal(true);
  };

  const closeDynamicKeyModal = () => {
    setDynamicKeyModal(false);
  };

  console.log("dynamicKey", dynamicKey);

  // END POINT USER ACCESS KEY----------------------------------------------------------------------------->>
  // END POINT LLAVE DE ACCESO USUARIO--------------------------------------------------------------------->>

  const getUserAccessKey = async () => {
    if (token) {
      try {
        const result = await api.getDynamicKey();
        setDynamicKey(result);
        setShowQrCode(true);
        setEndDate(result.access_key.valid_until);
        // endCountdown(
        //   Number(moment(result.access_key.valid_until).format("mm")) -
        //     Number(moment(result.access_key.valid_from).format("mm"))
        // );
        // console.log(Number(moment(result.access_key.valid_from).format("mm")));
        // console.log(Number(moment(result.access_key.valid_until).format("mm")));
        // console.log(
        //   Number(moment(result.access_key.valid_until).format("mm")) -
        //     Number(moment(result.access_key.valid_from).format("mm"))
        // );
        openDynamicKeyModal();
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  // console.log("dynamicKey", dynamicKey);

  return (
    <div>
      <div className="flex">
        <IoQrCodeSharp
          fontSize={24}
          className="primary-color ml-4 mr-4"
          onClick={onClickGenerateDynamicKey}
        />
      </div>

      <Modal disableEscapeKeyDown open={dynamicKeyModal}>
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="flex justify-content-space-between align-items-center m-0 p-0"
            >
              <p className="subtitle-color font-18 m-0 p-0 text-justify">
                {labels.dynamic_key}
              </p>
              <IconButton onClick={closeDynamicKeyModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className="w-100" />

          <div className="w-100 flex flex-dir-col align-items-center">
            <div className="w-100 flex justify-content-center">
              <div className="p-5 mt-8">
                <QRCode
                  value={`${qrCode}`}
                  size={200}
                  level={"H"}
                  // includeMargin={true}
                  renderAs="svg"
                />
              </div>
            </div>
            {/* <p
              className={
                !isExpired
                  ? "font-18 subtitle-color medium"
                  : "font-18 text-warning medium"
              }
            >
              {!isExpired ? labels.the_qr_code_expire : labels.qr_code_expired}
            </p>
            <div className="px-10 py-5 qr-border mb-10">
              <p className="m-0 p-0 bold font-28 text-primary-color">
                {timerMinutes >= 10 ? timerMinutes : `0${timerMinutes}`}:
                {timerSeconds >= 10 ? timerSeconds : `0${timerSeconds}`}
              </p>
            </div> */}
            <p className={"font-18 subtitle-color medium"}>
              {labels.the_qr_code_expire}{" "}
              <span className="bold font-24 text-primary-color">
                {timerSeconds}
              </span>
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DynamicKey;
