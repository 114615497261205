import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface InvitationsRegister {
  id: number;
  unique_id: string;
  visitor_name: string;
  visitor_phone: string;
  visitor_email: string;
  deleted_at: null | string;
  last_transaction_type: null | string;
  access_key_type_id: number;
  valid_until_date: string;
  valid_from_date: string;
  user: {
    name: string;
    network_group: {
      name: string | null;
    };
  };
  access_servers: [
    {
      name: string;
    }
  ];
  results: number;
  page: number;
  total_pages: number;
}

export interface InvitationsState {
  data: InvitationsRegister[];
}

const initialState: InvitationsState = {
  data: [],
};

export const invitationsSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    addRegister: (state, action) => {
      const newState = { ...state };
      newState.data = [...newState.data, action.payload];
      return newState;
    },
    setRegisters: (state, action) => {
      const newState = { ...state };
      newState.data = action.payload;
      return newState;
    },
  },
});

export const { addRegister, setRegisters } = invitationsSlice.actions;

export const selectInvitationsData = (state: RootState) =>
  state.invitations.data;

export default invitationsSlice.reducer;
