import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { IoIosClose } from "react-icons/io";
import React from "react";

interface ModalPhotoEnlargeProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  transactionPhoto: string;
}

const ModalPhotoEnlarge = ({
  showModal,
  setShowModal,
  transactionPhoto,
}: ModalPhotoEnlargeProps) => {

  const closePhotoModal = (event: any) => {
    event.stopPropagation();
    setShowModal(false);
  };

  return (
    <Modal disableEscapeKeyDown open={showModal}>
      <Box sx={style}>
        <div
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: 10,
            backgroundColor: "black",
            position: "relative",
          }}
        >
          <img
            src={transactionPhoto}
            alt="captured_photo"
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "contain" }}
          />
          <IoIosClose
            color={"white"}
            fontSize={40}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            onClick={closePhotoModal}
          />
        </div>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 800,
  height: "90%",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  px: 2,
  outline: 0,
  border: 0,
  padding: 1.5,
};

export default ModalPhotoEnlarge;
