import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";

interface AdminUsersListProps {
  onPress: () => void;
  name: string;
  department: string;
}

const AdminUsersList = ({ name, department, onPress }: AdminUsersListProps) => {
  const labels = useAppSelector(selectLabels);
  return (
    <>
      <div
        className="w-100 m-0 py-5 px-6 main-list-container"
        onClick={onPress}
      >
        <div className="flex justify-content-space-between align-items-baseline">
          <p className="p-0 m-0 primary-color font-18 medium">{name}</p>
        </div>
        <div className="flex justify-content-space-between align-items-baseline mt-5">
          <p className="p-0 m-0 subtitle-color font-14 medium">
            {labels.unit_admin}: {department ? department : labels.management}
          </p>
        </div>
      </div>
    </>
  );
};

export default AdminUsersList;
