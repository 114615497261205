import { AppDispatch } from "../app/store";
import { resetError, setError } from "../slices/error";
import { Order } from "./types";

export const timeToSeconds = (time: string) => {
  const [hours, minutes]: string[] = time.split(":");
  return Number(hours) * 60 * 60 + Number(minutes) * 60;
};

export const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (orderBy === "date") {
    const dateA = new Date(a[orderBy]).getTime();
    const dateB = new Date(b[orderBy]).getTime();
    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
  } else if (orderBy === "hour") {
    const timeA = timeToSeconds(a[orderBy]);
    const timeB = timeToSeconds(b[orderBy]);
    if (timeB < timeA) {
      return -1;
    }
    if (timeB > timeA) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
};

export const getComparator = (order: Order, orderBy: string) =>
  order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);

export const showError = (dispatch: AppDispatch, errorMessage: string) => {
  dispatch(setError(errorMessage));
  setTimeout(() => {
    dispatch(resetError());
  }, 3000);
};
