import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Button,
} from "@mui/material";
import React from "react";
import QrReader from "react-qr-reader";
import api, { ValidateKeyResponse } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { selectUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import RegisterQrScannedData from "../RegisterQrScannedData";
import { FaSignInAlt, FaSignOutAlt } from "react-icons/fa";

interface RegisterQrDesktopProps {
  invitationRegister: boolean;
  invKey?: string;
  lastTransactionType?: string | null;
  children?: React.ReactElement;
  setDoorId: (doorId: string) => void;
  setQrKey: (qrKey: string) => void;
  idPhoto: string | null;
  setIdPhoto: (idPhoto: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (platePhoto: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (personPhoto: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (otherPhoto: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (accreditationPhoto: string | null) => void;
  notes: string;
  setNotes: (notes: string) => void;
  registerCustomAttributes: any | null;
  setRegisterCustomAttributes: (registerCustomattributes: any | null) => void;
  buttonConfirmLoading: boolean;
  buttonConfirmAction: () => void;
  buttonDeniedAction: () => void;
  buttonNotAuthorizedAction: () => void;
  scannedData: ValidateKeyResponse | null;
  setScannedData: (scannedData: ValidateKeyResponse | null) => void;
}

const RegisterQrDesktop = ({
  invitationRegister,
  invKey,
  children,
  lastTransactionType,
  setDoorId,
  setQrKey,
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  notes,
  setNotes,
  registerCustomAttributes,
  setRegisterCustomAttributes,
  buttonConfirmLoading,
  buttonConfirmAction,
  buttonDeniedAction,
  buttonNotAuthorizedAction,
  scannedData,
  setScannedData,
}: RegisterQrDesktopProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [scannedData, setScannedData] =
  //   React.useState<ValidateKeyResponse | null>(null);
  const [accessType, setAccessType] = React.useState("entrance");
  const [accessDoor, setAccessDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(
        ({ type }) => type === "access"
      )[0]?.id
    }`
  );
  const [exitDoor, setExitDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(({ type }) => type === "exit")[0]
        ?.id
    }`
  );

  React.useEffect(() => {
    // if (accessType === "entrance" && accessDoor) {
    //   setDoorId(accessDoor);
    // }
    // if (accessType === "exit" && exitDoor) {
    //   setDoorId(exitDoor);
    // }
    if (accessDoor) {
      const filteredDoor = user?.last_access_selected?.gates.filter(
        ({ id }) => id === Number(accessDoor)
      )[0];
      if (filteredDoor?.type === "access") {
        setAccessType("entrance");
      }
      if (filteredDoor?.type === "exit") {
        setAccessType("exit");
      }
      setDoorId(accessDoor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessDoor, user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccessType((event.target as HTMLInputElement).value);
  };

  const handleAccessDoorChange = (event: SelectChangeEvent) => {
    setAccessDoor(event.target.value);
  };

  const handleExitDoorChange = (event: SelectChangeEvent) => {
    setExitDoor(event.target.value);
  };

  React.useEffect(() => {
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0]?.id
      }`
    );
    setExitDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "exit"
        )[0]?.id
      }`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const handleError = (err: any) => {
    console.error(err);
  };

  const handleScan = async (data: string | null) => {
    if (data) {
      try {
        setIsLoading(true);
        const newCodeScan = await api.validateKeyRequestNoTransaction(
          accessType === "entrance" ? Number(accessDoor) : Number(exitDoor),
          data
        );
        setScannedData(newCodeScan);
        setQrKey(data);
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.invalid_qr_code);
      } finally {
        setIsLoading(false);
      }
      console.log(data);
    }
  };

  const handleVerify = async () => {
    if (invKey) {
      try {
        setIsLoading(true);
        const newCodeScan = await api.validateKeyRequestNoTransaction(
          accessType === "entrance" ? Number(accessDoor) : Number(exitDoor),
          invKey
        );
        setScannedData(newCodeScan);
      } catch (error) {
        console.error(error);
        showError(dispatch, labels.generic_error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {invitationRegister ? (
        <>
          {!scannedData ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "#e8e8e8",
                  borderBottomColor: "#d9d9d9",
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 10,
                    paddingTop: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ backgroundColor: "white", borderRadius: 4 }}
                  >
                    {lastTransactionType === null && (
                      <Select
                        labelId="select-door-label"
                        value={accessDoor}
                        onChange={handleAccessDoorChange}
                      >
                        {user?.last_access_selected?.gates
                          .filter(({ type }) => type === "access")
                          .map(({ name, id }) => (
                            <MenuItem value={id} key={id}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaSignInAlt
                                  fontSize="16px"
                                  className="allowed m-0 p-0 mr-4"
                                />
                                {name}
                              </div>
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                    {lastTransactionType === "access" && (
                      <Select
                        labelId="select-door-label"
                        value={accessDoor}
                        onChange={handleAccessDoorChange}
                      >
                        {user?.last_access_selected?.gates
                          .filter(({ type }) => type !== "access")
                          .map(({ name, id }) => (
                            <MenuItem value={id} key={id}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaSignOutAlt
                                  fontSize="16px"
                                  className="not-allowed m-0 p-0 mr-4"
                                />
                                {name}
                              </div>
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 12,
                }}
              >
                {children}
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 12,
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={isLoading ? () => {} : handleVerify}
                  size="medium"
                  style={{ height: 36.5 }}
                >
                  {isLoading ? (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  ) : (
                    labels.verify_invitation_access
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                // backgroundColor: "red"
              }}
            >
              <RegisterQrScannedData
                scannedData={scannedData}
                setScannedData={setScannedData}
                accessType={accessType}
                idPhoto={idPhoto}
                setIdPhoto={setIdPhoto}
                frontPlatesPhoto={frontPlatesPhoto}
                setFrontPlatesPhoto={setFrontPlatesPhoto}
                backPlatesPhoto={backPlatesPhoto}
                setBackPlatesPhoto={setBackPlatesPhoto}
                otherPhoto={otherPhoto}
                setOtherPhoto={setOtherPhoto}
                accreditationPhoto={accreditationPhoto}
                setAccreditationPhoto={setAccreditationPhoto}
                notes={notes}
                setNotes={setNotes}
                registerCustomAttributes={registerCustomAttributes}
                setRegisterCustomAttributes={setRegisterCustomAttributes}
                buttonConfirmLoading={buttonConfirmLoading}
                buttonConfirmAction={buttonConfirmAction}
                buttonDeniedAction={buttonDeniedAction}
                buttonNotAuthorizedAction={buttonNotAuthorizedAction}
              />
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {!scannedData ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      backgroundColor: "#e8e8e8",
                      borderBottomColor: "#d9d9d9",
                      borderBottomStyle: "solid",
                      borderBottomWidth: 1,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                        paddingTop: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ backgroundColor: "white", borderRadius: 4 }}
                      >
                        <Select
                          labelId="select-door-label"
                          value={accessDoor}
                          onChange={handleAccessDoorChange}
                        >
                          {user?.last_access_selected?.gates.map(
                            ({ name, type, id }) => (
                              <MenuItem value={id} key={id}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {type === "access" ? (
                                    <FaSignInAlt
                                      fontSize="16px"
                                      className="allowed m-0 p-0 mr-4"
                                    />
                                  ) : (
                                    <FaSignOutAlt
                                      fontSize="16px"
                                      className="not-allowed m-0 p-0 mr-4"
                                    />
                                  )}
                                  {name}
                                </div>
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      paddingTop: 30,
                      paddingBottom: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <QrReader
                      delay={100}
                      style={{
                        width: "70%",
                      }}
                      onError={handleError}
                      onScan={handleScan}
                    />
                    <p style={{ fontSize: 16 }}>
                      Presenta tu código QR en la cámara
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    boxSizing: "border-box",
                    // backgroundColor: "red"
                  }}
                >
                  <RegisterQrScannedData
                    scannedData={scannedData}
                    setScannedData={setScannedData}
                    accessType={accessType}
                    idPhoto={idPhoto}
                    setIdPhoto={setIdPhoto}
                    frontPlatesPhoto={frontPlatesPhoto}
                    setFrontPlatesPhoto={setFrontPlatesPhoto}
                    backPlatesPhoto={backPlatesPhoto}
                    setBackPlatesPhoto={setBackPlatesPhoto}
                    otherPhoto={otherPhoto}
                    setOtherPhoto={setOtherPhoto}
                    accreditationPhoto={accreditationPhoto}
                    setAccreditationPhoto={setAccreditationPhoto}
                    notes={notes}
                    setNotes={setNotes}
                    registerCustomAttributes={registerCustomAttributes}
                    setRegisterCustomAttributes={setRegisterCustomAttributes}
                    buttonConfirmLoading={buttonConfirmLoading}
                    buttonConfirmAction={buttonConfirmAction}
                    buttonDeniedAction={buttonDeniedAction}
                    buttonNotAuthorizedAction={buttonNotAuthorizedAction}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RegisterQrDesktop;
