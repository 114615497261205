import React from "react";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useWindowSize,
} from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  Grid,
  Divider,
  Paper,
  TextField,
  Button,
  Chip,
  IconButton,
  Box,
  Modal,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import api, {
  LastAccessSelectedGates,
  OpenGateParams,
  RegisterCustomAttributes,
  RegisterVisitRequestParams,
  RegisterVisitResponseParams,
  UserDetails,
} from "../../api";
import { resetUser, selectUser } from "../../slices/user";
import { useNavigate } from "react-router-dom";
import { showError } from "../../utils/helper";
import "./visit-register.scss";
import PhotoButtons from "../PhotoButtons";
import PrintLabelModal from "../Modals/PrintLabelModal";
import moment from "moment";
import { resetToken, selectToken } from "../../slices/token";
import CustomAttributes from "../CustomAttributes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const styleMotiveModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 2,
};

interface Props {
  accessDoor: number;
}

const VisitRegister = ({ accessDoor }: Props) => {
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();

  const { isMobile } = useWindowSize();

  const [addName, setAddName] = React.useState<string>("");
  const [personWhoVisit, setPersonWhoVisit] = React.useState<string>("");
  const [personWhoVisitName, setPersonWhoVisitName] =
    React.useState<string>("");
  const [personWhoVisitEmail, setPersonWhoVisitEmail] =
    React.useState<string>("");
  const [departmentWhoVisit, setDepartmentWhoVisit] = React.useState<
    string | null
  >("");
  const [reasonForVisit, setReasonForVisit] = React.useState<string>("");
  const [remark, setRemark] = React.useState<string>("");
  const [reason, setReason] = React.useState<string>("");
  const [idPhoto, setIdPhoto] = React.useState<string | null>(null);
  const [frontPlatesPhoto, setFrontPlatesPhoto] = React.useState<string | null>(
    null
  );
  const [backPlatesPhoto, setBackPlatesPhoto] = React.useState<string | null>(
    null
  );
  const [userPhone, setUserPhone] = React.useState<string | null>(null);
  const [otherPhoto, setOtherPhoto] = React.useState<string | null>(null);
  const [accreditationPhoto, setAccreditationPhoto] = React.useState<
    string | null
  >(null);
  const [departmentsList, setDepartmentsList] = React.useState<string[]>([]);
  const [completeDepartmentsList, setCompleteDepartmentsList] = React.useState<
    UserDetails[]
  >([]);
  const [usersInDepartment, setUsersInDepartment] = React.useState<
    { id: number | string; name: string; phone: string }[]
  >([]);
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [accessAllow, setAccessAllow] = React.useState(true);
  const [showAccessDeniedModal, setShowAccessDeniedModal] =
    React.useState<boolean>(false);
  const [registerResponse, setRegisterResponse] =
    React.useState<RegisterVisitResponseParams | null>(null);
  const [printPhotoModal, setPrintPhotoModal] = React.useState<boolean>(false);
  const [isLoadingSuccess, setIsLoadingSuccess] =
    React.useState<boolean>(false);
  const [registerVisitorName, setRegisterVisitorName] =
    React.useState<string>("");
  const [registerVisitorLastname, setRegisterVisitorLastname] =
    React.useState<string>("");
  const [registerAccessTime, setRegisterAccessTime] =
    React.useState<string>("");
  const [registerHostName, setRegisterHostName] = React.useState<string>("");
  const [registerDepartment, setRegisterDepartment] =
    React.useState<string>("");
  const [registerImageUrl, setRegisterImageUrl] = React.useState<string>("");
  const [registerCustomAttributes, setRegisterCustomAttributes] =
    React.useState<any | null>(null);
  const [customAttributesRequired, setCustomAttributesRequired] =
    React.useState<boolean>(false);
  const [filteredGate, setFilteredGate] =
    React.useState<LastAccessSelectedGates | null>(null);
  const [showPhotosContainer, setShowPhotosContainer] =
    React.useState<boolean>(false);
  const [disabledButton, setDisabledButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      setShowPhotosContainer(true);
    } else {
      setShowPhotosContainer(false);
    }
  }, [user?.last_access_selected]);

  React.useEffect(() => {
    const gate = user?.last_access_selected?.gates.filter(
      ({ id }) => id === accessDoor
    );
    if (gate) {
      setFilteredGate(gate[0]);
    }
  }, [accessDoor, user]);

  const finalRegisterAccessTime =
    moment(registerAccessTime).format("MM/DD/YY HH:mm");

  React.useEffect(() => {
    getRequiredCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerCustomAttributes]);

  const getRequiredCustomAttributes = () => {
    const value = registerCustomAttributes?.every(
      ({ value, required }: any) => {
        if (required && !value) {
          return false;
        }
        return true;
      }
    );
    setCustomAttributesRequired(value);
  };

  console.log("registerCustomAttributes", registerCustomAttributes);
  console.log("accessDoor", accessDoor);
  console.log("filteredGate", filteredGate);

  const isDisabled =
    !addName ||
    !reasonForVisit ||
    !personWhoVisit ||
    !accessDoor ||
    (user?.last_access_selected?.settings.photo_id && !idPhoto) ||
    (user?.last_access_selected?.settings.front_plates && !frontPlatesPhoto) ||
    (user?.last_access_selected?.settings.back_plates && !backPlatesPhoto) ||
    (user?.last_access_selected?.settings.other && !otherPhoto) ||
    (user?.last_access_selected?.settings.accreditation && !accreditationPhoto);

  const isDisabledCA =
    !addName ||
    !reasonForVisit ||
    !personWhoVisit ||
    !accessDoor ||
    (user?.last_access_selected?.settings.photo_id && !idPhoto) ||
    (user?.last_access_selected?.settings.front_plates && !frontPlatesPhoto) ||
    (user?.last_access_selected?.settings.back_plates && !backPlatesPhoto) ||
    (user?.last_access_selected?.settings.other && !otherPhoto) ||
    (user?.last_access_selected?.settings.accreditation &&
      !accreditationPhoto) ||
    !customAttributesRequired;

  const disabledDeniedButton = !reason;

  const generateRegister = async () => {
    const payload: RegisterVisitRequestParams = {
      visitor_name: addName,
      reason_for_visit: reasonForVisit,
      notes: accessAllow ? remark : reason,
      user_id: Number(personWhoVisit),
      gate_id: accessDoor,
      access_key_type_id: 6,
      access_allowed: accessAllow,
    };
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (user?.last_access_selected?.settings.photo_id === true && idPhoto) {
        photos.id = idPhoto;
      }
      if (
        user?.last_access_selected?.settings.front_plates === true &&
        frontPlatesPhoto
      ) {
        photos.front_plates = frontPlatesPhoto;
      }
      if (
        user?.last_access_selected?.settings.back_plates === true &&
        backPlatesPhoto
      ) {
        photos.back_plates = backPlatesPhoto;
      }
      if (user?.last_access_selected?.settings.other === true && otherPhoto) {
        photos.other = otherPhoto;
      }
      if (
        user?.last_access_selected?.settings.accreditation === true &&
        accreditationPhoto
      ) {
        photos.accreditation = accreditationPhoto;
      }
      payload.photos = photos;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          // if (value) {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
          // }
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setIsLoading(true);
      await api.registerVisitRequest(payload);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateRegisterConfirm = async () => {
    const payload: RegisterVisitRequestParams = {
      visitor_name: addName,
      reason_for_visit: reasonForVisit,
      notes: accessAllow ? remark : reason,
      user_id: Number(personWhoVisit),
      gate_id: accessDoor,
      access_key_type_id: 6,
      access_allowed: accessAllow,
    };
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (user?.last_access_selected?.settings.photo_id === true && idPhoto) {
        photos.id = idPhoto;
      }
      if (
        user?.last_access_selected?.settings.front_plates === true &&
        frontPlatesPhoto
      ) {
        photos.front_plates = frontPlatesPhoto;
      }
      if (
        user?.last_access_selected?.settings.back_plates === true &&
        backPlatesPhoto
      ) {
        photos.back_plates = backPlatesPhoto;
      }
      if (user?.last_access_selected?.settings.other === true && otherPhoto) {
        photos.other = otherPhoto;
      }
      if (
        user?.last_access_selected?.settings.accreditation === true &&
        accreditationPhoto
      ) {
        photos.accreditation = accreditationPhoto;
      }
      payload.photos = photos;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          // if (value) {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
          // }
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setIsLoadingSuccess(true);
      const response = await api.registerVisitRequest(payload);
      setRegisterResponse(response);
      if (response.print_data) {
        // setTimeout(() => {
        // setIsLoadingSuccess(false);
        setShowDetailsModal(false);
        setPrintPhotoModal(true);
        if (response.print_data?.visitor_name) {
          setRegisterVisitorName(response.print_data?.visitor_name);
        }
        if (response.print_data?.created_at) {
          setRegisterAccessTime(response.print_data?.created_at);
        }
        if (response.print_data?.user_name) {
          setRegisterHostName(response.print_data?.user_name);
        }
        if (response.print_data?.user_network_group) {
          setRegisterDepartment(response.print_data.user_network_group);
        }
        if (response.print_data?.photo_url) {
          setRegisterImageUrl(response.print_data.photo_url);
        }
        // if (response.print_data?.visitor_last_name) {
        //   setRegisterVisitorLastname(response.print_data.visitor_last_name);
        // }
        // }, 1000);
      } else {
        if (
          response?.transaction?.access_granted === true &&
          response?.transaction?.access_allowed === true &&
          filteredGate?.remote_pulse === true
        ) {
          const openGateParams: OpenGateParams = {
            id: response?.transaction?.id,
          };
          await api.openGate(openGateParams);
        }
        // setTimeout(() => {
        // setIsLoadingSuccess(false);
        setShowDetailsModal(false);
        setShowSuccessModal(true);
        // }, 1000);
      }
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoadingSuccess(false);
    }
  };

  const debouncedSearchText = useDebounce(departmentWhoVisit, 1000);

  React.useEffect(() => {
    requestUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const usersInDepartment = completeDepartmentsList.filter(
      (user) => user.network_group?.name === departmentWhoVisit
    );
    setUsersInDepartment(usersInDepartment);
    // setDepartmentWhoVisitName()
    setPersonWhoVisit(
      usersInDepartment[0]?.id ? `${usersInDepartment[0]?.id}` : ""
    );
    setPersonWhoVisitName(
      usersInDepartment[0]?.name ? `${usersInDepartment[0]?.name}` : ""
    );
    setPersonWhoVisitEmail(
      usersInDepartment[0]?.email ? `${usersInDepartment[0]?.email}` : ""
    );
    // setUserPhone(
    //   usersInDepartment[0]?.phone ? `${usersInDepartment[0]?.phone}` : ""
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentWhoVisit]);

  //END POINT USERS LIST REQUEST------------------------------------------------>>
  //END POINT SOLICITUD DE LISTA DE USUARIOS------------------------------------>>
  const requestUserList = async () => {
    try {
      const result = await api.usersListRequest("");
      const departments = Array.from(
        new Set(
          result.users
            .filter((user) => user.network_group)
            .map((user) => user.network_group?.name)
        )
      );
      setCompleteDepartmentsList(result.users);
      setDepartmentsList(departments);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    }
  };

  React.useEffect(() => {
    getRegisterCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getRegisterCustomAttributes = async () => {
    if (token) {
      try {
        const result = await api.getRegisterCustomAttributes();
        setRegisterCustomAttributes(
          result.custom_attributes.map((item) => ({
            ...item,
            value:
              item.attribute_type === "string"
                ? ""
                : item.attribute_type === "integer"
                ? ""
                : item.attribute_type === "boolean"
                ? false
                : item.attribute_type === "list"
                ? ""
                : item.attribute_type === "date" && new Date(),
          }))
        );
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error.response.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error.response.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  console.log("registerResponse", registerResponse);
  console.log("accessAllow", accessAllow);
  console.log("completeDepartmentsList", completeDepartmentsList);

  return (
    <>
      <p className="subtitle-color font-20 ml-5 my-5">{labels.visit_data}</p>
      {/* {isMobile ? (
        <p className="subtitle-color font-20 ml-5 my-5">{labels.visit_data}</p>
      ) : (
        <p className="subtitle-color font-24 ml-5 my-5">{labels.visit_data}</p>
      )} */}
      <Paper
        className=" flex justify-content-center align-items-center flex-dir-col vertical-spacing-7"
        sx={{ padding: "14px" }}
        // sx={isMobile ? { padding: "14px" } : { padding: "32px" }}
      >
        <Grid container className="flex justify-content-center" spacing={1.5}>
          <Grid item xs={12} sx={{ marginBottom: "5px" }}>
            <Divider>
              <Chip
                label={labels.general_data}
                sx={{ minWidth: "150px" }}
                className="subtitle-color"
              />
            </Divider>
          </Grid>
          {/* {isMobile && (
            <Grid item xs={12} sx={{ marginBottom: "5px" }}>
              <Divider>
                <Chip
                  label={labels.general_data}
                  sx={{ minWidth: "150px" }}
                  className="subtitle-color"
                />
              </Divider>
            </Grid>
          )} */}

          {/* TEXT FIELD - NAME------------------------------------------>>*/}
          {/* CAMPO DE TEXTO - NOMBRE------------------------------------>>*/}
          <Grid item xs={12}>
            <TextField
              required
              value={addName}
              label={labels.full_name}
              fullWidth
              onChange={(e) => {
                setAddName(e.target.value);
              }}
            />
          </Grid>

          {/* TEXT FIELD AUTOCOMPLETE - DEPARTMENT----------------------->>*/}
          {/* CAMPO DE TEXTO AUTOCOMPLETAR - DEPARTAMENTO---------------->>*/}
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              fullWidth
              id="x"
              options={departmentsList}
              onChange={(event: any, newValue: string | null) => {
                setDepartmentWhoVisit(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  value={departmentWhoVisit}
                  onChange={(e) => {
                    setDepartmentWhoVisit(e.target.value);
                  }}
                  label={labels.visiting_department}
                />
              )}
            />
          </Grid>

          {/* SELECTOR - HOST-------------------------------------------->>*/}
          {/* SELECTOR - ANFITRION--------------------------------------->>*/}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel required>{labels.host}</InputLabel>
              <Select
                labelId="host"
                value={personWhoVisit}
                onChange={(e) => {
                  setPersonWhoVisit(e.target.value);
                }}
                label={labels.host}
                inputProps={{ "aria-label": "Whithout label" }}
              >
                {usersInDepartment.map(({ id, name }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* TEXT FIELD - OTHERS---------------------------------------->>*/}
          {/* CAMPO DE TEXTO - OTROS------------------------------------->>*/}
          {registerCustomAttributes && registerCustomAttributes.length > 0 && (
            <>
              <Grid item xs={12} sx={{ marginTop: "15px" }}>
                <Divider>
                  <Chip
                    label={labels.others}
                    sx={{ minWidth: "150px" }}
                    className="subtitle-color"
                  />
                </Divider>
              </Grid>
              {registerCustomAttributes.map(
                (
                  {
                    attribute_name,
                    attribute_type,
                    required,
                    options,
                    value,
                  }: any,
                  index: number
                ) => (
                  <Grid item xs={12} key={index} style={{ marginTop: 4 }}>
                    <CustomAttributes
                      type={attribute_type}
                      name={attribute_name}
                      required={required}
                      value={value}
                      setValue={(newValue: any) => {
                        const newRegisterCustomAttributes = [
                          ...registerCustomAttributes,
                        ];
                        newRegisterCustomAttributes[index].value = newValue;
                        setRegisterCustomAttributes(
                          newRegisterCustomAttributes
                        );
                      }}
                      listArray={options}
                      visitorNameReg={addName}
                      hostNameReg={personWhoVisitName}
                      hostIdReg={Number(personWhoVisit)}
                      departmentNameReg={`${departmentWhoVisit}`}
                      hostEmailReg={personWhoVisitEmail}
                    />
                  </Grid>
                )
              )}
            </>
          )}

          <Grid item xs={12} sx={{ marginTop: "15px", marginBottom: "5px" }}>
            <Divider>
              <Chip
                label={labels.visit_details}
                sx={{ minWidth: "150px" }}
                className="subtitle-color"
              />
            </Divider>
          </Grid>
          {/* {isMobile && (
            <Grid item xs={12} sx={{ marginTop: "15px", marginBottom: "5px" }}>
              <Divider>
                <Chip
                  label={labels.visit_details}
                  sx={{ minWidth: "150px" }}
                  className="subtitle-color"
                />
              </Divider>
            </Grid>
          )} */}

          {/* TEXT FIELD - REAZON FOR VISIT------------------------------>>*/}
          {/* CAMPO DE TEXTO - MOTIVO DE LA VISITA----------------------->>*/}
          <Grid item xs={12}>
            <TextField
              required
              value={reasonForVisit}
              label={labels.visit_reason}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              onChange={(e) => {
                setReasonForVisit(e.target.value);
              }}
            />
          </Grid>

          {/* TEXT FIELD - NOTES----------------------------------------->>*/}
          {/* CAMPO DE TEXTO - OBSERVACIONES----------------------------->>*/}
          <Grid item xs={12}>
            <TextField
              value={remark}
              id="observation"
              label={labels.notes}
              fullWidth
              multiline
              rows={3}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </Grid>

          {showPhotosContainer && (
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <Divider>
                <Chip
                  label={labels.photo_record}
                  sx={{ minWidth: "150px" }}
                  className="subtitle-color"
                />
              </Divider>
            </Grid>
          )}
          {/* {isMobile && (
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <Divider>
                <Chip
                  label={labels.photo_record}
                  sx={{ minWidth: "150px" }}
                  className="subtitle-color"
                />
              </Divider>
            </Grid>
          )} */}
        </Grid>

        {/* COMPONENT - SHOW BUTTONS BY PHOTO TYPE----------------------------->*/}
        {/* COMPONENTE - MOSTRAR BOTONES DE TIPO DE FOTO----------------------->*/}
        {showPhotosContainer && (
          <PhotoButtons
            idPhoto={idPhoto}
            setIdPhoto={setIdPhoto}
            frontPlatesPhoto={frontPlatesPhoto}
            setFrontPlatesPhoto={setFrontPlatesPhoto}
            backPlatesPhoto={backPlatesPhoto}
            setBackPlatesPhoto={setBackPlatesPhoto}
            otherPhoto={otherPhoto}
            setOtherPhoto={setOtherPhoto}
            accreditationPhoto={accreditationPhoto}
            setAccreditationPhoto={setAccreditationPhoto}
          />
        )}
      </Paper>
      <Divider className="my-6" />

      {/* CONTINUE BUTTON - OPEN REGISTER DETAILS MODAL--------------------->>*/}
      {/* BOTON CONTINUAR - ABRE MODAL DE DETALLES DEL REGISTRO------------->>*/}
      <div className="w-100 flex flex-dir-col align-items-center justify-content-center">
        <p className="subtitle-color p-0 m-0 mb-3 text-center">
          * {labels.required_fields}
        </p>
        <p className="title-color p-0 m-0 mb-3 text-center">
          {labels.to_continue}
        </p>
        <Button
          variant="contained"
          disableElevation
          size="large"
          sx={{
            paddingTop: "15px",
            paddingBottom: "15px",
            paddingLeft: "70px",
            paddingRight: "70px",
            marginBottom: "50px",
            marginTop: "10px",
          }}
          onClick={() => setShowDetailsModal(true)}
          disabled={
            registerCustomAttributes && registerCustomAttributes.length > 0
              ? isDisabledCA
              : isDisabled
          }
        >
          {labels.continue}
        </Button>
      </div>

      <div className="lower-div root-background" />

      {/* REGISTER DETAILS MODAL------------------------------------->>*/}
      {/* MODAL DETALLES DEL REGISTRO-------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showDetailsModal}>
        <Box sx={style}>
          <div className="m-0 p-0">
            <Grid container className="vertical-spacing-4">
              <Grid
                item
                xs={12}
                className="flex justify-content-space-between align-items-center m-0 p-0"
              >
                <p className="title-color medium uppercase font-16 ml-5 my-1">
                  {labels.register_details}
                </p>
                <IconButton
                  className="my-1"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} className="m-0 p-0">
                <Divider className="mb-5" />
              </Grid>
            </Grid>
          </div>
          <div className="m-0 p-0">
            <Grid container className="vertical-spacing-4">
              <Grid item xs={12}>
                <p className="title-color font-14 ml-5 mb-3 mt-0">
                  {labels.visit}:
                </p>
                <p className="subtitle-color font-24 ml-5 my-3 bold">
                  {addName}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className="title-color font-14 ml-5 my-3">
                  {labels.department}:
                </p>
                <p className="subtitle-color font-20 ml-5 my-3 medium">
                  {departmentWhoVisit}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                className="m-0 py-3 background-border-grey flex justify-content-space-between align-items-center"
              >
                <div>
                  <p className="title-color font-14 ml-5 mt-3 mb-1">
                    {labels.host}:
                  </p>
                  <p className="subtitle-color font-16 ml-5 mt-1 mb-3 medium">
                    {
                      usersInDepartment.find(
                        ({ id }) => id === Number(personWhoVisit)
                      )?.name
                    }
                  </p>
                </div>
                {/* <a
                  href={`msteams:${
                    usersInDepartment.find(
                      ({ id }) => id === Number(personWhoVisit)
                    )?.phone
                  }`}
                >
                  <IconButton className="call-icon-button-style-button mr-5">
                    <CallIcon className="call-icon-button-style-icon" />
                  </IconButton>
                </a> */}
                <a
                  href={`tel:${
                    usersInDepartment.find(
                      ({ id }) => id === Number(personWhoVisit)
                    )?.phone
                  }`}
                >
                  <IconButton className="call-icon-button-style-button mr-5">
                    <CallIcon className="call-icon-button-style-icon" />
                  </IconButton>
                </a>
              </Grid>
              <Grid item xs={12} className="m-0 p-0">
                <p className="title-color font-14 ml-5 mt-5 mb-1">
                  {labels.access_point}:
                </p>
                <p className="subtitle-color font-16 ml-5 mt-1 mb-3 medium">
                  {user?.last_access_selected?.name}
                </p>
                <p className="title-color font-14 ml-5 mt-5 mb-1">
                  {labels.visit_reason}:
                </p>
                <p className="subtitle-color font-14 ml-5 mt-1 mb-3">
                  {reasonForVisit}
                </p>
                {remark && (
                  <>
                    <p className="title-color font-14 ml-5 mt-5 mb-1">
                      {labels.notes}:
                    </p>
                    <p className="subtitle-color font-14 ml-5 mt-1 mb-3">
                      {remark}
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="m-0 p-0">
            <Grid container>
              <Grid item xs={12} className="m-0 p-0">
                <Divider />
              </Grid>
              <Grid item xs={12} className="flex justify-content-center">
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={generateRegisterConfirm}
                  className="py-5 mt-8 mb-3"
                  size="large"
                  disabled={isLoadingSuccess}
                >
                  {isLoadingSuccess ? (
                    <CircularProgress size={26.5} />
                  ) : (
                    labels.confirm_access
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} className="flex justify-content-center">
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    setAccessAllow(false);
                    setShowDetailsModal(false);
                    setShowAccessDeniedModal(true);
                  }}
                  className="py-5 mb-8 mt-3"
                  size="large"
                  disabled={isLoading}
                  color="error"
                >
                  {isLoading ? (
                    <CircularProgress size={26.5} />
                  ) : (
                    labels.deny_access
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>

      {/* SUCCESS REGISTER MODAL------------------------------------->>*/}
      {/* MODAL REGISTRO EXITOSO------------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {accessAllow ? labels.success_register : labels.access_denied}
            </p>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setTimeout(() => navigate("/logbook"), 500);
              }}
            >
              {labels.ok}
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* ACCESS DENIED MODAL---------------------------------------->>*/}
      {/* MODAL ACCESO DENEGADO-------------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showAccessDeniedModal}>
        <Box sx={styleMotiveModal}>
          <Grid container className="vertical-spacing-4">
            <Grid
              item
              xs={12}
              className="flex justify-content-flex-end align-items-center m-0 p-0"
            >
              <IconButton
                className="my-1"
                onClick={() => {
                  setShowAccessDeniedModal(false);
                  setShowDetailsModal(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className="w-100 align-items-center justify-content-center vertical-spacing-5"
          >
            <Grid item xs={12}>
              <TextField
                value={reason}
                id="observation"
                label={labels.reason}
                fullWidth
                multiline
                rows={3}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Grid>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                generateRegister();
                setShowAccessDeniedModal(false);
                setShowSuccessModal(true);
              }}
              disabled={disabledDeniedButton}
            >
              {labels.continue}
            </Button>
          </Grid>
        </Box>
      </Modal>
      <PrintLabelModal
        openModal={printPhotoModal}
        setOpenModal={setPrintPhotoModal}
        visitorName={registerVisitorName}
        visitorLastName={registerVisitorLastname}
        accessTime={finalRegisterAccessTime}
        hostName={registerHostName}
        department={registerDepartment}
        imageUrl={registerImageUrl}
        isDesktop={false}
      />
    </>
  );
};
export default VisitRegister;
