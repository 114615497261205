import React from "react";
import { useDispatch } from "react-redux";
import api, {
  LastAccessSelectedGates,
  LogExitRegisterParams,
  OpenGateParams,
  RegisterVisitRequestParams,
  RegisterVisitResponseParams,
  UserDetails,
  ValidateKeyResponse,
} from "../../api";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { resetToken, selectToken } from "../../slices/token";
import { resetUser, selectUser } from "../../slices/user";
import { showError } from "../../utils/helper";
import RegisterManualDesktop from "../RegisterManualDesktop";
import RegisterQrDesktop from "../RegisterQrDesktop";
import moment from "moment";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import PrintLabelModal from "../Modals/PrintLabelModal/PrintLabelModal";
import RegisterQrScannedData from "../RegisterQrScannedData/RegisterQrScannedData";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
};

const styleMotiveModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 500,
  maxHeight: "90%",
  overflow: "scroll",
  borderRadius: "4px",
  bgcolor: "white",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 2,
};

interface RegisterDesktopGuardProps {
  newRegister: boolean;
  setNewRegister: (newRegister: boolean) => void;
  invKey: string;
  setInvKey: (invKey: string) => void;
  invLastTransactionType: string | null;
  setInvLastTransactionType: (invLastTransactionType: string | null) => void;
  invHost: string | null;
  setInvHost: (invHost: string | null) => void;
  invGuest: string;
  setInvGuest: (invGuest: string) => void;
  invDepartment: string | null;
  setInvDepartment: (invDepartment: string | null) => void;
}

const RegistersDesktopGuard = ({
  newRegister,
  setNewRegister,
  invKey,
  setInvKey,
  invLastTransactionType,
  setInvLastTransactionType,
  invDepartment,
  setInvDepartment,
  invHost,
  setInvHost,
  invGuest,
  setInvGuest,
}: RegisterDesktopGuardProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useDispatch();

  const [registerType, setRegisterType] = React.useState<"manual" | "qr">(
    "manual"
  );
  const [isLoadingSuccess, setIsLoadingSuccess] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const [manualDoorId, setManualDoorId] = React.useState<string>("");
  const [qrDoorId, setQrDoorId] = React.useState<string>("");
  const [qrKey, setQrKey] = React.useState<string>("");
  const [manualRegisterName, setManualRegisterName] =
    React.useState<string>("");
  const [manualRegisterHost, setManualRegisterHost] =
    React.useState<string>("");
  const [manualRegisterReason, setManualRegisterReason] =
    React.useState<string>("");
  const [manualRegisterRemark, setManualRegisterRemark] =
    React.useState<string>("");
  const [manualDepartment, setManualDepartment] = React.useState<string | null>(
    ""
  );
  const [manualIdPhoto, setManualIdPhoto] = React.useState<string | null>("");
  const [manualFrontPlatePhoto, setManualFrontPlatePhoto] = React.useState<
    string | null
  >("");
  const [manualBackPlatePhoto, setManualBackPlatePhoto] = React.useState<
    string | null
  >("");
  const [manualOtherPhoto, setManualOtherPhoto] = React.useState<string | null>(
    ""
  );
  const [manualAccreditationPhoto, setManualAccreditationPhoto] =
    React.useState<string | null>("");
  const [qrNotes, setQrNotes] = React.useState<string>("");
  const [qrConfirmLoading, setQrConfirmLoading] =
    React.useState<boolean>(false);
  const [qrIdPhoto, setQrIdPhoto] = React.useState<string | null>("");
  const [qrFrontPlatePhoto, setQrFrontPlatePhoto] = React.useState<
    string | null
  >("");
  const [qrBackPlatePhoto, setQrBackPlatePhoto] = React.useState<string | null>(
    ""
  );
  const [qrOtherPhoto, setQrOtherPhoto] = React.useState<string | null>("");
  const [qrAccreditationPhoto, setQrAccreditationPhoto] = React.useState<
    string | null
  >("");
  const [registerCustomAttributes, setRegisterCustomAttributes] =
    React.useState<any | null>(null);
  const [registerResponse, setRegisterResponse] =
    React.useState<RegisterVisitResponseParams | null>(null);
  const [showDetailsModal, setShowDetailsModal] =
    React.useState<boolean>(false);
  const [printPhotoModal, setPrintPhotoModal] = React.useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [registerVisitorName, setRegisterVisitorName] =
    React.useState<string>("");
  const [registerAccessTime, setRegisterAccessTime] =
    React.useState<string>("");
  const [registerHostName, setRegisterHostName] = React.useState<string>("");
  const [registerDepartment, setRegisterDepartment] =
    React.useState<string>("");
  const [registerImageUrl, setRegisterImageUrl] = React.useState<string>("");
  const [filteredGate, setFilteredGate] =
    React.useState<LastAccessSelectedGates | null>(null);
  const [filteredQrGate, setFilteredQrGate] =
    React.useState<LastAccessSelectedGates | null>(null);
  const [accessAllow, setAccessAllow] = React.useState(true);
  const [showAccessDeniedModal, setShowAccessDeniedModal] =
    React.useState<boolean>(false);
  const [reason, setReason] = React.useState<string>("");
  const [registerVisitorLastname, setRegisterVisitorLastname] =
    React.useState<string>("");
  const [transactionId, setTransactionId] = React.useState<number | null>(null);
  const [scannedData, setScannedData] =
    React.useState<ValidateKeyResponse | null>(null);
  const [showAccessDeniedQrModal, setShowAccessDeniedQrModal] =
    React.useState<boolean>(false);
  const [showContent, setShowContent] = React.useState<boolean>(true);
  const [providerManualKey, setProviderManualKey] = React.useState<string>("");

  const tabsHeight = 60;

  const disabledDeniedButton = !reason;

  const finalRegisterAccessTime =
    moment(registerAccessTime).format("MM/DD/YY HH:mm");

  React.useEffect(() => {
    const gate = user?.last_access_selected?.gates.filter(
      ({ id }) => id === Number(manualDoorId)
    );
    if (gate) {
      setFilteredGate(gate[0]);
    }
  }, [manualDoorId, user]);

  React.useEffect(() => {
    const gate = user?.last_access_selected?.gates.filter(
      ({ id }) => id === Number(qrDoorId)
    );
    if (gate) {
      setFilteredQrGate(gate[0]);
    }
  }, [qrDoorId, user]);

  const successModal = () => {
    setShowSuccessModal(true);
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 1500);
  };

  const resetQrValues = () => {
    setQrNotes("");
    setQrIdPhoto("");
    setQrFrontPlatePhoto("");
    setQrBackPlatePhoto("");
    setQrOtherPhoto("");
    setQrAccreditationPhoto("");
    getRegisterCustomAttributes();
    setScannedData(null);
    setAccessAllow(true);
    setInvKey("");
    setInvDepartment(null);
    setInvGuest("");
    setInvHost(null);
    setInvLastTransactionType(null);
    setProviderManualKey("");
  };

  const resetManualValues = () => {
    // setManualDoorId("");
    setManualRegisterName("");
    setManualRegisterHost("");
    setManualRegisterReason("");
    setManualRegisterRemark("");
    setManualDepartment("");
    setManualIdPhoto("");
    setManualFrontPlatePhoto("");
    setManualBackPlatePhoto("");
    setManualOtherPhoto("");
    setManualAccreditationPhoto("");
    getRegisterCustomAttributes();
    setAccessAllow(true);
    setProviderManualKey("");
  };

  React.useEffect(() => {
    getRegisterCustomAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getRegisterCustomAttributes = async () => {
    if (token) {
      try {
        const result = await api.getRegisterCustomAttributes();
        setRegisterCustomAttributes(
          result.custom_attributes.map((item) => ({
            ...item,
            value:
              item.attribute_type === "string"
                ? ""
                : item.attribute_type === "integer"
                ? ""
                : item.attribute_type === "boolean"
                ? false
                : item.attribute_type === "list"
                ? ""
                : item.attribute_type === "date" && new Date(),
          }))
        );
      } catch (error: any) {
        console.error(error);
        if (error?.response?.status === 401) {
          showError(dispatch, labels.not_authorized);
        } else if (error?.response?.status === 422) {
          showError(dispatch, labels.unprocessable_entity);
        } else if (error?.response?.status === 403) {
          dispatch(resetToken());
          dispatch(resetUser());
        } else {
          showError(dispatch, labels.generic_error);
        }
      }
    }
  };

  console.log("manualDoorId", manualDoorId);

  const generateManualRegister = async () => {
    const payload: RegisterVisitRequestParams = {
      visitor_name: manualRegisterName,
      reason_for_visit: manualRegisterReason,
      notes: accessAllow ? manualRegisterRemark : reason,
      user_id: Number(manualRegisterHost),
      gate_id: Number(manualDoorId),
      access_key_type_id: 6,
      access_allowed: accessAllow,
    };
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (
        user?.last_access_selected?.settings.photo_id === true &&
        manualIdPhoto
      ) {
        photos.id = manualIdPhoto;
      }
      if (
        user?.last_access_selected?.settings.front_plates === true &&
        manualFrontPlatePhoto
      ) {
        photos.front_plates = manualFrontPlatePhoto;
      }
      if (
        user?.last_access_selected?.settings.back_plates === true &&
        manualBackPlatePhoto
      ) {
        photos.back_plates = manualBackPlatePhoto;
      }
      if (
        user?.last_access_selected?.settings.other === true &&
        manualOtherPhoto
      ) {
        photos.other = manualOtherPhoto;
      }
      if (
        user?.last_access_selected?.settings.accreditation === true &&
        manualAccreditationPhoto
      ) {
        photos.accreditation = manualAccreditationPhoto;
      }
      payload.photos = photos;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setLoadingButton(true);
      const response = await api.registerVisitRequest(payload);
      setRegisterResponse(response);
      if (response.print_data) {
        setShowDetailsModal(false);
        setPrintPhotoModal(true);
        if (response.print_data?.visitor_name) {
          setRegisterVisitorName(response.print_data?.visitor_name);
        }
        if (response.print_data?.created_at) {
          setRegisterAccessTime(response.print_data?.created_at);
        }
        if (response.print_data?.user_name) {
          setRegisterHostName(response.print_data?.user_name);
        }
        if (response.print_data?.user_network_group) {
          setRegisterDepartment(response.print_data.user_network_group);
        }
        if (response.print_data?.photo_url) {
          setRegisterImageUrl(response.print_data.photo_url);
        }
      } else {
        if (
          response?.transaction?.access_granted === true &&
          response?.transaction?.access_allowed === true &&
          filteredGate?.remote_pulse === true
        ) {
          const openGateParams: OpenGateParams = {
            id: response?.transaction?.id,
          };
          await api.openGate(openGateParams);
        }
        setShowDetailsModal(false);
        successModal();
        setNewRegister(true);
        resetManualValues();
      }
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setLoadingButton(false);
      setShowDetailsModal(false);
      setNewRegister(true);
      resetManualValues();
    }
  };

  const generateDeniedRegister = async () => {
    const payload: RegisterVisitRequestParams = {
      visitor_name: manualRegisterName,
      reason_for_visit: manualRegisterReason,
      notes: accessAllow ? manualRegisterRemark : reason,
      user_id: Number(manualRegisterHost),
      gate_id: Number(manualDoorId),
      access_key_type_id: 6,
      access_allowed: accessAllow,
    };
    if (
      user?.last_access_selected?.settings.photo_id === true ||
      user?.last_access_selected?.settings.front_plates === true ||
      user?.last_access_selected?.settings.back_plates === true ||
      user?.last_access_selected?.settings.other === true ||
      user?.last_access_selected?.settings.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (
        user?.last_access_selected?.settings.photo_id === true &&
        manualIdPhoto
      ) {
        photos.id = manualIdPhoto;
      }
      if (
        user?.last_access_selected?.settings.front_plates === true &&
        manualFrontPlatePhoto
      ) {
        photos.front_plates = manualFrontPlatePhoto;
      }
      if (
        user?.last_access_selected?.settings.back_plates === true &&
        manualBackPlatePhoto
      ) {
        photos.back_plates = manualBackPlatePhoto;
      }
      if (
        user?.last_access_selected?.settings.other === true &&
        manualOtherPhoto
      ) {
        photos.other = manualOtherPhoto;
      }
      if (
        user?.last_access_selected?.settings.accreditation === true &&
        manualAccreditationPhoto
      ) {
        photos.accreditation = manualAccreditationPhoto;
      }
      payload.photos = photos;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          // if (value) {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
          // }
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setLoadingButton(true);
      await api.registerVisitRequest(payload);
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setLoadingButton(false);
      setNewRegister(true);
      resetManualValues();
    }
  };

  // END POINT GENERATE EXIT TRANSACTION------------------------------------------------------------------->>
  // END POINT GENERAR REGISTRO DE SALIDA------------------------------------------------------------------>>
  const generateExitRegister = async () => {
    try {
      const payload: LogExitRegisterParams = {
        transaction_id: Number(transactionId),
        gate_id: Number(manualDoorId),
      };
      await api.logExitRegisterRequest(payload);
      setNewRegister(true);
      resetManualValues();
      successModal();
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        showError(dispatch, labels.not_authorized);
      } else if (error?.response?.status === 422) {
        showError(dispatch, labels.wrong_parameters);
      } else if (error?.response?.status === 403) {
        dispatch(resetToken());
        dispatch(resetUser());
      } else {
        showError(dispatch, labels.generic_error);
      }
    }
  };

  const generateQrDeniedRegister = async () => {
    const payload: RegisterVisitRequestParams = {
      key: providerManualKey ? providerManualKey : invKey ? invKey : qrKey,
      gate_id:
        registerType === "manual" ? Number(manualDoorId) : Number(qrDoorId),
      access_allowed: accessAllow,
    };
    if (
      scannedData?.requirements.photo_id === true ||
      scannedData?.requirements.front_plates === true ||
      scannedData?.requirements.back_plates === true ||
      scannedData?.requirements.other === true ||
      scannedData?.requirements.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (scannedData?.requirements.photo_id === true && qrIdPhoto) {
        photos.id = qrIdPhoto;
      }
      if (
        scannedData?.requirements.front_plates === true &&
        qrFrontPlatePhoto
      ) {
        photos.front_plates = qrFrontPlatePhoto;
      }
      if (scannedData?.requirements.back_plates === true && qrBackPlatePhoto) {
        photos.back_plates = qrBackPlatePhoto;
      }
      if (scannedData?.requirements.other === true && qrOtherPhoto) {
        photos.other = qrOtherPhoto;
      }
      if (
        scannedData?.requirements.accreditation === true &&
        qrAccreditationPhoto
      ) {
        photos.accreditation = qrAccreditationPhoto;
      }
      payload.photos = photos;
    }
    if (accessAllow === false) {
      payload.notes = reason;
    }
    if (accessAllow === true && qrNotes) {
      payload.notes = qrNotes;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setIsLoading(true);
      await api.registerVisitRequest(payload);
      setNewRegister(true);
      resetQrValues();
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateQrRegister = async () => {
    const payload: RegisterVisitRequestParams = {
      key: providerManualKey ? providerManualKey : invKey ? invKey : qrKey,
      gate_id:
        registerType === "manual" ? Number(manualDoorId) : Number(qrDoorId),
      access_allowed: accessAllow,
    };
    if (
      scannedData?.requirements.photo_id === true ||
      scannedData?.requirements.front_plates === true ||
      scannedData?.requirements.back_plates === true ||
      scannedData?.requirements.other === true ||
      scannedData?.requirements.accreditation === true
    ) {
      const photos: {
        id?: string;
        other?: string;
        back_plates?: string;
        front_plates?: string;
        accreditation?: string;
      } = {};
      if (scannedData?.requirements.photo_id === true && qrIdPhoto) {
        photos.id = qrIdPhoto;
      }
      if (
        scannedData?.requirements.front_plates === true &&
        qrFrontPlatePhoto
      ) {
        photos.front_plates = qrFrontPlatePhoto;
      }
      if (scannedData?.requirements.back_plates === true && qrBackPlatePhoto) {
        photos.back_plates = qrBackPlatePhoto;
      }
      if (scannedData?.requirements.other === true && qrOtherPhoto) {
        photos.other = qrOtherPhoto;
      }
      if (
        scannedData?.requirements.accreditation === true &&
        qrAccreditationPhoto
      ) {
        photos.accreditation = qrAccreditationPhoto;
      }
      payload.photos = photos;
    }
    if (accessAllow === false) {
      payload.notes = reason;
    }
    if (accessAllow === true && qrNotes) {
      payload.notes = qrNotes;
    }
    if (registerCustomAttributes) {
      const customAttributes: any = {};
      registerCustomAttributes.forEach(
        ({ attribute_name, attribute_type, value }: any) => {
          customAttributes[attribute_name] =
            attribute_type === "date"
              ? moment(value).format("DD-MM-YYYY")
              : value;
        }
      );
      payload.custom_attrs = customAttributes;
    }
    try {
      setIsLoading(true);
      const response = await api.registerVisitRequest(payload);
      setNewRegister(true);
      resetQrValues();
      if (response.print_data) {
        setIsLoading(true);
        setPrintPhotoModal(true);
        if (response.print_data?.visitor_name) {
          setRegisterVisitorName(response.print_data?.visitor_name);
        }
        if (response.print_data?.created_at) {
          setRegisterAccessTime(response.print_data?.created_at);
        }
        if (response.print_data?.user_name) {
          setRegisterHostName(response.print_data?.user_name);
        }
        if (response.print_data?.user_network_group) {
          setRegisterDepartment(response.print_data.user_network_group);
        }
        if (response.print_data?.photo_url) {
          setRegisterImageUrl(response.print_data.photo_url);
        }
      } else {
        if (
          response?.transaction?.access_granted === true &&
          response?.transaction?.access_allowed === true &&
          filteredQrGate?.remote_pulse === true
        ) {
          const openGateParams: OpenGateParams = {
            id: response?.transaction?.id,
          };
          await api.openGate(openGateParams);
        }
        setNewRegister(true);
        resetQrValues();
      }
    } catch (error) {
      console.error(error);
      showError(dispatch, labels.generic_error);
    } finally {
      setIsLoading(false);
    }
  };

  const qrDeniedAccess = () => {
    setAccessAllow(false);
    setShowAccessDeniedQrModal(true);
  };

  const onCloseRegisterInvitation = () => {
    setInvKey("");
    setInvDepartment(null);
    setInvHost(null);
    setInvLastTransactionType(null);
    setInvGuest("");
  };

  React.useEffect(() => {
    resetQrValues();
    resetManualValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerType]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        borderLeftColor: "#d9d9d9",
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderRightColor: "#d9d9d9",
        borderRightStyle: "solid",
        borderRightWidth: 1,
      }}
    >
      {invKey ? (
        <div
          style={{
            width: "100%",
            height: tabsHeight,
            backgroundColor: "#d9d9d9",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <p
            style={{
              fontSize: 18,
              fontWeight: registerType === "manual" ? "500" : "normal",
            }}
          >
            {labels.invitation_register}
          </p>
          <div
            style={{ cursor: "pointer" }}
            onClick={onCloseRegisterInvitation}
          >
            <CloseIcon />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: tabsHeight,
            backgroundColor: "#d9d9d9",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50%",
              height: tabsHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                registerType === "manual" ? "#e8e8e8" : "#d9d9d9",
              cursor: "pointer",
            }}
            onClick={() => setRegisterType("manual")}
          >
            <p
              style={{
                fontSize: 18,
                fontWeight: registerType === "manual" ? "500" : "normal",
              }}
            >
              {labels.manual_register}
            </p>
          </div>
          <div
            style={{
              width: "50%",
              height: tabsHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: registerType === "qr" ? "#e8e8e8" : "#d9d9d9",
              cursor: "pointer",
            }}
            onClick={() => setRegisterType("qr")}
          >
            <p
              style={{
                fontSize: 18,
                fontWeight: registerType === "qr" ? "500" : "normal",
              }}
            >
              {labels.qr_register}
            </p>
          </div>
        </div>
      )}
      {invKey ? (
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${tabsHeight}px)`,
            boxSizing: "border-box",
          }}
        >
          <RegisterQrDesktop
            invitationRegister={true}
            invKey={invKey}
            lastTransactionType={invLastTransactionType}
            children={
              <Paper
                component="form"
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  padding: 1.8,
                }}
              >
                <p className="primary-color font-18 medium mt-0">{invGuest}</p>
                <p className="m-0 p-0 subtitle-color">
                  {labels.destination}:{" "}
                  <span style={{ color: "black" }}>{invDepartment}</span>
                </p>
                <p className="m-0 p-0 mt-2 subtitle-color">
                  {labels.host}:{" "}
                  <span style={{ color: "black" }}>{invHost}</span>
                </p>
              </Paper>
            }
            setDoorId={setQrDoorId}
            setQrKey={setQrKey}
            idPhoto={qrIdPhoto}
            setIdPhoto={setQrIdPhoto}
            frontPlatesPhoto={qrFrontPlatePhoto}
            setFrontPlatesPhoto={setQrFrontPlatePhoto}
            backPlatesPhoto={qrBackPlatePhoto}
            setBackPlatesPhoto={setQrBackPlatePhoto}
            otherPhoto={qrOtherPhoto}
            setOtherPhoto={setQrOtherPhoto}
            accreditationPhoto={qrAccreditationPhoto}
            setAccreditationPhoto={setQrAccreditationPhoto}
            notes={qrNotes}
            setNotes={setQrNotes}
            registerCustomAttributes={registerCustomAttributes}
            setRegisterCustomAttributes={setRegisterCustomAttributes}
            buttonConfirmLoading={qrConfirmLoading}
            buttonConfirmAction={generateQrRegister}
            buttonDeniedAction={qrDeniedAccess}
            buttonNotAuthorizedAction={generateQrRegister}
            scannedData={scannedData}
            setScannedData={setScannedData}
          />
        </div>
      ) : (
        <>
          {registerType === "manual" && (
            <div
              style={{
                width: "100%",
                height: `calc(100% - ${tabsHeight}px)`,
                boxSizing: "border-box",
              }}
            >
              <RegisterManualDesktop
                setDoorId={setManualDoorId}
                name={manualRegisterName}
                setName={setManualRegisterName}
                host={manualRegisterHost}
                setHost={setManualRegisterHost}
                reason={manualRegisterReason}
                setReason={setManualRegisterReason}
                remark={manualRegisterRemark}
                setRemark={setManualRegisterRemark}
                department={manualDepartment}
                setDepartment={setManualDepartment}
                idPhoto={manualIdPhoto}
                setIdPhoto={setManualIdPhoto}
                frontPlatesPhoto={manualFrontPlatePhoto}
                setFrontPlatesPhoto={setManualFrontPlatePhoto}
                backPlatesPhoto={manualBackPlatePhoto}
                setBackPlatesPhoto={setManualBackPlatePhoto}
                otherPhoto={manualOtherPhoto}
                setOtherPhoto={setManualOtherPhoto}
                accreditationPhoto={manualAccreditationPhoto}
                setAccreditationPhoto={setManualAccreditationPhoto}
                registerCustomAttributes={registerCustomAttributes}
                setRegisterCustomAttributes={setRegisterCustomAttributes}
                registerButtonAction={() => setShowDetailsModal(true)} //---------------------------------------------------------------->>
                loadingButton={loadingButton}
                transactionId={transactionId}
                setTransactionId={setTransactionId}
                onClickExit={generateExitRegister}
                onClickExitAccessKey={generateQrRegister}
                gateId={Number(manualDoorId)}
                providerManualKey={providerManualKey}
                setProviderManualKey={setProviderManualKey}
                scannedData={scannedData}
                setScannedData={setScannedData}
                buttonConfirmLoading={qrConfirmLoading}
                buttonConfirmAction={generateQrRegister}
                buttonDeniedAction={qrDeniedAccess}
                buttonNotAuthorizedAction={generateQrRegister}
              />
            </div>
          )}
          {registerType === "qr" && (
            <div
              style={{
                width: "100%",
                height: `calc(100% - ${tabsHeight}px)`,
                boxSizing: "border-box",
              }}
            >
              <RegisterQrDesktop
                invitationRegister={false}
                setDoorId={setQrDoorId}
                setQrKey={setQrKey}
                idPhoto={qrIdPhoto}
                setIdPhoto={setQrIdPhoto}
                frontPlatesPhoto={qrFrontPlatePhoto}
                setFrontPlatesPhoto={setQrFrontPlatePhoto}
                backPlatesPhoto={qrBackPlatePhoto}
                setBackPlatesPhoto={setQrBackPlatePhoto}
                otherPhoto={qrOtherPhoto}
                setOtherPhoto={setQrOtherPhoto}
                accreditationPhoto={qrAccreditationPhoto}
                setAccreditationPhoto={setQrAccreditationPhoto}
                notes={qrNotes}
                setNotes={setQrNotes}
                registerCustomAttributes={registerCustomAttributes}
                setRegisterCustomAttributes={setRegisterCustomAttributes}
                buttonConfirmLoading={qrConfirmLoading}
                buttonConfirmAction={generateQrRegister}
                buttonDeniedAction={qrDeniedAccess}
                buttonNotAuthorizedAction={generateQrRegister}
                scannedData={scannedData}
                setScannedData={setScannedData}
              />
            </div>
          )}
        </>
      )}
      {/* REGISTER DETAILS MODAL------------------------------------->>*/}
      {/* MODAL DETALLES DEL REGISTRO-------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showDetailsModal}>
        <Box sx={style}>
          <div className="m-0 p-0">
            <Grid container className="vertical-spacing-4">
              <Grid
                item
                xs={12}
                className="flex justify-content-space-between align-items-center m-0 p-0"
              >
                <p className="title-color medium uppercase font-16 ml-5 my-1">
                  {labels.register_details}
                </p>
                <IconButton
                  className="my-1"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} className="m-0 p-0">
                <Divider className="mb-5" />
              </Grid>
            </Grid>
          </div>
          <div className="m-0 p-0">
            <Grid container className="vertical-spacing-4">
              <Grid item xs={12}>
                <p className="title-color font-14 ml-5 mb-3 mt-0">
                  {labels.visit}:
                </p>
                <p className="subtitle-color font-24 ml-5 my-3 bold">
                  {manualRegisterName}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className="title-color font-14 ml-5 my-3">
                  {labels.department}:
                </p>
                <p className="subtitle-color font-20 ml-5 my-3 medium">
                  {manualDepartment}
                </p>
              </Grid>
              {/* <Grid
                item
                xs={12}
                className="m-0 py-3 background-border-grey flex justify-content-space-between align-items-center"
              >
                <div>
                  <p className="title-color font-14 ml-5 mt-3 mb-1">
                    {labels.host}:
                  </p>
                  <p className="subtitle-color font-16 ml-5 mt-1 mb-3 medium">
                    {
                      usersInDepartment.find(
                        ({ id }) => id === Number(personWhoVisit)
                      )?.name
                    }
                  </p>
                </div>
                <a
                  href={`tel:${
                    usersInDepartment.find(
                      ({ id }) => id === Number(personWhoVisit)
                    )?.phone
                  }`}
                >
                  <IconButton className="call-icon-button-style-button mr-5">
                    <CallIcon className="call-icon-button-style-icon" />
                  </IconButton>
                </a>
              </Grid> */}
              <Grid item xs={12} className="m-0 p-0">
                <p className="title-color font-14 ml-5 mt-5 mb-1">
                  {labels.access_point}:
                </p>
                <p className="subtitle-color font-16 ml-5 mt-1 mb-3 medium">
                  {user?.last_access_selected?.name}
                </p>
                <p className="title-color font-14 ml-5 mt-5 mb-1">
                  {labels.visit_reason}:
                </p>
                <p className="subtitle-color font-14 ml-5 mt-1 mb-3">
                  {manualRegisterReason}
                </p>
                {manualRegisterRemark && (
                  <>
                    <p className="title-color font-14 ml-5 mt-5 mb-1">
                      {labels.notes}:
                    </p>
                    <p className="subtitle-color font-14 ml-5 mt-1 mb-3">
                      {manualRegisterRemark}
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="m-0 p-0">
            <Grid container>
              <Grid item xs={12} className="m-0 p-0">
                <Divider />
              </Grid>
              <Grid item xs={12} className="flex justify-content-center">
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={generateManualRegister}
                  className="py-5 mt-8 mb-3"
                  size="large"
                  disabled={loadingButton}
                >
                  {loadingButton ? (
                    <CircularProgress size={26.5} />
                  ) : (
                    labels.confirm_access
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} className="flex justify-content-center">
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    setAccessAllow(false);
                    setShowDetailsModal(false);
                    setShowAccessDeniedModal(true);
                  }}
                  className="py-5 mb-8 mt-3"
                  size="large"
                  disabled={loadingButton}
                  color="error"
                >
                  {loadingButton ? (
                    <CircularProgress size={26.5} />
                  ) : (
                    labels.deny_access
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
      {/* ACCESS DENIED MODAL---------------------------------------->>*/}
      {/* MODAL ACCESO DENEGADO-------------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showAccessDeniedModal}>
        <Box sx={styleMotiveModal}>
          <Grid container className="vertical-spacing-4">
            <Grid
              item
              xs={12}
              className="flex justify-content-flex-end align-items-center m-0 p-0"
            >
              <IconButton
                className="my-1"
                onClick={() => {
                  setShowAccessDeniedModal(false);
                  setShowDetailsModal(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className="w-100 align-items-center justify-content-center vertical-spacing-5"
          >
            <Grid item xs={12}>
              <TextField
                value={reason}
                id="observation"
                label={labels.reason}
                fullWidth
                multiline
                rows={3}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Grid>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                generateDeniedRegister();
                setShowAccessDeniedModal(false);
              }}
              disabled={disabledDeniedButton}
            >
              {labels.continue}
            </Button>
          </Grid>
        </Box>
      </Modal>
      {/* ACCESS DENIED MODAL QR---------------------------------------->>*/}
      {/* MODAL ACCESO DENEGADO QR-------------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showAccessDeniedQrModal}>
        <Box sx={styleMotiveModal}>
          <Grid container className="vertical-spacing-4">
            <Grid
              item
              xs={12}
              className="flex justify-content-flex-end align-items-center m-0 p-0"
            >
              <IconButton
                className="my-1"
                onClick={() => {
                  setShowAccessDeniedQrModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className="w-100 align-items-center justify-content-center vertical-spacing-5"
          >
            <Grid item xs={12}>
              <TextField
                value={reason}
                id="observation"
                label={labels.reason}
                fullWidth
                multiline
                rows={3}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Grid>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                generateQrDeniedRegister();
                setShowAccessDeniedQrModal(false);
              }}
              disabled={disabledDeniedButton}
            >
              {labels.continue}
            </Button>
          </Grid>
        </Box>
      </Modal>
      {/* SUCCESS REGISTER MODAL------------------------------------->>*/}
      {/* MODAL REGISTRO EXITOSO------------------------------------->>*/}
      <Modal disableEscapeKeyDown open={showSuccessModal}>
        <Box sx={style}>
          <Grid
            container
            className="flex flex-dir-col align-items-center justify-content-center vertical-spacing-5"
          >
            <p className="subtitle-color font-20 my-5">
              {accessAllow ? labels.success_register : labels.access_denied}
            </p>
          </Grid>
        </Box>
      </Modal>
      <PrintLabelModal
        openModal={printPhotoModal}
        setOpenModal={setPrintPhotoModal}
        visitorName={registerVisitorName}
        visitorLastName={registerVisitorLastname}
        accessTime={finalRegisterAccessTime}
        hostName={registerHostName}
        department={registerDepartment}
        imageUrl={registerImageUrl}
        isDesktop={true}
      />
    </div>
  );
};

export default RegistersDesktopGuard;
