import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectUser } from "../../slices/user";
import RegisterManualAccessDesktop from "../RegisterManualAccessDesktop";
import RegisterManualExitDesktop from "../RegisterManualExitDesktop";
import { FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import SearchInputHeader from "../SearchInputHeader";
import { ValidateKeyResponse } from "../../api";

interface RegisterManualDesktopProps {
  setDoorId: (doorId: string) => void;
  name: string;
  setName: (name: string) => void;
  host: string;
  setHost: (host: string) => void;
  reason: string;
  setReason: (reason: string) => void;
  remark: string;
  setRemark: (remark: string) => void;
  department: string | null;
  setDepartment: (department: string | null) => void;
  idPhoto: string | null;
  setIdPhoto: (idPhoto: string | null) => void;
  frontPlatesPhoto: string | null;
  setFrontPlatesPhoto: (platePhoto: string | null) => void;
  backPlatesPhoto: string | null;
  setBackPlatesPhoto: (personPhoto: string | null) => void;
  otherPhoto: string | null;
  setOtherPhoto: (otherPhoto: string | null) => void;
  accreditationPhoto: string | null;
  setAccreditationPhoto: (accreditationPhoto: string | null) => void;
  registerCustomAttributes: any | null;
  setRegisterCustomAttributes: (registerCustomattributes: any | null) => void;
  registerButtonAction: () => void;
  loadingButton: boolean;
  transactionId: number | null;
  setTransactionId: (transactionId: number | null) => void;
  onClickExit: () => void;
  onClickExitAccessKey: () => void;
  gateId: number;
  providerManualKey: string;
  setProviderManualKey: (providerManualKey: string) => void;
  scannedData: ValidateKeyResponse | null;
  setScannedData: (scannedData: ValidateKeyResponse | null) => void;
  buttonConfirmLoading: boolean;
  buttonConfirmAction: () => void;
  buttonDeniedAction: () => void;
  buttonNotAuthorizedAction: () => void;
}

const RegisterManualDesktop = ({
  setDoorId,
  name,
  setName,
  host,
  setHost,
  reason,
  setReason,
  remark,
  setRemark,
  department,
  setDepartment,
  idPhoto,
  setIdPhoto,
  frontPlatesPhoto,
  setFrontPlatesPhoto,
  backPlatesPhoto,
  setBackPlatesPhoto,
  otherPhoto,
  setOtherPhoto,
  accreditationPhoto,
  setAccreditationPhoto,
  registerCustomAttributes,
  setRegisterCustomAttributes,
  registerButtonAction,
  loadingButton,
  transactionId,
  setTransactionId,
  onClickExit,
  onClickExitAccessKey,
  gateId,
  providerManualKey,
  setProviderManualKey,
  scannedData,
  setScannedData,
  buttonConfirmAction,
  buttonConfirmLoading,
  buttonDeniedAction,
  buttonNotAuthorizedAction,
}: RegisterManualDesktopProps) => {
  const user = useAppSelector(selectUser);

  const [accessType, setAccessType] = React.useState("entrance");
  const [accessDoor, setAccessDoor] = React.useState<string | undefined>(
    `${
      user?.last_access_selected?.gates.filter(
        ({ type }) => type === "access"
      )[0]?.id
    }`
  );
  const [containerHeight, setContainerHeight] = React.useState<number>(0);
  const [searchInput, setSearchInput] = React.useState<string>("");

  const debouncedSearchText = useDebounce(searchInput, 500);

  const headerRef: any = React.useRef(null);

  React.useEffect(() => {
    const height = headerRef.current.offsetHeight;
    setContainerHeight(height);
  }, []);

  React.useEffect(() => {
    if (accessDoor) {
      const filteredDoor = user?.last_access_selected?.gates.filter(
        ({ id }) => id === Number(accessDoor)
      )[0];
      if (filteredDoor?.type === "access") {
        setAccessType("entrance");
      }
      if (filteredDoor?.type === "exit") {
        setAccessType("exit");
      }
      setDoorId(accessDoor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessDoor, user]);

  const handleAccessDoorChange = (event: SelectChangeEvent) => {
    setAccessDoor(event.target.value);
  };

  React.useEffect(() => {
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0]?.id
      }`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.last_access_selected?.name]);

  const onButtonConfirmAction = () => {
    buttonConfirmAction();
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0]?.id
      }`
    );
  };

  const onButtonDeniedAction = () => {
    buttonDeniedAction();
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0]?.id
      }`
    );
  };

  const onButtonNotAuthorizedAction = () => {
    buttonNotAuthorizedAction();
    setAccessDoor(
      `${
        user?.last_access_selected?.gates.filter(
          ({ type }) => type === "access"
        )[0]?.id
      }`
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={headerRef}
        style={{
          width: "100%",
          boxSizing: "border-box",
          backgroundColor: "#e8e8e8",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
          paddingBottom: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottomColor: "#d9d9d9",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        }}
      >
        <div
          style={{
            width: accessType === "exit" ? "49%" : "100%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl
            fullWidth
            size="small"
            style={{ backgroundColor: "white", borderRadius: 4 }}
          >
            <Select
              labelId="select-door-label"
              value={accessDoor}
              onChange={handleAccessDoorChange}
            >
              {user?.last_access_selected?.gates.map(({ name, type, id }) => (
                <MenuItem value={id} key={id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {type === "access" ? (
                      <FaSignInAlt
                        fontSize="16px"
                        className="allowed m-0 p-0 mr-4"
                      />
                    ) : (
                      <FaSignOutAlt
                        fontSize="16px"
                        className="not-allowed m-0 p-0 mr-4"
                      />
                    )}
                    {name}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {accessType === "exit" && (
          <div
            style={{
              width: "49%",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SearchInputHeader value={searchInput} setValue={setSearchInput} />
          </div>
        )}
      </div>
      {accessType === "entrance" ? (
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containerHeight}px)`,
            boxSizing: "border-box",
          }}
        >
          <RegisterManualAccessDesktop
            name={name}
            setName={setName}
            host={host}
            setHost={setHost}
            reason={reason}
            setReason={setReason}
            remark={remark}
            setRemark={setRemark}
            department={department}
            setDepartment={setDepartment}
            idPhoto={idPhoto}
            setIdPhoto={setIdPhoto}
            frontPlatesPhoto={frontPlatesPhoto}
            setFrontPlatesPhoto={setFrontPlatesPhoto}
            backPlatesPhoto={backPlatesPhoto}
            setBackPlatesPhoto={setBackPlatesPhoto}
            otherPhoto={otherPhoto}
            setOtherPhoto={setOtherPhoto}
            accreditationPhoto={accreditationPhoto}
            setAccreditationPhoto={setAccreditationPhoto}
            registerCustomAttributes={registerCustomAttributes}
            setRegisterCustomAttributes={setRegisterCustomAttributes}
            registerButtonAction={registerButtonAction}
            loadingButton={loadingButton}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: `calc(100% - ${containerHeight}px)`,
            boxSizing: "border-box",
          }}
        >
          <RegisterManualExitDesktop
            debouncedSearchText={debouncedSearchText}
            transactionId={transactionId}
            setTransactionId={setTransactionId}
            onClickExit={onClickExit}
            onClickExitAccessKey={onClickExitAccessKey}
            notes={""}
            setNotes={function (notes: string): void {
              throw new Error("Function not implemented.");
            }}
            registerCustomAttributes={registerCustomAttributes}
            setRegisterCustomAttributes={setRegisterCustomAttributes}
            buttonConfirmLoading={buttonConfirmLoading}
            buttonConfirmAction={onButtonConfirmAction}
            buttonDeniedAction={onButtonDeniedAction}
            buttonNotAuthorizedAction={onButtonNotAuthorizedAction}
            providerManualKey={providerManualKey}
            setProviderManualKey={setProviderManualKey}
            scannedData={scannedData}
            setScannedData={setScannedData}
            gateId={gateId}
          />
        </div>
      )}
    </div>
  );
};

export default RegisterManualDesktop;
